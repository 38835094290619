import React, { useState } from "react";
import { Link } from 'react-router-dom'
import Reactselect from '../select';
import Empty from '../../atom/empty';
import EmptyImage from '../../../assets/images/myaccount/giftcard.png';
import HappyBithday from '../../../assets/images/gifts/cards/happy-bithday.png';
import Anniversary from '../../../assets/images/gifts/cards/anniversary.png';
import MothersDay from '../../../assets/images/gifts/cards/mothers-day.png';
import Diwali from '../../../assets/images/gifts/cards/diwali.png';
import './giftCard.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ImCross } from 'react-icons/im';

// let arr = []

function GiftCard(){

    const allCardData = [
        { id:1, card_type: "Buy", src:HappyBithday, card_name: "Happy Birthday", card_number: "35592 9500 4229 7569", full_name: "Kaushal Ranpura", total_amount: "10,000", balance_amount: "5,000", card_validity: "27 Jun 2023", status: "Active"},
        { id:2, card_type: "Gift", src:Anniversary, card_name: "Happy Anniversary", card_number: "38792 9556 4229 7569", full_name: "Avtar Singh", total_amount: "3,000", balance_amount: "1,000", card_validity: "21 May 2023", status: "Expired"},
        { id:3, card_type: "Buy", src:MothersDay, card_name: "Mother’s Day", card_number: "35592 9556 3469 7895", full_name: "Avtar Singh", total_amount: "5,000", balance_amount: "0", card_validity: "21 May 2023", status: "Expired"},
        { id:4, card_type: "Gift", src:Diwali, card_name: "Happy Diwali", card_number: "35592 9556 3469 7895", full_name: "Avtar Singh", total_amount: "5,000", balance_amount: "2,000", card_validity: "28 Jun 2023", status: "Active"}
    ];

    const buyCardData = allCardData.filter(item => item.card_type === 'Buy');
    const giftCardData = allCardData.filter(item => item.card_type === 'Gift');

    const transaction = [
        {id:1, transaction_date: '7 May 2023', transaction_id: '454656456464', invoice_no: '0123456789', redeemed_amount: '2500'},
        {id:2, transaction_date: '28 May 2023', transaction_id: '454656456464', invoice_no: '0123456789', redeemed_amount: '2500'}
    ]

    const RedeemedDetails = (item) => (
        <Popup trigger={<span className="redeem">Redeemed Details</span>} modal aria-describedby={"pinHome"}>
          {close => (
            <div className='terms-popup'>
                <div className='pop-title'>
                    <h2>Gift Card - Redeem Details</h2>
                    <a className="close" onClick={close}><span><ImCross className="closebtn" /></span></a>
                </div>
    
                <div className='pop-body'>
                    <div className='container inner-div'>
                        <div className="table-responsive">
                            <table className='all-card'>
                                <tbody className="redeem-detail">
                                    <td className="img">
                                        <img src={item.src} alt={item.name} title={item.name} />
                                    </td>
                                    <td className="card-name">
                                        <h4>{item.card_type} A Card</h4>
                                        <span>{item.card_name}</span>
                                    </td>
                                    <td className="card-number">
                                        <h4>Card Number</h4>
                                        <span>{item.card_number}</span>
                                    </td>
                                    <td className="full-name">
                                        <h4>Name</h4>
                                        <span>{item.full_name}</span>
                                    </td>
                                    <td className="total-amount">
                                        <h4>Total Amount</h4>
                                        <span>{item.total_amount}</span>
                                    </td>
                                    <td className="balance-amount">
                                        <h4>Balance Amount</h4>
                                        <span>{item.balance_amount}</span>
                                    </td>
                                </tbody>
                            </table>
                        </div>

                        <div className="invoiceDetails table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Transaction Date</th>
                                        <th>Transaction ID</th>
                                        <th>Invoice No.</th>
                                        <th>Redeemed Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transaction.map((item, index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td>{item.transaction_date}</td>
                                                    <td>{item.id}</td>
                                                    <td>{item.invoice_no}</td>
                                                    <td>₹ {item.redeemed_amount}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan='2'></td>
                                        <td><span>Total Redeemed Amount</span></td>
                                        <td><span>₹ 5,000</span></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>  
                </div>
            </div>
          )}
          </Popup>
      );

    // const orderCount = giftCardData.length;
    // console.log(orderCount);

    return(
            <div className="container giftcard">
            <h5>Gift Card</h5>
            {
                // arr.length ? <>
                <>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#allcard" type="button" role="tab" aria-controls="allcard" aria-selected="true">ALL</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="buy-a-card-tab" data-bs-toggle="tab" data-bs-target="#buyacard" type="button" role="tab" aria-controls="buyacard" aria-selected="false">Buy a card</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="gift-a-card-tab" data-bs-toggle="tab" data-bs-target="#giftacard" type="button" role="tab" aria-controls="giftacard" aria-selected="true">Gift a card</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane show active" id="allcard" role="tabpanel" aria-labelledby="all-card-tab">
                    <div className='row'>
                        {
                            allCardData.map((item, index) => {
                                return (
                                    <div className="table-responsive card" key={index}>
                                        <table className='all-card'>
                                            <tbody>
                                                <td className="img">
                                                    <img src={item.src} alt={item.name} title={item.name} />
                                                </td>
                                                <td className="card-name">
                                                    <h4>{item.card_type} A Card</h4>
                                                    <span>{item.card_name}</span>
                                                </td>
                                                <td className="card-number">
                                                    <h4>Card Number</h4>
                                                    <span>{item.card_number}</span>
                                                </td>
                                                <td className="full-name">
                                                    <h4>Name</h4>
                                                    <span>{item.full_name}</span>
                                                </td>
                                                <td className="total-amount">
                                                    <h4>Total Amount</h4>
                                                    <span>{item.total_amount}</span>
                                                </td>
                                                <td className="balance-amount">
                                                    <h4>Balance Amount</h4>
                                                    <span>{item.balance_amount}</span>
                                                    {RedeemedDetails(item)}
                                                </td>
                                                <td className="card-validity">
                                                    <h4>Card Validity</h4>
                                                    <span>{item.card_validity}</span>
                                                </td>
                                                <td className="status">
                                                    <h4>Status</h4>
                                                    <span className={`${item.status}`}>{item.status}</span>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </div>

                <div class="tab-pane" id="buyacard" role="tabpanel" aria-labelledby="buy-a-card-tab">
                    <div className='row'>
                        {
                            buyCardData.length ? (
                            buyCardData.map((item, index) => {
                                return (
                                    <div className="table-responsive card" key={index}>
                                        <table className='all-card'>
                                            <tbody>
                                                <td className="img">
                                                    <img src={item.src} alt={item.name} title={item.name} />
                                                </td>
                                                <td className="card-name">
                                                    <h4>{item.card_type} A Card</h4>
                                                    <span>{item.card_name}</span>
                                                </td>
                                                <td className="card-number">
                                                    <h4>Card Number</h4>
                                                    <span>{item.card_number}</span>
                                                </td>
                                                <td className="full-name">
                                                    <h4>Name</h4>
                                                    <span>{item.full_name}</span>
                                                </td>
                                                <td className="total-amount">
                                                    <h4>Total Amount</h4>
                                                    <span>{item.total_amount}</span>
                                                </td>
                                                <td className="balance-amount">
                                                    <h4>Balance Amount</h4>
                                                    <span>{item.balance_amount}</span>
                                                    {RedeemedDetails(item)}
                                                </td>
                                                <td className="card-validity">
                                                    <h4>Card Validity</h4>
                                                    <span>{item.card_validity}</span>
                                                </td>
                                                <td className="status">
                                                    <h4>Status</h4>
                                                    <span className={`${item.status}`}>{item.status}</span>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div> 
                                )
                            })
                            ) : <p> No Cards Found. </p>
                        }
                    </div>
                </div>

                <div class="tab-pane show" id="giftacard" role="tabpanel" aria-labelledby="gift-a-card-tab">
                    <div className='row'>
                        {
                            giftCardData.length ? (
                            giftCardData.map((item, index) => {
                                return (
                                    <div className="table-responsive card" key={index}>
                                        <table className='all-card'>
                                            <tbody>
                                                <td className="img">
                                                    <img src={item.src} alt={item.name} title={item.name} />
                                                </td>
                                                <td className="card-name">
                                                    <h4>{item.card_type} A Card</h4>
                                                    <span>{item.card_name}</span>
                                                </td>
                                                <td className="card-number">
                                                    <h4>Card Number</h4>
                                                    <span>{item.card_number}</span>
                                                </td>
                                                <td className="full-name">
                                                    <h4>Name</h4>
                                                    <span>{item.full_name}</span>
                                                </td>
                                                <td className="total-amount">
                                                    <h4>Total Amount</h4>
                                                    <span>{item.total_amount}</span>
                                                </td>
                                                <td className="balance-amount">
                                                    <h4>Balance Amount</h4>
                                                    <span>{item.balance_amount}</span>
                                                    {RedeemedDetails(item)}
                                                </td>
                                                <td className="card-validity">
                                                    <h4>Card Validity</h4>
                                                    <span>{item.card_validity}</span>
                                                </td>
                                                <td className="status">
                                                    <h4>Status</h4>
                                                    <span className={`${item.status}`}>{item.status}</span>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div> 
                                )
                            })
                        ) : <p> No Cards Found. </p>
                    }
                    </div>
                </div>
            </div>
            </>
                //  </> : <Empty imgsrc={EmptyImage} content={"No Gift Card Yet"} />
            }
            </div>   
    )
}
export default GiftCard;