import { Modal, ModalBody } from "reactstrap";

const TermsConditions = (props) => {

    // const [modalOpen, setModalOpen] = useState(false);

    return(
        <>
         {/* <Button
        color="primary"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Launch demo modal
      </Button> */}
      <Modal className="termsconditions" toggle={props.handleModalOpen} isOpen={props.modalOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Terms and Conditions
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={props.handleModalOpen}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
            <div className="para">
                <h6>Loreum Ipsum</h6>
                <ul className="modal-para">
                    <li>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.
                        </p>
                    </li>
                    <li>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacusat sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.</p>
                    </li>
                </ul>
            </div>
            <div className="para">
                <h6>Maecenas Vitae</h6>
                <ul className="modal-para">
                    <li>
                        <p>
                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.
                        </p>
                    </li>
                    <li>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacusat sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.
                        </p>
                    </li>
                </ul>
            </div>
        </ModalBody>
      </Modal>
    </>
        
    )
}
export default TermsConditions;