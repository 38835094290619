
const MyAccData = [
    {
        "id": "01",
        "order_placed": "12 Jan 2023",
        "total_amount": "11,600",
        "total_items": "01",
        "mode": "Pickup from Store",
        "order_no": "MAZE202471002", 
    },
    {
        "id": "02",
        "order_placed": "02 Jan 2023",
        "total_amount": "15,400",
        "total_items": "03",
        "mode": "Delivery",
        "order_no": "MAZE202471002",
    },
    {
        "id": "03",
        "order_placed": "25 Jan 2023",
        "total_amount": "16,700",
        "total_items": "03",
        "mode": "Pickup from Store",
        "order_no": "MAZE202471002",
    },
    {
        "id": "04",
        "order_placed": "20 Jan 2023",
        "total_amount": "15,400",
        "total_items": "03",
        "mode": "Delivery",
        "order_no": "MAZE202471002",
    }

]

export default MyAccData;