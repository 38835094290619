import React from 'react';
import { AiOutlineDownload } from "react-icons/ai";

const DownloadButton = ({ fileName, filePath }) => {
  const handleDownload = () => {
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  return (
    <span className="btn btn-secondary" onClick={handleDownload}> <AiOutlineDownload /> Download Card </span>
  );
};

export default DownloadButton;
