import {React,useState,useEffect} from 'react'
import { Link,redirect ,useLocation } from 'react-router-dom';
import Checkbox from '../../../components/atom/checkbox/input'
// import TextInput from  '../../components/molecules/input'
import {BiRupee} from "react-icons/bi";
import './index.css'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ImCross } from 'react-icons/im';
import {FaCaretRight} from "react-icons/fa";
import {FaCaretDown} from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { paymentGetwayAction } from '../../../redux/actions/paymentAction';
import { fetchWithWait } from '../../helper/method';
import { useDispatch, useSelector } from 'react-redux';
import Payment from '../../Checkout/payment';

let returnUrl = process.env.REACT_APP_QUICKBUY_CALLBACK_URL
let merchantIdentifier = process.env.REACT_APP_MERCHANT_IDENTIFIER


const Promocodes =[
    {id:1, code:'First10',save_value:'301',percent_off:'10',min_purchase_value:'',expire_date:'12th March 2023'},
    {id:2, code:'Summera40',save_value:'5,998',percent_off:'40',min_purchase_value:'100000',expire_date:'15th March 2023'},
    {id:3, code:'Summera20',save_value:'602',percent_off:'20',min_purchase_value:'1500',expire_date:'15th March 2023'},
    {id:4, code:'Summera30',save_value:'851',percent_off:'30',min_purchase_value:'5500',expire_date:'15th March 2023'},
    {id:5, code:'First20',save_value:'301',percent_off:'20',min_purchase_value:'',expire_date:'12th March 2023'},
    {id:6, code:'First30',save_value:'5,998',percent_off:'30',min_purchase_value:'100000',expire_date:'15th March 2023'}
]

export default function Review() {
    const location = useLocation()
    const dispatch = useDispatch();

    const { state } = location

    const { isLoading } = useSelector((state) => state.loader)


    const [checkbox,setCheckbox] = useState(true);
    const [promoModal, setPromoModal] = useState(false);
    const [promocheckbox,setPromoCheckbox] = useState(false);
    const[promoshow, setPromoshow] = useState(false);
    const [promoapply, setPromoapply] = useState(false);
    const [promo, setPromo] = useState("");
    const [active, setActive] = useState(false)


// Apply promocode show hide
const handlePromo = e =>{
    setPromoshow(!promoshow);
    setPromoModal(!promoModal)
    setPromoapply(true)
}

const validateCode = () => {
    setPromoshow(false)
    setPromoapply(true)
}

const handleRemove = () => {
    setPromoshow(false)
    setPromoapply(false)
}

const handleCheckboxChange = () => {
    setCheckbox(!checkbox)
}

const handelFormSubmit = async (e) => {
    e.preventDefault()
    const postData = {
        merchantIdentifier,
        orderId: '12345',
        currency: 'INR',
        amount:5000,
        buyerEmail: "mukesh@gmail.com",
        buyerAddress: '123 Main St',
        returnUrl
    }

    fetchWithWait({ dispatch, action: paymentGetwayAction(postData) }).then((res) => {

        if(res.status === 200 ){
            const {data, checksum, checksumstring, redirectUrl } = res

            const byteArray = new Uint8Array(checksum.words.length * 4);
            for (let i = 0; i < checksum.words.length; i++) {
                byteArray[i * 4] = (checksum.words[i] >> 24) & 0xff;
                byteArray[i * 4 + 1] = (checksum.words[i] >> 16) & 0xff;
                byteArray[i * 4 + 2] = (checksum.words[i] >> 8) & 0xff;
                byteArray[i * 4 + 3] = checksum.words[i] & 0xff;
            }

            // Convert the byte array to a hexadecimal string
            const receivedChecksum = Array.prototype.map.call(byteArray, byte => {
            return ('0' + (byte & 0xff).toString(16)).slice(-2);
            }).join('');

            const form = document.createElement('form');
            form.method = 'post';
            form.action = `${redirectUrl}`;
        
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = data[key];
                form.appendChild(input);
                }
            }
            const checkInp = document.createElement('input');
            checkInp.type = 'hidden';
            checkInp.name = "checksum";
            checkInp.value = receivedChecksum;
            form.appendChild(checkInp);
        
            document.body.appendChild(form);
            form.submit();
           
        }else{
            alert("error")
        }
        }).catch((e) => {
            console.log(`error`, e)
        })

    }

  const TermsAndConditions = () => (
    <Popup trigger={<Link>Terms and Conditions</Link>} modal aria-describedby={"pinHome"}>
      {close => (
        <div className='terms-popup'>
            <div className='pop-title'>
                <h2>Terms and Conditions</h2>
                <a className="close" onClick={close}><span><ImCross className="closebtn" /></span></a>
            </div>

            <div className='pop-body'>
                <div className='inner-div'>
                    <div className="para">
                        <h3 className="title">Lorem Ipsum is simply dummy</h3>
                        <ul>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                        </ul>
                    </div>

                    <div className="para">
                        <h3 className="title">Lorem Ipsum is simply dummy</h3>
                        <ul>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                        </ul>
                    </div>

                    <div className="para">
                        <h3 className="title">Lorem Ipsum is simply dummy</h3>
                        <ul>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                        </ul>
                    </div>     
                </div>  
            </div>
        </div>
      )}
      </Popup>
  );

  const handlePromoModal = () => {
    setPromoModal(!promoModal)
} 

const handlePromoCheck = (code) => {
    // console.log("code", code);
    // console.log("promocheckbox", promocheckbox);
    setActive(code)
    // if(value === code){
    //     console.log("code", code);
    //     console.log("value", value);
    // }
    setPromoCheckbox(!promocheckbox)
    // event.currentTarget.classList.toggle('active');
  };

  return (
    <>
    {
      !isLoading ? 
        <div className='wrapper'>
            <div className="checkout-details reviews">
                <div className="container">
                    <h2>Review</h2>
                    <div className="contact">
                        <div className='left'>
                            <div className='contact-details'>
                                <div className='review-add-details'>
                                    <h4>Company Details</h4>

                                    { state ? <ul>
                                        <li>
                                            <h6>Company Type</h6>
                                            <p>{"Manufacture"}</p>
                                        </li>
                                        <li>
                                            <h6>Company Name</h6>
                                            <p>{state["companyName"]}</p>
                                        </li>
                                        <li>
                                            <h6>Business Type</h6>
                                            <p>Private Limited Company</p>
                                        </li>
                                        <li>
                                            <h6>Website</h6>
                                            <p><a href={`${state["website"]}`} target='_blank'>{state["website"]}</a></p>
                                        </li>
                                    </ul> : null }

                                    <h4>Contact  Details</h4>
                                    {state ?<ul>
                                        <li>
                                            <h6>Name</h6>
                                            <p>{`${state["firstName"]} ${state["lastName"]}`}</p>
                                        </li>
                                        <li>
                                            <h6>Email ID</h6>
                                            <p>{state["email"]}</p>
                                        </li>
                                        <li>
                                            <h6>Mobile</h6>
                                            <p>{state["mobile"]}</p>
                                        </li>
                                    </ul> : null }

                                    <h4>Billing Address</h4>
                                    {state?<address>
                                        <span>{`${state["firstName"]} ${state["lastName"]}`}</span>
                                        {`${state["address"]} ${state["pincode"]}`}
                                    </address>: null}

                                    <ul>
                                        <li>
                                            <h6>PAN</h6>
                                            <p>{state["panNumber"]}</p>
                                        </li>
                                        <li>
                                            <h6>GSTN No.</h6>
                                            <p>{state["gstNumber"]}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="make-info">
                                <div className="checkbox">
                                    <Checkbox 
                                        autoComplete="off"
                                        id={"agree"}
                                        type={"checkbox"}
                                        name={"checkbox"}
                                        inputClass={"styled-checkbox"}
                                        onChange={handleCheckboxChange}
                                        value={checkbox}
                                        checked={true}
                                    />
                                    <label for="agree">I agree to {TermsAndConditions()} </label>
                                </div>
                                <button 
                                    className="btn btn-primary" 
                                    type="submit" 
                                    onClick={handelFormSubmit}><span>Make Payment</span></button>
                            </div>
                        </div>
                        <div className="right">
                            <div className="_border_bottom checkout-summary">
                                <h4>Membership Summary</h4>
                            </div>

                            <div className="summary-data">
                                <span className="close">&times;</span>

                                <div className="_border_bottom">
                                    <div className="custom_flex">
                                        <h6 className="cust-total">Sub Total</h6>
                                        <div className="price cust-total">₹ 3,200</div>
                                    </div>
                                </div>

                                <div className="_border_bottom">
                                    <div className="custom_flex">
                                        <h6>Discount</h6>
                                        <div className="price">-</div>
                                    </div>
                                </div>

                                <div className="_border_bottom">

                                    {!promoapply ?
                                        <div className="custom_flex custapplypromo">
                                            <h6 className='cust-total'>Apply Promo</h6>
                                            { !promoshow ? <div className="view_all" onClick={handlePromoModal}><span>View all</span> <FaCaretRight className="caret" /></div>
                                            : <div className="price"><FaCaretDown className="caret" /></div> }
                                        </div> : null
                                    }   
                                    
                                    <div className={ promoModal ? 'cart-list show' : 'cart-list'}>
                                            <div className="topheader">
                                                <h2>Apply Coupon</h2>
                                                <span className="btn-close btn-cart" onClick={handlePromoModal}><IoClose /></span>
                                            </div>
                                            <div className="cart-body">
                                                <ul className="product-details">
                                                    {
                                                        Promocodes.map((item,index)=>{
                                                            return(
                                                                <li key={index} className={`${item.code === active ? "active" : "" }`}>
                                                                    <div className='card'>
                                                                        <div className='card-body'>
                                                                            <div className='coupon'>
                                                                                <div className='checkbox'>
                                                                                    <Checkbox 
                                                                                        autoComplete="off"
                                                                                        id={item.id}
                                                                                        type={"checkbox"}
                                                                                        name={item.code}
                                                                                        inputClass={"styled-checkbox"}
                                                                                        onChange={() => handlePromoCheck(item.code)}
                                                                                        value={promocheckbox}
                                                                                    />
                                                                                    <label for={item.id} onClick={() => handlePromoCheck(item.code)}> 
                                                                                        <span className="promo-btn">{item.code}</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className='save'>
                                                                                    <h6>Save ₹ {item.save_value}</h6>
                                                                                </div>
                                                                            </div>
                                                                                { !item.min_purchase_value ? <p>Get <span>{`${item.percent_off}`}% off </span>on your first purchase. </p> : "" }
                                                                                { item.min_purchase_value ? <p>Get <span>{`${item.percent_off}`}% off </span>on minimum purchase of ₹ {item.min_purchase_value}.</p> : "" }
                                                                                <p>Expires on: {item.expire_date}</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    } 

                                                </ul>
                                                <div className="cart-footer">
                                                    <div className="total-prize">
                                                        <p>Maximum Savings</p>
                                                        <h5>₹ 5,998</h5>
                                                    </div>
                                                    <div className="cart-button">
                                                        <button className="btn btn-primary" onClick={handlePromo}><span>Apply</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div className={ promoModal ? 'bg-overlay show' : 'bg-overlay'}></div>
                                </div>

                                <div className="_border_bottom">
                                    <div className="custom_flex">
                                        <h6>GST</h6>
                                        <div className="price">₹ 32</div>
                                    </div>
                                </div>

                                <div className="_border_bottom">
                                    <div className="custom_flex">
                                        <h6 className="cust-total">Total Amount</h6>
                                        <div className="price cust-total">₹ 3,232</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        :<div className='payment'>
            <div className='container'>
                <div className='custompayment'>
                    <h2>Payment Gateway Redirect</h2>
                </div>
            </div>
        </div>
    }
    
    </>
  )
}
