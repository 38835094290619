import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import TextInput from "../../components/atom/input/input";

const RequestVideoCall = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [stdcode, setStdcode] = useState("IN 91");
    const [mobile, setMobile] = useState("");

    return (
        <div className='wrapper request-videocall'>
            <div className="container main-div">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Video Call</li>
                    </ol>
                </nav>

                <button className="btn btn-primary back-button"><span>Back</span></button>

                <div className="login-box">
                    <div className="login-wrapper">
                        <h2>Request Video Call</h2>
                        <h5>Please fill the below details.</h5>
                        <h6>* Mandatory Fields</h6>

                        <div className="form-group">
                            <label>Full Name*</label>
                            <TextInput
                                type={"text"}
                                inputClass={"form-control"}
                                autoComplete={"off"}
                                placeholder={"Enter First and Last Name"}
                            />
                        </div>

                        <li className="form-group mobile">
                            <label>Mobile Number (Whatsapp)*</label>
                            <div className="cust-select">
                            <select 
                                required="required" 
                                className="form-control form-select"
                                value={stdcode}
                                name="stdcode">
                                <option value="">Select</option>
                                {
                                <option value={stdcode}>{stdcode}</option>
                                }
                            </select>
                            <TextInput
                                type={"tel"}
                                value={mobile}
                                name={"mobile"}
                                placeholder={""}
                                inputClass={"form-control"}
                                autoComplete="off"
                                // errorMSg={errors.errMobileno}
                            />
                            
                            </div>
                        </li>

                        <div className="confirm">
                            <Link className="btn btn-primary" to={'/video-call-thank-you'}><span>Confirm Request</span></Link>
                        </div>
                    </div>  
                </div>  
            </div>
        </div>
    )
}


export default RequestVideoCall;