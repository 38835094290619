import { React, useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import Review from '../../components/molecules/ReviewCart';
import {FiHeart} from "react-icons/fi";
import {BiTrash} from "react-icons/bi";
import {BiRupee} from "react-icons/bi";
import RingsFlow from '../../assets/images/ring-prod-detail-11.jpg';
import mobile from '../../assets/images/icons/mobile.png';
import events from '../../assets/images/icons/events.png';
import subtraction from '../../assets/images/icons/subtraction.png';
// import shopping from '../../assets/images/icons/shopping_bag.png';

const ReviewCart = () =>{

    const location = useLocation(); 

    const [selected, setSelected] = useState("review_order")

    useEffect(() => {
        if(location.state === null){
            setSelected("home_cart")
        }else{
            setSelected("review_order")
        }
        // console.log("object routing location", location);
        window.scrollTo(0, 0)
      }, [])

const handleClick = event => {
    // 👇️ toggle class on click
    event.currentTarget.classList.toggle('active');
};


const handleClickbtn = (value) => {
    setSelected(value)
}

const [shopping, setShopping] = useState([
    { id:1, src:RingsFlow},
  ]);

const removeElement = (index) => {
    const newShopping = shopping.filter((_, i) => i !== index);
    setShopping(newShopping);
};


const [data, setData] = useState([
    { id:1, src:RingsFlow, alt: "Diamond Rings", name: "Diamond Rings", detail: "18 KT Yellow Gold (3.400 g)", trial: "FREE TRIAL", availability: "Available to try from Tomorrow!", price: "30,999"},
  ]);

const removeElement1 = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
};

const Order_shopping =[
    {id:1, sub_total:'Sub Total',sub_total_price:'35,999',discount:'Discount',discount_price:'5,999',saved_with_promo:'Saved with Promo',saved_price:'5,999',delivery_charges:'Delivery Charges',delivery_charges_price:'-',gst:'GST',gst_price:'18%',total_amount:'Total Amount',total_amount_price:'30,999',}
]

const Order_tryathome =[
    {id:1, free_trial:'Free Trial',free_trial_price:'0',service_charge:'Service Charge',service_charge_price:'0',total_amount:'Total Amount',total_amount_price:'0',}
]

    return(
            <div className='main-page'>
                <div className="container review_cart">
                    <h2>Review your order</h2>

                    <ul className="review-list">
                        <li className={`${selected === "review_order" ? "custom-active" : " "}`}  onClick= {() => handleClickbtn("review_order")}> 
                            Shopping Cart ({shopping.length})
                        </li>
                        <li className={`${selected === "home_cart" ? "custom-active" : " "}`} onClick={() => handleClickbtn("home_cart")}>
                           Try at Home Cart ({data.length})
                        </li>
                    </ul>

                { selected === "review_order" ?
                <ul className="review-order">
                <li className="left">

                    {   shopping.length > 0 &&
                        <ul className="order-list">

                            {
                                shopping.map((item,index)=>{
                                    return(
                                        <>
                                            <li key={index}>
                                                <ul className="product-price-counter">
                                                    <Review />
                                                    <li>
                                                        <ul className="wishlist">
                                                            <li>
                                                                <Link to={''}>
                                                                    <span className='wishlist-link'>
                                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                                    </span> Move to Wishlist
                                                                </Link>    
                                                            </li>
                                                            <li>
                                                                <span className='wishlist-link' onClick={() => removeElement(index)}>
                                                                    <BiTrash /> Remove
                                                                </span> 
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>

                                            <hr />
                                        </>
                                    )
                                })
                            }

                        </ul>
                    }    
                </li>
                <li className="right">
                    <div className="order-summary">
                        <div className="order-sum">
                            <h3>Order Summary</h3>
                        </div>

                        {
                            Order_shopping.map((item,index)=>{
                                return(

                                    <div className="summary-data" key={index}>
                                        <span className="close">&times;</span>
                                        <ul>
                                            <li>
                                                <h5>{item.sub_total}</h5>
                                                <span className="amount"><strong><BiRupee />{item.sub_total_price}</strong></span>
                                            </li>
                                            <li>
                                                {item.discount}<span className="amount"><BiRupee />{item.discount_price}</span>
                                            </li>
                                            <li>{item.delivery_charges}<span className="amount">{item.delivery_charges_price}</span></li>
                                            <li>
                                                {item.gst}<span className="amount"><BiRupee />{item.gst_price}</span>
                                            </li>
                                            <li>
                                                <h5>{item.total_amount}</h5><span className="amount"><BiRupee /><strong>{item.total_amount_price}</strong></span>
                                            </li>
                                        </ul>
                                        <div className="text-center"><Link className="btn btn-primary" to={'/login'}><span>Proceed to Checkout</span></Link></div>
                                    </div>

                                )
                            })

                        } 

                    </div>
                    <div className='text-center'>
                        <Link href="#" className="continue-link">Continue shopping</Link>
                    </div>
                    
                </li>
            </ul> : null
                }

                {
                    selected === "home_cart" ?
                    <div className="try-at-home">
                    <p className="count">Items ({data.length} / 5) Add <span>Minimum 1 & Maximum 5 designs</span></p>
                    <ul className="try-at-home-cart">
                        <li className="left">
                            
                            {   data.length > 0 &&
                                <ul className="order-list">

                                    {
                                        data.map((item,index)=>{
                                            return(
                                                <>
                                                    <li key={index}>
                                                        <ul className="product-price-counter">
                                                            <li>
                                                                <figure>
                                                                    <img src={item.src} className="card-img-top" alt={item.alt} title={item.alt} />
                                                                </figure>
                                                            </li>
                                                            <li>
                                                                <p>{item.name}</p>
                                                                <p className="size">{item.detail}</p>
                                                                <div className='try'>
                                                                    <div>
                                                                        <p className="trial">{item.trial}</p>
                                                                        <p className="available">{item.availability}</p>
                                                                    </div>                
                                                                    <div className="last-prize">
                                                                            <BiRupee />{item.price}
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <ul className="wishlist">
                                                                    <li>
                                                                        <span className='wishlist-link'>
                                                                            <FiHeart js-heart="true" onClick={handleClick} />
                                                                        </span> Move to Wishlist
                                                                    </li>
                                                                    <li>
                                                                        <span className='wishlist-link' onClick={() => removeElement1(index)}>
                                                                            <BiTrash /> Remove
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <hr />
                                                </>
                                            )
                                        })
                                    }

                                </ul>
                            }    

                        </li>
                        <li className="right">
                            <div className="order-summary">
                                <div className="order-sum">
                                    <h3>Order Summary</h3>
                                </div>

                                {
                                    Order_tryathome.map((item,index)=>{
                                        return(

                                            <div className="summary-data" key={index}>
                                                <span className="close">&times;</span>
                                                <ul>
                                                    <li>
                                                        <h5>{item.free_trial}</h5>
                                                        <span className="amount"><strong><BiRupee />{item.free_trial_price}</strong></span>
                                                    </li>
                                                    <li>
                                                        {item.service_charge}<span className="amount"><BiRupee />{item.service_charge_price}</span>
                                                    </li>
                                                    <li>
                                                        <h5>{item.total_amount}</h5><span className="amount"><BiRupee /><strong>{item.total_amount_price}</strong></span>
                                                    </li>
                                                </ul>
                                                <div className="text-center"><Link className="btn btn-primary" to={'/schedule-your-appointment'}><span>Schedule Your Appointment</span></Link></div>
                                            </div>

                                        )
                                    })
                                } 

                            </div>
                            <div className='text-center'>
                                <Link className="continue-link" to={'/product'}>Add More Designs</Link>
                                <p className='min'>Add Minimum 1 & Maximum 5 designs</p>
                            </div>
                            
                        </li>
                    </ul>

                    <div className='home-works'>
                        <h2>How Try at Home Works?</h2>

                        <p className='sub-title'>It's free and there's no obligation to buy!</p>

                        <div className='process'>
                            <div className='card'>
                                <div className='card-body'>
                                    <img src={mobile} alt="Pick your favourite designs online" title="Pick your favourite designs online" />

                                    <h5>Pick your favourite <span>designs online</span></h5>

                                    <p>Add Minimum 1 and <span>Maximum 5 designs.</span></p>
                                </div>
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <img src={events} alt="Book an appointment (It’s Free!)" title="Book an appointment (It’s Free!)" />

                                    <h5>Book an appointment <span>(It’s Free!)</span></h5>

                                    <p>Select your convenient date, <span>time and place. </span></p>
                                </div>
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <img src={subtraction} alt="Try the designs" title="Try the designs" />

                                    <h5>Try the <span>designs</span></h5>

                                    <p>Our Try at Home consultants will get you your chosen designs - at home or at work!</p>
                                </div>
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <img src={shopping} alt="Buy Only if You like" title="Buy Only if You like" />

                                    <h5>Buy Only if <span>You like</span></h5>

                                    <p>Buy on the spot! And if you don't <span>like it, there's no obligation to buy.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                     : null
                }

                </div>
            </div>     
    
    )
}


export default ReviewCart