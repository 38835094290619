import { Link } from "react-router-dom";

export const Requestsuccess = (props) => {
    return (
        <div className="reqMesg">
            <div className="top-info">
                <h2>{props?.message?.name}</h2>
            </div>
            <div className="mesgInfo">
                <h4>{props?.message?.msg}</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                <h6><Link to={'/'}>Need Help?</Link></h6>
                <div className="contactInfo">
                    <ul>
                        <li>
                            <p>Call Us:</p>
                            <Link to={'tel:9167028213'}><span>9167028213 / 14 / 15 / 16 </span></Link>
                        </li>
                        <li>
                            <p>Email Us:</p>
                            <Link to={'mailto:enquiry@summera.in'}><span>enquiry@summera.in</span></Link>
                        </li>
                    </ul>
                </div>
                <div className="conShopping">
                    <Link to={'/'} className="btn btn-conShopping">Continue Shopping</Link>
                </div>
            </div>
        </div>
    )
}

export default Requestsuccess;