import React from 'react'
import ring from '../../../assets/images/ring1.png'
import { Link } from 'react-router-dom'
import './index.css'
import { AiOutlineFilePdf } from "react-icons/ai";
import { FaTruck } from "react-icons/fa";
import { BiRupee } from 'react-icons/bi';


export default function index() {
document.body.classList.add('thankyou');    
  return (
    <div className='thankyou-wrapper membership-thankyou'>
        <div className='container'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Thank You</li>
                </ol>
            </nav>
        </div>
        <div className='d-flex'>
            <div className='inner'>
            <div className="heading">
                <div className="container">
                <h2>Thank you for your Membership Subscription</h2>
                <h4>Your Payment has been received.</h4>
                </div>
            </div>

            <div className="order-info">
                <div className="container">
                <div className="track-order">      
                    <div className="order-details">
                    <ul>
                        <li>Membership Number : <span>SJ2326562568</span></li>
                        <li>Membership Plan Status : <span>Active</span></li>
                    </ul>
                    </div>
                    <div className="track-order-details">
                    <ul>
                        <li><Link to={''} className="btn btn-secondary"><AiOutlineFilePdf /> Download Invoice</Link></li>
                    </ul>       
                    </div>
                </div>
                </div>
            </div>

            <div className="address-details">
                <div className="container">
                <div className="billing-shipping">
                    <ul>
                    <li>
                        <h4>Billing Address</h4>
                        <div className="custom-address">
                            <h6>Kaushal Ranpura</h6>
                            <address>405 A Wing MK Plaza, Ghodbunder Rd, Anand Nagar, Thane West, Thane, Maharashtra 400615</address>
                        </div>
                    </li>
                    </ul>
                </div>
                </div>
            </div>

            <div className="thank-order-summary">
                <div className="container">
                    <h4>Membership Plan Summary</h4> 
                    <div className="your-order-summary"> 
                        <div className="left">  
                            <div className='left-wrapper'>
                                <ul>
                                    <li>
                                        <div className="order-product">
                                            <div className="order_product_details">
                                                <ul>
                                                    <li>
                                                        <h4>One Time Registration Fees</h4>
                                                        <h4>Per Month Subscription Fees</h4>
                                                        <h4>Photoshoot Service for 10 Products</h4>
                                                        <h4>Additional Photoshoot Service</h4>
                                                    </li>                  
                                                </ul>
                                                <ul className="second-list">
                                                    <li><span>₹</span> 2,000</li>
                                                    <li><span>₹</span> 1,000</li>
                                                    <li>Included</li>
                                                    <li><div><span>QTY:</span> 2</div> <div><span>₹</span> 200</div></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="right">
                            <div className='order-summary'>
                                <div className="summary-data">
                                
                                    <ul>
                                        <li>
                                            <h5>Sub Total</h5>
                                            <span className="amount">₹ 3,200</span>
                                        </li>
                                        <li>
                                            Discount<span className="amount">-</span>
                                        </li>
                                        <li>
                                            GST<span className="amount">₹ 32</span>
                                        </li>
                                        <li>
                                            <h5>Total Amount</h5><span className="amount">₹ 3,232</span>
                                        </li>
                                    </ul>
                                
                                </div>
                            </div>
                            {/* <div className="summary-cancle">
                                <Link className="btn btn-primary btn-cancle" title="Link to Cancel">Cancel</Link>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}