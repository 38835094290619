import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { alphabatesWithSpace, isValidEmail, isValidMobileNo, numberText } from "../../../components/helper/method";
import TextInput from "../../../components/molecules/input";
import {Accordion,AccordionBody,AccordionHeader,AccordionItem,} from 'reactstrap';
// import Data from './data'
import { HiPlusSm } from "react-icons/hi";
import { HiMinusSm } from "react-icons/hi";
import './index.css'
// import { updateAddressAction } from "../../../redux/actions/addressAction";

const BookingInformation = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let hasError;
    const [fullName, setFullName] = useState("");
    // const [stdcode, setStdcode] = useState("IN 91");
    const stdcode = useState("IN 91");
    const [mobile, setMobile] = useState("9865890245");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [country, setCountry] = useState("India");
    const [state, setState] = useState("Maharashtra");
    const [city, setCity] = useState("Mumbai");
    const [pincode, setPincode] = useState("400615");

    const [open, setOpen] = useState('1');
    const toggle = (i) => {
        if (open === i) {
        setOpen();
        } else {
        setOpen(i);
        }
    };

    const [errors, setErrors] = useState({
        errfullName: "",
        errMobile: "",
        errEmail: "",
        errAddress1: "",
        // errAddress2: "",
        // errCountry: "",
        // errState: "",
        // errCity: "",
        // errPincode: "",
    })

    const handleStateChange = (name, value) => {
        // console.log("handle State change", name, "value =", value);

        if (name === "fullName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setFullName(check)
                handleError(name, value)
            }
        }
        if (name === "mobile") {
            let check = value
            if (numberText(check)) {
                setMobile(check)
                handleError(name, value)
            }
        }
        if (name === "email") {
            setEmail(value)
            handleError(name, value)
        }
        if (name === "address1") {
            setAddress1(value)
            handleError(name, value)
        }
        if (name === "address2") {
            setAddress2(value)
            // handleError(name, value)
        }
        if (name === "country") {
            setCountry(value)
            handleError(name, value)
        }
        if (name === "state") {
            setState(value)
            handleError(name, value)
        }
        if (name === "city") {
            setCity(value)
            handleError(name, value)
        }
        if (name === "pincode") {
            let check = value
            if (numberText(check)) {
                setPincode(check)
                handleError(name, value)
            }
        }
    }


    const handleError = (name, value) => {

        // console.log("handle OnBlure Change", name, "value", value)

        let allState = errors;

        if (name === "fullName") {
            if (!value.length) {
                allState["errfullName"] = "Enter your full name"
            } else if (value.length < 3) {
                allState["errfullName"] = "Name length should be 3 charector"
            } else {
                allState["errfullName"] = ""
            }
        }
        if (name === "email") {
            if (!value.length) {
                allState["errEmail"] = "Enter your Email"
            } else if (value.length < 3) {
                allState["errEmail"] = "Email length should be 3 charector"
            } else {
                allState["errEmail"] = ""
            }
        }
        if (name === "mobile") {
            if (!value.length) {
                allState["errMobile"] = "Enter mobile"
            } else if (value.length < 3) {
                allState["errMobile"] = "Mobile length should be 3 charector"
            } else {
                allState["errMobile"] = ""
            }
        }
        if (name === "address1") {
            if (!value.length) {
                allState["errAddress1"] = "Address 1 is required."
            } else {
                allState["errAddress1"] = ""
            }
        }
        // if (name === "country") {
        //     if (!value.length) {
        //         allState["errCountry"] = "Select Country."
        //     } else {
        //         allState["errCountry"] = ""
        //     }
        // }
        // if (name === "state") {
        //     if (!value.length) {
        //         allState["errState"] = "Select state."
        //     } else {
        //         allState["errState"] = ""
        //     }
        // }
        // if (name === "city") {
        //     if (!value.length) {
        //         allState["errCity"] = "Select city."
        //     } else {
        //         allState["errCity"] = ""
        //     }
        // }
        // if (name === "pincode") {
        //     if (!value.length) {
        //         allState["errPincode"] = "Pincode is required."
        //     } else {
        //         allState["errPincode"] = ""
        //     }
        // }
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () => {
        hasError = false;
        let allState = errors;

        if (!fullName.length) {
            allState["errfullName"] = "Enter your full name"
            hasError = true;
        } else if (fullName.length < 3) {
            allState["errfullName"] = "Name length should be 3 charector"
            hasError = true;
        } else {
            allState["errfullName"] = ""
        }
        if (!email.length) {
            allState["errEmail"] = "Enter your email"
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }
        if (!mobile.length) {
            allState["errMobile"] = "Mobile no is required."
            hasError = true
        } else if (!isValidMobileNo(mobile)) {
            allState["errMobile"] = "Enter a valid number."
            hasError = true
        } else {
            allState["errMobile"] = ""
        }
        if (!address1.length) {
            allState["errAddress1"] = "Address 1 is required."
            hasError = true
        } else {
            allState["errAddress1"] = ""
        }
        // if (!optionalAddress.length) {
        //     allState["errOptionalAddress"] = "Adress is required."
        //     hasError = true
        // } else {
        //     allState["errOptionalAddress"] = ""
        // }
        // if (!country.length) {
        //     allState["errCountry"] = "Select country."
        //     hasError = true
        // } else {
        //     allState["errCountry"] = ""
        // }
        // if (!state.length) {
        //     allState["errState"] = "Select state."
        //     hasError = true
        // } else {
        //     allState["errState"] = ""
        // }
        // if (!city.length) {
        //     allState["errCity"] = "Select city."
        //     hasError = true
        // } else {
        //     allState["errCity"] = ""
        // }
        // if (!pincode.length) {
        //     allState["errPincode"] = "Pincode is required."
        //     hasError = true
        // } else {
        //     allState["errPincode"] = ""
        // }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }

    // const handelAddAddress = (e) => {
    //     e.preventDefault()
    //     if(!checkVailidation()){
    //         let data = {
    //             id: Date.now(),
    //             type: selected,
    //             fullName: fullName,
    //             email: email,
    //             mobile: mobile,
    //             address1: address1,
    //             state: state,
    //             city: city,
    //             pincode: pincode,
    //         }

    //         dispatch(addAddressAction(data))
    //         setShowaddress(false)
    //         setChecked(false);
    //         setIsDisabled(true)
    //     }
    // }

    const handelFormUpdate = (e) => {
        e.preventDefault()
        if(!checkVailidation()){
            
            // let data = {
                // id: id,
                // type: selected,
            //     fullName: fullName,
            //     email: email,
            //     mobile: mobile,
            //     address1: address1,
            //     country: country,
            //     state: state,
            //     city: city,
            //     pincode: pincode
            // }

            // dispatch(updateAddressAction(data))
        }
    }


    return (
        <div className='wrapper booking-information'>
            <div className="details">
                <div className="container">
                    <h2>Booking Information</h2>
                    <div className="contact">

                        <div className='left'>
                            <div className="booking-details">
                                <ul className='change'>
                                    <li><p>Tuesday, Feb 07, 2023 - 12:00 PM</p></li>
                                    <li><p>2 Designs</p></li>
                                    <li><Link className="btn btn-primary back-button" to={'/schedule-your-appointment'}><span>Change</span></Link></li>
                                </ul>

                                <h6 className='mandatory'>* Mandatory Fields</h6>
                                
                                <div className="form-group">
                                    <label>Full Name*</label>
                                    <TextInput
                                        type={"text"}
                                        name={"fullName"}
                                        inputClass={"form-control"}
                                        placeholder={"Enter First and Last Name"}
                                        value={fullName}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        errorMSg={errors.errfullName}
                                    />
                                </div>

                                <div className="select-group">

                                    <li className="form-group">
                                        <label>Email ID*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"email"}
                                            inputClass={"form-control"}
                                            value={email}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            errorMSg={errors.errEmail}
                                        />
                                    </li>

                                    <li className="form-group mobile">
                                        <label>Mobile*</label>
                                        <div className="cust-select">
                                        <select 
                                            required="required" 
                                            className="form-control form-select"
                                            value={stdcode}
                                            name="stdcode" 
                                            disabled>
                                            <option value="">Select</option>
                                            {
                                            <option value={stdcode}>{stdcode}</option>
                                            }
                                        </select>
                                        <TextInput
                                            type={"tel"}
                                            value={mobile}
                                            name={"mobile"}
                                            placeholder={""}
                                            inputClass={"form-control"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            errorMSg={errors.errMobile}
                                            disabled={true}
                                        />
                                        
                                        </div>
                                    </li>

                                    <h6>Your Address*</h6>

                                    <li className="form-group">
                                        <label>Address 1*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"address1"}
                                            inputClass={"form-control"}
                                            value={address1}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            errorMSg={errors.errAddress1}
                                        />
                                    </li>

                                    <li className="form-group">
                                        <label>Address 2*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"address2"}
                                            inputClass={"form-control"}
                                            value={address2}
                                            onChange={handleStateChange}
                                        />
                                    </li>

                                    <li className="form-group">
                                        <label>Country</label>
                                        <TextInput
                                            type={"text"}
                                            name={"country"}
                                            inputClass={"form-control"}
                                            value={country}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            disabled={true}
                                            // errorMSg={errors.errCountry}
                                        />
                                    </li>

                                    <li className="form-group">
                                        <label>State*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"state"}
                                            inputClass={"form-control"}
                                            value={state}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            disabled={true}
                                            // errorMSg={errors.errState}
                                        />
                                    </li>

                                    <li className="form-group">
                                        <label>City*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"city"}
                                            inputClass={"form-control"}
                                            value={city}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            disabled={true}
                                            // errorMSg={errors.errCity}
                                        />
                                    </li>

                                    <li className="form-group">
                                        <label>Pincode*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"pincode"}
                                            inputClass={"form-control"}
                                            value={pincode}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            disabled={true}
                                            // errorMSg={errors.errPincode}
                                        />
                                    </li>

                                </div>    

                            </div>
                            <div className="customise">
                                    <Link className="btn btn-primary" to={'/thankyou-for-tryathome'} onClick={handelFormUpdate}><span>Book Now</span></Link>
                                    {/* <button className="btn btn-primary" type="submit" onClick={handelFormUpdate}>Book Now</button> */}
                            </div>
                        </div>

                        <div className="right">
                            <div className="faq-accordion">
                                <h5>FAQs</h5>
                                
                            <Accordion open={open} toggle={toggle}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">Lorem Ipsum is simply dummy text of the printing?
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="2">Lorem Ipsum is simply dummy text of ?
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="3">Lorem Ipsum is simply dummy text of ?
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="4">Lorem Ipsum is simply dummy text of the printing? 
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="5">Lorem Ipsum is simply dummy text of the printing? 
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
    </div>
    )
}


export default BookingInformation;