import React from 'react';
import { Link } from 'react-router-dom';
import Input from '../radio'
import Checkbox from '../checkbox'
import { FiRefreshCw } from "react-icons/fi";

const GoldSideNav = ({state}) => {

    const [showprice, setShowprice] = React.useState();
    const [showsize, setShowsize] = React.useState();
    const [showgifts, setShowgifts] = React.useState();
    const [showbrand, setShowbrand] = React.useState();

    return (
        <ul className='sideNav'>
            <li>
            
                <div className='filter-toggle'>Filter By
                    <Link to={''} className='clearAll'><FiRefreshCw /> Clear All</Link>
                </div>
            
            </li>
            
            <li>
                <ul className='filter_lbl'>
                    <li>
                        <ul>
                            <li><h2>Price</h2></li>
                            <div className={`mycheck ${showprice ? "open" : ""}`}>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 0 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 10,000 </span>
                                                <span>(30)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 10,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 20,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                            <Input name="radio" />
                                                <span>₹ 20,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 30,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 30,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 40,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 40,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 50,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 10,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 20,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                            <Input name="radio" />
                                                <span>₹ 20,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 30,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 30,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 40,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 40,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 50,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                            </div>
                        </ul>
                        <Link to='#' className='knowMore' onClick={() => setShowprice(true)}>More Price</Link>
                    </li>

                    {state === 'Cities' ? <li>
                        <ul>
                            <li><h2>Cities</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range" />
                                            <span>Mumbai</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range2" />
                                            <span>Delhi</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range3" />
                                            <span>Jaipur</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range4" />
                                            <span>Banglore</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range4" />
                                            <span>Patna</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>:null}

                    <li>
                        <ul>
                            <li><h2>Weight range</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range" />
                                            <span>0 - 2 gm
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range2" />
                                            <span>2 - 5 gm 
                                                <span>(29)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range3" />
                                            <span>5 - 10 gm 
                                                <span>(16)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range4" />
                                            <span>10 gm and above 
                                                <span>(49)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Size</h2></li>
                            <div className={`mycheck ${showsize ? "open" : ""}`}>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="size" />
                                                <span>17
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="size2" />
                                                <span>18 
                                                    <span>(29)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="size3" />
                                                <span>19 
                                                    <span>(16)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="size4" />
                                                <span>20 
                                                    <span>(49)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="size5" />
                                                <span>21 
                                                    <span>(55)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="size3" />
                                            <span>19 
                                                <span>(16)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="size4" />
                                            <span>20 
                                                <span>(49)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="size5" />
                                            <span>21 
                                                <span>(55)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            </div>
                        </ul>
                        <Link to={''} className='knowMore' onClick={() => setShowsize(true)}>More Size</Link>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Available in</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="avail-in18" />
                                            <span>18Kt Gold
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="avail-in22" />
                                            <span>22Kt Gold 
                                                <span>(29)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="avail-in24" />
                                            <span>24kt Gold 
                                                <span>(16)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Shop for</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="radio" />
                                            <span>Women
                                                <span>(295)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="radio" />
                                            <span>Men 
                                                <span>(29)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="radio" />
                                            <span>Kids 
                                                <span>(55)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Collections</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="collection" />
                                            <span>17
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="collection" />
                                            <span>17
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="collection" />
                                            <span>17
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="collection" />
                                            <span>17
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="collection" />
                                            <span>17
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Theme</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Floral
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Quirky
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Essentials
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Christmas
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Cut Out
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Gifts</h2></li>
                            <div className={`mycheck ${showgifts ? "open" : ""}`}>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Birthday Gifts
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Annivarsary Gifts
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Gifts for Family
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Gifts for Friends
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Gifts for Wife
                                                    <span>(95)</span>
                                                </span>
                                                
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Gifts for Family
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Gifts for Friends
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Gifts for Wife
                                                <span>(95)</span>
                                            </span>
                                            
                                        </span>
                                    </label>
                                </div>
                            </li>
                            </div>
                        </ul>
                        <Link to={''} className='knowMore' onClick={() => setShowgifts(true)}>More Gifts</Link>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Discounts</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Upto 15% off
                                                <span>(95)</span>
                                            </span>
                                            
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Flat 10% on MRP</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Flat 20% on Making Charges
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Flat 10% on MRP 
                                                <span>(95)</span>
                                            </span>
                                            
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Store Brands</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Anil Jewel Paradise</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Batukbhai Sons Jewellers</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Bella Pierre India</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Bharat Jewellers</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Damordas Jewellers</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                        <Link to={''} className='knowMore' onClick={() => setShowbrand(true)}>More Store Brands</Link>
                    </li>
                </ul>
            </li>
        </ul>
    );
};

export default GoldSideNav