import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { GrFormClose } from 'react-icons/gr';
import videoCall from '../../../assets/images/video-call.png';


const TryAtHome = () => {
  
    const VideoCallPopup = () => (
        <Popup trigger={<button className="btn btn-secondary">Video Call</button>} modal aria-describedby={"pinHome"}>
      {close => (
          <div className='pop-body'>
            <span className="close" onClick={close}>
              <span><GrFormClose className="closebtn" /></span>
            </span>

            <h2>Video Call At Your Convenience</h2>
            <img src={videoCall} alt="Schedule a Video Call" title="Schedule a Video Call" />

            <Link className="btn btn-primary" to={'/request-video-call'} onClick={close}><span>Schedule a Video call</span></Link>
            
          </div>
      )}
      </Popup>
      );
  
  return (
    VideoCallPopup()
  )
};
export default TryAtHome;