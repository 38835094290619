import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';
import error from '../../assets/images/error.svg'; 

const NotFound = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wrapper not-found'>
            <div className="container">

                <section className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Error</li>
                            </ol>
                        </nav>

                        <div className="main">

                            <ul className="error">
                                <li>
                                    <div className="img">
                                        <img src={error} alt="404 Error" title="404 Error" />
                                    </div>
                                </li>
                                <li>
                                    <div className="content">
                                        <h3>Oops!</h3>
                                        <p className="not-found">Error 404 - Page Not Found</p>
                                        <p className="check">Following are few easy links you may want to check</p>

                                        <div className="details">
                                            <Link className="btn btn-secondary" to={'/'}> Home </Link>
                                            <Link className="btn btn-secondary" to={'/'}> Gold</Link>
                                            <Link className="btn btn-secondary" to={'/diamond'}> Diamond</Link>
                                            <Link className="btn btn-secondary" to={'/'}> Solitaire</Link>
                                            <Link className="btn btn-secondary" to={'/'}> Gemstones</Link>
                                            <Link className="btn btn-secondary" to={'/'}> Platinum </Link>
                                            <Link className="btn btn-secondary" to={'/'}> Silver</Link>
                                            <Link className="btn btn-secondary" to={'/'}> Coins / Bar</Link>
                                            <Link className="btn btn-secondary" to={'/'}> Occassion</Link>
                                            <Link className="btn btn-secondary" to={'/customised'}> Customised</Link>
                                            <Link className="btn btn-secondary" to={'/'}> Gifts</Link>
                                        </div>

                                        <hr />

                                        <h5>Need Help?</h5>

                                        <ul className="help">
                                            <li>
                                                <h6>Call Us:</h6>
                                                <Link className="btn" href="tel:917770007841"> +91 77700 07841</Link>, <Link className="btn" href="tel:918291890911">+91 82918 90911</Link>
                                            </li>
                                            <li>
                                                <h6>Email Us:</h6>
                                                <Link className="btn" href="mailto:enquiry@summera.in"> enquiry@summera.in </Link>
                                            </li>
                                        </ul>

                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}


export default NotFound;