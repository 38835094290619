import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import whoarewe from '../../assets/images/who_are_we.png';
import SummeraPromise from '../SummeraPromise';
import mission from '../../assets/images/mission.png';
import get_an_offer from '../../assets/images/icons/get_an_offer.png';
import online_offer from '../../assets/images/icons/online_offer.png';
import assured_quality from '../../assets/images/icons/assured_quality.png';

const AboutSummera = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wrapper about-summera'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>About Summera</h1>
                </div>
            </div>

            <div className="container">

                <div className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">About Summera</li>
                            </ol>
                        </nav>

                        <div className="main">

                            <section className='who-we-are'>
                                <ul>
                                    <li>
                                        <h5>Who We are</h5>
                                        <p>Summera Fine Diamond Jewelry was born out of a passion
                                        for precious stones, driven by the vision to make them
                                        attainable to all. We diligently source exquisite
                                        gemstones and diamonds that meet international standards
                                        of cut, color, and clarity. Keeping a close eye on
                                        market trends, we craft inspiring collections that cater
                                        to every taste. Our design process involves meticulous
                                        hand-sketching to curate pieces that harmoniously
                                        complement our collection narratives, while also
                                        accommodating personalized custom design requests we
                                        receive from our valued customers.</p>
                                    </li>
                                    <li>
                                        <img src={whoarewe} alt="Who We are" title="Who We are" />
                                    </li>
                                </ul>
                            </section>

                            <section className='summera-promise spacer'>
                                <h2>The Summera Promise</h2>
                                <div className='container'>
                                    <SummeraPromise />
                                </div>
                            </section>

                            <section className='mission'>
                                <div className='container'>
                                    <ul>
                                        <li>
                                            <img src={mission} alt="Mission" title="Mission" />
                                        </li>
                                        <li>
                                            <h5>Mission</h5>
                                            <p className='sub-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including of Lorem Ipsum.</p>
                                        </li>
                                    </ul>
                                </div>
                            </section>

                            <section className='best-feature'>
                                <div className='container'>
                                    <h2>Our Best Feature</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                
                                    <div className='services'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <img src={get_an_offer} alt="Get On Offer Price" title="Get On Offer Price" />
                                                <h6>Get On Offer Price</h6>
                                            </div>
                                        </div>

                                        <div className='card'>
                                            <div className='card-body'>
                                                <img src={online_offer} alt="Online Special Offers" title="Online Special Offers" />
                                                <h6>Online Special Offers</h6>
                                            </div>
                                        </div>

                                        <div className='card'>
                                            <div className='card-body'>
                                                <img src={assured_quality} alt="Assured Quality" title="Assured Quality" />
                                                <h6>Assured Quality</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default AboutSummera;