import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../input';
import { isValidEmail } from "../../helper/method";
import './mysettings.css';

const MySettings = () => {

    let hasError = false;
    const [email, setEmail] = useState("")
    const [isSubscribed, setIsSubscribed] = useState(true);
    const [errors, setErrors] = useState({
        errEmail: ""
    })
    
    const handleStateChange = (name,value) => {
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
    }
    
    const handleError = (name,value) => {

        let allState = errors;

        if(name === "email"){
            if(!value.length){
                allState["errEmail"] = "Enter your email"
            }else if(!isValidEmail(value)){
                allState["errEmail"] = "Invalid email."
            }else{
                allState["errEmail"] = ""
            }
        }
    }    

    const checkVailidation = () => {
        hasError = false;
        let allState = errors;

        if (!email.length) {
            allState["errEmail"] = "Enter your email"
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }  

    const handelFormSubmit = async(e) => {
        e.preventDefault()
        if(!checkVailidation()){
            setIsSubscribed(false);
        }
    }

    return (

        <div className="container">
            <div className="delivery-details my-setting">
                <div className="topheading">
                    <h5>My Settings</h5>
                   <h6>* Mandatory Fields</h6>
                </div>

                <div className="unsubscribe-mail">
                    <h4>Unsubscribe from our mailing list</h4>

                    {isSubscribed ? (
                    <form>
                        <div className="form-group">
                            <TextInput
                                type={"text"}
                                name="email"
                                label={"Email*"}
                                id={"email"}
                                value={email}
                                errorMSg={errors.errEmail}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                // type={"email"}
                                inputClass={"form-control"}
                                placeholder={"kaushalranpura077@gmail.com"}
                                errorMsg={errors.errEmail}
                            />
                        </div>
                        <button className="btn btn-primary" type="submit" onClick={handelFormSubmit}><span>Unsubscribe</span></button>
                    </form> ) : (
                        <p>You have successfully unsubscribed from our emails.</p>
                    )}
                </div>
            </div>
        </div>

    );
}

export default MySettings;