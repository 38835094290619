export const ACTIVE_CART_VIEW = "ACTIVE_CART_VIEW"

export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_TO_CART = "REMOVE_TO_CART"

export const UNIT_OF_PURCHASE = "UNIT_OF_PURCHASE"

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST"
export const REMOVE_TO_WISHLIST = "REMOVE_TO_WISHLIST"

export const ACTIVE_SIMILAR_PRODUCT = "ACTIVE_SIMILAR_PRODUCT"
