import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Accordion,AccordionBody,AccordionHeader,AccordionItem,} from 'reactstrap';
// import Data from './data'
import { HiPlusSm } from "react-icons/hi";
import { HiMinusSm } from "react-icons/hi";
import './index.css'
import ring3 from '../../../assets/images/ring1.png';
import ring4 from '../../../assets/images/ring2.png';
import { GrFormClose } from 'react-icons/gr';

const ScheduleYourAppointment = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [open, setOpen] = useState('1');
    const toggle = (i) => {
        if (open === i) {
        setOpen();
        } else {
        setOpen(i);
        }
    };

    const [active, setActive] = useState()
    const [active1, setActive1] = useState()

    const Date =[
        {id:1, date:'MON, 6 feb'},
        {id:2, date:'TUE, 7 feb'},
        {id:3, date:'WED, 8 feb'},
        {id:4, date:'THU, 9 feb'},
        {id:5, date:'FRI, 10 feb'}
    ]

    const Time =[
        {id:1, time:'12:00 PM'},
        {id:2, time:'01:30 PM'},
        {id:3, time:'03:00 PM'},
        {id:4, time:'04:30 PM'},
        {id:5, time:'06:00 PM'}
    ]

    const [designs, setDesigns] = useState([
        {id:1, img: ring3, name:'Gold Ear Ring'},
        {id:2, img: ring4, name:'Silver Ear Ring'}
      ]);

      const removeElement = (index) => {
        const newDesigns = designs.filter((_, i) => i !== index);
        setDesigns(newDesigns);
      };

    return (
        <div className='wrapper schedule-appointment'>
            <div className="details">
                <div className="container">
                    <h2>Select a Date & Time</h2>
                    <div className="contact">

                        <div className='left'>
                            <div className="select-details">
                                <ul>
                                    <li>
                                        <h5>Select Date</h5>    
                                        <ul className='dates'>
                                            {
                                                Date.map((item, index)=>{
                                                return(
                                                    <li key={index}>
                                                        <button id={index} key={index} onClick={() => setActive(index)} className={`btn btn-primary ${active === index && 'active'}`}><span>{item.date}</span></button>
                                                    </li>
                                                )
                                                })
                                            } 
                                        </ul>
                                    </li> 
                                    <li>
                                        <h5>Select Time</h5>    
                                        <ul className='dates'>
                                            {
                                                Time.map((item,index)=>{
                                                return(
                                                    <li key={index}>
                                                        <button id={index} key={index} onClick={() => setActive1(index)} className={`btn btn-primary ${active1 === index && 'active'}`}><span>{item.time}</span></button>
                                                    </li>
                                                )
                                                })
                                            }
                                        </ul>
                                    </li> 
                                    <li>
                                        <h5 className='selected'>Selected Designs</h5>    
                                        <ul className='selected-designs'>
    
                                            {designs.map((design, index) => (
                                                <li key={index}>
                                                        <span className="close" onClick={() => removeElement(index)}><GrFormClose className="closebtn" /></span>
                                                    <div className='img-div'>
                                                        <img src={design.img} alt={design.name} title={design.name} />
                                                    </div>
                                                    <h6>{design.name}</h6>
                                                </li>
                                            ))}
                                            
                                        </ul>
                                    </li>  

                                </ul>      

                            </div>
                            <div className="customise">
                                    <Link className="btn btn-primary" to={'/booking-information'}><span>Confirm Date & Time</span></Link>
                            </div>
                        </div>

                        <div className="right">
                            <div className="faq-accordion">
                                <h5>FAQs</h5>
                                
                            <Accordion open={open} toggle={toggle}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">Lorem Ipsum is simply dummy text of the printing?
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="2">Lorem Ipsum is simply dummy text of ?
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="3">Lorem Ipsum is simply dummy text of ?
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="4">Lorem Ipsum is simply dummy text of the printing? 
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="5">Lorem Ipsum is simply dummy text of the printing? 
                                    <span className='plusicon'><HiPlusSm /></span>
                                    <span className='minusicon'><HiMinusSm /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
    </div>
    )
}


export default ScheduleYourAppointment;