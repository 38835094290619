import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../../components/Card/card.css';
import StyleTip from '../../assets/images/icons/style-tip.png';
import JewelleryCare from '../../assets/images/jewellery-care.jpg';
import CutDiamond from '../../assets/images/jewelry-guide/diamond/cut-diamond.png';
import ClarityDiamond from '../../assets/images/jewelry-guide/diamond/clarity-diamond.png';
import ColourDiamond from '../../assets/images/jewelry-guide/diamond/colour-diamond.png';
import CaratDiamond from '../../assets/images/jewelry-guide/diamond/carat-diamond.png';
import Info from '../../assets/images/icons/info.png';

const DiamondCareGuide = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const CutData = [
        { id:1, src:CutDiamond, title:'Excellent'},
        { id:2, src:CutDiamond, title:'Very Good'},
        { id:3, src:CutDiamond, title:'Good'},
        { id:4, src:CutDiamond, title:'Fair'},
        { id:5, src:CutDiamond, title:'Poor'}
    ]

    const ClarityData = [
        { id:1, src:ClarityDiamond, title:'FL'},
        { id:2, src:ClarityDiamond, title:'IF'},
        { id:3, src:ClarityDiamond, title:'VVS1/VVS2'},
        { id:4, src:ClarityDiamond, title:'VS1/VS2'},
        { id:5, src:ClarityDiamond, title:'S1/S2'},
        { id:6, src:ClarityDiamond, title:'l1/l2/l3'}
    ]

    const ColorData = [
        { id:1, src:ColourDiamond, title:'D - Colorless'},
        { id:2, src:ColourDiamond, title:'E - Colorless'},
        { id:3, src:ColourDiamond, title:'F - Colorless'},
        { id:4, src:ColourDiamond, title:'G-J - Near Colorless'},
        { id:5, src:ColourDiamond, title:'K-M - Faint'},
        { id:6, src:ColourDiamond, title:'N-R - Very Light'},
        { id:7, src:ColourDiamond, title:'S-Z - Light'}
    ]

    const CaratData = [
        { id:1, src:CaratDiamond, title:'0.50 ct.'},
        { id:2, src:CaratDiamond, title:'0.75 ct.'},
        { id:3, src:CaratDiamond, title:'1.00 ct.'},
        { id:4, src:CaratDiamond, title:'2.00 ct.'},
        { id:5, src:CaratDiamond, title:'5.00 ct.'}
    ]

    return (
        <div className='wrapper diamond-care-guide'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Diamond Care Guide</h1>
                </div>
            </div>

            <div className="container"> 

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={'/jewellery-care-guide'}>Jewelry Care Guide</Link></li>
                        <li className="breadcrumb-item active">Diamond Care Guide</li>
                    </ol>
                </nav>
                    
                <p>We know you’re here because you only want the best and we would love to help you find the perfect diamond for you. We also know that buying diamonds can be a little overwhelming, but that doesn’t mean that you should ever settle or stress! So just sit back with a cup of coffee and let us walk you through everything you need to know before you buy that sparking diamond!</p>  

                <h2>Back to the Basics - The 4 Cs of a Diamond</h2>  

                <div className='basics'>
                    <ol type='1'>
                        <li>
                            <h5>Cut:</h5>
                            <p>We believe the cut is the most important aspect of choosing a diamond. It's basically what determines the proportion, symmetry and polish of a diamond. The right cut will enhance the sparke and the scintillation of the diamond. The better the cut, the more likely your diamond is to sparkle bright and look gorgeous!</p>
                            <div className='style-tip'>
                                <img src={StyleTip} alt='Summera Style Tip:'  />
                                <h6>Summera Style Tip:</h6>
                            </div>
                            <p>Invest in the cut. It’s the ultimate diamond hack (you’ll not find it in the books). A beautifully cut diamond can make the color and clarity appear better than they are.</p>
                            <div className='cut'>
                                {
                                    CutData.map((item,index)=>{
                                        return(
                                            <div className='brand-img' key={index}>
                                                <figure>
                                                    <img src={item.src} alt={item.title} title={item.title} />
                                                    <h5>{item.title}</h5>
                                                </figure>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </li>
                        <li>
                            <h5>Clarity:</h5>
                            <p>When light passes through a diamond, it bounces back, making the diamond sparkle! Some diamonds have imperfections in the form of spots and blemishes. The more the imperfections, the more difficult it is for light to pass through, thereby reducing the sparkle. If you’re lost, here’s all you need to remember: Lesser imperfection and inclusions = Better clarity.</p>
                            <div className='style-tip'>
                                <img src={StyleTip} alt='Summera Style Tip:'  />
                                <h6>Summera Style Tip:</h6>
                            </div>
                            <p>If you’re buying a diamond that’s under a carat, don’t obsess over the spots or blemishes because they aren’t really visible to an untrained eye. It’s better to invest in the cut and sparkle on!</p>
                            <div className='clarity'>
                                {
                                    ClarityData.map((item,index)=>{
                                        return(
                                            <div className='brand-img' key={index}>
                                                <figure>
                                                    <img src={item.src} alt={item.title} title={item.title} />
                                                    <div className='info'><h5>{item.title}</h5> <img src={Info} alt={item.title} title={item.title} /></div>
                                                </figure>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </li>
                        <li>
                            <h5>Colour:</h5>
                            <p>Ironically, the best color for a diamond would be ‘colorless’. The colors range from D (colorless) and go all the way to Z (light yellow). To be honest, the colour difference in diamonds within a colour family is quite subtle to an untrained eye. Nevertheless, they do create a difference in the price and quality of the diamond. Bottom line is, colorless diamonds are the most precious!</p>
                            <div className='style-tip'>
                                <img src={StyleTip} alt='Summera Style Tip:'  />
                                <h6>Summera Style Tip:</h6>
                            </div>
                            <p>As diamond size increases, colour becomes more noticeable. This is especially important to keep in mind if you are purchasing a diamond of two carats or greater. If you pick a slightly yellow colored diamond, you can pair it with a white gold or platinum band. The white gold will complement the yellow hue of your diamond, and they won’t clash with each other!</p>
                            <div className='colour'>
                                {
                                    ColorData.map((item,index)=>{
                                        return(
                                            <div className='brand-img' key={index}>
                                                <figure>
                                                    <img src={item.src} alt={item.title} title={item.title} />
                                                    <h5>{item.title}</h5>
                                                </figure>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </li>
                        <li>
                            <h5>Carat:</h5>
                            <p>Ironically, the best color for a diamond would be ‘colorless’. The colors range from D (colorless) and go all the way to Z (light yellow). To be honest, the colour difference in diamonds within a colour family is quite subtle to an untrained eye. Nevertheless, they do create a difference in the price and quality of the diamond. Bottom line is, colorless diamonds are the most precious!</p>
                            <div className='style-tip'>
                                <img src={StyleTip} alt='Summera Style Tip:'  />
                                <h6>Summera Style Tip:</h6>
                            </div>
                            <p>As diamond size increases, colour becomes more noticeable. This is especially important to keep in mind if you are purchasing a diamond of two carats or greater. If you pick a slightly yellow colored diamond, you can pair it with a white gold or platinum band. The white gold will complement the yellow hue of your diamond, and they won’t clash with each other! </p>
                            <div className='clarity'>
                                {
                                    CaratData.map((item,index)=>{
                                        return(
                                            <div className='brand-img carat' key={index}>
                                                <figure>
                                                    <img src={item.src} alt={item.title} title={item.title} className='scale-img' />
                                                    <div className='info'><h5>{item.title}</h5></div>
                                                </figure>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <p className='recomended'>Summera Recomended - As big as you want !</p>    
                        </li>
                    </ol>
                    
                </div>

            </div>

            <div className='jewellery-care'>
                <div className='container'>   
                    <h2>Diamond Care</h2>
                    <ul className='outer-div'>
                        <li>
                            <ul>
                                <li>Once every week, clean your diamond jewellery in lukewarm soap water and rub it down gently with a brush, to remove the dirt and grime from the surface and ensure a long lasting shine.</li>
                                <li>Remove your diamond jewellery when you go swimming or while doing household chores, so that the grime doesn’t make your jewellery look dull.</li>
                                <li>Keep your jewellery in separate boxes to reduce the chances of scratches on the metal.</li>
                            </ul>
                        </li>
                        <li>
                            <img src={JewelleryCare} alt='Jewellery Care' />
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}


export default DiamondCareGuide;