import React, {useState,useEffect} from 'react';
import ImgCarousel from '../atom/ImgCarousel';
import images from '../atom/ImgCarousel/images';
import SummeraPromise from '../SummeraPromise';
import { BrandSlider } from '../ShopByBrands';
import { Link } from 'react-router-dom';
import { ShopByCollections } from '../ShopByCollections'
import CustomizedBanner from '../CustomizedBanner';
import ShopByCategory from '../ShopByCategory'
import ShopByBudget from '../ShopByBudget'
import ShopOccasion from '../ShopOccasion';
import jewelry from '../../assets/images/jewelry-banner.jpg'
import ring from '../../assets/images/ring-banner.jpg'
import shopnow from '../../assets/images/shop-now-banner.jpg'
import ShopGender from '../ShopGender'
import About from '../About'
import { BsWhatsapp } from "react-icons/bs";
import { RiVideoAddLine } from 'react-icons/ri';
import { RiMapPin2Line } from 'react-icons/ri';
import { TestimonialSlider } from '../TestimonialSlider';
import { FaQuoteLeft } from "react-icons/fa";
import ProducCard from '../Card/shopbynewarrivalslisting';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { GrFormClose } from 'react-icons/gr';
import tryAtHome from '../../assets/images/tryathome.png';
import videoCall from '../../assets/images/video-call.png';
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import FindYourRingSize from '../molecules/FindYourRingSize';
import "../../assets/Scss/style.scss";
import ShopbyCities from '../ShopbyCities';

const Home = () => {
    document.body.classList.add('home'); 

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const TryAtHome = () => (
        <Popup trigger={<li><span className='fontSize'><RiMapPin2Line />Experience Luxury in the Comfort of Your Home</span></li>} modal aria-describedby={"pinHome"}>
          {close => (
              <div className='pop-body'>
                <span className="close" onClick={close}>
                  <span><GrFormClose className="closebtn" /></span>
                </span>
    
                <h2>Experience Luxury in the Comfort of Your Home</h2>
                <p>Book a Free Home Trial for a 'Try At Home' Experience. Select up to 5 designs and buy only if you love them!"</p>
                <img src={tryAtHome} alt="Schedule a Video Call" title="Schedule a Video Call" />
    
                <Link className="btn btn-primary" onClick={close} to={'/product'}><span>Add to Try at Home Cart</span></Link>
                
              </div>
          )}
          </Popup>
      );

      const VideoCall = () => (
        <Popup trigger={<li><span><RiVideoAddLine />Schedule a Personalized Video Call</span></li>} modal aria-describedby={"pinHome"}>
          {close => (
              <div className='pop-body'>
                <span className="close" onClick={close}>
                  <span><GrFormClose className="closebtn" /></span>
                </span>

                <h2>Video Call At Your Convenience</h2>
                <img src={videoCall} alt="Schedule a Video Call" title="Schedule a Video Call" />

                <Link className="btn btn-primary" to={'/request-video-call'} onClick={close}><span>Schedule a Video call</span></Link>
                
              </div>
          )}
          </Popup>
      );

    return (
        <div className='wrapper'>
            <ImgCarousel images={images} dot={true} arrows={false}/>

            {/* <div className='shop-cat spacer'>
                <div className='container'>
                    <h2>Shop by Cities</h2>
                    <p className='text-center sub-title'>Explore our diverse array of enchanting jewelry pieces, categorized to cater to your every desire at Summera Jewels.</p>
                    <ShopbyCities />
                </div>
            </div> */}

            {/* <div className='shop-cat spacer'>
                <div className='container'>
                    <h2>Shop by Categories</h2>
                    <p className='text-center sub-title'>Explore our diverse array of enchanting jewelry pieces, categorized to cater to your every desire at Summera Jewels.<Link to={''} className='btn btn-view'>View All</Link></p>
                    <ShopByCategory />
                </div>
            </div> */}

            <div className='product-arrivals spacer'>
                <div className='container'>
                    <h2>Shop by New Arrivals</h2>
                    <p className='text-center sub-title'>Stay ahead of the trends and immerse yourself in the latest expressions of beauty and style with our captivating new arrivals. <Link to={'/shop-by-new-arrivals'} className='btn btn-view'>View All</Link></p>
                    <ProducCard slider={true} slidestoshow ={5} slidestoscroll ={3}  />
                </div>
            </div>

            {/* <div className='home-banner spacer'>
                <div className="container">
                    <CustomizedBanner />
                </div>
            </div> */}
            
            <div className='shop-budget spacer'>
                <div className='container'>
                    <h2>Shop by Budget</h2>
                    <p className='text-center sub-title'>Find your perfect treasure without compromise - Shop by Budget at Summera Jewels.<Link to={'/product'} className='btn btn-view'>View All</Link></p>
                    <ShopByBudget />
                </div>
            </div>

            <div className='shop-occasion spacer'>
                <div className='container'>
                    <h2>Shop by Occasion</h2>
                    <p className='text-center sub-title'>Adorn every special moment with brilliance and grace.</p>
                    <ShopOccasion />  
                </div>
            </div>
    
            <div className='shop-collections spacer'>
                <div className='container'>
                    <h2>Shop by Bridal Collections</h2>
                    <p className='text-center sub-title'>Essence of individuality and artistic expression - exquisitely curated jewelry pieces tailored to your unique style. <Link to={'/shop-by-brand'} className='btn btn-view'>View All</Link></p>
                    <ShopByCollections />
                </div>
            </div>

            <div className='SummeraBanner spacer'>
                <div className='container'>
                    <ul className='BannerList'>

                        {/* <li className='ShopNow'>
                            <figure>
                                <img src={shopnow} alt={jewelry.alt} title={jewelry.alt} />
                                <figcaption>
                                    <h2>0</h2>
                                    <span className='percentage'>%</span> 
                                    <h3>Making Charges </h3>
                                    On 500+ Designs
                                    <Link to={''} className='btn btn-primary'><span>Shop now</span></Link>
                                   
                                </figcaption>
                            </figure>
                        </li>
                        <li className='ShopGender'>
                            <div className='lightbg'>
                                <h2>Timeless Beauty for All </h2>
                                <p className='text-center sub-title'>Find the perfect expression of style and grace for everyone.</p>
                                <ShopGender />
                            </div>
                        </li> */}

                        <li className='JewelryBanner'>
                            <figure>
                                <Link to="" onClick={onOpenModal}>
                                    <img src={ring} alt={ring} title={ring} />
                                    <figcaption>
                                        Find Your <strong>Ring Size</strong>
                                    </figcaption>
                                </Link>
                                <Modal open={open} onClose={onCloseModal} center>
                                    <FindYourRingSize />
                                </Modal>
                            </figure>
                        </li>
                        <li className='JewelryBanner'>
                            <figure>
                                <Link to="/jewellery-care-guide">
                                    <img src={jewelry} alt={jewelry} title={jewelry} />
                                    <figcaption>
                                        The Jewelry <strong>Care Guide</strong>
                                    </figcaption>
                                </Link>
                            </figure>
                        </li>
                    </ul>

                    <div className='shop-brand spacer'>
                <div className='container text-center'>
                    <h2>Shop by brands</h2>
                    <p className='sub-title'>Discover exquisite elegance and timeless beauty with our curated collection of renowned brands at Summera Jewels. <Link className="btn btn-view" to={'/shop-by-brand'}>View All</Link></p>
                    <BrandSlider />
                </div>
            </div> 
                    <div className='btm-border'></div>
                </div>
            </div>

            <About />

            <div className='home-contact-list spacer'>
                <div className='container'>
                    <ul className='contact-list'>
                            {/* {TryAtHome()} */}
                        <li>
                        
                            <Link aria-label="Chat on WhatsAppss" to={"https://wa.me/917770007841?text=Chat%20with%20Summera%20Jewels%20for%20Exclusive%20Offers%20and%20Personalized%20Assistance"} target='_blank'>
                                <BsWhatsapp /> Stay Connected with Us on WhatsApp
                            </Link>
                        </li>
                            {/* {VideoCall()} */}
                    </ul>
                </div>
            </div>

            <section className="testimonial text-center spacer">
            <div className="container">
              <div className="inner-wrapper">
                <h2>Customer Says</h2>
                <p className='sub-title'>Lorem ipsum dummy text dummy text dummy text <Link to={'/customer-says'} className='btn btn-view'>View All</Link></p>
                <div className="testi-icon">
                    <FaQuoteLeft />
                </div>
                <TestimonialSlider />
              </div>
            </div>
            </section> 
        </div>

    )
}

export default Home;