import { put } from 'redux-saga/effects';
import loginApi from "../services/loginApi";

let api = new loginApi();

export function* panVerificationSaga({ payload, resolve }) {

    try {
        let data = yield api.PanVarification(payload);
        
        if (data.status_code === 200) {
            resolve && resolve(data)
            console.log("status_codestatus_code", data)
        }
        else {
            resolve && resolve(data)
        }
    } catch (e) {
        alert(e)
    }
}