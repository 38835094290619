import Slider from "react-slick";
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css"
import { Link } from "react-router-dom";




const ImgCarousel = (props) => {
  
    const settings = {
      infinite: true,
      dots: props.dot ? props.dot : false,
      arrows: props.arrows ? props.arrows : false,
      slidesToShow: props.slidtoShow ? props.slidtoShow : 1,
      slidesToScroll: props.slidestoscroll ? props.slidestoscroll : 1,
      lazyLoad: true,
      fade: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 6000,
    };
    
    return (
      <>
        <div className="imgslider">
          <Slider {...settings}>
            {props.images.map((item, index) => (
                <Link to={'/diamond'} key={index}>
                  <figure>
                  <div key={item.id}>
                    <img src={item.src}  alt={item.alt} title={item.alt} />
                  </div>
                  <div className="container">
                    <figcaption className="bannerTxt">
                      <h3>{item.title}</h3>
                      <h2>{item.subtitle}</h2>
                      
                    </figcaption>
                  </div>
                </figure>
                </Link>
            ))}
           
          </Slider>
        </div>
            </>
    )
  }
  export default ImgCarousel;