import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../../components/Card/card.css';
import Garnet from '../../assets/images/jewelry-guide/gemstone/garnet.jpg';
import Amethyst from '../../assets/images/jewelry-guide/gemstone/amethyst.jpg';
import Aquamarine from '../../assets/images/jewelry-guide/gemstone/aquamarine.jpg';
import Diamond from '../../assets/images/jewelry-guide/gemstone/diamond.jpg';
import Emerald from '../../assets/images/jewelry-guide/gemstone/emerald.jpg';
import Pearl from '../../assets/images/jewelry-guide/gemstone/pearl.jpg';
import Ruby from '../../assets/images/jewelry-guide/gemstone/ruby.jpg';
import Peridot from '../../assets/images/jewelry-guide/gemstone/peridot.jpg';
import Sapphire from '../../assets/images/jewelry-guide/gemstone/sapphire.jpg';
import Tourmaline from '../../assets/images/jewelry-guide/gemstone/tourmaline.jpg';
import Citrine from '../../assets/images/jewelry-guide/gemstone/citrine.jpg';
import BlueTopaz from '../../assets/images/jewelry-guide/gemstone/blue-topaz.jpg';
import JewelleryCare from '../../assets/images/jewellery-care.jpg';

const GemstoneCareGuide = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const GemstoneData = [
        { id:1, src:Garnet, title:'Garnet', month:'January', quote:'Garnet’s virtues have been long believed to include passion, friendship, success, loyalty and faith.', desc: 'Bold oranges, royal greens and hues of purples and pinks - are some of the hues of a Garnet. If you’re a January baby, you’re in for a treat! There are so many varieties to pick from, so go ahead uplift your spirit with your favorite Garnet gemstone.'},
        { id:2, src:Amethyst, title:'Amethyst', month:'February', quote:'Amethyst is said to be associated with qualities of peace, courage and stability.', desc: 'The Amethyst gemstone comes in different shades of the spectacular purple colour that ranges from a blend of a deep violet to a lighter lilac hue. This gemstone is as dreamy as the come, so be prepared to be transported to a soothing state of mind.'},
        { id:3, src:Aquamarine, title:'Aquamarine', month:'March', quote:'Aquamarine: a timeless symbol of youth, health and hope.', desc:'Sea, salt, sun and everything fun! These aqua-blue colored babies are a perfect ode to the ocean. So if you’re a total beach baby or if you just love the mesmerising hues of blue, go ahead and pick one.'},
        { id:4, src:Diamond, title:'Diamond', month:'April', quote:'Considered as a paramount of gestures, it’s a symbol of eternal and endless love.', desc:'They say diamonds are a girl’s best friend. Well, they’re absolutely right. :) A diamond is the perfect gesture that there is, and honestly, who can say no to it? Wearing a diamond is also said to bring benefits such as balance, clarity and abundance. So go ahead, and give it a try.'},
        { id:5, src:Emerald, title:'Emerald', month:'May', quote:'Emerald, known as the gem of Venus, is considered to be a strong symbol of the bonds of love and rebirth.', desc:'Royal. Bold. Elegant. For all our Queens out there, there’s no other way to slay the royalty quotient than with a glorious Emerald. The deep hues of green will make you weak in the knees. :)'},
        { id:6, src:Pearl, title:'Pearl', month:'June', quote:'Pearl is a symbol of purity and innocence.', desc:'Be it a pair of ripped denim, a blazer or a formal gown: Pearl is a one stop solution to give you that charming look you’ve been wanting. They’re sophisticated, classy and will be sure to make heads turn.'},
        { id:7, src:Ruby, title:'Ruby', month:'July', quote:'Ruby, the King of Gemstones, is said to represent love, courage and passion', desc:'The Ruby is said to be the reigning gemstone, and is said to bring an abundance of love. The deeper and more glorious the hue of the gemstone, the more valuable it is. Pick a gorgeous Ruby out, we promise you won’t regret it.'},
        { id:8, src:Peridot, title:'Peridot', month:'August', quote:'Peridot is a happy stone. It is known to bring happiness, warmth and emotional strength in the lives of the wearers.', desc:'This mystical gemstone is said to have healing powers, and is known as the Gem of the Sun. After all, green does have a soothing effect on us. The refreshing hue of a Peridot is something we bet you’ll always, always love.'},
        { id:9, src:Sapphire, title:'Sapphire', month:'September', quote:'Sapphires are believed to focus the mind, encourage self-discipline and channel higher powers.', desc:'Nothing speaks royal like a true blue sapphire. Go pick out a dark blue hued beauty, if you’re a September born, or you love accessorising like the royalty you are.'},
        { id:10, src:Tourmaline, title:'Tourmaline', month:'October', quote:'Tourmaline is believed to protect one against evil forces, toxins, pollutants and all negative energy.', desc:'The beauty of a Tourmaline gemstone is that it comes in a variety of gorgeous colours. Tourmaline is known to ward off negative energy. So you can totally pick one out, in your favourite colour too!'},
        { id:11, src:Citrine, title:'Citrine', month:'November', quote:'Part of the Quartz family, Citrine is famously known as the Healing Quartz. It helps to support vitality and promote health.', desc:'If you’re all for ‘fun in the sun’, the Citrine gemstone is meant for you. Known to be a healing gemstone, citrine is said to be a gift from the Sun. So go and get yourself some sunshine.'},
        { id:12, src:BlueTopaz, title:'Blue Topaz', month:'December', quote:'Blue Topaz, being a symbol of love, is said to foster kindness and a sweet nature.', desc:'Blue Topaz is known for its amazing aqua blue shade. It also is the perfect gemstone for all things ‘Love’ - as it represents eternal romance. So if you’re a December baby, or you just love all things cheesy, this gemstone is the one for you.'},
    ]

    return (
        <div className='wrapper gemstone-care-guide'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Gemstone Care Guide</h1>
                </div>
            </div>

            <div className="container"> 

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={'/jewellery-care-guide'}>Jewellery Care Guide</Link></li>
                        <li className="breadcrumb-item active">Gemstone Care Guide</li>
                    </ol>
                </nav>

                <p>Here’s your guide to Everything-Gemstones! With more than a rainbow of colors to choose from - you can go with your very own birthstone, or just pick your favourite colour too!</p>

                <ul className='gemstone'>
                    {
                        GemstoneData.map((item,index)=>{
                            return(
                                <li className='brand-img' key={index}>
                                    <figure>
                                        <img src={item.src} alt={item.title} title={item.title} />
                                        <h5>{item.title}</h5>
                                        <p className='month'>Tha Fact - {item.month}</p>
                                        <p className='quote'>{item.quote}</p>
                                        <p className='desc'>{item.desc}</p>
                                    </figure>
                                </li>
                            )
                    })
                    }
                </ul>
            </div>    

            <div className='jewellery-care'>    
                <div className='container'>
                    <h2>Gemstone Care</h2>
                    <ul className='outer-div'>
                        <li>
                            <ul>
                                <li>Clean your gemstone jewellery with some warm water and gentle soap.</li>
                                <li>Do not soak your pearls, corals or turquoise jewellery in water. We repeat, don’t do it.</li>
                                <li>Humidity and moisture? Trust us, they are the enemy.</li>
                                <li>Don’t wear your jewellery while swimming, in the shower or at the gym.</li>
                                <li>Your gemstones aren’t perfume-friendly or lotion-friendly. So apply them first, and wear your jewellery after they settle into your skin.</li>
                                <li>Got yourself a pretty string of pearls? Don’t forget to get them restrung every other year or so.</li>
                            </ul>
                        </li>
                        <li>
                            <img src={JewelleryCare} alt='Jewellery Care' />
                        </li>
                    </ul>
                </div>
            </div>
            
        </div>
    )
}


export default GemstoneCareGuide;