import React, {useEffect} from 'react'
import GoldProductGallery from '../../../components/ProductGallery/goldring';
import { Link } from 'react-router-dom';

const BrandProductDetails = () =>{
    document.body.classList.add('gold_product_details'); 

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
        <div className="container">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                <li className="breadcrumb-item"><Link to={'/shop-by-brand'}>Shop by Brand</Link></li>
                <li className="breadcrumb-item"><Link to={'/shop-by-brand/jewels-box'}>Jewels Box</Link></li>
                <li className="breadcrumb-item"><Link to={'/gold'}>Gold</Link></li>
                <li className="breadcrumb-item"><Link to={'/shop-by-brand/jewels-box/gold/rings'}>Ring</Link></li>
                <li className="breadcrumb-item active">Gold Ring</li>
            </ol>
        </nav>

        <div>
           <GoldProductGallery />
        </div>
        
    </div>
        </div>
    )
}

export default BrandProductDetails;
