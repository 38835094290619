import {React,useState,useEffect} from 'react';
import {Accordion,AccordionBody,AccordionHeader,AccordionItem,} from 'reactstrap';
import { Link } from 'react-router-dom';
import { HiPlusSm } from "react-icons/hi";
import { HiMinusSm } from "react-icons/hi";
import Data from '../../components/molecules/BookingInformation/data'

const Faqs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [open, setOpen] = useState('1');
        const toggle = (i) => {
            if (open === i) {
            setOpen();
            } else {
            setOpen(i);
        }
    };

    return (
        <div className='wrapper faqs'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Frequently Asked Questions</h1>
                </div>
            </div>

            <div className="container"> 

                <section className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Frequently Asked Questions</li>
                            </ol>
                        </nav>

                        <div className="main faq-accordion">

                            {
                                Data.map((items, index) => {
                                    return (
                                        <Accordion open={open} toggle={toggle} key={index}>
                                            <AccordionItem>
                                                <AccordionHeader targetId={index.toString()}>{items.title}
                                                    <span className='plusicon'><HiPlusSm size={20} /></span>
                                                    <span className='minusicon'><HiMinusSm size={20} /></span>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={index.toString()}>
                                                    <p>{items.content1}</p>
                                                    <p>{items.content2}</p>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}


export default Faqs;