import Slider from "react-slick";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css"

const CartCarousel = (props) => {
  
  const navigate = useNavigate();

    const settings = {
      infinite: true,
      dots: props.dot ? props.dot : false,
      slidesToShow: props.slidtoShow ? props.slidtoShow : 5,
      slidesToScroll: props.slidestoscroll ? props.slidestoscroll : 1,
      lazyLoad: true,
      autoplay: false,
      autoplaySpeed: 2000,
      
      responsive: [
        {
            breakpoint: 1300,
            settings: {
              slidesToShow: 4,
            }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
            }
        }
      ]
    };
    return (
      <>
        <div className="imgslider typ2">
          <div className="container">
            <Slider {...settings}>
              {props.categoriesSlider.map((item, index) => (
                  <figure key={index}>
                    <span onClick={()=> navigate(`${item.link}`, {state:[item.title]})}>
                      <div key={item.id}>
                        <img src={item.src}  alt={item.alt} title={item.alt} />
                      </div>
                    </span>
                    <h3>{item.title}</h3>
                  </figure>
              ))}
            </Slider>
          </div>
        </div>
        </>
    )
  }
  
  export default CartCarousel;