import * as Yup from 'yup';


const SignupSchema = Yup.object().shape({

    pincode: Yup.string()
        .required("Please enter your Pincode")
        .matches(
            /^[1-9][0-9]{5}$/g,
            "Invalid pincode"
        ),

});

export default SignupSchema;