import React from 'react'
import Logo from '../../../assets/images/logo.png'
import { Link } from 'react-router-dom'



export default function logo() {
  return (
      <div className="logo">
        <Link to={'/'} className="navbar-brand">
            <img src={Logo} title='Summera' alt='Summera' />
        </Link>
      </div>

  )
}


