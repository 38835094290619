import React, { useEffect, useState } from "react";
import Reactselect from '../select';
import Empty from '../../atom/empty';
import EmptyImage from '../../../assets/images/myaccount/quote.svg';
import goldRing from '../../../assets/images/gold-ring.jpg';
import './videoCall.css';

function VideoCall() {
  const durationOptions = [
    { label: 'Past 3 Months', value: '3m' },
    { label: 'Past 6 Months', value: '6m' },
    { label: 'Past 9 Months', value: '9m' },
    { label: 'Past 12 Months', value: '12m' }
  ];
 
  const data = [
    { id: 1, date: new Date('2023-06-17'), src:goldRing, name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", price: "99,999", full_name: "Kaushal Ranpura", mobile_no: "6435237534", call_status: "Open" },
    { id: 2, date: new Date('2022-06-15'), src:goldRing, name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", price: "99,999", full_name: "Kaushal Ranpura", mobile_no: "6435237534", call_status: "Open" },
    { id: 3, date: new Date('2023-01-10'), src:goldRing, name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", price: "99,999", full_name: "Kaushal Ranpura", mobile_no: "6435237534", call_status: "Open" },
  ];

  const [selectedDuration, setSelectedDuration] = useState('all');
  const [filteredData, setFilteredData] = useState(data);

  const handleDurationChange = (event) => {
    const duration = event.target.value;
    setSelectedDuration(duration);

    // Filter data based on the selected duration
    const filteredData = filterDataByDuration(duration);
    setFilteredData(filteredData);
  };

  const filterDataByDuration = (duration) => {
    if (duration === '3m') {
      return data.filter((item) => {
        const months3 = new Date();
        months3.setDate(months3.getDate() - 90);
        return item.date >= months3;
      });
    } else if (duration === '6m') {
      return data.filter((item) => {
        const months6 = new Date();
        months6.setDate(months6.getDate() - 180);
        return item.date >= months6;
      });
    } else if (duration === '9m') {
      return data.filter((item) => {
        const months9 = new Date();
        months9.setDate(months9.getDate() - 270);
        return item.date >= months9;
      });
    } else if (duration === '12m') {
        return data.filter((item) => {
          const months12 = new Date();
          months12.setDate(months12.getDate() - 365);
          return item.date >= months12;
        });
      } else {
      return data; // Return all data if 'all' or no duration is selected
    }
  };

  return (

    <>
    <div className="container videocall">
        <h5>Video Call</h5>
        {
            <div className="Data">
                <div className="filterMonth">
                    <label>Showing <span> {filteredData.length} orders</span> in</label>
                    <select value={selectedDuration} onChange={handleDurationChange}>
                        <option value="">All Time</option>
                        {durationOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                        ))}
                    </select>
                </div>

                    {filteredData.length > 0 ? (
                        <div className="Table table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Product Details</th>
                                        <th>Price</th>
                                        <th>Full Name</th>
                                        <th>Mobile No.</th>
                                        <th>Order Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {filteredData.map((item, index) => (
                                        <tr key={index}>
                                            <td><span>{item.date.toLocaleDateString('en-in', { day:"numeric", month:"short", year:"numeric"})}</span></td>
                                            <td>
                                                <ul>
                                                    <li><img src={item.src} alt={item.name} title={item.name} /></li>
                                                    <li>
                                                        <span className="name">{item.name}</span>
                                                        <span className="detail">{item.detail}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <span className="name">₹{item.price}</span></td>
                                            <td>
                                                <span className="mobile">{item.full_name}</span>
                                            </td>
                                            <td>
                                                <span className="mobile">{item.mobile_no}</span>
                                            </td>
                                            <td>
                                                <span className="open">{item.call_status}</span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (<Empty imgsrc={EmptyImage} content={"No Video Call Order Found Yet"} />)}
                    
            </div>
        }
    </div>
    </>   

  );
}

export default VideoCall;