import EarRing from "../../../assets/images/ring1.png";

const ItemTable = () => {
  return (
    <div className="orderInfo">
      <div className="ordertable table-responsive">
        <table>
          <thead>
            <tr>
              <th>Item(s)</th>
              <th>Qty.</th>
              <th>Amount</th>
              <th>Discount</th>
              <th>GST</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ul>
                  <li>
                    <img src={EarRing} alt="Ear Ring" />
                  </li>
                  <li>
                    <span className="name">Gold Ring Lorem Ipsum - SJERN23568</span>
                    <span className="details">18 KT Yellow Gold (3.400 g)</span>
                  </li>
                </ul>
              </td>
              <td data-label="Qty.">
                <select required="" className="form-select">
                  <option value="" selected="selected">
                    1
                  </option>
                  <option value="1">2</option>
                  <option value="2">3</option>
                  <option value="2">4</option>
                  <option value="2">5</option>
                  <option value="2">6</option>
                </select>
              </td>
              <td data-label="Amount">₹ 10,500</td>
              <td data-label="Discount">₹ 500</td>
              <td data-label="GST">₹ 205</td>
              <td data-label="Total">₹ 4,305</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ItemTable;
