import React, { useRef } from 'react';
import Logo from '../../assets/images/logo.png'

const Invoice = ({componentRef}) => {

  return (
    <div className='d-none'>

    <div className="invoicePdf" ref={componentRef}>         
            <div className="container">
                <div className="invoiceOrder">
                    <div className="orderDetails">

                        <div className="invoiceHeader">         
                            <img src={Logo} alt="Summera" />
                            <h2>Retail / Tax Invoice</h2>
                        </div> 

                        <div className="scocialInfo">
                            <ul>
                                <li>
                                    <p>Card : </p> 
                                    <span> Gift A Card - Happy Birthday </span>
                                </li>
                                <li>
                                    <p>Email ID : </p> 
                                    <span> kaushalranpura077@gmail.com</span>
                                </li>
                                <li>
                                    <p>Mobile : </p>
                                    <span> 9819550989</span>
                                </li>
                            </ul>
                        </div>

                        <div className="orderData">
                            <ul>
                                <li>
                                    <h4>Order No.</h4>
                                    <span>BJFE2045653216</span>
                                </li>
                                <li>
                                    <h4>Order Date</h4>
                                    <span>16 Feb 2023</span>
                                </li>
                                <li>
                                    <h4>Invoice No.</h4>
                                    <span>BJ00123-44-88</span>
                                </li>
                                <li>
                                    <h4>Invoice Date and Time</h4>
                                    <span>16 Feb 2023   10:05AM</span>
                                </li>
                                <li>
                                    <h4>Place of Supply</h4>
                                    <span>Maharashtra</span>
                                </li>
                            </ul>
                        </div>

                        <div className="invoiceDetails table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Sr. No.</th>
                                        <th className="itemInfo" rowSpan="2">Item Code</th>
                                        <th rowSpan="2">HSN</th>
                                        <th rowSpan="2">Qty</th>
                                        <th rowSpan="2">Unit Price</th>
                                        <th rowSpan="2">Discount</th>
                                        <th rowSpan="2">Taxable Value</th>
                                        <th colSpan="2">CGST</th>
                                        <th colSpan="2">SGST/UGST</th>
                                        <th colSpan="2">IGST</th>
                                        <th rowSpan="2">Total</th>
                                    </tr>
                                    <tr>
                                        <th>Rate</th>
                                        <th>Amt</th>
                                        <th>Rate</th>
                                        <th>Amt</th>
                                        <th>Rate</th>
                                        <th>Amt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td></td>
                                    <td>
                                        <div className="productInfo">
                                            <span className="tags">Buy A Card</span>
                                            <p className="prodName">Happy Birthday</p>
                                            <p>3592 9500 4229 7569</p>
                                        </div>
                                    </td>
                                    <td>-</td>
                                    <td>1</td>
                                    <td>3,000</td>
                                    <td>-</td>
                                    <td>3,000</td>
                                    <td>0.00</td>
                                    <td>0.00</td>
                                    <td>0.00</td>
                                    <td>0.00</td>
                                    <td>120</td>
                                    <td>120</td>
                                    <td>240</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan='3'></td>
                                        <td><span>Total</span></td>
                                        <td>3,000</td>
                                        <td>-</td>
                                        <td>3,000</td>
                                        <td></td>
                                        <td>0.00</td>
                                        <td></td>
                                        <td>0.00</td>
                                        <td></td>
                                        <td>240</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div className="totalInfo">
                                <ul>
                                    <li>
                                        <p>Total Amount(+)</p>
                                        <p className="amount">₹ 3,000</p>
                                    </li>
                                    <li>
                                        <p>Discount(-)</p>
                                        <p className="amount">-</p>
                                    </li>
                                    <li>
                                        <p>Total Tax(+)</p>
                                        <p className="amount">₹ 240</p>
                                    </li>
                                    <li>
                                        <p>Net Payable</p>
                                        <p className="amount">₹ 3,240</p>
                                    </li>
                                </ul>
                            </div>
                            <p className="wordAmt">Amount Chargeable (in Words) : Three Thousand Two Hundred Forty only.</p>

                            <p className="signInfo">For Summera Jewels</p>

                            <div className="declaration">
                                <ul>
                                    <li>
                                        <h4>Declaration</h4>
                                        <p>We declare that this invoice shows the actual price of the
                                        goods described and that all particulars are true and correct.</p>
                                    </li>
                                    <li>
                                        <p className="authSign">Authorised Signatory</p>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div className="footerAddress">
                            <p>Registered Address for Summera Jewels</p>
                            <p className="address">Shop No. 10, Rizvi Mahal, R K Patkar Marg - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.</p>
                            <ul>
                                <li>
                                    <p>GSTN No. : 29AAAcp7879d1zs</p>
                                </li>
                                <li>
                                    <p>PAN : MassZ0567K0</p>
                                </li>
                                <li>
                                    <p>State : Maharashtra</p>
                                </li>
                                <li>
                                    <p>Code : 33</p>
                                </li>
                            </ul>
                            <div className="info">
                                <p>For more information on your order or to return an item write email to : <strong>enquiry@summera.in</strong></p>
                                <p>Customer Support : <strong> +91 77700 07841, +91 82918 90911</strong></p>
                            </div>                       
                        </div>

                        <div className="smallInfo">
                            <small>This is a computer generated invoice.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>   

    </div>

  );

};

export default Invoice;