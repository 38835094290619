import React, { useState } from 'react';
import OrderHeader from '../../../atom/myaccountitems/OrderHeader';
import Reactselect from '../../../molecules/select';
import TermsConditions from "../../../molecules/termsConditions";

const CancelOrderData = [
    { id: 1, label: "Select size", value: "selectsize" },
    { id: 2, label: "Not interested now", value: "notinterestednow" },
    { id: 3, label: "I change my mind", value: "ichangemymind" },
    { id: 4, label: "other", value: "other" },
]

const CancelOrderForm = (props) => {

    const [cancelOrder, setCancelOrder] = useState("Select Reason");
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(!modalOpen);
    };

    const [errors, setErrors] = useState({
        errCancelOrder: ""
    });

    const handleStateChange = (name, value) => {
        setCancelOrder(value);
        handleError(name, value);
    }

    const handleError = (name, value) => {
        let allState = errors;
        if (name === "cancelorder") {
            if (!value.length) {
                allState["errCancelOrder"] = "Please select reason";
            }
        }

        setErrors(prevErr => ({
            ...prevErr,
            ...allState
        }))
    }

    return (
        <div className="Order">
            <div className='top-info'>
                <h2>Cancel Order</h2>
                <button type="button" onClick={props.handlingBack} className="backBtnToTab btn btn-secondary">Back</button>
            </div>

            <OrderHeader orderplace={"02 Jan 2023"} ordernumber={"MAZE202471002"} />

            <div className="order-info">
                <div className="order-details">
                    <h4>Are you sure you want to send a cancellation request?</h4>

                    <div className="FormDetails">
                        <p>*Mandatory Fields</p>
                        <form>
                            <ul className="form-row">
                                <li className="form-group return">
                                    <Reactselect
                                        name={"cancelorder"}
                                        id={"cancelOrder"}
                                        label={"Select Reason for Order Cancellation*"}
                                        options={CancelOrderData}
                                        value={cancelOrder}
                                        class={"form-select"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        errorMsg={errors.errBillstate}
                                    />
                                </li>
                            </ul>
                            <div className="form-row">
                                <div className="form-group comments">
                                    <label>Your Comments</label>
                                    <textarea rows="4" cols="50"></textarea>
                                </div>
                            </div>
                        </form>

                        <div className="submitData">
                            <div className="checkbox">
                                <input
                                className="styled-checkbox"
                                id="agree"
                                type="checkbox"
                                checked
                                />
                                <label for="agree">
                                I agree to{" "}
                                <span onClick={handleModalOpen}>
                                    <TermsConditions
                                    modalOpen={modalOpen}
                                    handleModalOpen={handleModalOpen}
                                    />
                                    Terms and Conditions
                                </span>{" "}
                                </label>
                            </div>
                            <button
                                className="btn btn-secondary btn-submit"
                                onClick={() => {
                                props.handleSubmitMessage({
                                    requestModal: "RequestSuccess",
                                    name: "Cancel Order",
                                    msg: "Your Cancel Order request successfully sent to our team."
                                });
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelOrderForm