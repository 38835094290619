import { put } from 'redux-saga/effects';
import loginApi from "../services/postApi";
import {  ADD_VENDOR_FAILED, ADD_VENDOR_RESPONSE, FETCH_CITY_FAILED, FETCH_CITY_RESPONSE, FETCH_COUNTRY_FAILED, FETCH_COUNTRY_RESPONSE, FETCH_MASTER_FAILED, FETCH_MASTER_RESPONSE } from "../redux/types/vendorType";

let api = new loginApi();



export function* countryAndStateSaga({ payload, resolve }) {

    try {
        let data = yield api.CountryData(payload);
        if (data.status === 200) {
            yield put({ type: FETCH_COUNTRY_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            console.log("FETCH_COUNTRY_FAILED", data);
            yield put({ type: FETCH_COUNTRY_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: FETCH_COUNTRY_FAILED, payload: e })

    }
}


export function* masterDataSaga({ payload, resolve }) {

    try {
        let data = yield api.Master(payload);
        if (data.status === 200) {
            yield put({ type: FETCH_MASTER_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            console.log("FETCH_MASTER_FAILED", data);
            yield put({ type: FETCH_MASTER_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: FETCH_MASTER_FAILED, payload: e })

    }
}

export function* cityDataSaga({ payload, resolve }) {

    try {
        let data = yield api.City(payload);
        if (data.status === 200) {
            yield put({ type: FETCH_CITY_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            console.log("FETCH_CITY_FAILED", data);
            yield put({ type: FETCH_CITY_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: FETCH_CITY_FAILED, payload: e })

    }
}


export function* addVendorSaga({ payload, resolve }) {

    try {
        let data = yield api.Vendor(payload);
        if (data.status === 200) {
            yield put({ type: ADD_VENDOR_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            console.log("ADD_VENDOR_FAILED", data);
            yield put({ type: ADD_VENDOR_FAILED, payload: data })
            resolve && resolve(data)
        }
    } catch (e) {
        yield put({ type: ADD_VENDOR_FAILED, payload: e })

    }
}