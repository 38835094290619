import React, {useEffect} from 'react'
import ProductGallery from '../ProductGallery/index';
import { Link } from 'react-router-dom';

const ProductDetails = () =>{
    document.body.classList.add('product_details'); 

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='product_details'>
        <div className="container">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to={'/'}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={'/shop-by-new-arrivals'}>Shop By New Arrivals</Link>
                </li>
                <li className="breadcrumb-item active">Diamond Rings</li>
            </ol>
        </nav>

        <div>
           <ProductGallery />
        </div>
        
    </div>
        </div>
    )
}

export default ProductDetails;
