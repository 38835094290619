import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';
import iconTick from '../../../assets/images/icons/icon_tick.png';
import './index.css'

function CustomiseRequestThankYou() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className='wrapper customise-thank-you'>
        
            <div className='container'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">Thank You</li>
                    </ol>
                </nav>

                <div className='login-box'>
                    <div className="login-wrapper">
                        <h5>Hello Mr. Kaushal Ranpura</h5>

                        <ul className="contact-list">
                            <li>
                                <img src={iconTick} alt="Thank You for registering at Summera" /> <h2>Thank You for customised at Summera</h2>
                            </li>
                        </ul>

                        <h5 className="grant">We will be in touch with the latest collections <span>as per your requirement.</span></h5>

                        <p>Although this is an automated email, We wanted to personally thank you <span>for Customised at  <Link to={'/'}>summera.in</Link> platform.</span></p>

                        <hr />

                        <p className="choice">Thank you for your choice</p>
                        <p className="team">Team, Summera</p>

                    </div>  
                </div> 

            </div>
        </div>
    )
}

export default CustomiseRequestThankYou