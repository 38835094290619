
import React from 'react'
import wedding from '../../assets/images/wedding-jewelry.jpg';
import Manglsutra from '../../assets/images/manglsutra.jpg';
import Gifts from '../../assets/images/gifts.jpg';
import Rings from '../../assets/images/engagement-rings.jpg';
import Bracelets from '../../assets/images/everyday-bracelets.jpg';
import Studs from '../../assets/images/gorgeous-studs.jpg';
import OccasionCard from './ShopOccasion'
import './shopoccassion.css'


export default function index() {
  return (
    <ul className='occasion-list'>
        <OccasionCard
            imgsrc={wedding}
              title="Wedding Jewellery" 
              url="/wedding-jewelry"
        />
        <OccasionCard
            imgsrc={Manglsutra}
              title="Modern Manglsutra" 
              url="/modern-manglsutra"
        />
        <OccasionCard
            imgsrc={Gifts}
              title="Gifts" 
              url="/gifts"
        />
        <OccasionCard
            imgsrc={Rings}
              title="Engagement Rings" 
              url="/engagement-rings"
        />
        <OccasionCard
            imgsrc={Bracelets}
              title="Everyday Wear Bracelets" 
              url="/everyday-wear-bracelets"
        />
        <OccasionCard
            imgsrc={Studs}
              title="Gorgeous Studs" 
              url="/gorgeous-studs"
        />
    </ul>
  )
}
