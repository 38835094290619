import React, {useState, useEffect} from 'react'
import SimilarProduct from '../../../components/SimilarProducts';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import "../../../components/atom/ImgCarousel/Slider.css";
import GorgeousStud from '../../../assets/images/engagement-banner.jpg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import newshoparrival1 from '../../../assets/images/products/rings/ring3.png';
import newshoparrival3 from '../../../assets/images/products/earrings/ear-ring1.png';
import newshoparrival5 from '../../../assets/images/products/rings/ring1.png';
import newshoparrival7 from '../../../assets/images/products/rings/ring3.png';
import newshoparrival9 from '../../../assets/images/products/rings/ring1.png';
import { FiHeart } from 'react-icons/fi';
import { BiRupee } from 'react-icons/bi';
import '../../../components/Card/card.css';
import { useDispatch } from 'react-redux';
import { viewSimilarProductAction } from '../../../redux/actions/cartAction';
import { useMediaQuery } from 'react-responsive';
import SideNav from '../../atom/SideNav';
import SortBy from '../../atom/SortBy';

const Data = [
    { id:1,  src:GorgeousStud, title:"Gorgeous Studs"}   
]

export default function GorgeousStuds() {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const { similarActive  } = useSelector((state)  => state.cart); 

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }  

    const Sdata = [
        {id:1, imgsrc: newshoparrival1, pname:'Diamond Ring', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:2, imgsrc: newshoparrival3, pname:'Earring', price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
        {id:3, imgsrc: newshoparrival5, pname:'Pendant', price: "1200", offerPrice:"", category:"New", discountOffer:"" },
        {id:4, imgsrc: newshoparrival7, pname:'Bangles', price: "2533", offerPrice:"", category:"New", discountOffer:"" },
        {id:5, imgsrc: newshoparrival9, pname:'Bracelets', price: "4251", offerPrice:"", category:"New", discountOffer:"" },
        {id:6, imgsrc: newshoparrival1, pname:'Necklaces', price: "1520", offerPrice:"", category:"New", discountOffer:"" },
        {id:7, imgsrc: newshoparrival1, pname:'Mangalsutra', price: "54,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:8, imgsrc: newshoparrival3, pname:'Chains', price: "1000", offerPrice:"25,551", category:"New", discountOffer:"5" },
        {id:9, imgsrc: newshoparrival5, pname:'Nose Pins', price: "199", offerPrice:"", category:"New", discountOffer:"" },
        {id:10, imgsrc: newshoparrival7, pname:'Engagement Ring', price: "299", offerPrice:"", category:"New", discountOffer:"" },
        {id:11, imgsrc: newshoparrival9, pname:'Fashion Rings', price: "999", offerPrice:"", category:"New", discountOffer:"" },
        {id:12, imgsrc: newshoparrival1, pname:'Casuls Ring', price: "1999", offerPrice:"", category:"New", discountOffer:"" },
        {id:13, imgsrc: newshoparrival1, pname:'Cocktails Ring', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:14, imgsrc: newshoparrival3, pname:'Bands Ring', price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
        {id:15, imgsrc: newshoparrival5, pname:'Studs', price: "989", offerPrice:"", category:"New", discountOffer:"" },
        {id:16, imgsrc: newshoparrival7, pname:'Jumkas', price: "2999", offerPrice:"", category:"New", discountOffer:"" },
        {id:17, imgsrc: newshoparrival9, pname:'Drops', price: "2500", offerPrice:"", category:"New", discountOffer:"" },
        {id:18, imgsrc: newshoparrival1, pname:'Heart', price: "1500", offerPrice:"", category:"New", discountOffer:"" },
        {id:19, imgsrc: newshoparrival1, pname:'Flexible', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:20, imgsrc: newshoparrival3, pname:'Kangan',price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
        {id:21, imgsrc: newshoparrival5, pname:'Hats', price: "200", offerPrice:"", category:"New", discountOffer:"" },
        {id:22, imgsrc: newshoparrival7, pname:'Chandbali', price: "999", offerPrice:"", category:"New", discountOffer:"" },
        {id:23, imgsrc: newshoparrival9, pname:'Nose Ring', price: "899", offerPrice:"", category:"New", discountOffer:"" },
        {id:24, imgsrc: newshoparrival1, pname:'Bracelates', price: "8050", offerPrice:"", category:"New", discountOffer:"" },
        {id:25, imgsrc: newshoparrival5, pname:'Hats', price: "200", offerPrice:"", category:"New", discountOffer:"" },
        {id:26, imgsrc: newshoparrival7, pname:'Chandbali', price: "999", offerPrice:"", category:"New", discountOffer:"" },
        {id:27, imgsrc: newshoparrival9, pname:'Nose Ring', price: "899", offerPrice:"", category:"New", discountOffer:"" },
        {id:28, imgsrc: newshoparrival1, pname:'Bracelates', price: "8050", offerPrice:"", category:"New", discountOffer:"" }
      ] 

      const dispatch = useDispatch();

    const handleClick = event => {
        event.currentTarget.classList.toggle('active');
    };

    const {state} = location;
    // console.log("state",state);
  return (
            <div className='wrapper product'>
                
                <Slider {...settings}>
                    {
                        Data.map((item,index)=>{
                            return(
                                <div className='singleimgslider' key={index}>
                                    <figure>
                                        <img src={item.src} alt={item.title} title={item.title}/>
                                        <div className='container'>
                                            <h3>{state !== null ? state[0] : item.title}</h3>
                                        </div>
                                    </figure>
                                </div>
                            )
                        })
                    }
                </Slider>

               <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">{state !== null ? state[0] : "Gorgeous Studs" }</li>
                        </ol>
                    </nav>
                    <h2 className='title mb-0'>{state !== null ? state[0] : "Gorgeous Studs" }</h2>
                    <p>Our gorgeous studs collection features meticulously crafted earrings that exude sophistication and grace, enhancing your every look with understated glamour.</p>
                    <div className='product-listing'>
                    <div className='left-column desktop'>
                    
                        <SideNav />
                        
                    </div>
                    <div className={`left-column mobile ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
                    {isMobile && (
                        
                        <SideNav />

                        )}
                    </div>
                    <div className='right-column'>
                        <div className='title-sort-option'>
                            <h3 className='title'>{Sdata.length} Products</h3>
                            <SortBy />
                        </div>
                        <div className='product-wrapper'>
                            {
                                Sdata.map((item, index) => {
                                
                                return (
                                <div className='card' key={index}>
                                    <div className='card-header'>
                                        <Link to={'/gorgeous-studs/product-details'}>
                                            <img src={item.imgsrc} className='card-img-top' alt={item.pname} title={item.pname} />
                                        </Link>
                                    </div>
                                    <div className='product-label'>
                                        <ul>
                                            <li className=
                                                {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                            </li>
                                        </ul>
                                        <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                            <FiHeart js-heart="true" onClick={handleClick} />
                                        </span>
                                    </div>
                                    <div className='card-body'>
                                        <p>{item.pname}</p>
                                        <div className='price'><span><BiRupee />{item.price}</span>
                                            {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                        </div>
                                        { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                        <div className='btn-similar'>
                                            <button className='btn' onClick={() => dispatch(viewSimilarProductAction())} >View Similar</button>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                            }
                            <div className='d-flex justify-content-center w-100 mt-3'>
                                <Link to={''} className='btn btn-primary'><span>Load More</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <SimilarProduct similarActive={similarActive}  />
               </div>     
            </div>
        )
}
