import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FaQuoteLeft } from 'react-icons/fa';

const CustomerSays = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const testimonial = [
        {
            "id": 1,
            "quote": "Thank you so much for sending such joy my way. She was impressed from the moment she opened her package. It was wrapped to look like Million Dollars . The packaging is impeccable and the ring itself is awesome.",
            "author": "Ms. Preeti, Gurugram, Haryana"
        },
        {
            "id": 2,
            "quote": "She was impressed from the moment she opened her package. It was wrapped to look like Million Dollars. The packaging is impeccable and the ring itself is awesome.",
            "author": "Ms. Preeti, Gurugram, Haryana"
        },
        {
            "id": 3,
            "quote": "Thank you so much for sending such joy my way. She was impressed from the moment she opened her package. It was wrapped to look like Million Dollars . The packaging is impeccable and the ring itself is awesome.",
            "author": "Ms. Preeti, Gurugram, Haryana"
        },
        {
            "id": 4,
            "quote": "She was impressed from the moment she opened her package. It was wrapped to look like Million Dollars. The packaging is impeccable and the ring itself is awesome.",
            "author": "Ms. Preeti, Gurugram, Haryana"
        },
        {
            "id": 5,
            "quote": "Thank you so much for sending such joy my way. She was impressed from the moment she opened her package. It was wrapped to look like Million Dollars . The packaging is impeccable and the ring itself is awesome.",
            "author": "Ms. Preeti, Gurugram, Haryana"
        }
    ]    

    return (
        <div className='wrapper customer-says'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Customer Says</h1>
                </div>
            </div>

            <div className="container">

                <section className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Customer Says</li>
                            </ol>
                        </nav>

                        <div className="main">
                            <div className="content">

                                {
                                    testimonial.map((items, index) => {
                                        return (
                                            <div className='testimonial-div' key={index}>
                                                <div className='icon'>
                                                    <FaQuoteLeft className="quote-icon" />
                                                </div>
                                                <div className='icon-content'>
                                                    <p>{items.quote}</p>
                                                    <p className='name'>{items.author}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}


export default CustomerSays;