import React,{useState,useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MembershipSubscriptionPlan from '../../../assets/images/membership-subscription-plan.png';
import Plus from '../../../assets/images/icons/plus.png';
import Line from '../../../assets/images/line.png';
import Check from '../../../assets/images/icons/check.svg';
import './index.css'
import  PageLoader from '../../atom/loader/pageLoader'
import Reactselect from '../../molecules/select';
import { ImCross } from 'react-icons/im';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import TermsConditions from "../../molecules/termsConditions";

const ProductData = [
    { id: 1, label: "1", value: 1 },
    { id: 2, label: "2", value: 2 },
    { id: 3, label: "3", value: 3 },
    { id: 4, label: "4", value: 4 },
    { id: 5, label: "5", value: 5 },
    { id: 6, label: "6", value: 6 },
    { id: 7, label: "7", value: 7 },
    { id: 8, label: "8", value: 8 },
    { id: 9, label: "9", value: 9 },
    { id: 10, label: "10",  value: 10 }
]

function SubscriptionPlan() {

    const location = useLocation()

    const { state } = location

    const navigate = useNavigate()
    const [loader,setLoader] = useState(false);
    const [cancelitem, setCancelitem] = useState(0);
    const [selectTotAmt , setSelectTotAmt] = useState(0);
    const [totalAmt, setTotalAmt] = useState(3000);
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => {
        setModalOpen(!modalOpen);
    };

    const handleStateChange = (name, value) => {
        let a = 2000, b = 1000, d = 100;
        let mul = value * d;
        setCancelitem(value);
        setSelectTotAmt(mul)
        let c = a + b + mul;
        setTotalAmt(c)
    }

    const TermsAndConditions = () => (
        <Popup trigger={<Link> *Terms and Conditions</Link>} modal aria-describedby={"pinHome"}>
          {close => (
            <div className='terms-popup'>
                <div className='pop-title'>
                    <h2>Terms and Conditions</h2>
                    <a className="close" onClick={close}><span><ImCross className="closebtn" /></span></a>
                </div>
    
                <div className='pop-body'>
                    <div className='inner-div'>
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>
    
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>
    
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>     
                    </div>  
                </div>
            </div>
          )}
          </Popup>
      );

      const handelFormSubmit = (e) => {
        e.preventDefault()
        navigate('/sell-on-summera/review', {state : state})
    }

  return (
    <div className='wrapper sell-on-summera'>

        <div className='container'>

            <section className='row gap0'>
                <div className='col-md-5'>
                    <figure className='bgImg'>
                        <img src={MembershipSubscriptionPlan} alt='Membership Subscription Plan' />
                    </figure>
                </div>

                <div className='col-md-7'>
                    <div className='shadow-box-rounded'>
                        <div className="topheading">
                            <h5>{`Hi ${state["firstName"]} ${state["lastName"]},`}</h5>
                            <p>Lorem ipsum dummy text lorem ipsum dummy text. Lorem ipsum dummy text lorem ipsum dummy text. Lorem ipsum dummy text lorem ipsum dummy text. </p>
                        </div>

                        <h2>Membership Subscription Plan</h2>

                        <div className='fees'>
                            <ul>
                                <li>
                                    ₹ 2,000
                                    <p><span>One Time</span> Registration Fees</p>
                                </li>
                                <li>
                                    ₹ 1,000
                                    <p><span>Per Month</span> Subscription Fees</p>
                                </li>
                            </ul>
                            <img src={Plus} alt='Plus' className='plus' />
                            <img src={Line} alt='Line' className='line' />
                        </div>

                        <div className='plan-details'>

                            <div className='card'>
                                <div className='card-body'>
                                    <h4>Included Services</h4>
                                    <ul>
                                        <li><img src={Check} alt='10 Products photoshoots services for per year' /> <span> 10 Products photoshoots </span> services for per year </li>
                                        <li><img src={Check} alt='Lorem ipsum dummy text lorem ipsum lorem ipsum.' /> Lorem <span> ipsum dummy </span> text lorem ipsum lorem ipsum. </li>
                                        <li><img src={Check} alt='Lorem ipsum dummy text lorem ipsum' /> <span> Lorem </span> ipsum dummy text lorem ipsum. </li>
                                        <li><img src={Check} alt='Lorem ipsum dummy text lorem ipsum lorem ipsum.' /> Lorem ipsum dummy <span> text lorem </span> ipsum lorem ipsum. </li>                
                                    </ul>
                                </div>
                            </div>

                            <div className="extra-service">
                                <p className='want'>Want <span>more</span> than <span> 10 Products </span> photoshoots service?</p>
                                <ul>
                                    <li>Per Photoshoot <span> ₹ 100 </span></li>
                                    <li>No. of Products 
                                        <span> 
                                            <form>
                                                <ul className="form-row">
                                                    <li className="form-group">
                                                        <Reactselect
                                                            name={"cancelitem"}
                                                            id={"cancelitem"}
                                                            // label={""}
                                                            options={ProductData}
                                                            value={cancelitem}
                                                            class={"form-select"}
                                                            onChange={handleStateChange}
                                                            onBlur={handleStateChange}
                                                            // errorMsg={errors.errcancelitem}
                                                        />
                                                    </li>
                                                </ul>
                                            </form>
                                        </span>
                                    </li>
                                    <li className={`${cancelitem > 0 ? "" : "total" }`}>Total <span>{`₹ ${selectTotAmt}`}</span></li>
                                </ul>
                            </div>

                            <hr />

                            <div className='total-amount'>
                                <p> Total Amount <span> <sup>₹</sup>{totalAmt}</span></p>
                                <button className="btn btn-primary" type="submit" onClick={handelFormSubmit}><span>Proceed to review</span></button>
                                <p className='terms'>
                                    <span>
                                        {TermsAndConditions()}
                                    </span> Apply
                                </p>
                            </div>

                        </div>    
                        
                    </div>
                </div>
            </section>

        </div>
        {
            loader ? <PageLoader /> : null
        }
    </div>
  )
}

export default SubscriptionPlan