import React, {useEffect} from 'react';
import Logo from './components/atom/logo'
import Navbar from './shared/navbar';
import Searchfield from './components/atom/SearchField';
import CartList from './components/atom/CartList';

import Offermsg from './components/atom/Offermsg';
import TopList from './components/atom/TopList';
import DeliveryLocation from './components/atom/DeliveryLocation'
import { useDispatch } from 'react-redux';
import AutoSearchComplete from './components/atom/SearchAutoComplete';

export default function Header() {
  const dispatch = useDispatch()

//   useEffect(() => {
//     dispatch.fetchCategor
//   },[])

  // // Sticky Menu Area
  // useEffect(() => {
  //   window.addEventListener('scroll', isSticky);
  //   return () => {
  //       window.removeEventListener('scroll', isSticky);
  //   };
  // })
  // const isSticky = (e) => {
  //   const header = document.querySelector('.header-section');
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 78 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  // };
  return (
    <header className="dark">
      <div className='topbar'>
        <div className='container'>
          <ul className='topbarwrapper'>
            <Offermsg />
          </ul>
          <TopList />
        </div>      
      </div>
      <div className="top-header">
        <div className="container">
          <ul>
            <li>
                <Logo />
                <DeliveryLocation />
                {/* <Searchfield /> */}
                <AutoSearchComplete />
            </li>
            <li>
                <CartList />
            </li>
			    </ul>
        </div>
      </div>
      <Navbar />
    </header>
  )
}
