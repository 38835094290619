import React from 'react';
import { Link } from 'react-router-dom';
// import Help from '../assets/images/need-help1.png';
// import Eporium from '../assets/images/eporium-logo.png';
import { FaMobileAlt } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import FooterLogo from '../assets/images/footer-logo.png';
import SummeraPromise from '../components/SummeraPromise';
// import {EmailShareButton} from "react-share";
// import {FacebookShareButton} from "react-share";
// import {LinkedinShareButton} from "react-share";
// import {TwitterShareButton} from "react-share";

const Footer = () => {
  return (
    <>
    <div className='summera-promise spacer'>
        <h2 className='text-center'>The Summera Promise</h2>
        <div className='container'>
          <SummeraPromise />
        </div>
      </div>
      <footer>
        <div className="container">
          <ul className="footer-list">
            <li className="SummeraSocial">
              <div className="Footerlogo">
                <img src={FooterLogo} alt="Summera" title="Summera" />
              </div>
              <h5 className="follow-heading">Follow us on</h5>
              <ul className="social-link">
                <li className="youtube"><Link to={'https://www.youtube.com/channel/UCXIGx7jmMwupGP5uQn7HMiQ/videos'} target="_blank" rel="noreferrer"><FaYoutube /></Link></li>
                <li className="insta"><Link to={'https://www.instagram.com/Summeraofficial'} target="_blank" rel="noreferrer"><FaInstagram /></Link></li>
                <li className="linkedin"><Link to={'https://www.linkedin.com/in/summera-e-commerce-platform-b65a34219/'} target='_blank'><FaLinkedin /></Link></li>
                <li className="facebook"><Link to={'https://www.facebook.com/Summeraofficial'} target='_blank'><FaFacebookSquare /></Link></li>
                <li className="twitter"><Link to={'https://twitter.com/JewelsSummera'} target='_blank'><FaTwitterSquare /></Link></li>
              </ul>
              <h5 className="stay">Stay Updated</h5>
              <div className="input-group subscribe">
                <input type="text" id="email-address" className="form-control" placeholder="Enter your email address" />
                <Link to={'/'} className="btn btn-primary" type="button"><span>SUBSCRIBE</span></Link>
              </div>
            </li>
            <li>
              <ul className="LinkFooter">
                <li>
                  <h5>SUMMERA</h5>
                  <ul className="footer-link">
                    <li><Link to={'/about-summera'}>About Summera</Link></li>
                    <li><Link to={'/sell-on-summera'}>Sell on Summera</Link></li>
                    <li><Link to={'/customer-says'}>Customer Says</Link></li>
                    <li><Link to={'/whats-new'}>What’s New</Link></li>
                  </ul>
                </li>
                <li>
                  <h5>Help</h5>
                  <ul className="footer-link">
                    <li><Link to={'find-store'}>Find Store</Link></li>
                    <li><Link to={'customer-service'}>Customer Service</Link></li>
                    <li><Link to={'/faqs'}>FAQs</Link></li>
                    <li><Link to={'/contact-us'}>Contact Us</Link></li>
                    <li><Link>Sitemap</Link></li>
                  </ul>
                </li>
                <li>
                  <h5>Policies</h5>
                  <ul className="footer-link">
                    <li><Link to={'/orders-and-returns'}>Order and Returns</Link></li>
                    <li><Link to={'/payment-policy'}>Payment Policy</Link></li>
                    <li><Link to={'/shipping-policy'}>Shipping Policy</Link></li>
                    <li><Link to={'/terms-and-conditions'}>Terms and Conditions</Link></li>
                    <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                    <li><Link to={'/cookie-policy'}>Cookies Policy</Link></li>
                  </ul>
                </li>
                <li className='ContactFooter'>
                  <h5>Contact Us</h5>
                  <ul className="footer-link">
                    <li className="address"><ImLocation className="Location" />Shop No. 10, Rizvi Mahal, R K Patkar Marg - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.</li>
                    <li><FaMobileAlt className="Icon" /><Link to={'tel:+91 7770007841'}>+91 77700 07841</Link>, <Link to={'tel:+91 8291890911'}>+91 8291890911</Link></li>
                    <li><IoMdCall className="Icon" /><Link to={'tel:02235080525'}>022-35080525</Link></li>
                    <li><MdEmail className="Icon" /><span>enquiry@summera.in</span></li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="copyright">
            <li>Copyright © 2022 <strong>Summera.</strong> <span>All rights reserved.</span></li>
            <li>Powered by <Link to={'https://www.sublimis.tech/'} target="_blank" rel="noreferrer"><strong>Sublimis</strong>
              {/* <img src={Eporium} className="eporium-img" alt="eporium" title="Eporium" /> */}
            </Link></li>
          </ul>
        </div>

      </footer>
    </>
  )
}

export default Footer;