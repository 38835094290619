import React from 'react'
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './components';
import { store }  from './redux/store'
// import swDev from './swDev';
import './assets/Scss/mediaStyle.css';
import './assets/Scss/style.scss';


createRoot(document.getElementById('root'))
.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
     </React.StrictMode>
)

reportWebVitals();
// swDev();