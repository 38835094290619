import React, { useState } from "react";
import Reactselect from '../select';
// import Empty from '../../atom/empty';
// import EmptyImage from '../../../assets/images/myaccount/customise.svg';
import goldRing from '../../../assets/images/gold-ring.jpg';
import { Link } from "react-router-dom";
import './customiseorder.css';

// let arr = []

function MyCustomiseOrder (){

    const myCustomiseOrder = [
        { id:1, date: "12 Jan 2023", quote_no: "MAZE202471002", src:goldRing, name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", qty: "1", remark: "Lorem Ipsum Is Of And Typesetting", call_status: "Open"},
        { id:2, date: "12 Jan 2023", quote_no: "MAZE202471002", src:goldRing, name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", qty: "1", remark: "Lorem Ipsum Is Of And Typesetting", read_more: "login", call_status: "Open"}
    ];

    return(
         <>
         <h5>My Customise Order</h5>
         {
            //  arr.length ? <>
            <div className="Data">
            <FilterMonth/>
            <div className="Table table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Quote No.</th>
                        <th>Product Details</th>
                        <th>Qty</th>
                        <th>Remark</th>
                        <th>Order Status</th>
                    </tr>
                </thead>
                <tbody>

                <>

                    {
                        myCustomiseOrder.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <td><p>{item.date}</p></td>
                                    <td><p>{item.quote_no}</p></td>
                                    <td>
                                        <ul>
                                            <li><img src={item.src} alt={item.name} title={item.name} /></li>
                                            <li>
                                                <p className="name">{item.name}</p>
                                                <p className="detail">{item.detail}</p>
                                            </li>
                                        </ul>
                                    </td>
                                    <td><p>{item.qty}</p></td>
                                    <td>
                                        <p className="remark">{item.remark} <Link to={item.read_more}>Read More</Link> </p>
                                    </td>
                                    <td><span className="open">{item.call_status}</span></td>
                                </tr>
                            )
                        })
                    }

                </>

                </tbody>
            </table>
            </div>
          </div>
            //    </> : <Empty imgsrc={EmptyImage} content={"No Customise Order Found Yet"} />
         }
         </>   
    )

}
export default MyCustomiseOrder;

// Last month drop down section 
export const FilterMonth = () => {

    const sortData = [
        { id: 1, label: "Past 3 Month", value: "past3month" },
        { id: 2, label: "Past 6 Month", value: "past6month" },
        { id: 3, label: "Past 9 Month", value: "past9month" },
        { id: 4, label: "Past 12 Month", value: "past12month" },
    ]

    const [selectMonth, setselectMonth] = useState(sortData[3].value);
    const handleStateChange = (name, value) => {
        setselectMonth(value);
    }
    const handleError = () => { }


    return (
        <div className="filterMonth">
            <Reactselect
                name={"selectMonth"}
                id={"selectMonth"}
                label={"Showing <span>3 orders</span> in"}
                options={sortData}
                value={selectMonth}
                lblClass={"input-lable"}
                onChange={handleStateChange}
                onBlur={handleError}
                class={"form-control"}
            />
        </div>
    )
}
