import React, {useEffect} from 'react'
import MyAccountPage from '../../components/organism/myAccount';

const MyAccount = () =>{

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='main-page'>
            <div className="container my_account">
                <MyAccountPage />
            </div>
        </div>        
    )
}

export default MyAccount;
