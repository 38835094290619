import Api from '.';
import { endpoints } from './endpoints';

// const token = localStorage.getItem("token")

export default class postApi extends Api {

    Auth(data) {
        let url = this.buildUrl(endpoints.AuthUrl.auth)
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }
    Register(data) {
        let url = this.buildUrl(endpoints.Register.UserRegister)
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }
    SendUserOtp(data) {
        let url = this.buildUrl(endpoints.Login.SendUserOtp)
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }
    VerifyUserOtp(data) {
        let url = this.buildUrl(endpoints.Login.VerifyUserOtp)
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }

    Posts(data){
        let url = this.buildUrl(endpoints.Posts.posts)
        return this.fetch(url, "GET", null, data).then(response => response)
    }

    HomeApi(data){
        let url = this.buildUrl(endpoints.HomeUrl.homePage)
        return this.fetch(url, "GET", null, data ).then(response => response)
    }

    CountryData(data){
        let url = this.buildUrl(endpoints.Vendor.countryAndState)
        return this.fetchNormal(url, "GET", null, data ).then(response => response)
    }


    Master(data){
        let url = this.buildUrl(endpoints.Vendor.master)
        return this.fetchNormal(url, "GET", null, data ).then(response => response)
    }


    City(data){
        let url = this.buildUrl(endpoints.Vendor.city)
        if(data.param){
            return this.fetchParams(url, "GET", null, `/${data.param}` ).then(response => response)
        }
    }

    Vendor(data) {
        let url = this.buildUrl(endpoints.Vendor.vendor)
        return this.fetchFile(url, "POST", (data)).then(response => response)
    }

    // GetRegisteredBanks(data) {
    //     let url = this.buildUrl(endpoints.quickbuy.GetRegisteredBanks)
    //     return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    // }
    // folioBrokerList(data) {
    //     let url = this.buildUrl(endpoints.quickbuy.folioBrokerList)
    //     return this.fetch(url, "GET", null, data).then(response => response)
}