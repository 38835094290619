import * as type from "../types/addtocart"

const initialState = {
    isActive: false,
    similarActive: false,
    cartLength:0,

}


const  cartReducer = (state = initialState, action) => {

    switch (action.type) {
         case type.ADD_TO_CART :{
        //     const product = action.payload;
        //     const cart = state.cart;
        //     const existingProductIndex = findProductIndex(cart, product.id);
        //     const updatedCart = existingProductIndex >= 0 ? updateProductUnits(cart, product) : discount(cart, product);
        //     const totalAmount = grandAmountFun(updatedCart);
        //     const totalDiscount = totalDiscountAmt(updatedCart);
        //     const afterDisAmt = totalAmtAfterDiscount(updatedCart);
        //     return {...state, cart: updatedCart, cartLength : updatedCart.length, totalAmount, grandDiscount: totalDiscount, grandTotalAfterDis: afterDisAmt };
        
        return {...state,cartLength : state.cartLength+1}
    }
        
        // case type.REMOVE_TO_CART: {
        //     const product = action.payload;
        //     const cart = state.cart;
        //     let new_items = cart.filter(item=> product.id !== item.id)
        //     const totalAmount = grandAmountFun(new_items);
        //     const totalDiscount = totalDiscountAmt(new_items);
        //     const afterDisAmt = totalAmtAfterDiscount(new_items);
        //     return { ...state, cart: new_items, cartLength: new_items.length, totalAmount, grandDiscount: totalDiscount, grandTotalAfterDis: afterDisAmt }
        // }

        // case type.ADD_TO_WISHLIST :{
        //     const product = action.payload;
        //     const wishlist = state.wishlist;
        //     const existingProductIndex = findProductIndex(wishlist, product.id);
        //     const updatedWishlist = existingProductIndex >= 0 ? [...wishlist] : [...wishlist, product];
        //     return {...state, wishlist: updatedWishlist, wishlistlength : updatedWishlist.length};
        // }
        

        // case type.REMOVE_TO_WISHLIST: {
        //     const product = action.payload;
        //     const wishlist = state.wishlist;
        //     let new_items = wishlist.filter(item=> product.id !== item.id)
        //     return { ...state, wishlist: new_items, wishlistlength: new_items.length }
        // }

        case type.ACTIVE_CART_VIEW: {
            // console.log("ACTIVE_CART_VIEW",state.isActive )
            return { ...state, isActive: !state.isActive }
        }

        case type.ACTIVE_SIMILAR_PRODUCT: {
            return { ...state, similarActive: !state.similarActive }
        }

        // case type.UNIT_OF_PURCHASE: {
        //     let product = action.payload;
        //     let cart = state.cart;
        //     let updatedCart = updateProductUnits(cart,product);
        //     let totalAmount = grandAmountFun(updatedCart);
        //     return { ...state, cart: updatedCart, totalAmount }
        // }
        default:
            return state
    }
}

export default cartReducer


// const findProductIndex = (cart, productID) => {
//     return cart.findIndex(p => p.id === productID);
// };

// const grandAmountFun = (cart) => {
//     console.log("updatedCartupdatedCart", cart)
//     return cart.reduce((total,sum) => parseInt(total) + parseInt(sum.priceofProduct), 0 )
// }

// const totalDiscountAmt = (cart) => {
//     return cart.reduce((total,sum) =>  parseInt(total) + parseInt(sum.totalDiscount) , 0 )
// }

// const totalAmtAfterDiscount = (cart) => {
//     return cart.reduce((total,sum) =>  parseInt(total) + parseInt(sum.afterDiscountAmu) , 0 )
// }

// const updateProductUnits = (cart, product) => {
//     const productIndex = findProductIndex(cart, product.id);
//     const updatedCart = [...cart];
//     const existingProduct = updatedCart[productIndex];
//     const priceOfProduct = parseInt(existingProduct.units) * parseInt(product.price)
//     let totDis = 0, afterDisAmt= 0, dis = product.discount, prodAmt = product.priceofProduct, totalDiscount = 0;

//     if(product.hasOwnProperty("discount")){
//         totDis = (parseFloat(prodAmt) * parseFloat(dis)) / 100;
//         afterDisAmt = parseFloat(prodAmt) - parseFloat(totDis);
//         totalDiscount = parseFloat(prodAmt) - afterDisAmt;
//     }else{
//         afterDisAmt = prodAmt;
//         totalDiscount = 0;
//     }
    
//     afterDisAmt = parseFloat(prodAmt) - parseFloat(totDis);
//     totalDiscount = parseFloat(prodAmt) - afterDisAmt;

//     const updatedUnitsProduct = {
//         ...existingProduct,
//         unitOfPurchase: parseInt(product.unitOfPurchase),
//         priceOfProduct: priceOfProduct,
//         afterDiscountAmu: afterDisAmt,
//         totalDiscount: totalDiscount
//     };

//     updatedCart[productIndex] = updatedUnitsProduct;

//     return updatedCart;
// };

// const discount = (cart, product) => {
//     const updatedCart = [...cart, product];
//     updatedCart.forEach(item => {
//         let totDis = 0;
//         let afterDisAmt = 0;
//         let dis = item.discount;
//         let prodAmt = item.priceofProduct;
//         if(item.hasOwnProperty("discount" ) ){
//             totDis = (parseFloat(prodAmt) * parseFloat(dis)) / 100;
//             afterDisAmt = parseFloat(prodAmt) - parseFloat(totDis)
//             item.afterDiscountAmu = afterDisAmt; 
//             item.totalDiscount = parseFloat(prodAmt) - afterDisAmt
//         }else{
//             item.afterDiscountAmu = prodAmt; 
//             item.totalDiscount = 0
//         }
        
//     })
//     return [ ...updatedCart]
// }