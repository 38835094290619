import React from "react";
// import './videoCall.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RingBanner from '../../../assets/images/ring-banner1.jpg';
import RingImg from '../../../assets/images/step1.png';
import RingImg1 from '../../../assets/images/step2.png';
import RingImg2 from '../../../assets/images/step3.png';
import RingImg3 from '../../../assets/images/ring-img.png';

// let arr = []

function FindYourRingSize(){

    return(
            <>
                <div className='maindiv'>
                    <figure className='bannerimg'>
                        <img src={RingBanner} alt='Ring Banner' title='Ring Banner'/>
                    </figure>
                    <div className='container'>
                        <div className='Ring-tabs'>
                            <Tabs>
                                <TabList>
                                    <Tab>1. If you Don’t Have a Ring</Tab>
                                    <Tab>2. If you Have a Ring</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className='inner-wrapper'>
                                        <p className='text-center'>A thin strip of paper, a pen and a ruler</p>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h2>Step 1</h2>
                                                <p>Wrap a thin strip of paper around your finger.</p>
                                            </div>
                                            <div className='col-md-6'>
                                                <figure>
                                                    <img src={RingImg} alt='Ring' title='Ring'/>
                                                </figure>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h2>Step 2</h2>
                                                <p>Mark the meeting point with a pen.</p>
                                            </div>
                                            <div className='col-md-6'>
                                                <figure>
                                                    <img src={RingImg1} alt='Ring' title='Ring'/>
                                                </figure>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h2>Step 3</h2>
                                                <p>Measure the length with a ruler.</p>
                                            </div>
                                            <div className='col-md-6'>
                                                <figure>
                                                    <img src={RingImg2} alt='Ring' title='Ring'/>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='graybg'>
                                        <p>You now have the circumference of your ring, use the following chart to determine your ring size.</p>
                                        <h3>Sizes are for Indian Scale</h3>
                                    </div>
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Ring size</th>
                                                <th colSpan={2}>Circumference</th>
                                                <th>Ring size</th>
                                                <th colSpan={2}>Circumference</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>(mm)</th>
                                                <th>(inches*)</th>
                                                <th></th>
                                                <th>(mm)</th>
                                                <th>(inches*)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>6</td>
                                                <td>45.9</td>
                                                <td>1.81</td>
                                                <td>19</td>
                                                <td>59.1</td>
                                                <td>2.33</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>47.1</td>
                                                <td>1.86</td>
                                                <td>20</td>
                                                <td>60.0</td>
                                                <td>2.36</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>48.1</td>
                                                <td>1.89</td>
                                                <td>21</td>
                                                <td>60.9</td>
                                                <td>2.4</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>49.0</td>
                                                <td>1.93</td>
                                                <td>22</td>
                                                <td>61.9</td>
                                                <td>2.44</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>50.0</td>
                                                <td>1.97</td>
                                                <td>23</td>
                                                <td>62.8</td>
                                                <td>2.47</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>50.9</td>
                                                <td>2</td>
                                                <td>24</td>
                                                <td>59.1</td>
                                                <td>2.33</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>51.8</td>
                                                <td>2.04</td>
                                                <td>25</td>
                                                <td>64.7</td>
                                                <td>2.55</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>52.8</td>
                                                <td>2.08</td>
                                                <td>26</td>
                                                <td>66.0</td>
                                                <td>2.6</td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>54.0</td>
                                                <td>2.13</td>
                                                <td>27</td>
                                                <td>66.9</td>
                                                <td>2.63 </td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>55.0</td>
                                                <td>2.16</td>
                                                <td>28</td>
                                                <td>67.9</td>
                                                <td>2.67</td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td>55.9</td>
                                                <td>2.2</td>
                                                <td>29</td>
                                                <td>69.1</td>
                                                <td>2.72</td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td>56.9</td>
                                                <td>2.24</td>
                                                <td>30</td>
                                                <td>70.1</td>
                                                <td>2.76</td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td>57.8</td>
                                                <td>2.28</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='btm-content'>
                                        <p>The measurement of Length is in millimeter as per Legal Metrology Act, 2009.</p> 
                                        <p>For convenience of customers its conversion to length value is also reflected</p>
                                    </div>   
                                </TabPanel>
                                <TabPanel className="have-ring">
                                    <div className='inner-wrapper'>
                                        <div className='row'>
                                            <div className='col-md-6 d-flex'>
                                                <ul>
                                                    <li>Place the ring</li>
                                                    <li>Measure the largest on the ruler.</li>
                                                    <li>Measure the distance from center between the inner edges of your ring.</li>
                                                </ul>
                                            </div>
                                            <div className='col-md-6'>
                                                <figure>
                                                    <img src={RingImg3} alt='Ring' title='Ring'/>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='graybg'>
                                        <p>You now have the circumference of your ring, use the following chart to determine your ring size.</p>
                                        <h3>Sizes are for Indian Scale</h3>
                                    </div>
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Ring size</th>
                                                <th>Diameter <span>(in mm)</span></th>
                                                <th>Ring size</th>
                                                <th>Diameter <span>(in mm)</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>6</td>
                                                <td>14.6</td>
                                                <td>19</td>
                                                <td>18.4</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>15.0</td>
                                                <td>20</td>
                                                <td>19.1</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>15.3</td>
                                                <td>21</td>
                                                <td>19.4</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>15.6</td>
                                                <td>22</td>
                                                <td>19.7</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>15.9</td>
                                                <td>23</td>
                                                <td>20.0</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>16.2</td>
                                                <td>24</td>
                                                <td>20.3</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>16.5</td>
                                                <td>25</td>
                                                <td>20.6</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>16.8</td>
                                                <td>26</td>
                                                <td>21.0</td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>17.2</td>
                                                <td>27</td>
                                                <td>21.3</td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>17.5</td>
                                                <td>28</td>
                                                <td>21.6</td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td>17.8</td>
                                                <td>29</td>
                                                <td>22.0</td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td>18.1</td>
                                                <td>30</td>
                                                <td>22.3</td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td>18.4</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='btm-content'>
                                        <p>The measurement of Length is in millimeter as per Legal Metrology Act, 2009.</p> 
                                        <p>For convenience of customers its conversion to length value is also reflected</p>
                                    </div>   
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </>   
    )
}
export default FindYourRingSize;