import newshoparrival1 from '../../assets/images/products/rings/ring3.png';
import newshoparrival2 from '../../assets/images/products/rings/ring2.png';
import newshoparrival3 from '../../assets/images/products/rings/ring1.png';
import newshoparrival4 from '../../assets/images/products/rings/ring4.png';
import newshoparrival5 from '../../assets/images/products/rings/ring1.png';
import newshoparrival6 from '../../assets/images/products/rings/ring2.png';
import newshoparrival7 from '../../assets/images/products/rings/ring3.png';
import newshoparrival8 from '../../assets/images/products/rings/ring4.png';
import newshoparrival9 from '../../assets/images/products/rings/ring1.png';
import newshoparrival10 from '../../assets/images/products/rings/ring2.png';

import NewShopArrival1 from '../../assets/images/new-arrvial-1.jpg';
import NewShopArrival2 from '../../assets/images/new-arrvial-2.jpg';
import NewShopArrival3 from '../../assets/images/new-arrvial-3.jpg';
import NewShopArrival4 from '../../assets/images/new-arrvial-4.jpg';
import NewShopArrival5 from '../../assets/images/new-arrvial-5.jpg';
import RingImgFlow from '../../assets/images/ring-prod-detail-11.jpg';

const Sdata = [
    {id:1, imgsrc: RingImgFlow, pname:'Rings', price: "30,999", offerPrice:"35,999", category:"New", discountOffer:"10"},
    {id:2, imgsrc: NewShopArrival2, pname:'Pendants', price: "40,000", offerPrice:"", category:"Trending",  discountOffer:"" },
    {id:3, imgsrc: NewShopArrival3, pname:'Kada', price: "40,000", offerPrice:"47,551", category:"New", discountOffer:"5" },
    {id:4, imgsrc: NewShopArrival4, pname:'Pendants', price: "6,770", offerPrice:"", category:"Trending", discountOffer:"" },
    {id:4, imgsrc: NewShopArrival5, pname:'Gold Rings', price: "6,770", offerPrice:"", category:"Trending", discountOffer:"" },
    {id:5, imgsrc: NewShopArrival1, pname:'Earrings', price: "30,999", offerPrice:"35,999", category:"New", discountOffer:"10"},
    {id:6, imgsrc: NewShopArrival2, pname:'Pendants', price: "40,000", offerPrice:"", category:"Trending",  discountOffer:"" },
    {id:7, imgsrc: NewShopArrival3, pname:'Ring', price: "40,000", offerPrice:"47,551", category:"New", discountOffer:"5" },
    {id:8, imgsrc: NewShopArrival4, pname:'Pendants', price: "6,770", offerPrice:"", category:"Trending", discountOffer:"" },
  ] 

  export default Sdata

  export const Content = [
  {
    "id": "1",
    "slug_name": "prodcut_title_slugname",
    "name": "Product1",
    "code": "0PRD1",
    "price": "500",
    "currency": "Rs",
    "cover_image": newshoparrival1,
    "wishlist": false,
    "units": "1",
    "discount": "5",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "500",
    "size": "H 13 x W 35 x L 24",
    "product_details": [
      {"width": "6.3mm" } ,
      {"height": "10.4mm" } ,
      {"purity": "18kt" } ,
      {"gross_weight": "3.302g" } ,
      {"size": "12(51.8mm)" } ,
    ],
    "price_breakup": [
      {"width": "6.3mm" } ,
      {"height": "10.4mm" } ,
      {"purity": "18kt" } ,
      {"gross_weight": "3.302g" } ,
      {"size": "12(51.8mm)" } ,
    ],
    "media": [
      newshoparrival1,
      newshoparrival2,
      newshoparrival3,
      newshoparrival4
    ]
  },
  {
    "id": "2",
    "slug_name": "prodcut_title_slugname",
    "name": "Product2",
    "code": "0PRD2",
    "price": "5000",
    "currency": "Rs",
    "cover_image": newshoparrival2,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "5000",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival2,
      newshoparrival1,
      newshoparrival3,
      newshoparrival4
      ]
  },
  {
    "id": "3",
    "slug_name": "prodcut_title_slugname",
    "name": "Product3",
    "code": "0PRD3",
    "price": "1500",
    "currency": "Rs",
    "cover_image": newshoparrival3,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "1500",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival3,
      newshoparrival2,
      newshoparrival1,
      newshoparrival4
      ]
  },
  {
    "id": "4",
    "slug_name": "prodcut_title_slugname",
    "name": "Product4",
    "code": "0PRD4",
    "price": "1800",
    "currency": "Rs",
    "cover_image": newshoparrival4,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "1800",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival4,
      newshoparrival3,
      newshoparrival2,
      newshoparrival1
      ]
  },
  {
    "id": "5",
    "slug_name": "prodcut_title_slugname",
    "name": "Product5",
    "code": "0PRD5",
    "price": "2000",
    "currency": "Rs",
    "cover_image": newshoparrival5,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "2000",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival5,
      newshoparrival7,
      newshoparrival8,
      newshoparrival9
      ]
  },
  {
    "id": "6",
    "slug_name": "prodcut_title_slugname",
    "name": "Product6",
    "code": "0PRD6",
    "price": "1900",
    "currency": "Rs",
    "cover_image": newshoparrival6,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "1900",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival6,
      newshoparrival7,
      newshoparrival8,
      newshoparrival9
      ]
  },
  {
    "id": "7",
    "slug_name": "prodcut_title_slugname",
    "name": "Product7",
    "code": "0PRD7",
    "price": "5200",
    "currency": "Rs",
    "cover_image": newshoparrival7,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "5200",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival7,
      newshoparrival8,
      newshoparrival6,
      newshoparrival9
      ]
  },
  {
    "id": "8",
    "slug_name": "prodcut_title_slugname",
    "name": "Product8",
    "code": "0PRD8",
    "price": "5000",
    "currency": "Rs",
    "cover_image": newshoparrival8,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "5000",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival8,
      newshoparrival7,
      newshoparrival6,
      newshoparrival9
      ]
  },
  {
    "id": "9",
    "slug_name": "prodcut_title_slugname",
    "name": "Product9",
    "code": "0PRD9",
    "price": "5100",
    "currency": "Rs",
    "cover_image": newshoparrival9,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "5100",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival9,
      newshoparrival8,
      newshoparrival7,
      newshoparrival6,
      ]
  },
  {
    "id": "10",
    "slug_name": "prodcut_title_slugname",
    "name": "Product10",
    "code": "0PRD10",
    "price": "3000",
    "currency": "Rs",
    "cover_image": newshoparrival10,
    "wishlist": false,
    "units": "1",
    "discount": "10",
    "priceofDiscount": "",
    "unitOfPurchase": "1",
    "priceofProduct": "3000",
    "size": "H 13 x W 35 x L 24",
    "media": [
      newshoparrival10,
      newshoparrival8,
      newshoparrival7,
      newshoparrival6,
      ]
  },
]