import {React,useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from  '../../components/molecules/input'
import Checkbox from '../../components/atom/checkbox/input'
import { alphabatesWithSpace, isValidEmail, isValidMobileNo, numberText } from "../../helper/method";
import Reactselect from "../../components/molecules/select";
import { HiMapPin } from "react-icons/hi2";
import { BsFillClockFill } from "react-icons/bs";
import { FaMobileAlt } from "react-icons/fa";
import { AiFillGift } from "react-icons/ai";
// import AddressList from '../../components/molecules/addressForm/addressList';
import {BiRupee} from "react-icons/bi";
import {FaCaretRight} from "react-icons/fa";
import {FaCaretDown} from "react-icons/fa";
// import { useSelector } from 'react-redux';
import './index.css'
import '../../components/Mycart/index.css'
import { IoClose } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';

const stateData = [
    {id: 1, value: "maharashtra", label: "Maharashtra"},
    {id: 2, value: "gujarat", label: "Gujarat"},
    {id: 3, value: "himanchal", label: "Himanchal"},
    {id: 4, value: "utterprdesh", label: "UP"},
]

const cityData = [
    {id: 1, value: "thane", label: "Thane"},
    {id: 2, value: "vapi", label: "Vapi"},
    {id: 3, value: "sodhi", label: "Sodhi"},
    {id: 4, value: "deoria", label: "Deoria"},
]

const Order =[
    {id:1, sub_total:'Sub Total',sub_total_value:'1,09,999',discount:'Discount',discount_value:'10,000',saved_with_promo:'Saved with Promo',saved_value:'5,999',delivery_charges:'Delivery Charges',delivery_charges_value:'-',gst:'GST',gst_value:'5,999',total_amount:'Total Amount',total_amount_value:'1,05,998',}
]

const Promocodes =[
    {id:1, code:'First10',save_value:'301',percent_off:'10',min_purchase_value:'',expire_date:'12th March 2023'},
    {id:2, code:'Summera40',save_value:'5,998',percent_off:'40',min_purchase_value:'100000',expire_date:'15th March 2023'},
    {id:3, code:'Summera20',save_value:'602',percent_off:'20',min_purchase_value:'1500',expire_date:'15th March 2023'},
    {id:4, code:'Summera30',save_value:'851',percent_off:'30',min_purchase_value:'5500',expire_date:'15th March 2023'},
    {id:5, code:'First20',save_value:'301',percent_off:'20',min_purchase_value:'',expire_date:'12th March 2023'},
    {id:6, code:'First30',save_value:'5,998',percent_off:'30',min_purchase_value:'100000',expire_date:'15th March 2023'}
]


function Checkout() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    // const isMobile = useMediaQuery({ maxWidth: 767 });

    // const handleToggle = () => {
    //     setIsOpen(!isOpen);
    // };
// document.body.classList.add('checkout');
const [active, setActive] = useState(false)

let hasError = false;

const [fullName, setFullName] = useState("");
const [email, setEmail] = useState("");
const [stdcode, setStdcode] = useState("+91");
const [mobile, setMobile] = useState("");
const [pin, setpin] = useState("");
const [promoModal, setPromoModal] = useState(false);


const [addressId, setAddressId] = useState(false);
// const { address: addressList, lastOrderId } = useSelector((state) => state.address);
const [addressList, setAddressList] = useState([]);
const [showaddress, setShowaddress] = useState(true);
const [selected, setSelected] = useState("shipping")
const [radioselected, setradioSelected] = useState("residential")
const [Radiobtn,setRadiobtn] = useState('');
const [Textbox,setTextbox] = useState('');
const [checkbox,setCheckbox] = useState(false);
const [promocheckbox,setPromoCheckbox] = useState(false);

const [address, setAddress] = useState("");
const [optionalAddress, setOptionalAddress] = useState("");
const [state, setState] = useState("");
const [city, setCity] = useState("");
const [pincode, setPincode] = useState("");

const [officename, setOfficeName] = useState("");
const [personName, setPersonName] = useState("");
const [otherField, setOtherField] = useState("");

const [companyName, setcompanyName] = useState("");



const[promoshow, setPromoshow] = useState(false);
const [promoapply, setPromoapply] = useState(false);
const [promo, setPromo] = useState("");
const [gstn, setGstn] = useState("");

// Apply promocode show hide
const handlePromo = e =>{
    setPromoshow(!promoshow);
    setPromoModal(!promoModal)
    setPromoapply(true)
}

const validateCode = () => {
    setPromoshow(false)
    setPromoapply(true)
}

const handleRemove = () => {
    setPromoshow(false)
    setPromoapply(false)
}

//Checkbox click on enabled and disabled button
//const [isDisabled, setIsDisabled] = useState(true);
//const [checked, setChecked] = useState(false);

const [errors, setErrors] = useState({
    errfullName: "",
    errEmail: "",
    errMobileno: "",
    errAdress:"",
    errOptionalAddress:"",
    errPincode:"",
    errState:"",
    errCity:"",
    errCompanyName:"",
    errCompanyPerson:"",
    errPersonName:"",
    errPartnerName:"",
    errOtherField:"",
    errpin: "",
    errcompanyName:"",
    errGSTNNo:"",
}) 

useEffect(() => {
    addressList.forEach(item => {
        if(item.id === addressId){
            setFullName(item.fullName);
            setAddress(item.address)
            setOptionalAddress(item.optionalAddress)
            setState(item.state)
            setCity(item.city)
            setPincode(item.pincode)
        }
    })
    // setCheckOutDetails({
    //     ...checkOutDetails,
    //     products: [...cart]
    // })

}, [addressId])

const handleStateChange = (name, value) => {

    if (name === "fullName") {
        let check = String(value)
        if (alphabatesWithSpace(check)) {
            setFullName(check)
            handleError(name, value)
        }
    }
    if(name === "email"){
        setEmail(value)
        handleError(name,value)
    }
    if(name === "mobile"){
        let check = value
        if(numberText(check)){
            setMobile(check)
            handleError(name,value)
        }
    }
    if (name === "Radiobtn") {
        let check = value
            setRadiobtn(check)
    }

    if(name === "address"){
        setAddress(value)
        handleError(name,value)
    }
    if(name === "optionalAddress"){
        setOptionalAddress(value)
        handleError(name,value)
    }
    if(name === "pincode"){
        let check = value
        if(numberText(check)){
            setPincode(check)
            handleError(name,value)
        }
    }
    if(name === "state"){
        setState(value)
        handleError(name,value)
    }
    if(name === "city"){
        setCity(value)
        handleError(name,value)
    }
    if(name === "officename"){
        setOfficeName(value)
        handleError(name,value)
    }
    if(name === "otherField"){
        setOtherField(value)
        handleError(name,value)
    }
    if(name === "personName"){
        setPersonName(value)
        handleError(name,value)
    }
    if(name === "companyName"){
        setcompanyName(value)
        handleError(name,value)
    }
    if (name === "promoCode") {
        setPromo(value)
        handleError(name, value)
    }
    if(name === "gstn"){
        setGstn(value)
        handleError(name,value)
    }
}

const handleError = (name, value) => {

    // console.log("handle OnBlure Change", name, "value", value)

    let allState = errors;

    if (name === "fullName") {
        if (!value.length) {
            allState["errfullName"] = "Enter your full name"
        } else if (value.length < 3) {
            allState["errfullName"] = "Name length should be 3 charector"
        } else {
            allState["errfullName"] = ""
        }
    }
    if(name === "email"){
        if(!value.length){
            allState["errEmail"] = "Enter your email"
        }else if(!isValidEmail(value)){
            allState["errEmail"] = "Invailid email."
        }else{
            allState["errEmail"] = ""
        }
    }
   if(name === "mobile"){
       if(!value.length){
           allState["errMobileno"] = "Mobile no is required."
       }else if(!isValidMobileNo(value)){
           allState["errMobileno"] = "Enter a valid number."
       }else{
           allState["errMobileno"] = ""
       }
   }
    if(name === "address"){
       if(!value.length){
           allState["errAdress"] = "Adress is required."
       }else{
           allState["errAdress"] = ""
       }
   }
    if(name === "optionalAddress"){
       if(!value.length){
           allState["errOptionalAddress"] = "Adress is required."
       }else{
           allState["errOptionalAddress"] = ""
       }
   }
    if(name === "pincode"){
       if(!value.length){
           allState["errPincode"] = "Pincode is required."
       }else{
           allState["errPincode"] = ""
       }
   }
    if(name === "state"){
       if(!value.length){
           allState["errState"] = "Select state."
       }else{
           allState["errState"] = ""
       }
   }
    if(name === "city"){
        if(!value.length){
            allState["errCity"] = "Select city."
        }else{
            allState["errCity"] = ""
        }
    }
    if(name === "officename"){
        if(!value.length){
            allState["errCompanyName"] = "Enter company name."
        }else{
            allState["errCompanyName"] = ""
        }
    }
    if(name === "personName"){
        if(!value.length){
            allState["errCompanyPerson"] = "Enter person name."
        }else{
            allState["errCompanyPerson"] = ""
        }
    }
    if(name === "otherField"){
        if(!value.length){
            allState["errOtherField"] = "Required field."
        }else{
            allState["errOtherField"] = ""
        }
    }
    if(name === "companyName"){
        if(!value.length){
            allState["errCompanyName"] = "Enter company name."
        }else{
            allState["errCompanyName"] = ""
        }
    }
    if (name === "promoCode") {
        if (!value.length) {
            allState["errAdress"] = "Promo Code is required."
        } else {
            allState["errAdress"] = ""
        }
    }
    if(name === "gstn"){
        if(!value.length){
            allState["errgstn"] = "Enter company name."
        }else{
            allState["errgstn"] = ""
        }
    }
    setErrors(prevError => ({
        ...prevError,
        ...allState
    }))
}

const checkVailidation = () => {
    hasError = false;
    let allState = errors;
    
    if(!fullName.length){
        allState["errfullName"] = "Enter your full name"
        hasError = true;
    }else if(fullName.length < 3){
        allState["errfullName"] = "Name length should be 3 charector"
        hasError = true;
    }else{
        allState["errfullName"] = ""
    }
    if(!email.length){
        allState["errEmail"] = "Enter your email"
        hasError = true
    }else if(!isValidEmail(email)){
        allState["errEmail"] = "Invailid email."
        hasError = true
    }else{
        allState["errEmail"] = ""
    }
    if(!mobile.length){
        allState["errMobileno"] = "Mobile no is required."
        hasError = true
    }else if(!isValidMobileNo(mobile)){
        allState["errMobileno"] = "Enter a valid number."
        hasError = true
    }else{
        allState["errMobileno"] = ""
    }
    if(!address.length){
        allState["errAdress"] = "Adress is required."
        hasError = true
    }else{
        allState["errAdress"] = ""
    }
    if(!optionalAddress.length){
        allState["errOptionalAddress"] = "Adress is required."
        hasError = true
    }else{
        allState["errOptionalAddress"] = ""
    }
    if(!pincode.length){
        allState["errPincode"] = "Pincode is required."
        hasError = true
    }else{
        allState["errPincode"] = ""
    }
    if(!state.length){
        allState["errState"] = "Select state."
        hasError = true
    }else{
        allState["errState"] = ""
    }
    if(!city.length){
        allState["errCity"] = "Select city."
        hasError = true
    }else{
        allState["errCity"] = ""
    }

    setErrors(prevError => ({
        ...prevError,
        ...allState
    }))
    return hasError
}

const handelFormSubmit = (e) => {
    
    navigate('/checkoutreview')
    // e.preventDefault()
    // if(!checkVailidation()){
    //     alert('mi');
    //     // let data = {
    //     //     type: selected,
    //     //     fullName: fullName,
    //     //     email: email,
    //     //     mobile: mobile,
    //     //     stdcode: stdcode,
    //     //     address: address,
    //     //     optionalAddress: optionalAddress,
    //     //     state: state,
    //     //     city: city,
    //     //     pincode: pincode,
    //     //     officename: officename,
    //     //     personName: personName,
    //     //     otherField: otherField
    //     // }

    //     // setAddressList( preState => [...preState, data])
    //     // setShowaddress(true)
        
    // }
}

const handleCheckboxChange = () => {
    setCheckbox(!checkbox)
}

// const handlePromoChange = () => {
//     setPromoCheckbox(!checkbox)
// }

const handlePromoCheck = (code) => {
    // console.log("code", code);
    // console.log("promocheckbox", promocheckbox);
    setActive(code)
    // if(value === code){
    //     console.log("code", code);
    //     console.log("value", value);
    // }
    setPromoCheckbox(!promocheckbox)
    // event.currentTarget.classList.toggle('active');
  };

const handelShowAddress = () => {
    setShowaddress(!showaddress)
}

const handleRadioButton = (e) => {
    let value = e.target.value
    setradioSelected(value)
}

const handleClick = (value) => {
    setSelected(value)
    setCheckbox(false)
}

const handlePromoModal = () => {
    setPromoModal(!promoModal)
} 

//const dataSubmit = () => {
 //   return checked ? setIsDisabled(true) : setIsDisabled(false);
//};

//const onCheckboxClick = () => {    
 //   setChecked(!checked);
//    return dataSubmit();
//};


// console.log("Checkbox", checkbox)
  return (
    <div className='wrapper'>
        <div className="checkout-details">
            <div className="container">
                <h2>Checkout</h2>
                <div className="contact">
                    <div className='left'>
                        <div className='contact-details'>
                            <div className='topheading'>
                                <h4>Contact Details</h4>
                                <h6>* Mandatory Fields</h6>
                            </div>
                            <form action="#">
                                <ul className='form-row'>
                                    <li className='form-group'>
                                        <label>Full Name*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"fullName"}
                                            placeholder={"Enter First and Last Name"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Your Name *"}
                                            value={fullName}
                                            autoComplete="off"
                                            errorMSg={errors.errfullName}
                                        />
                                    </li>

                                    <li className="form-group">
                                        <label>Email ID*</label>
                                        <TextInput
                                            type={"text"}
                                            name={"email"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Email ID*"}
                                            value={email}
                                            autoComplete="off"
                                            errorMSg={errors.errEmail}
                                        />
                                    </li>

                                    <li className="form-group mobile">
                                        <label>Mobile *</label>
                                        <div className="cust-select">
                                        <select 
                                            required="required" 
                                            className="form-control form-select"
                                            value={stdcode}
                                            onChange={(e) =>handleStateChange(e)}
                                            name="stdcode" disabled>
                                            <option value="">Select</option>
                                            {
                                            <option value={stdcode}>{stdcode}</option>
                                            }
                                        </select>
                                        <TextInput
                                            type={"tel"}
                                            value={mobile}
                                            name={"mobile"}
                                            placeholder={""}
                                            inputClass={"form-control"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            autoComplete="off"
                                            // errorMSg={errors.errMobileno}
                                            disabled={true}
                                        />
                                        
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <div className='shipping-pickup'>   
                                <h4>Select Shipping / Pickup from Store</h4>
                                <ul className="form-row custom-store">
                                    <li> 
                                        <Link to={''} className={`${selected === "shipping" ? "custom-active btn btn-outline-primary delivery" : "btn btn-outline-primary delivery"}`}  onClick= {() => handleClick("shipping")}><AiFillGift /> Shipping</Link>
                                    </li>
                                    <li>
                                        <Link to={''} className={`${selected === "pickup" ? "custom-active btn btn-outline-primary pickup-store" : "btn btn-outline-primary pickup-store"}`} onClick={() => handleClick("pickup")}><HiMapPin /> Pickup from Store</Link>
                                    </li>
                                </ul>
                            
                                { selected === "pickup" ?
                                    <div className="pickup-address">
                                        <div className="address-details">
                                            <div className="address">
                                                <h4><HiMapPin /> Collection from Summera Jewels Shop</h4>
                                                <ul>
                                                    <li>Shop No. 10, Rizvi Mahal,</li>
                                                    <li>R K Patkar Road - Water Field Road,</li>
                                                    <li>Next to Bhabha Hospital, Bandra (W),</li>
                                                    <li>Mumbai 400 050.</li>
                                                </ul>
                                            </div>
                                            <div className="store-time">
                                                <h4><BsFillClockFill />Store Time</h4>
                                                <span>11 AM to 8 PM</span>
                                            </div>
                                            <div className="call">
                                                <h4><FaMobileAlt /> Call</h4>
                                                <span>77700 07841, 82918 90911, 022-35080525</span>
                                            </div>
                                        </div>
                                        <div className='map'>
                                            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.133309735756!2d72.82913102924594!3d19.057875516241914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c91003d606cd%3A0xcc8d562c06b226e0!2sSUMMERA%20JEWELS!5e0!3m2!1sen!2sin!4v1673949440712!5m2!1sen!2sin" 
                                            width="500" height="250" />
                                        </div>
                                    </div> : null
                                }

                                <div className="delivery-details">
                                
                                <div className="address-update">
                                    <div className="shipping-add">
                                        <h4>Shipping  Details</h4>
                                        <h4>Residential</h4>
                                        <div className="custom-address">
                                            <p>Kaushal Ranpura</p>
                                            <address>501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400
                                            045. India</address>
                                            <button className="btn-secondary" id="shipping-btn"><i className="fa fa-check"></i>Shipping to this
                                            address</button>
                                            <ul className="edit-list">
                                                <li><Link to={''}>Edit</Link></li>
                                                <li><Link to={''} className="remove-add">Remove</Link></li>
                                            </ul>
                                            <Link to={''} className="btn-secondary add-address" id="btn1">Add New Address</Link>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                {
                                    selected === "shipping" ?
                                    <div className="residential-address">
                                        <ul className="form-row">
                                            <li className="form-group">
                                            <label className="price-info" htmlFor="address">Address 1 *</label>
                                            <TextInput
                                                type={"text"}
                                                name={"address"}
                                                inputClass={"form-control"}
                                                lblClass={"input-lable"}
                                                autoComplete={"off"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                label={"Address*"}
                                                value={address}
                                                errorMSg={errors.errAdress}
                                            />
                                            </li>

                                            <li className="form-group">
                                                <label className="price-info" htmlFor="address">Address 2</label>
                                                <TextInput
                                                    type={"text"}
                                                    name={"optionalAddress"}
                                                    label={"Optional Address"}
                                                    value={optionalAddress}
                                                    inputClass={"form-control"}
                                                    lblClass={"input-lable"}
                                                    autoComplete={"off"}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    errorMSg={errors.errOptionalAddress}
                                                />
                                            </li>

                                            <li className="form-group">
                                                <label className="price-info" htmlFor="country">Country</label>
                                                <TextInput
                                                    type={"text"}
                                                    name={"Country"}
                                                    label={"Country"}
                                                    value={"India"}
                                                    inputClass={"form-control"}
                                                    lblClass={"input-lable"}
                                                    autoComplete={"off"}
                                                    disabled={"disabled"}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                />
                                            </li>
                                        </ul>

                                        <ul className="form-row">
                                            <li className="form-group">
                                                <label className="price-info" htmlFor="state">State *</label>
                                                <Reactselect
                                                    name={"state"}
                                                    id={"state"}
                                                    label={"State *"}
                                                    options={stateData}
                                                    value={state}
                                                    class={"form-select"}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    errorMsg={errors.errState}
                                                />
                                            </li>

                                            <li className="form-group">
                                                <label className="price-info" htmlFor="city">City *</label>
                                                <Reactselect
                                                    name={"city"}
                                                    id={"city"}
                                                    label={"City *"}
                                                    options={cityData}
                                                    class={"form-select"}
                                                    value={city}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    errorMsg={errors.errCity}
                                                />
                                            </li>

                                            <li className="form-group">
                                                <label className="price-info" htmlFor="pincode">Pincode *</label>
                                                <TextInput
                                                    type={"text"}
                                                    name={"pincode"}
                                                    label={"Pincode"}
                                                    value={pincode}
                                                    placeholder={""}
                                                    inputClass={"form-control"}
                                                    lblClass={"input-lable"}
                                                    autoComplete={"off"}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    errorMSg={errors.errPincode}
                                                />
                                            </li>
                                        </ul>
                                    </div> : null
                                }

                                { selected === "shipping" ?
                                    <div className="checkbox">
                                    <Checkbox 
                                        autoComplete="off"
                                        id={"agree"}
                                        type={"checkbox"}
                                        name={"checkbox"}
                                        inputClass={"styled-checkbox"}
                                        onChange={handleCheckboxChange}
                                        value={checkbox}
                                        defaultChecked={checkbox}
                                    />
                                        <label for="agree">Is your billing address different? </label>
                                    </div> : selected === "pickup" ? <label>Billing Address</label> : null
                                }
                            </div>

                            {
                                selected === "pickup" || checkbox ?
                                <div className="residential-address">
                                    <ul className="form-row">
                                        <li className="form-group">
                                        <label className="price-info" htmlFor="address">Address 1 *</label>
                                        <TextInput
                                            type={"text"}
                                            name={"address"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            autoComplete={"off"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Address*"}
                                            value={address}
                                            errorMSg={errors.errAdress}
                                        />
                                        </li>

                                        <li className="form-group">
                                            <label className="price-info" htmlFor="address">Address 2</label>
                                            <TextInput
                                                type={"text"}
                                                name={"optionalAddress"}
                                                label={"Optional Address"}
                                                value={optionalAddress}
                                                inputClass={"form-control"}
                                                lblClass={"input-lable"}
                                                autoComplete={"off"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                errorMSg={errors.errOptionalAddress}
                                            />
                                        </li>

                                        <li className="form-group">
                                            <label className="price-info" htmlFor="country">Country</label>
                                            <TextInput
                                                type={"text"}
                                                name={"Country"}
                                                label={"Country"}
                                                value={"India"}
                                                inputClass={"form-control"}
                                                lblClass={"input-lable"}
                                                autoComplete={"off"}
                                                disabled={"disabled"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                            />
                                        </li>
                                    </ul>

                                    <ul className="form-row">
                                        <li className="form-group">
                                            <label className="price-info" htmlFor="state">State *</label>
                                            <Reactselect
                                                name={"state"}
                                                id={"state"}
                                                label={"State *"}
                                                options={stateData}
                                                value={state}
                                                class={"form-select"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                errorMsg={errors.errState}
                                            />
                                        </li>

                                        <li className="form-group">
                                            <label className="price-info" htmlFor="city">City *</label>
                                            <Reactselect
                                                name={"city"}
                                                id={"city"}
                                                label={"City *"}
                                                options={cityData}
                                                class={"form-select"}
                                                value={city}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                errorMsg={errors.errCity}
                                            />
                                        </li>

                                        <li className="form-group">
                                            <label className="price-info" htmlFor="pincode">Pincode *</label>
                                            <TextInput
                                                type={"text"}
                                                name={"pincode"}
                                                label={"Pincode"}
                                                value={pincode}
                                                placeholder={"Enter your pincode"}
                                                inputClass={"form-control"}
                                                lblClass={"input-lable"}
                                                autoComplete={"off"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                errorMSg={errors.errPincode}
                                            />
                                        </li>
                                    </ul>
                                </div> : null
                            }

                            
                                <div className='gst-details'>
                                    <h4>GSTN Details <span>(Optional)</span></h4>
                                    <ul className='form-row'>
                                        <li className='form-group'>
                                            <label>Company Name</label>
                                            <TextInput
                                                type={"text"}
                                                name={"companyName"}
                                                placeholder={"Company Name"}
                                                inputClass={"form-control"}
                                                lblClass={"input-lable"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                label={"Company Name*"}
                                                value={companyName}
                                                autoComplete="off"
                                                errorMSg={errors.errcompanyName}
                                            />
                                        </li>

                                        <li className="form-group">
                                            <label>GSTN No.</label>
                                            <TextInput
                                                type={"text"}
                                                name={"gstn"}
                                                placeholder={"GSTN No."}
                                                inputClass={"form-control"}
                                                lblClass={"input-lable"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                label={"GSTN No."}
                                                value={email}
                                                autoComplete="off"
                                                errorMSg={errors.errGSTNNo}
                                            />
                                        </li>
                                    </ul>
                                </div> 
                            
                        </div>
                        
                        </div>
                        <div className="make-info">
                            
                                <button 
                                    className="btn btn-primary" 
                                    type="submit"
                                    onClick={handelFormSubmit}><span>Save and Continue</span>
                                </button>
                            
                        </div>
                    </div>
                    <div className="right">
                        <div className={`_border_bottom checkout-summary  ${isOpen ? 'open' : ''}`}>
                            <h4>Order Summary</h4>
                        </div>

                        {
        
                            Order.map((item,index)=>{
                            return(

                                <div className="summary-data" key={index}>
                                    <span className="close">&times;</span>

                                    <div className="_border_bottom">
                                        <div className="custom_flex">
                                            <h6 className="cust-total">{item.sub_total}</h6>
                                            <div className="price cust-total"><BiRupee />{item.sub_total_value}</div>
                                        </div>
                                    </div>

                                    <div className="_border_bottom">
                                        <div className="custom_flex">
                                            <h6>{item.discount}</h6>
                                            <div className="price"><BiRupee />{item.discount_value}</div>
                                        </div>
                                    </div>

                                    <div className="_border_bottom">

                                        {!promoapply ?
                                            <div className="custom_flex custapplypromo">
                                                <h6 className='cust-total'>Apply Promo</h6>
                                                { !promoshow ? <div className="view_all" onClick={handlePromoModal}><span>View All</span> <FaCaretRight className="caret" /></div>
                                                : <div className="price"><FaCaretDown className="caret" /></div> }
                                            </div> : null
                                        }   
                                        
                                        <div className={ promoModal ? 'cart-list show' : 'cart-list'}>
                                                <div className="topheader">
                                                    <h2>Apply Coupon</h2>
                                                    <span className="btn-close btn-cart" onClick={handlePromoModal}><IoClose /></span>
                                                </div>
                                                <div className="cart-body">
                                                    <ul className="product-details">

                                                        {
    
                                                            Promocodes.map((item,index)=>{
                                                                return(

                                                                    <li key={index} className={`${item.code === active ? "active" : "" }`}>
                                                                        <div className='card'>
                                                                            <div className='card-body'>
                                                                                <div className='coupon'>
                                                                                    <div className='checkbox'>
                                                                                        <Checkbox 
                                                                                            autoComplete="off"
                                                                                            id={item.id}
                                                                                            type={"checkbox"}
                                                                                            name={item.code}
                                                                                            inputClass={"styled-checkbox"}
                                                                                            onChange={() => handlePromoCheck(item.code)}
                                                                                            value={promocheckbox}
                                                                                        />
                                                                                        <label for={item.id} onClick={() => handlePromoCheck(item.code)}> 
                                                                                            <span className="promo-btn">{item.code}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='save'>
                                                                                        <h6>Save ₹ {item.save_value}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                    { !item.min_purchase_value ? <p>Get <span>{`${item.percent_off}`}% off </span>on your first purchase. </p> : "" }
                                                                                    { item.min_purchase_value ? <p>Get <span>{`${item.percent_off}`}% off </span>on minimum purchase of ₹ {item.min_purchase_value}.</p> : "" }
                                                                                    <p>Expires on: {item.expire_date}</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                )
                                                            })
                                                                
                                                        } 

                                                    </ul>
                                                    <div className="cart-footer">
                                                        <div className="total-prize">
                                                            <p>Maximum Savings</p>
                                                            <h5>₹ 5,998</h5>
                                                        </div>
                                                        <div className="cart-button">
                                                            <button className="btn btn-primary" onClick={handlePromo}><span>Apply</span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        <div className={ promoModal ? 'bg-overlay show' : 'bg-overlay'}></div>

                                        
                                        { promoapply ?
                                        <div className="custom_flex save-promo">
                                            <div className="savepromocode">
                                                <h6 className="withpromo">{item.saved_with_promo}</h6>
                                                <p className="remove" onClick={handleRemove}>Remove</p>
                                                <div className="price price-promo"><BiRupee />{item.saved_value}</div>
                                            </div>
                                        </div> : null
                                        }
                                        
                                    </div>

                                    <div className="_border_bottom">
                                        <div className="custom_flex">
                                            <h6>{item.delivery_charges}</h6>
                                            <div className="price">{item.delivery_charges_value}</div>
                                        </div>
                                    </div>

                                    <div className="_border_bottom">
                                        <div className="custom_flex">
                                            <h6>{item.gst}</h6>
                                            <div className="price"><BiRupee />{item.gst_value}</div>
                                        </div>
                                    </div>

                                    <div className="_border_bottom">
                                        <div className="custom_flex">
                                            <h6 className="cust-total">{item.total_amount}</h6>
                                            <div className="price cust-total"><BiRupee />{item.total_amount_value}</div>
                                        </div>
                                    </div>
                                </div>
            
                            )
                            })
                        
                        } 



                        <div className="summary-cancle">
                            <Link className="btn btn-primary btn-cancle" title="Link to Cancel">Cancel</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Checkout;
