import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';

const CookiePolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wrapper cookie-policy'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Cookie Policy</h1>
                </div>
            </div>

            <div className="container"> 

                <section className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Cookie Policy</li>
                            </ol>
                        </nav>

                        <div className="main">
                            <ul className="filter">
                                <li className="one"><Link to={'/orders-and-returns'}> <span>Orders And Returns</span></Link></li>
                                <li className="one"><Link to={'/payment-policy'}> <span>Payment Policy</span></Link></li>
                                <li className="one"><Link to={'/shipping-policy'}> <span>Shipping Policy</span></Link></li>
                                <li className="one"><Link to={'/terms-and-conditions'}> <span>Terms And Condition</span></Link></li>
                                <li className="one"><Link to={'/privacy-policy'}> <span>Privacy Policy</span></Link></li>
                                <li className="current"><Link to={'/'}> <span>Cookie Policy</span></Link></li>
                            </ul>

                            <div className='boxGroup'>
                                <div className="content">
                                    <div className="para">
                                        <h3 className="title">Cookie Policy</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                        <ol type="1">
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                        </ol>
                                    </div>

                                    <div className="para">
                                        <h3 className="title">Lorem Ipsum is simply dummy text</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                        <ol type="1">
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                        </ol>
                                    </div>

                                    <div className="para">
                                        <h3 className="title">Lorem Ipsum is simply dummy text</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                        <ol type="1">
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                        </ol>
                                    </div>

                                    <div className="para">
                                        <h3 className="title">Lorem Ipsum is simply dummy text</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                        <ol type="1">
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                        </ol>
                                    </div>

                                    <div className="para">
                                        <h3 className="title">Lorem Ipsum is simply dummy text</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                        <ol type="1">
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply</li>
                                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}


export default CookiePolicy;