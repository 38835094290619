import React from 'react';
import './TopList.css'
import { Link } from 'react-router-dom'
import { ImMobile } from 'react-icons/im';
import { HiMail } from 'react-icons/hi';
import faqs from '../../../assets/images/icons/help/faqs.svg';
import contact from '../../../assets/images/icons/help/contact.svg';

export default function index() {
  return (
    <div className='toplist'>
        <ul className='desktop-visible'>
          <li>
            <Link to={'/find-store'}>Find Store</Link>
          </li>
          <li>
            <Link to={'/sell-on-summera'}>Sell On Summera</Link>
          </li>
        </ul>
        <ul className='mobile-visible'>
          <li>
            <Link to={'/find-store'}>Find Store</Link>
          </li>
          <li>
            <Link to={'/sell-on-summera'}>Sell On Summera</Link>
          </li>
        </ul>

        <div className='help'>
            <Link to={'#'} className="dropbtn">Help</Link>
            <ul className="dropdown-content">

            <li>
                <div className="dropdown">
                    <ul className="dropdown-menu d-block">

                        <li>
                            <div className="icon-div">
                                <HiMail size={25} className="icon" />
                            </div>
                            <div className="details">
                                <Link className="btn" href="mailto:enquiry@summera.in"> enquiry@summera.in </Link>
                            </div>
                        </li>
                    
                        <li>
                            <div className="icon-div">
                                <ImMobile size={25} className="icon" />
                            </div>
                            <div className="details">
                                <Link className="btn" href="tel:917770007841"> +91 77700 07841 </Link>
                                <Link className="btn" href="tel:918291890911"> +91 82918 90911 </Link>
                                <Link className="btn" href="tel:02235080525"> 022-35080525 </Link>
                            </div>
                        </li>

                        <hr />

                        <li>
                            <div className="icon-div">
                                <img src={faqs} alt="FAQs" title="FAQs" />
                            </div>
                            <div className="details">
                                <Link className="btn" to={'/faqs'}> FAQs </Link>
                            </div>
                        </li>

                        <li>
                            <div className="icon-div">
                                <img src={contact} alt="Contact" title="Contact" />
                            </div>
                            <div className="details">
                                <Link className="btn" to={'/contact-us'}> Contact </Link>
                            </div>
                        </li>

                    </ul>
                </div> 
            </li>

            </ul>
        </div>

    </div>
  )
}
