import * as type from '../types/vendorType'

export function fetchCountryAction(){
    
    return {
        type: type.FETCH_COUNTRY,
    }
}


export function fetchCityAction(data){
    
    return {
        type: type.FETCH_CITY,
        payload: data
    }
}


export function fetchMasterAction(){
    
    return {
        type: type.FETCH_MASTER,
    }
}


export function addVendorAction(data){
    
    return {
        type: type.ADD_VENDOR,
        payload: data
    }
}