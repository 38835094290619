import React from 'react';
import './index.css'
const Label = (props) => {

    let label = props.label
    let star = props.star
    return <>
        <label htmlFor={props.for} className={`lbl ${props.class}`}
            dangerouslySetInnerHTML={{
                __html: label,
            }}
        ></label>{star ? <span className='text-danger'>*</span> : null }
    </> 
};

export default Label