import { React, useState} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import ringimg from '../../assets/images/ring-prod-detail-11.jpg';
import { BiRupee } from 'react-icons/bi';
import {BiTrash} from "react-icons/bi";
import { viewCartAction } from '../../redux/actions/cartAction';
import { IoClose } from "react-icons/io5";
import './index.css'
import Review from '../../components/molecules/ReviewCart/index';
import {FiHeart} from "react-icons/fi";
// import ring from '../../assets/images/ring3.png';
import ring from '../../assets/images/ring-prod-detail-11.jpg';

export default function Mycart({isActive}) {

    const size = [
        {
            id: 1,
            label: "23 (62.8mm)",
            value: "23 (62.8mm)",
        },
        {
            id: 2,
            label: "25 (62.8mm)",
            value: "25 (62.8mm)",
        },
        {
            id: 3,
            label: "23 (62.8mm)",
            value: "23 (62.8mm)",
        },
        {
            id: 4,
            label: "25 (62.8mm)",
            value: "25 (62.8mm)",
        },
    ]; 
    
    const qty = [
        {
            id: 1,
            label: "1",
            value: "1",
        },
        {
            id: 2,
            label: "2",
            value: "2",
        },
        {
            id: 3,
            label: "3",
            value: "3",
        },
        {
            id: 4,
            label: "4",
            value: "4",
        },
    ]; 

    const dispatch = useDispatch();

    const [shopping, setShopping] = useState([
        { id:1, src:ring, name: "Diamond Ring", detail: "18 CT (3.400 g)", charges: "10", final_price: "30,999", original_price: "35,999"}
      ]);

      const handleClick = event => {
        // 👇️ toggle class on click
        event.currentTarget.classList.toggle('active');
    };
    
    const removeElement = (index) => {
        const newShopping = shopping.filter((_, i) => i !== index);
        setShopping(newShopping);
    };

  return (
    <>
    <div className={ isActive ? 'cart-list show' : 'cart-list'}>
        <div className="topheader">
            <h2>My Cart</h2>
            <span onClick={() => dispatch(viewCartAction())} className="btn-close btn-cart"><IoClose /></span>
        </div>
        <div className="cart-body">
            <ul className="product-details">

                {
                    shopping.map((item,index)=>{
                        return(
                            
                                <li key={index}>
                                    <ul className="product-price-counter">
                                        <li>
                                            <figure>
                                                <img src={item.src} className="card-img-top" alt={item.name} title={item.name} />
                                            </figure>
                                        </li>
                                        <li>
                                            <span className="product"><strong>{item.name}</strong></span>
                                            <span className="product-desc">{item.detail}</span>
                                            <div className='order-wrapper'>
                                                <div className='selectwrapper'>
                                                    <div className='selectfield'>
                                                        <span className="title">Size:</span>
                                                        <select className="form-select" id="size">
                                                            {size.map((size,s) => (
                                                                <option key={s} value={size.value}>{size.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                
                                                    <div className='selectfield'>
                                                        <span className="title">Qty:</span>
                                                        <select className="form-select" id='qty'>
                                                            {qty.map((qty,q) => (
                                                                <option key={q} value={qty.value}>{qty.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-calculation">
                                                <div className="prize-details">
                                                    <span className="multiply-icon"><i className="fa fa-times"></i></span>
                                                    <span><BiRupee />{item.final_price}</span>
                                                    <s><small><BiRupee />{item.original_price}</small></s>
                                                </div>
                                                <div className="last-prize">
                                                    <BiRupee /> {item.final_price}
                                                    <span className='deleteamt' onClick={() => removeElement(index)}><BiTrash /></span>
                                                </div>
                                            </div>	
                                            <div className="product-label-offer">
                                                <h5 className="offer">{item.charges}% off on Making Charges</h5>
                                            </div>		
                                        </li>
                                    </ul>
                                </li>                            
                        )
                    })
                }
            </ul>
            <div className="cart-footer">
                <div className="cart-button">
                    <Link to="/review-cart" state={{data:"review_order"}}  className="btn-secondary" onClick={() => dispatch(viewCartAction())}><span>View Cart</span></Link>
                    <Link to="/login" className="btn btn-primary" onClick={() => dispatch(viewCartAction())}> <span>Proceed to Checkout</span></Link>
                </div>
                <div className="total-prize">
                    <BiRupee />30,999
                </div>
            </div>
        </div>
    </div>
    <div className={ isActive ? 'bg-overlay show' : 'bg-overlay'}></div>
    </>
  )
}
