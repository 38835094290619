import React from 'react'
import { Link } from 'react-router-dom';
import './Offermsg.css';
import Marquee from "react-fast-marquee";

export default function index() {
  return (
    <li className='offer-msg'>
    <Marquee pauseOnHover={true} speed={50}>
      <h5>WE ARE COMING SOON</h5>
    </Marquee>
    </li>
  )
}
