import React from 'react'
import './index.css'
import Brand1 from '../../assets/images/brand1.png';
import Brand2 from '../../assets/images/brand2.png';
import Brand3 from '../../assets/images/brand3.png';
import Brand4 from '../../assets/images/brand4.png';
import Brand5 from '../../assets/images/brand5.png';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Data = [
    { id:1,  src:Brand1},
    { id:2,  src:Brand2},
    { id:3,  src:Brand3},
    { id:4,  src:Brand4},
    { id:5,  src:Brand5},
    { id:6,  src:Brand2},
    { id:7,  src:Brand4}
]


export const BrandSlider = () =>{
    const settings = {
        dots: false,
        autoplay: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                  slidesToShow: 4,
                }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
    }
    
    return(
        <Slider {...settings}>
        {
            Data.map((item,index)=>{
                return(
                    <Link to={'/shop-by-brand/jewels-box'} key={index}>
                        <div className='brand-img'>
                            <figure>
                                <img src={item.src} alt='Brand' title='Brand'/>
                            </figure>
                        </div>
                    </Link>
                )
          })
        }
        </Slider>
    )
}

