import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../../atom/input/input";
import Error from "../../atom/error";
import postApi from "../../../services/postApi";
import {
  isValidEmail,
  isValidMobileNo,
  alphabatesText,
} from "../../helper/method";
import "./index.css";

const api = new postApi();
const Registration = () => {
  //document.body.classList.add('registration-page');
  const [openOpt, setOpenSet] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    save: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateForm = () => {
    let errorObj = {};
    if (formData?.email) {
      if (!isValidEmail(formData?.email)) errorObj.email = "Invalid Email";
    } else {
      errorObj.email = "Email is Required";
    }
    if (formData?.phone) {
      if (!isValidMobileNo(formData?.phone))
        errorObj.phone = "Invalid mobile number";
    } else {
      errorObj.phone = "Mobile number is Required";
    }
    if (formData?.firstName) {
      if (!alphabatesText(formData?.firstName))
        errorObj.firstName = "Invalid name";
    } else {
      errorObj.firstName = "Full name is Required";
    }

    if (Object.keys(errorObj)?.length) {
      setErrors((prev) => {
        return { ...prev, ...errorObj };
      });
    }

    return Object.keys(errorObj)?.length;
  };

  const saveHandler = (e) => {
    e?.preventDefault();
    let isError = validateForm();
    if (!isError) {
      api?.Register(formData)?.then((response) => {
        if (response?.status === 200) {
          setOpenSet(!openOpt);
          api
            ?.SendUserOtp({ useremail: formData?.email })
            ?.then((response) => console.log(response, "userOtp"));
        } else {
          setErrors((prev) => ({ ...prev, save: `${response?.message}` }));
        }
      });
    }
  };

  const handleChange = (e) => {
    let targetEle = e?.target?.name;
    let value = e?.target?.value;
    let errorNames = {
      email: "Email",
      firstName: "Full name",
      lastName: "",
      phone: "Mobile number",
    };

    setFormData((prev) => {
      let newData = {
        ...prev,
        [targetEle]: value,
      };
      return { ...newData };
    });

    setErrors((prev) => {
      let newError = {
        ...prev,
        [targetEle]: !value ? `${errorNames[targetEle]} is Required` : "",
        save: "",
      };
      return { ...newError };
    });
  };

  return (
    <div className="wrapper">
      <div className="container">
        <div className="registration-box">
          <div className="registration-wrapper text-center">
            {openOpt ? (
              <div className="registrationForm">
                <h2>Register Now</h2>
                <h5>Please provide below details to know you better.</h5>
                <div className="form-group">
                  <label>Full Name</label>
                  <TextInput
                    type={"text"}
                    label={"Full Name"}
                    inputClass={"form-control"}
                    lblClass={"input-lable"}
                    autoComplete={"off"}
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <Error
                    class={!errors.firstName ? "err_hide" : ""}
                    errorMessage={errors.firstName}
                  />
                </div>
                <div className="form-group">
                  <label>Enter your 10 digit Mobile Number</label>
                  <TextInput
                    type={"tel"}
                    label={"Enter your 10 digit Mobile Number"}
                    inputClass={"form-control"}
                    lblClass={"input-lable"}
                    autoComplete={"off"}
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <Error
                    class={!errors.phone ? "err_hide" : ""}
                    errorMessage={errors.phone}
                  />
                </div>
                <div className="form-group">
                  <label>Email Id</label>
                  <TextInput
                    type={"email"}
                    label={"Email Id"}
                    inputClass={"form-control"}
                    lblClass={"input-lable"}
                    autoComplete={"off"}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Error
                    class={!errors.email ? "err_hide" : ""}
                    errorMessage={errors.email}
                  />
                  <Error
                    class={!errors.save ? "err_hide" : ""}
                    errorMessage={errors.save}
                  />
                </div>

                <button className="btn btn-primary" onClick={saveHandler}>
                  <span>Save and Continue</span>
                </button>
              </div>
            ) : (
              <OTPVerify userEmail={formData?.email} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;

// OPT Verfication
export const OTPVerify = ({ userEmail }) => {
  // number of inputs
  const numerOfInputs = 6;
  const inputRef = useRef(new Array());

  // create a array of refs
  const [inputRefsArray] = useState(() =>
    Array.from({ length: numerOfInputs }, () => null)
  );
  // state for current input index
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleKeyPress = () => {
    setCurrentIndex((prevIndex) => {
      // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
      const nextIndex = prevIndex < numerOfInputs - 1 ? prevIndex + 1 : 0;
      const nextInput = inputRef?.current?.[nextIndex];
      nextInput.focus();
      nextInput.select();
      return nextIndex;
    });
  };

  useEffect(() => {
    console.log(inputRef, "inputRef");
    // focus the first input initially
    if (inputRef?.current?.[0]) {
      inputRef?.current?.[0]?.focus();
    }

    // add the event listener for keyup keyboard event
    window.addEventListener("keyup", handleKeyPress, false);

    // remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  const [enteredOtp, setEnteredOtp] = useState({
    digit1: null,
    digit2: null,
    digit3: null,
    digit4: null,
    digit5: null,
    digit6: null,
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const changeHandler = (e) => {
    let targetEle = e?.target?.name;
    let value = e?.target?.value;
    setEnteredOtp((prev) => {
      return {
        ...prev,
        [targetEle]: value ? value : null,
      };
    });
  };

  const resendOtp = () => {
    api
      ?.SendUserOtp({ useremail: userEmail })
      ?.then((response) => console.log(response, "userOtpResent"));
  };

  const varify = () => {
    let { digit1, digit2, digit3, digit4, digit5, digit6 } = enteredOtp;
    if (digit1 && digit2 && digit3 && digit4 && digit5 && digit6) {
      let userTypedOtp = digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
      console.log(userTypedOtp, "userTypedOtp");
      api
        ?.VerifyUserOtp({ useremail: userEmail, otp: userTypedOtp })
        ?.then((response) => {
          console.log(response, "verifyOtpResponse");
          if (response?.status === 200) navigate("/");
          else setError(response?.message);
        });
    } else {
      setError("Invalid Otp");
    }
  };

  return (
    <div className="otpverifyPage">
      <h2>OTP Verification</h2>
      <form>
        <label>Enter the OTP sent to +91 XXXXXX0245</label>
        <div className="inputFields">
          {inputRefsArray?.map((val, index) => {
            return (
              <TextInput
                key={index}
                reference={(ele) => inputRef?.current?.push(ele)}
                id={`digit${index + 1}`}
                type={"tel"}
                autoComplete={"off"}
                maxLength="1"
                name={`digit${index + 1}`}
                onChange={changeHandler}
                onClick={(e) => {
                  setCurrentIndex(index);
                  e?.target?.select();
                }}
              />
            );
          })}
        </div>
        <Link className="btn btn-primary" to={"/thank-you"}>
          <span onClick={varify}>Verify & Proceed</span>
        </Link>
        <p>
          Don't receive the OTP?{" "}
          <Link to={''} onClick={resendOtp}>
            Resend OTP
          </Link>
        </p>
      </form>
    </div>
  );
};
