import React, { useState } from 'react';
import ItemTable from '../../../atom/myaccountitems/itemsTable';
import OrderHeader from '../../../atom/myaccountitems/OrderHeader';
import Reactselect from '../../../molecules/select';
import TermsConditions from "../../../molecules/termsConditions";

const ExchangeData = [
    { id: 1, label: "Select size", value: "selectsize" },
    { id: 2, label: "Large", value: "large" },
    { id: 3, label: "Medium", value: "medium" },
    { id: 4, label: "Small", value: "small" },
]

const SelectColorData = [
    { id: 1, label: "Select size", value: "selectsize" },
    { id: 2, label: "Large", value: "large" },
    { id: 3, label: "Medium", value: "medium" },
    { id: 4, label: "Small", value: "small" },
]


const Exchangeform = (props) => {
    const [exchangsize, setExchangsize] = useState("");
    const [selectcolor, setSelectcolor] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => {
        setModalOpen(!modalOpen);
    };

    const [errors, setErrors] = useState({
        errexchangsize: "",
        errselectcolor: ""
    });

    const handleStateChange = (name, value) => {
        if (name === "exchangsize") {
            setExchangsize(value);
            handleError(name, value);
        }

        if (name === "selectcolor") {
            setSelectcolor(value);
            handleError(name, value);
        }

    }

    const handleError = (name, value) => {
        // console.log("Errors", name, value)
        let allState = errors
        if (name === "exchangsize") {
            if (!value.length) {
                allState["errexchangsize"] = "Please select size";
            } else {
                allState["errexchangsize"] = "";
            }
        }
        if (name === "selectcolor") {
            if (!value.length) {
                allState["errselectcolor"] = "Please select color";
            } else {
                allState["errselectcolor"] = "";
            }
        }
        setErrors(prevErr => ({
            ...prevErr,
            ...allState,
        }))


    }

    return (
        <div className="Order">
            <div className='top-info'>
                <h2>Exchange</h2>
                <button type="button" className="backBtnToTab btn btn-secondary" onClick={props.handlingBack}>Back</button>
            </div>

            <OrderHeader orderplace={"02 Jan 2023"} ordernumber={"MAZE202471002"} />

            <div className="order-info">
                <div className="order-details">
                    <h4>Are You sure you want to send a exchange request?</h4>

                    <ItemTable />

                    <div className="FormDetails">
                        <p>*Mandatory Fields</p>
                        <form>
                            <ul className="form-row">
                                <li className="form-group">
                                    <Reactselect
                                        name={"exchangsize"}
                                        id={"exchangsize"}
                                        label={"Select Exchange size* <small>( in Inches )</small>"}
                                        options={ExchangeData}
                                        value={exchangsize}
                                        class={"form-select"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        errorMsg={errors.errexchangsize}
                                    />
                                </li>
                                <li className="form-group">
                                    <Reactselect
                                        name={"selectcolor"}
                                        id={"selectcolor"}
                                        label={"Select Exchange color*"}
                                        options={SelectColorData}
                                        value={selectcolor}
                                        class={"form-select"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        errorMsg={errors.errselectcolor}
                                    />
                                </li>
                            </ul>
                            <div className="form-row">
                                <div className="form-group comments">
                                    <label>Your Comments</label>
                                    <textarea rows="4" cols="50"></textarea>
                                </div>
                            </div>
                        </form>

                        <div className="submitData">
                            <div className="checkbox">
                                <input
                                className="styled-checkbox"
                                id="agree"
                                type="checkbox"
                                checked
                                />
                                <label for="agree">
                                I agree to{" "}
                                <span onClick={handleModalOpen}>
                                    <TermsConditions
                                    modalOpen={modalOpen}
                                    handleModalOpen={handleModalOpen}
                                    />
                                    Terms and Conditions
                                </span>{" "}
                                </label>
                            </div>
                            <button
                                className="btn btn-primary btn-submit"
                                onClick={() => {
                                props.handleSubmitMessage({
                                    requestModal: "RequestSuccess",
                                    name: "Exchange",
                                    msg: "Your exchange request successfully sent to our team.",
                                });
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Exchangeform