import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './CartList.css';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { FaRegHeart } from 'react-icons/fa';
// import { MdOutlineShoppingCart } from 'react-icons/md';
import { BsBag } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Input from '../input/input';
import Label from '../label/label';
import Myaccount from '../../../assets/images/icons/myaccount.svg';
import India from '../../../assets/images/icons/india-flag.png'
import Logout from '../../../assets/images/icons/logout.svg';
import { Link, useNavigate} from 'react-router-dom';
import { GrHomeRounded } from 'react-icons/gr';
import { RiVideoAddLine } from 'react-icons/ri';
// import CurrencyDropdown from '../CurrencyDropdown'
import { viewCartAction } from '../../../redux/actions/cartAction';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import videoCall from '../../../assets/images/video-call.png';
import { GrFormClose } from 'react-icons/gr';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import userimg from '../../../assets/images/header/user-old.svg';
import logout from '../../../assets/images/header/logout.svg';
import tryAtHome from '../../../assets/images/tryathome.png';
// import { RiMapPin2Line } from 'react-icons/ri';


export default function CartList() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {cartLength} = useSelector((state)=> state.cart)
    
    const [dropdownOpen, setdDropdownOpen] = useState(false)

    const toggle = () => {
        setdDropdownOpen(!dropdownOpen);
    }

    const handleRedirect = (close) => {
        close();
        navigate(`/diamond`, {state:['Try at Home']})
    }

    const VideoCall = () => (
        <Popup trigger={<button className="video-call"><span><RiVideoAddLine className='videoIcn' />
        <span className='icon-text'>Video Call</span></span></button>} modal aria-describedby={"pinHome"}>
          {close => (
              <div className='pop-body'>
                <Link className="close" onClick={close}>
                  <span><GrFormClose className="closebtn" /></span>
                </Link>

                <h2>Video Call At Your Convenience</h2>
                <p>Discover the World of Exquisite Jewelry with Our Experts from Summera Jewels</p>
                <img src={videoCall} alt="Schedule a Video Call" title="Schedule a Video Call" />

                <Link className="btn btn-primary" to={'/request-video-call'} onClick={close}><span>Schedule a Video call</span></Link>
                
              </div>
          )}
          </Popup>
    );

    const TryAtHome = () => (
        <Popup trigger={<button className="tryathome"><span><GrHomeRounded className='homeIcn' /><span className='icon-text'>Try at Home</span></span></button>} modal aria-describedby={"pinHome"}>
          {close => (
              <div className='pop-body'>
                <a className="close" onClick={close}>
                  <span><GrFormClose className="closebtn" /></span>
                </a>
    
                <h2>Experience Luxury in the Comfort of Your Home</h2>
                <p>Book a Free Home Trial for a 'Try At Home' Experience. Select up to 5 designs and buy only if you love them!"</p>
                <img src={tryAtHome} alt="Schedule a Video Call" title="Schedule a Video Call" />
    
                <button className="btn btn-primary" onClick={() =>handleRedirect(close)} ><span>Continue</span></button>
                
              </div>
          )}
          </Popup>
      );

    return (
        <ul className="e-cart-list before-login">
            <li className='home-video'>
                {/* {TryAtHome()} */}
                {/* {VideoCall()} */}
            </li>
            
            <li className='cart-wishlist'>
                <ul>
                    <li className="after-login">
                    <Link to={'/'} className="showAfter"><FaMapMarkerAlt /></Link>
                    <div className="dropdownCus">
                    <Input className="dropdownCus__switch" id="filter-switch" hidden type='checkbox' />
                        <Label for="filter-switch" className="dropdownCus__options-filter" />
                    
                        <ul className="dropdownCus__filter" role="listbox" tabIndex="-1">
                            <li className="dropdownCus__filter-selected" aria-selected="true">
                                Kaushal R
                            </li>
                            <li className="noPadding"><i className="fa fa-caret-down" aria-hidden="true"></i></li>
                            <li className="dropDownli">
                            <ul className="dropdownCus__select">
                                <Link to={'/summera-jewels/my-account'}>
                                    <li className="dropdownCus__select-option" role="option">
                                        <img src={Myaccount} alt='My Account' title='My Account'/>My Account
                                    </li>
                                </Link>
                                <Link to={'/'}>
                                <li className="dropdownCus__select-option" role="option">
                                    <img src={Logout} alt='Logout' title='Logout'/>Logout
                                </li>
                            </Link>
                            </ul>
                            </li>
                        </ul>			
                        
                    </div>
                    </li>
                    <li>
                        <Link to={''}>
                            <FaRegHeart  className='wishlistIcn' /><span className='cart-counter'>0</span>
                            <span className='icon-text'>Wishlist</span>
                        </Link>
                    </li>
                    <li className="cart-product">
                        <Link onClick={cartLength ? () => dispatch(viewCartAction()) : null}  >
                            <BsBag  className='cartIcn' /><span className='cart-counter'>{cartLength}</span>
                            <span className='icon-text'>Cart</span>
                        </Link>
                    </li>
                    <li className="showBefore"> 
                        <Link to={'/login'}>
                            <HiOutlineUserCircle  className='userIcn' />
                            <span className='icon-text'>Sign In</span>
                        </Link>
                        <Dropdown className='after-login d-none' isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret>
                            {/* <img src={userimg} alt="User" className="user-img" /> */}
                            <HiOutlineUserCircle  className='userIcn' />
                            <span>Hi</span>
                            </DropdownToggle>
                            <DropdownMenu>
                            <DropdownItem>
                                <Link to={'/myaccount'}>
                                    <img src={userimg} alt="User" className="user-img" title="User" /><span>My Account</span>
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link to={'/myaccount'}>
                                <img src={logout} alt="logout" className="user-img" title="logout" /><span>Logout</span>
                                </Link>
                            </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    <li className='currency_drop'>
                    <img src={India} alt='India Flag' title='India Flag'/><span className='icon-text'>India</span>
                    </li>
                </ul>
            </li>
        </ul>
  )
}
