import React from 'react';
import Budget1 from '../../assets/images/budget1.jpg';
import Budget2 from '../../assets/images/budget2.jpg';
import Budget3 from '../../assets/images/budget3.jpg';
import Budget4 from '../../assets/images/budget4.jpg';
import Budget5 from '../../assets/images/budget5.jpg';
import BudgetCard from './shopbudget';
import './index.css'

export default function index() {
  return (
    <div className='BudgetList'>
        <BudgetCard
            link={'/product'}
            imgsrc={Budget1}
            productinfo="Under" 
            CorrectAmount="10,000"
        />
        <BudgetCard
            link={'/product'}
            imgsrc={Budget2}
            productinfo="Under" 
            CorrectAmount="20,000"
        />
        <BudgetCard
            link={'/product'}
            imgsrc={Budget3}
            productinfo="Under" 
            CorrectAmount="30,555"
        />
        <BudgetCard
            link={'/product'}
            imgsrc={Budget4}
            productinfo="Under" 
            CorrectAmount="40,999"
        />
        <BudgetCard
            link={'/product'}
            imgsrc={Budget5}
            productinfo="Under" 
            CorrectAmount="50,000"
        />
        
    </div>
  )
}
