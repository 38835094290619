import React, { useState } from 'react';
import ItemTable from '../../../atom/myaccountitems/itemsTable';
import OrderHeader from '../../../atom/myaccountitems/OrderHeader';
import Reactselect from '../../../molecules/select';
import TermsConditions from "../../../molecules/termsConditions";

const CancelItemData = [
    { id: 1, label: "Select Reason", value: "selectreason" },
    { id: 2, label: "Not interested now", value: "notinterestednow" },
    { id: 3, label: "I change my mind", value: "ichangemymind" },
    { id: 4, label: "other", value: "other" },
]

const CancelItemForm = (props) => {

    const [cancelitem, setCancelitem] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(!modalOpen);
    };

    const [errors, setErrors] = useState({
        errcancelitem: ""
    });

    const handleStateChange = (name, value) => {
        if (name === "cancelitem") {
            setCancelitem(value);
            handleError(name, value);
        }
    }

    const handleError = (name, value) => {
        let allState = errors;

        if (name === "cancelitem") {
            if (!value.length) {
                allState["errcancelitem"] = "Please select reason";
            } else {
                allState["errcancelitem"] = "";
            }
        }

        setErrors(prevErr => ({
            ...prevErr,
            ...allState,
        }))

    }

    return (
        <div className="Order">
            <div className='top-info'>
                <h5>Cancel Item</h5>
                <button type="button" className="backBtnToTab btn btn-secondary" onClick={props.handlingBack}>Back</button>
            </div>

            <OrderHeader orderplace={"02 Jan 2023"} ordernumber={"MAZE202471002"} />

            <div className="order-info">
                <div className="order-details">
                    <h4>Are you sure you want to send a cancellation request?</h4>

                    <ItemTable />

                    <div className="FormDetails">
                        <p>*Mandatory Fields</p>
                        <form>
                            <ul className="form-row">
                                <li className="form-group return">
                                    <Reactselect
                                        name={"cancelitem"}
                                        id={"cancelitem"}
                                        label={"Select Reason for Order Cancellation*"}
                                        options={CancelItemData}
                                        value={cancelitem}
                                        class={"form-select"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        errorMsg={errors.errcancelitem}
                                    />
                                </li>
                            </ul>
                            <div className="form-row">
                                <div className="form-group comments">
                                    <label>Your Comments</label>
                                    <textarea rows="4" cols="50"></textarea>
                                </div>
                            </div>
                        </form>

                        <div className="submitData">
                            <div className="checkbox">
                                <input
                                className="styled-checkbox"
                                id="agree"
                                type="checkbox"
                                checked
                                />
                                <label for="agree">
                                I agree to{" "}
                                <span onClick={handleModalOpen}>
                                    <TermsConditions
                                    modalOpen={modalOpen}
                                    handleModalOpen={handleModalOpen}
                                    />
                                    Terms and Conditions
                                </span>
                                </label>
                            </div>
                            <button
                                className="btn btn-primary btn-submit"
                                onClick={() => {
                                props.handleSubmitMessage({
                                    requestModal: "RequestSuccess",
                                    name: "Cancel Item",
                                    msg: "Your Cancel Item request successfully sent to our team.",
                                });
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelItemForm