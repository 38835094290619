import React,{useState,useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import TextInput from "../../../components/molecules/input";
import Reactselect from "../../molecules/select";
import { alphabatesWithSpace, isValidEmail, isValidMobileNo, numberText } from "../../../components/helper/method";
import bannerImage from '../../../assets/images/customise-request-banner.jpg';
import customisebg from '../../../assets/images/customise-bg.png';
import iconUSer from '../../../assets/images/icons/icon-user.png';
import iconDoc from '../../../assets/images/icons/icon-document.png';
import iconUpload from '../../../assets/images/icons/icon-upload.png';
import iconCart from '../../../assets/images/icons/icon-cart.png';

function CustomiseRequest() {
    // const [checkbox,setCheckbox] = useState(true);
    const navigate = useNavigate();

    let hasError = false;

    const countryCodeData = [
        { id: 1, value: "IN 91", label: "IN 91" }
    ]

    const categoryData = [
        { id: 1, value: "Gold", label: "Gold" },
        { id: 2, value: "Diamond", label: "Diamond" },
        { id: 3, value: "Solitaire", label: "Solitaire" },
        { id: 4, value: "Gemstones", label: "Gemstones" },
        { id: 5, value: "Platinum", label: "Platinum" },
        { id: 6, value: "Silver", label: "Silver" },
        { id: 7, value: "Coins / Bar", label: "Coins / Bar" }
    ]

    const subCategoryData = [
        {id: 1, value: "Rings", label: "Rings"},
        {id: 2, value: "Earrings", label: "Earrings"},
        {id: 3, value: "Pendant", label: "Pendant"},
        {id: 4, value: "Bangles", label: "Bangles"},
        {id: 5, value: "Bracelets", label: "Bracelets"},
        {id: 6, value: "Necklaces", label: "Necklaces"},
        {id: 7, value: "Mangalsutra", label: "Mangalsutra"},
        {id: 8, value: "Chains", label: "Chains"},
        {id: 9, value: "Nose Pins", label: "Nose Pins"}
    ]

    const productTypeData = [
        {id: 1, value: "Engagement", label: "Engagement"},
        {id: 2, value: "Fashion", label: "Fashion"},
        {id: 3, value: "Casual", label: "Casual"},
        {id: 4, value: "Cocktail", label: "Cocktail"},
        {id: 5, value: "Bands", label: "Bands"},
        {id: 6, value: "Adjustable", label: "Adjustable"},
        {id: 7, value: "Rings", label: "Rings"},
        {id: 8, value: "Studs", label: "Studs"},
        {id: 9, value: "Jhumkas", label: "Jhumkas"},
        {id: 10, value: "Earcuffs", label: "Earcuffs"},
        {id: 11, value: "Chandbali", label: "Chandbali"},
        {id: 12, value: "Drops", label: "Drops"},
        {id: 13, value: "Hoops & Huggies", label: "Hoops & Huggies"},
        {id: 14, value: "Sudhaga", label: "Sudhaga"}
    ]

    const [fullName, setFullName] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [productType, setProductType] = useState("");

    const [errors, setErrors] = useState({
        errFullName: "",
        errCountryCode: "",
        errEmail: "",
        errMobileNo: "",
        errCategory: "",
        errSubCategory: "",
        errproductType: "",
    }) 

    const handleStateChange = (name, value) => {

        if (name === "fullName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setFullName(check)
                handleError(name, value)
            }
        }
        if (name === "countryCode") {
            setCountryCode(value)
            handleError(name, value)
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "mobileNo"){
            let check = value
            if(numberText(check)){
                setMobileNo(check)
                handleError(name,value)
            }
        }
        if (name === "category") {
            setCategory(value)
            handleError(name, value)
        }
        if (name === "subCategory") {
            setSubCategory(value)
            handleError(name, value)
        }
        if (name === "productType") {
            setProductType(value)
            handleError(name, value)
        }
    }

    const handleError = (name, value) => {
    
        let allState = errors;

        if (name === "fullName") {
            if (!value.length) {
                allState["fullName"] = "Enter your Full name"
            } else if (value.length < 3) {
                allState["fullName"] = "Full Name length should be 3 charector"
            } else {
                allState["fullName"] = ""
            }
        }
        if (name === "countryCode") {
            if (!value.length) {
                allState["errCountryCode"] = "Select Country Code."
            } else {
                allState["errCountryCode"] = ""
            }
        }
        if(name === "mobileNo"){
            if(!value.length){
                allState["errMobileNo"] = "Mobile no is required."
            }else if(!isValidMobileNo(value)){
                allState["errMobileNo"] = "Enter a valid number."
            }else{
                allState["errMobileNo"] = ""
            }
        }
        if(name === "email"){
            if(!value.length){
                allState["errEmail"] = "Enter your email"
            }else if(!isValidEmail(value)){
                allState["errEmail"] = "Invalid email."
            }else{
                allState["errEmail"] = ""
            }
        }
        if (name === "subCategory") {
            if (!value.length) {
                allState["errSubCategory"] = "Select Sub Category."
            } else {
                allState["errSubCategory"] = ""
            }
        }
        if (name === "category") {
            if (!value.length) {
                allState["errCategory"] = "Select Category."
            } else {
                allState["errCategory"] = ""
            }
        }
        if (name === "productType") {
            if (!value.length) {
                allState["errProductType"] = "Select Product Type."
            } else {
                allState["errProductType"] = ""
            }
        }
      
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () => {
        hasError = false;
        let allState = errors;

        if (!fullName.length) {
            allState["fullName"] = "Enter your Full Name"
            hasError = true;
        } else if (fullName.length < 3) {
            allState["fullName"] = "Full Name length should be 3 charector"
            hasError = true;
        } else {
            allState["fullName"] = ""
        }

        if (!countryCode.length) {
            allState["errCountryCode"] = "Select Country Code."
            hasError = true
        } else {
            allState["errCountryCode"] = ""
        }

        if (!mobileNo.length) {
            allState["errMobileNo"] = "Mobile no is required."
            hasError = true
        } else if (!isValidMobileNo(mobileNo)) {
            allState["errMobileNo"] = "Enter a valid number."
            hasError = true
        } else {
            allState["errMobileNo"] = ""
        }

        if (!email.length) {
            allState["errEmail"] = "Enter your email"
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }

        if (!category.length) {
            allState["errCategory"] = "Select Category."
            hasError = true
        } else {
            allState["errCategory"] = ""
        }

        if (!subCategory.length) {
            allState["errSubCategory"] = "Select Sub Category."
            hasError = true
        } else {
            allState["errSubCategory"] = ""
        }

        if (!productType.length) {
            allState["errProductType"] = "Select Product Type."
            hasError = true
        } else {
            allState["errProductType"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }    

    const handelFormSubmit = (e) => {
        e.preventDefault()
        if(!checkVailidation()){
            navigate("/customise-request/thank-you")
            // let data = {
            //     id: Date.now(),
            //     fullName: fullName,
            //     countryCode: countryCode,
            //     email: email,
            //     category: category,
            //     mobileNo: mobileNo,
            //     subCategory: subCategory,
            //     productType: productType
            // }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //   const handleCheckboxChange = () => {
    //     setCheckbox(!checkbox)
    // }
    // const handelFormSubmit = (e) => {
    //     e.preventDefault()
    // }

  return (
    <div className='wrapper customise-request'>
    
        <section className='bannerSec'>
            <figure>
                <img src={bannerImage} alt='Customise your request on Summera today and Enjoy Your Collections Globally' title='Customise your request on Summera today and Enjoy Your Collections Globally' />
                <div className='container'>
                    <figcaption>
                        <h1>Customise your request on Summera today and Enjoy Your Collections Globally</h1>
                    </figcaption>
                </div>
            </figure>
        </section>

        <div className='container'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Customise Request</li>
                </ol>
            </nav>
            <p>Elevate your life with personalized elegance: Discover the allure of custom-made jewelry at Summera Jewels.</p>
            <section className='row gap0'>
                <div className='col-md-5'>
                    <h2>Customise Request</h2>
                    
                    <div className='orangeBox'>
                        <ul>
                            <li>
                                <span className='icon'><img src={iconUSer} alt='User' /></span>
                                <span>
                                    <h5><b>Lorem</b></h5>
                                    <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                </span>
                            </li>
                            <li>
                                <span className='icon'><img src={iconDoc} alt='Doc' /></span>
                                <span>
                                    <h5><b>Lorem</b></h5>
                                    <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                </span>
                            </li>
                            <li>
                                <span className='icon'><img src={iconUpload} alt='Upload' /></span>
                                <span>
                                    <h5><b>Lorem</b></h5>
                                    <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                </span>
                            </li>
                            <li>
                                <span className='icon'><img src={iconCart} alt='Cart' /></span>
                                <span>
                                    <h5><b>Lorem</b></h5>
                                    <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <figure className='bgImg'>
                        <img src={customisebg} alt='' />
                    </figure>
                </div>

                <div className='col-md-7'>
                    <div className='shadow-box-rounded'>
                        <div className="topheading">
                            <h5>Customise Request</h5>
                            <span>* Mandatory Fields</span>
                        </div>

                        <div className="form-group">
                            <TextInput
                                type={"text"}
                                name={"fullName"}
                                id={"fullName"}
                                placeholder={"Enter First and Last Name"}
                                inputClass={"form-control"}
                                lblClass={"input-lable"}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                label={"Full Name *"}
                                value={fullName}
                                autoComplete="off"
                                errorMSg={errors.fullName}
                            />
                        </div>

                        <div className="form-row">
                            <div className="form-group country-code">
                                <Reactselect
                                    name={"countryCode"}
                                    id={"countryCode"}
                                    label={"Mobile Number *"}
                                    options={countryCodeData}
                                    value={countryCode}
                                    lblClass={"input-lable"}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    // errorMsg={errors.errCountryCode}
                                />
                            </div>

                            <div className="form-group tel">
                                <TextInput
                                    type={"tel"}
                                    value={mobileNo}
                                    name={"mobileNo"}
                                    id={"mobileNo"}
                                    placeholder={"Enter Mobile Number"}
                                    inputClass={"form-control"}
                                    maxLength={10}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    autoComplete="off"
                                    errorMSg={errors.errMobileNo}
                                />
                            </div>

                            <div className="form-group">
                                <TextInput
                                    type={"text"}
                                    name={"email"}
                                    id={"email"}
                                    label={"Email *"}
                                    value={email}
                                    placeholder={"Enter Email Id"}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMSg={errors.errEmail}
                                />
                            </div>
                        </div>    

                        <div className="form-row">
                            <div className="form-group">
                                <Reactselect
                                    name={"category"}
                                    id={"category"}
                                    label={"Category *"}
                                    value={category}
                                    options={categoryData}
                                    lblClass={"input-lable"}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errCategory}
                                />
                            </div>
                            <div className="form-group">
                                <Reactselect
                                    name={"subCategory"}
                                    id={"subCategory"}
                                    label={"Sub-Category *"}
                                    options={subCategoryData}
                                    value={subCategory}
                                    lblClass={"input-lable"}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errSubCategory}
                                />
                            </div>
                        </div> 

                        <div className="form-row">
                            <div className="form-group">
                                <Reactselect
                                    name={"productType"}
                                    id={"productType"}
                                    label={"Product Type *"}
                                    options={productTypeData}
                                    value={productType}
                                    lblClass={"input-lable"}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errProductType}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group req">
                                <label className="requirements" htmlFor="requirements">Specify your Requirements</label>
                                <textarea name='requirements' rows="4" />
                            </div>
                        </div>

                        <div className="make-info">
                            <button 
                                className="btn btn-primary" 
                                type="submit" 
                                onClick={handelFormSubmit}><span>Customise Now</span></button>
                        </div>  

                    </div>
                </div>
            </section>

        </div>
    </div>
  )
}

export default CustomiseRequest