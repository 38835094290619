import React, {useEffect} from 'react'
import ProductGallery from '../../ProductGallery/index';
import MyCart from '../../Mycart';
import { Link } from 'react-router-dom';

const ProductDetails = () =>{
    document.body.classList.add('product_details'); 

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
        <div className="container">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to={'/'}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to={'/shop-for-kids'}>Shop for Kids</Link>
                </li>
                <li className="breadcrumb-item active">Ring</li>
            </ol>
        </nav>

        <div>
           <ProductGallery />
        </div>
        
    </div>
        </div>
    )
}

export default ProductDetails;
