import React,{useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';
import iconTick from '../../../assets/images/icons/icon_tick.png';
import './index.css'

function SellOnSummeraThankYou() {

    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    

    // console.log("objectlocationlocation", location)
    const { state } = location
    return (
        <div className='wrapper sell-thank-you'>
        
            <div className='container'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">Thank You</li>
                    </ol>
                </nav>

                <div className='login-box'>
                    <div className="login-wrapper">
                        <h5>Hello {state[0]} <span>{state[1]}</span></h5>

                        <ul className="contact-list">
                            <li>
                                <img src={iconTick} alt="Thank You for registering at Summera" /> <h2>Thank You for registering at Summera</h2>
                            </li>
                        </ul>

                        <h5 className="grant">You will be granted access to Supplier Profile after your <span>account verification procedure is completed.</span></h5>

                        {/* <p>Although this is an automated email, We wanted to personally thank you <span>to register your company as a Seller to explore <Link to={'/'}>summera.in</Link> platform.</span></p> */}
                    </div>  
                </div> 

            </div>
        </div>
    )
}

export default SellOnSummeraThankYou