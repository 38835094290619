import React from 'react'
import { Link } from 'react-router-dom';
import { FiHeart } from 'react-icons/fi';
import { BiRupee } from 'react-icons/bi';
import './card.css';
import productData from './productdata';
import { Alert } from 'bootstrap';
import { useDispatch } from 'react-redux';
import { viewSimilarProductAction } from '../../redux/actions/cartAction';
import Carousel from '../atom/Carousel';

export default function ProducCard({slider,slidestoshow,slidestoscroll,centerpadding}) {

    const dispatch = useDispatch();

    const handleClick = event => {
        event.currentTarget.classList.toggle('active');
    };
    
    const cards = productData.map((item, index) => {
       
            return (
            <div className='card' key={index}>
                <div className='card-header'>
                    <Link to={'/productdetails'}>
                        <img src={item.imgsrc} className='card-img-top' alt={item.pname} title={item.pname} />
                    </Link>
                </div>
                <div className='product-label'>
                    <ul>
                        <li className=
                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                        </li>
                    </ul>
                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                        <FiHeart js-heart="true" onClick={handleClick} />
                    </span>
                </div>
                <div className='card-body'>
                    <p>{item.pname}</p>
                    <div className='price'><span><BiRupee />{item.price}</span>
                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                    </div>
                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                    <div className='btn-similar'>
                        <button className='btn' onClick={() => dispatch(viewSimilarProductAction())} >View Similar</button>
                    </div>
                </div>
            </div>
            )
        })
  return (
        <>
            {slider ?
                <Carousel slidestoshow ={slidestoshow} slidestoscroll ={slidestoscroll} centerpadding ={centerpadding}>
                    {cards}
                </Carousel>
                : <> {cards} </>
            }
        </>
  )
}
