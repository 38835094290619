import {React,useEffect} from 'react'
import ring from '../../assets/images/ring1.png'
import { Link } from 'react-router-dom'
import '../../components/Thankyou/index.css'
import { AiOutlineFilePdf } from "react-icons/ai";
import { FaTruck } from "react-icons/fa";
import { BiRupee } from 'react-icons/bi';

const Data = [
    { id:1, src:ring, alt: "Ear Rings", name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", trial: "FREE TRIAL", price: "1,09,999"},
    { id:2, src:ring, alt: "Ear Rings", name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", trial: "FREE TRIAL", price: "1,09,999"},
    { id:3, src:ring, alt: "Ear Rings", name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", trial: "FREE TRIAL", price: "1,09,999"},
    { id:4, src:ring, alt: "Ear Rings", name: "Gold Ear Ring Lorem Ipsum", detail: "18 KT Yellow Gold (3.400 g)", trial: "FREE TRIAL", price: "1,09,999"}
]

const ThankyouForTryAtHome = () => {
    document.body.classList.add('thankyou'); 

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <div className='thankyou-wrapper thankyou-try'>
        <div className='container'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Thank You</li>
                </ol>
            </nav>
        </div>
        <div className='d-flex'>
            <div className='inner'>
            <div className="heading">
                <div className="container">
                <h2>Thank you for Try at Home Request</h2>
                <h4>Your request has been sent successfully. Someone will be in connect with you shortly.</h4>
                </div>
            </div>

            <div className="address-details">
                <div className="container">
                <div className="billing-shipping">
                    <div className='inner-div'>
                        <div className='address'>
                            <h4>Address</h4>
                            <div className="custom-address">
                            <h6>Kaushal Ranpura</h6>
                            <address>501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400 045. India</address>
                            </div>
                        </div>
                        <div className='details'>
                            <ul>
                                <li>
                                    <h4>Appointment Date</h4>
                                    <div className="custom-address">
                                    <h6>Tuesday, Feb 07, 2023</h6>
                                    </div>
                                </li>
                                <li>
                                    <h4>Appointment Time</h4>
                                    <div className="custom-address">
                                    <h6>12:00 PM</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="thank-order-summary">
                <div className="container">
                    <h4>Your Try at Home Summary</h4>
                    <div className="your-order-summary"> 
                            
                        <div className="left">  
                             
                            <div className='left-wrapper'>
                                <ul>

                                    {
                                        Data.map((item,index)=>{
                                            return(
                                                <>
                                                    <li key={index}>
                                                        <div className="order-product">
                                                            <div className="order_product_details">
                                                                <ul>
                                                                    <li>
                                                                        <figure>
                                                                            <img src={item.src} className="card-img-top" alt={item.alt} title={item.alt} />
                                                                        </figure>
                                                                    </li>
                                                                    <li>
                                                                        <h4>{item.name}</h4>
                                                                        <h6>{item.detail}</h6>
                                                                        <p className="trial">{item.trial}</p>
                                                                    </li>                  
                                                                </ul>
                                                                <ul className="second-list">
                                                                    <li><BiRupee />{item.price}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <hr />
                                                </>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                        
                        <div className="right">
                            <div className="order-summary">
                                    <div className="order-sum">
                                        <h3>Try at Home Summary</h3>
                                    </div>
                                    <div className="summary-data">
                                        <span className="close">&times;</span>
                                        <ul>
                                            <li>
                                                <h5>Free Trial</h5>
                                                <span className="amount"><strong><BiRupee />0</strong></span>
                                            </li>
                                            <li>
                                                Service Charge<span className="amount"><BiRupee />0</span>
                                            </li>
                                            <li>
                                                <h5>Total Amount</h5><span className="amount"><BiRupee /><strong>0</strong></span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            <div className="summary-cancle">
                                <Link className="btn btn-primary btn-cancle" title="Link to Cancel">Cancel</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default ThankyouForTryAtHome;