import React from 'react'
import { Link } from 'react-router-dom'

export default function ShopOccasion(props) {
  return (
      <li>
            <Link to={props.url}>
                <figure>
                    <img src={props.imgsrc} alt="" title="" />
                    <figcaption>
                        <h3>{props.title}</h3>
                    </figcaption>
                </figure>
            </Link>
        </li>
  )
}
