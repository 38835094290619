import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';
import { GrFormClose } from 'react-icons/gr';
import tryAtHome from '../../../assets/images/tryathome.png';

const TryAtHome = () => {
  
    const TryAtHomePopup = () => (
        <Popup trigger={<button className="btn btn-secondary"><span>Try at Home</span></button>} modal aria-describedby={"pinHome"}>
      {close => (
          <div className='pop-body'>
            <span className="close" onClick={close}>
              <span><GrFormClose className="closebtn" /></span>
            </span>

            <h2>Experience Luxury in the Comfort of Your Home</h2>
            <p>Buy only if you like! Try minimum 1 & maximum 5 designs free of cost</p>
            <img src={tryAtHome} alt="Schedule a Video Call" title="Schedule a Video Call" />

            <Link className="btn btn-primary" to={'/review-cart'} onClick={close}><span>Add to Try at Home Cart</span></Link>
            
          </div>
      )}
      </Popup>
    );
  
  return (
    TryAtHomePopup()
  )
};
export default TryAtHome;