
const MyAccData = [
    {
        "id": "01",
        "appointment_placed": "12 Jan 2023",
        "appointment_date_time": "07 Feb 2023 12:00 AM",
        "total_items": "03",
        "appointment_address": "Kaushal Ranpura",
        "appointment_no": "MAZE202471002", 
        "appointment_status": "confirm", 
    },
    {
        "id": "02",
        "appointment_placed": "12 Jan 2023",
        "appointment_date_time": "07 Feb 2023 12:00 AM",
        "total_items": "03",
        "appointment_address": "Kaushal Ranpura",
        "appointment_no": "MAZE202471002", 
        "appointment_status": "closed", 
    },
    {
        "id": "03",
        "appointment_placed": "12 Jan 2023",
        "appointment_date_time": "07 Feb 2023 12:00 AM",
        "total_items": "03",
        "appointment_address": "Kaushal Ranpura",
        "appointment_no": "MAZE202471002", 
        "appointment_status": "confirm", 
    },

]

export default MyAccData;