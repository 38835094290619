import React, { useRef } from 'react'
import HappyBirthday from '../../assets/images/gifts/cards/happy-bithday-big.png'
import PerfectCouple from '../../assets/images/gifts/cards/perfect-couple.png'
import { Link } from 'react-router-dom'
import '../Thankyou/index.css'
import { AiOutlineFilePdf } from "react-icons/ai";
import { AiOutlinePrinter } from "react-icons/ai";
import { useReactToPrint } from 'react-to-print';
import PrintableInvoice from './invoice';
import DownloadButton from './DownloadButton';
import { useLocation } from 'react-router-dom';

export default function ThankYou() {
document.body.classList.add('thankyou'); 
const location = useLocation()
const url = location.pathname;

const componentRef = useRef();

const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

const handlePrint1 = () => {
    const printWindow = window.open('','_current');
    printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
              body {
                margin: 0;
              }
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          </style>
        </head>
        <body>
          <img src="${HappyBirthday}" alt="Happy Birthday" />
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handlePrint2 = () => {
    const printWindow = window.open('','_current');
    printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
              body {
                margin: 0;
              }
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          </style>
        </head>
        <body>
          <img src="${PerfectCouple}" alt="Perfect Couple" />
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className='thankyou-wrapper thanks'>
        <div className='container'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Thank You</li>
                </ol>
            </nav>
        </div>
        <div className='d-flex'>
            <div className='inner'>
                <div className="heading">
                    <div className="container">
                        <h2>Dear Kaushal Ranpura,</h2>
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula  sapien consectetur, ultrices mauris. Maecenas vitae mattis tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.</h4>

                        <h5>Thank You</h5>

                        <hr />
                    </div>
                </div>

            <div className="order-info">
                <div className="container">
                    <div className="track-order">      
                        <div className="order-details">
                            <ul>
                                <li>Total Amount : <span>₹ 3,000</span></li>
                                <li>Booking Reference Number : <span>56428569</span></li>
                            </ul>
                        </div>
                        <div className="track-order-details">
                            <ul>
                                <li>
                                    {/* <div id="invoice-container">
                                        <Invoice ref={componentRef} />
                                    </div> */}
                                    <PrintableInvoice  componentRef={componentRef} />
                                    <span className="btn btn-secondary" onClick={handlePrint}><AiOutlineFilePdf /> Download Invoice</span>
                                </li>
                            </ul>       
                        </div>
                    </div>
                </div>
            </div>

            <div className="thank-order-summary">
                <div className="container">
                    {url === "/gifts/buy-a-card-customised/thank-you" ? <h4> Buy A Card - Perfect Couple </h4> : <h4>{url === "/gifts/buy-a-card/thank-you" ? "Buy" : "Gift" } A Card - Happy Birthday</h4> }

                    <div className='card-details'>
                        {url === "/gifts/buy-a-card-customised/thank-you" ? <img src={PerfectCouple} className='card-img' alt='Perfect Couple' title='Perfect Couple' /> : <img src={HappyBirthday} className='card-img' alt='HappyBirthday' title='HappyBirthday' /> }
                        <span className='card-type'>Birthday</span>
                        <span className='pric'>₹ 3,000</span>
                        <span className='card-number'>3592 9500 4229 7569</span>
                        <span className='valid-from'> VALID FROM <span> 08/23 </span></span>
                        <span className='valid-thru'> VALID THRU <span> 08/24 </span></span>
                        <span className='name'>KAUSHAL RANPURA</span>
                    </div>

                    <div className="get-card">
                        <ul>
                            <li>
                                {url === "/gifts/buy-a-card-customised/thank-you" ? <span className="btn btn-secondary" onClick={handlePrint2}><AiOutlinePrinter /> Print Card </span> : <span className="btn btn-secondary" onClick={handlePrint1}><AiOutlinePrinter /> Print Card </span> }
                            </li>
                            <li>
                                {url === "/gifts/buy-a-card-customised/thank-you" ? <DownloadButton fileName="perfect-couple.png" filePath={PerfectCouple} /> : <DownloadButton fileName="happy-bithday-details.png" filePath={HappyBirthday} /> }
                            </li>
                        </ul> 

                        <Link className="btn btn-primary" to={'/'}><span>Back To Home</span></Link>      
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}
