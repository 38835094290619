import {React,useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import Checkout from '../Checkout'


const Checkoutpage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Checkout />
  )
}
export default Checkoutpage;
