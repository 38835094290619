import React from 'react'
import './index.css';
import Certified from '../../assets/images/icons/insured-certified.png';
import Exchange from '../../assets/images/icons/icon-exchange.png';
import Delivery from '../../assets/images/icons/icon-delivery.png';
import Transparency from '../../assets/images/icons/icon-transparency.png';
import ZeroDeduction  from '../../assets/images/icons/zerodeduction.png';
import COD from '../../assets/images/icons/icon-cod.png';
import Guaranteed from '../../assets/images/icons/icon-guaranteed.png';
import International from '../../assets/images/icons/international-insurance.png';

const Data =[
  {id:1, src:Certified, cardtext:'Your Jewellery is Insured Certified'},
  {id:2, src:Exchange, cardtext:'Jewellery Returns and Exchange'},
  {id:3, src:Delivery, cardtext:'Free Delivery'},
  {id:4, src:Transparency, cardtext:'Complete Transparency'},
  {id:5, src:ZeroDeduction, cardtext:'Zero Deduction Gold Exchange'},
  {id:6, src:COD, cardtext:'COD Available'},
  {id:7, src:Guaranteed, cardtext:'Guaranteed Buyback'},
  {id:8, src:International, cardtext:'International Transit Insurance'}
]

const SummeraPromise = () => {
  return (
    <div className='promise-card-wrapper'>
      {
        
          Data.map((item,index)=>{
            return(
                
                <div className='promise-card' key={index}>
                  <figure className='icon'>
                      <img src={item.src} alt={item.cardtext} title={item.cardtext} />
                  </figure>
                  <figcaption>
                    {item.cardtext}
                  </figcaption>
                </div>
            )
          })
        
      } 
      </div>
  )
}

export default SummeraPromise;