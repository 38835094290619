import {React,useState} from 'react';
import facebook from '../../../assets/images/facebook.png'
import google from '../../../assets/images/google.png'
import { Link } from 'react-router-dom';
import TextInput from "../../../components/atom/input/input";
import './index.css'

const Login = () => {
    //document.body.classList.add('login-page');
    const [openOpt, setOpenSet] = useState(true);
    const OpenOtpSec = () => {
        setOpenSet(!openOpt);
    }

  return (
    <div className='wrapper'>
        <div className="container">
            <div className="login-box">
                <div className="login-wrapper text-center">
                {
                    openOpt?
                        <div className='loginForm'>
                            <h2>Login</h2>
                            <h5>Easy Login</h5>
                            <ul className="login-list">
                                <li><Link to={''}><img src={facebook} alt="facebook icon" title="facebook icon" /></Link></li>
                                <li><Link to={''}><img src={google} alt="google icon" title="google icon" /></Link></li>
                            </ul>
                            <h6>OR</h6>
                            <div className='form-group'>
                                <label>Enter Mobile Number Or Email Id</label>
                                <TextInput
                                        type={"tel"}
                                        label={"Enter Mobile Number Or Email Id"}
                                        inputClass={"form-control"}
                                        lblClass={"input-lable"}
                                        autoComplete={"off"}
                                />
                                <button className="btn btn-primary" onClick={OpenOtpSec}><span>Login</span></button>

                                <p>Don't have an account? <Link to={'/registration'}>Register Now</Link></p>
                            </div>
                        </div>:
                    <OTPVerify />
                }

                    </div>    
                     
            </div> 
        </div>
    </div>
  )
}

export default Login

// OPT Verfication 
export const OTPVerify = () => {
    return (
        <div className="otpverifyPage">
            <h2>OTP Verification</h2>
            <form>
                <label>Enter the OTP sent to +91 XXXXXX0245</label>
                <div className="inputFields">
                    <TextInput
                        type={"tel"}
                        autoComplete={"off"}
                    />
                    <TextInput
                        type={"tel"}
                        autoComplete={"off"}
                    />
                    <TextInput
                        type={"tel"}
                        autoComplete={"off"}
                    />
                    <TextInput
                        type={"tel"}
                        autoComplete={"off"}
                    />
                    <TextInput
                        type={"tel"}
                        autoComplete={"off"}
                    />
                    <TextInput
                        type={"tel"}
                        autoComplete={"off"}
                    />
                </div>
                <Link className="btn btn-primary" to={'/checkout'}><span>Verify & Proceed</span></Link>
                <p>Don't receive the OTP? <Link to={''}>Resend OTP</Link></p>
            </form>
        </div>
    )
}