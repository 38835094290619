const OrderHeader = (props) => {
    return (
        <div className="topOrderInfo">
            <ul>
                <li>
                    <ul>
                        <li>
                            <h4>Order Placed</h4>
                            <span>{props.orderplace}</span>
                        </li>
                        <li>
                            <h4>Order No.</h4>
                            <span>{props.ordernumber}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

    export default OrderHeader;