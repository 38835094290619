import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


class Carousel extends Component {
  render() {

    const { slidestoshow, slidestoscroll, dots, infinite, speed, variableWidth, arrows, lazyLoad  } = this.props

    var settings = {
      dots: dots ? dots : false,
      infinite: infinite ? infinite : true,
      speed: speed ? speed : 500,
      arrows: arrows ? arrows : true,
      slidesToShow: slidestoshow ? slidestoshow : 1,
      variableWidth: variableWidth ? variableWidth : true,
      lazyLoad: lazyLoad ? lazyLoad :  true,
      slidesToScroll: slidestoscroll ? slidestoscroll : 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
        <div className="container">
          <div className="sliderWapper">
            <Slider {...settings}>
              {this.props.children}
            </Slider>
          </div>
        </div>
    );
  }
}

export default Carousel;