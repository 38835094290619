import {React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import Customised from '../../assets/images/gifts/cards/customised.png'
import Loreum from '../../assets/images/gifts/icons/loreum.svg';
import { alphaNumeric, alphaNumericWithSpecil, isValidEmail, isValidMobileNo, numberText } from "../helper/method";
import TextInput from "../molecules/input";
import Reactselect from "../molecules/select";
import Checkbox from '../atom/checkbox/input'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ImCross } from 'react-icons/im';
import OTPVerification from '../molecules/OTPVerification'
import { Input,FormGroup} from 'reactstrap';
import Delete from '../../assets/images/gifts/icons/delete.svg'

export default function BuyACardCustomised() {

    const countryCodeData =[
        {id:1, value:'IN 91', label:'IN 91'},
        {id:1, value:'USA 1', label:'USA 1'}
    ]

    const Amount =[
        {id:1, amount:'2,000'},
        {id:2, amount:'3,000'},
        {id:3, amount:'4,000'},
        {id:4, amount:'5,000'},
        {id:5, amount:'7,500'},
        {id:6, amount:'10,000'}
    ]

    const titleData =[
        {id:1, value:'Mrs', label:'Mrs'},
        {id:2, value:'Ms', label:'Ms'},
        {id:3, value:'Mr', label:'Mr'}
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let hasError = false;
    const [title, setTitle] = useState("");
    const [cardName, setCardName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [stdcode, setStdcode] = useState("IN 91");
    const [mobileNo, setMobileNo] = useState("");
    const [amount, setAmount] = useState("");
    const [selectedAmount, setSelectedAmount] = useState()
    const [quantity, setQuantity] = useState("");
    const [active, setActive] = useState();
    const [checkbox,setCheckbox] = useState(false);
    // const [panNumber, setPanNumber] = useState("");
    const [cardFile, setCardFile] = useState(null);
    const [cardImage, setCardImage] = useState("");
    const [uploadedImage, setUploadedImage] = useState(null);

    const [errors, setErrors] = useState({
        errTitle: "",
        errCardName: "",
        errFirstName: "",
        errLastName: "",
        errEmail: "",
        errContryCode: "",
        errMobileNo: "",
        errQuantity: "",
        // errPanNumber: "",
        errCardFile: "",
        errAmount: "",
    })

    const handleStateChange = (name, value) => {
        // console.log("name and value", name ,value)

        if (name === "title") {
            setTitle(value)
            handleError(name, value)
        }
        // if (name === "panNumber") {
        //     let check = value.toUpperCase()
        //     if (alphaNumeric(check)) {
        //         setPanNumber(check)
        //         handleError(name, value)
        //     }
        // }
        if (name === "cardName") {
            let check = String(value)
            if (alphaNumericWithSpecil(check)) {
                setCardName(check)
                handleError(name, value)
            }
        }
        if (name === "firstName") {
            let check = String(value)
            if (alphaNumericWithSpecil(check)) {
                setFirstName(check)
                handleError(name, value)
            }
        }
        if (name === "lastName") {
            let check = String(value)
            if (alphaNumericWithSpecil(check)) {
                setLastName(check)
                handleError(name, value)
            }
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "mobileNo"){
            let check = value
            if(numberText(check)){
                setMobileNo(check)
                handleError(name,value)
            }
        }
        if (name === "stdcode") {
            setStdcode(value)
            handleError(name, value)
        }
        if (name === "amount") {
            let check = value
            if (numberText(check)) {
                setAmount(value)
                setActive("textamt")
                setSelectedAmount()
                handleError(name, value)
            }
        }
        if (name === "quantity") {
            let check = value.toUpperCase()
            if (alphaNumeric(check)) {
                setQuantity(check)
                handleError(name, value)
            }
        }
    }

    const handleError = (name, value) => {
    
        // console.log("handle OnBlure Change", name, "value", value)
    
        let allState = errors;

        if (name === "title") {
            if (!value.length) {
                allState["errTitle"] = "Select Title."
            } else {
                allState["errTitle"] = ""
            }
        }
        // if (name === "panNumber") {
        //     let check = value
        //     if (!value.length) {
        //         allState["errPanNumber"] = "Enter your PAN Number"
        //     } else if (!checkPanNumber(check)) {
        //         allState["errPanNumber"] = "Invailid Pan number!"
        //     } else {
        //         allState["errPanNumber"] = ""
        //     }
        // }
        if (name === "cardName") {
            if (!value.length) {
                allState["errCardName"] = "Enter your Card name"
            } else if (value.length < 3) {
                allState["errCardName"] = "Card Name length should be 3 character"
            } else {
                allState["errCardName"] = ""
            }
        }
        if (name === "firstName") {
            if (!value.length) {
                allState["errFirstName"] = "Enter your First name"
            } else if (value.length < 3) {
                allState["errFirstName"] = "First Name length should be 3 character"
            } else {
                allState["errFirstName"] = ""
            }
        }
        if (name === "lastName") {
            if (!value.length) {
                allState["errLastName"] = "Enter Last Name"
            } else if (value.length < 3) {
                allState["errLastName"] = "Last Name length should be 3 character"
            } else {
                allState["errLastName"] = ""
            }
        }
        if(name === "email"){
            if(!value.length){
                allState["errEmail"] = "Enter your email"
            }else if(!isValidEmail(value)){
                allState["errEmail"] = "Invalid email."
            }else{
                allState["errEmail"] = ""
            }
        }
        if (!stdcode.length) {
            allState["errContryCode"] = "Please select country code."
            hasError = true
        }else {
            allState["errContryCode"] = ""
        }
        if(name === "mobileNo"){
            if(!value.length){
                allState["errMobileNo"] = "Mobile no is required."
            }else if(!isValidMobileNo(value)){
                allState["errMobileNo"] = "Enter a valid number."
            }else{
                allState["errMobileNo"] = ""
            }
        }
        if (name === "quantity") {
            if (!value.length) {
                allState["errQuantity"] = "Please select Quantity."
            }else {
                allState["errQuantity"] = ""
            }
            // else if(value.length < 6) {
            //     allState["errQuantity"] = "Quantity error!"
            // }
        }
        if (name === "amount") {
            if (!value.length) {
                allState["errAmount"] = "Enter your Amount"
            } else {
                allState["errAmount"] = ""
            }
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () => {
        hasError = false;
        let allState = errors;

        if (!title.length) {
            allState["errTitle"] = "Select Title."
            hasError = true
        } else {
            allState["errTitle"] = ""
        }
        // if (!panNumber.length) {
        //     allState["errPanNumber"] = "Enter your PAN Number"
        //     hasError = true;
        // } else if (panNumber.length < 10) {
        //     allState["errPanNumber"] = "PAN Number length should be 10 charector"
        //     hasError = true;
        // } else {
        //     allState["errPanNumber"] = ""
        // }
        if (!cardImage.length) {
            allState["errCardFile"] = "Select Card File."
            hasError = true
        } else {
            allState["errCardFile"] = ""
        }
        if (!cardName.length) {
            allState["errCardName"] = "Enter your Card Name"
            hasError = true;
        } else if (cardName.length < 3) {
            allState["errCardName"] = "Card Name length should be 3 character"
            hasError = true;
        } else {
            allState["errCardName"] = ""
        }
        if (!firstName.length) {
            allState["errFirstName"] = "Enter your First Name"
            hasError = true;
        } else if (firstName.length < 3) {
            allState["errFirstName"] = "First Name length should be 3 character"
            hasError = true;
        } else {
            allState["errFirstName"] = ""
        }
        if (!lastName.length) {
            allState["errLastName"] = "Enter Last Name"
            hasError = true;
        } else if (lastName.length < 3) {
            allState["errLastName"] = "Last Name length should be 3 character"
            hasError = true;
        } else {
            allState["errLastName"] = ""
        }
        if (!email.length) {
            allState["errEmail"] = "Enter your email"
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }
        if (!stdcode.length) {
            allState["errContryCode"] = "Please select country code."
            hasError = true
        }else {
            allState["errContryCode"] = ""
        }
        if (!mobileNo.length) {
            allState["errMobileNo"] = "Mobile no is required."
            hasError = true
        } else if (!isValidMobileNo(mobileNo)) {
            allState["errMobileNo"] = "Enter a valid number."
            hasError = true
        } else {
            allState["errMobileNo"] = ""
        }
        if (!quantity.length) {
            allState["errQuantity"] = "Please select Quantity."
            hasError = true
        }else {
            allState["errQuantity"] = ""
        }
        let errAmt = amount || selectedAmount 
        if (!errAmt) {
            allState["errAmount"] = "Please select Amount."
            hasError = true
        }else {
            allState["errAmount"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }    

    const handleCardUpload = ({target: {files,name}}) => {
        const file = files[0];
        const length = files.length;
        const size = file.size;
        const type = file.type;

        if (name === "cardImage") {
            setCardFile(file)
            setCardImage(file.name)
            // setUploadedImage(URL.createObjectURL(file));
            handleFileError(name,file,length,size,type)
        }
    }

    const handleCardDelete = () => {
        setUploadedImage(null);
    };

    const handleFileError = (name,file,length,size,type) => {
        const validTypes = ['image/jpeg', 'image/png'];

        // console.log("handleFileError" ,size,type)

        let allState = errors;

        if (name === "cardImage") {
            if (!length) {
                allState["errCardFile"] = "Select Card Image."
                return false;
            }
            if (size > 40000) {
                allState["errCardFile"] = "Image size should be less than 40KB."
                return false;
            }
            if (!validTypes.includes(type)) {
                allState["errCardFile"] = "Please select jpg or png image only."
                return false;
            } else {
                setUploadedImage(URL.createObjectURL(file));
                allState["errCardFile"] = ""
            }
        }
      
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const handelFormSubmit = async(e) => {
        e.preventDefault()
        if(!checkVailidation()){ 
            if(checkbox){
            setOpenSet(!openOpt);
            }else{
                alert("Please check term & conditions.")
            }
        }
    }

    const TermsAndConditions = () => (
        <Popup trigger={<Link>Terms and Conditions</Link>} modal aria-describedby={"pinHome"}>
          {close => (
            <div className='terms-popup'>
                <div className='pop-title'>
                    <h2>Terms and Conditions</h2>
                    <span className="close" onClick={close}><span><ImCross className="closebtn" /></span></span>
                </div>
    
                <div className='pop-body'>
                    <div className='inner-div'>
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>
    
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>
    
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>     
                    </div>  
                </div>
            </div>
          )}
          </Popup>
      );

      const handleCheckboxChange = () => {
        setCheckbox(!checkbox)
    }

    const [openOpt, setOpenSet] = useState(true);
    const OpenOtpSec = () => {
        setOpenSet(!openOpt);
    }

    const handleSelectAmount = (index,name,value) => {
        hasError = false
        let allState = errors;

        setActive(index)
        setSelectedAmount(value)
        setAmount("")

        if (!value) {
            allState["errAmount"] = "Please select Amount."
            hasError = true
        }else {
            allState["errAmount"] = ""
        }
        
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }

  return (
    <div className='wrapper buy-a-card buy-a-card-customised'>
        {
            openOpt?
            <>
                <div className='banner-div'>
                    <div className='container'>
                        <h1>Buy A Card</h1>
                    </div>
                </div>

                <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                            <li className="breadcrumb-item"><Link to={'/gifts'}>Gifts</Link></li>
                            <li className="breadcrumb-item active">Buy A Card - Customised</li>
                        </ol>
                    </nav>
                
                    <p>May you be gifted with life’s biggest joys and never-ending bliss. After all, you yourself are a gift to earth, so you deserve the best. Count not the candles…see the lights they give. Count not the years, but the life you live. Wishing you a wonderful time ahead..Happy birthday.</p>

                    <div className="tab-content" id="myTabContent">

                        <section className='row gap0'>
                            <div className='card-benefits'>
                                <div className='orangeBox'>
                                    <figure className='bgImg'>
                                        <img src={Customised} alt='Customised' />
                                    </figure>
                                    <h2>Card Benefits</h2>
                                    <ul>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Lorem ipsum</b></h5>
                                                <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Lorem ipsum</b></h5>
                                                <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Lorem ipsum</b></h5>
                                                <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Lorem ipsum</b></h5>
                                                <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>

                            <div className='form'>
                                <div className='shadow-box-rounded'>
                                    <div className="topheading">
                                        <h5>Buy A Card - Customised</h5>
                                        <span>* Mandatory Fields</span>
                                    </div>

                                    <div className="form-group radio">
                                        <input type="radio" id="GVCard" name="GVCard" value="e-GV Card" defaultChecked />
                                        <label htmlFor="GVCard">e-GV Card</label>
                                    </div>

                                    <div className='form-group card-div'>
                                        {!uploadedImage ? 
                                            <FormGroup>
                                                <Input
                                                    type="file"
                                                    id="exampleCustomFileBrowser"
                                                    name="cardImage"
                                                    onChange={handleCardUpload}
                                                />
                                                {errors["errCardFile"] ? <div className='error-message'>{errors.errCardFile}</div> : null}
                                            </FormGroup> 
                                            : 
                                            <>
                                                <div className='card-details'>
                                                    <img src={uploadedImage} className='card-img' alt='Perfect Couple' title='Perfect Couple' />
                                                    <span className='card-type'>Birthday</span>
                                                    <span className='pric'>₹ 3,000</span>
                                                    <span className='card-number'>3592 9500 4229 7569</span>
                                                    <span className='valid-from'> VALID FROM <span> 08/23 </span></span>
                                                    <span className='valid-thru'> VALID THRU <span> 08/24 </span></span>
                                                    <span className='name'>KAUSHAL RANPURA</span>
                                                </div>
                                                <div className='update'>
                                                    <FormGroup>
                                                        <Input
                                                            type="file"
                                                            id="exampleCustomFileBrowser"
                                                            name="cardImage"
                                                            onChange={handleCardUpload}
                                                            className="edit"
                                                        />
                                                    </FormGroup>
                                                    <img src={Delete} className='delete' alt='Delete' title='Delete' onClick={handleCardDelete} />
                                                </div>
                                            </>
                                        }
                                    </div>  

                                    <div className="form-group card-name">
                                        <TextInput
                                            type={"text"}
                                            name={"cardName"}
                                            id={"cardName"}
                                            placeholder={"Enter Card Name"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Card Name*"}
                                            value={cardName}
                                            autoComplete="off"
                                            maxLength="20"
                                            errorMSg={errors.errCardName}
                                        />
                                        <div className="message">Max: 20 Characters</div>
                                    </div>

                                    <div className="form-group">
                                        <label className='ammount'>Amount*</label>
                                        <ul className='amount'>
                                            {
                                                Amount.map((item, index)=>{
                                                return(
                                                    <li key={index}>
                                                        <input type='button'
                                                            key={index} 
                                                            id={index}
                                                            name={"amount"} 
                                                            onClick={() => handleSelectAmount(index,'amount', item.amount)} 
                                                            className={`btn btn-primary ${active === index ? 'active' : "" }`} 
                                                            value={`₹ ${item.amount}`}
                                                        />
                                                    </li>
                                                )
                                                })
                                            } 
                                            
                                            <TextInput
                                                type={"text"}
                                                name={"amount"}
                                                id={"amount"}
                                                placeholder={"Other Amount"}
                                                inputClass={`form-control ${active === "textamt" ? "active" : "" }`}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                value={amount}
                                                autoComplete="off"
                                                maxLength="6"
                                            />
                                            <div className="error-message">{errors.errAmount}</div>
                                        </ul>
                                    </div>

                                    <div className="form-group quantity">
                                        <TextInput
                                            type={"text"}
                                            name={"quantity"}
                                            id={"quantity"}
                                            placeholder={"Enter"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Quantity*"}
                                            value={quantity}
                                            autoComplete="off"
                                            errorMSg={errors.errQuantity}
                                            maxLength="3"
                                        />
                                    </div>

                                    <h4>Personal Information</h4>

                                    <div className="form-group name">
                                        <Reactselect
                                            name={"title"}
                                            id={"title"}
                                            label={"Title*"}
                                            options={titleData}
                                            value={title}
                                            lblClass={"input-lable"}
                                            class={"form-select"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            errorMsg={errors.errTitle}
                                        />

                                        <TextInput
                                            type={"text"}
                                            name={"firstName"}
                                            id={"firstName"}
                                            placeholder={"Enter First Name"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"First Name*"}
                                            value={firstName}
                                            autoComplete="off"
                                            errorMSg={errors.errFirstName}
                                        />

                                        <TextInput
                                            type={"text"}
                                            name={"lastName"}
                                            id={"lastName"}
                                            placeholder={"Enter Last Name"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Last Name*"}
                                            value={lastName}
                                            autoComplete="off"
                                            errorMSg={errors.errLastName}
                                        />
                                    </div>

                                    <div className="form-group">
                                        
                                    </div>

                                    <div className="form-group mobile select-city">
                                        <div className="cust-select">
                                            <Reactselect
                                                name={"stdcode"}
                                                id={"stdcode"}
                                                label={"Mobile Number*"}
                                                value={stdcode}
                                                options={countryCodeData}
                                                onChange={handleStateChange}
                                                class={"form-control form-select"}
                                                onBlur={handleError}
                                                errorMsg={errors.errMobileNo}
                                            />
                                            
                                            <TextInput
                                                type={"tel"}
                                                value={mobileNo}
                                                name={"mobileNo"}
                                                id={"mobileNo"}
                                                placeholder={"Enter Mobile Number"}
                                                inputClass={"form-control"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                autoComplete="off"
                                                maxLength="10"
                                                // errorMSg={errors.errMobileNo}
                                            />

                                            <TextInput
                                                type={"text"}
                                                name={"email"}
                                                id={"email"}
                                                placeholder={"Enter Your Email Id"}
                                                inputClass={"form-control"}
                                                lblClass={"input-lable"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                label={"Email ID*"}
                                                value={email}
                                                autoComplete="off"
                                                errorMSg={errors.errEmail}
                                            />
                                        </div>
                                    </div>

                                    <div className="make-info">
                                        <div className="checkbox">
                                            <Checkbox 
                                                autoComplete="off"
                                                id={"agree"}
                                                type={"checkbox"}
                                                name={"checkbox"}
                                                inputClass={"styled-checkbox"}
                                                onChange={handleCheckboxChange}
                                                value={checkbox}
                                                checked={checkbox ? true : false}
                                            />
                                            <label htmlFor="agree">I agree the {TermsAndConditions()} </label>
                                        </div>
                                        <button className="btn btn-primary" type="submit" onClick={handelFormSubmit}><span>Submit</span></button>
                                    </div> 
                                </div>
                            </div>
                        </section>
                            
                    </div>
                </div></> :
            <OTPVerification />
        }

    </div>
  )
}