import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';
import Handshake from '../../assets/images/icons/handshake.png';

const Thank = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wrapper thank-you'>
            <div className="container">
                <div className='login-box'>
                    <div className="login-wrapper text-center">
                        <img src={Handshake} alt='Thank you' title='Thank you'/>
                        <h2>Thank you for your registration with Summera Jewels.</h2>

                        <Link className="btn btn-primary" to={'/login'}><span>Login Now</span></Link>
                    </div>  
                </div>  
            </div>
        </div>
    )
}


export default Thank;