import {React, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './index.css'
import SelectGiftCard from '../../assets/images/gifts/icons/select-gift-card.svg'
import EnterDetails from '../../assets/images/gifts/icons/enter-details.svg'
import MakePayment from '../../assets/images/gifts/icons/make-payment.svg'
import HappyBirthday from '../../assets/images/gifts/cards/happy-bithday.png'
import Anniversary from '../../assets/images/gifts/cards/anniversary.png'
import MothersDay from '../../assets/images/gifts/cards/mothers-day.png'
import WomensDay from '../../assets/images/gifts/cards/womens-day.png'
import Valentines from '../../assets/images/gifts/cards/valentines.png'
import Diwali from '../../assets/images/gifts/cards/diwali.png'
import FirstDate from '../../assets/images/gifts/cards/first-date.png'
import ThankYou from '../../assets/images/gifts/cards/thank-you.png'
import Congratulations from '../../assets/images/gifts/cards/congratulations.png'
import Proposal from '../../assets/images/gifts/cards/proposal.png'
import Customised from '../../assets/images/gifts/cards/customised.png'
import {Accordion,AccordionBody,AccordionHeader,AccordionItem,} from 'reactstrap';
import { HiPlusSm } from "react-icons/hi";
import { HiMinusSm } from "react-icons/hi";
import Data from '../molecules/BookingInformation/data'
import CorporateEnquiryImg from '../../assets/images/gifts/corporate-enquiry.png';
import Loreum from '../../assets/images/gifts/icons/loreum.svg';
import { alphaNumeric, alphaNumericWithSpecil, isValidEmail, isValidMobileNo, numberText } from "../../components/helper/method";
import TextInput from "../molecules/input";
import Reactselect from "../molecules/select";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Gifts() {

    const giftcarddata = [
        { id:1, imgsrc: HappyBirthday, name:'Happy Birthday', price: '3,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:2, imgsrc: Anniversary, name:'Anniversary', price: '2,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:3, imgsrc: MothersDay, name:"Mother's Day", price: '3,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:4, imgsrc: WomensDay, name:"Women's Day", price: '2,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:5, imgsrc: Valentines, name:"Valentine's", price: '3,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:6, imgsrc: Diwali, name:'Diwali', price: '6,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:7, imgsrc: FirstDate, name:'First Date', price: '2,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:8, imgsrc: ThankYou, name:'Thank You', price: '2,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:9, imgsrc: Congratulations, name:'Congratulations', price: '3,000', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:10, imgsrc: Proposal, name:'Proposal', price: '7,500', buycardurl: '/gifts/buy-a-card', giftcardurl: '/gifts/gift-a-card'},
        { id:11, imgsrc: Customised, name:'Customised', price: '4,000', buycardurl: '/gifts/buy-a-card-customised', giftcardurl: '/gifts/gift-a-card'}
    ];

    const countryCodeData =[
        {id:1, value:'IN 91', label:'IN 91'},
        {id:1, value:'USA 1', label:'USA 1'}
    ]

    const settings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToShow: 4,
        // slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 4,
                }
            },
            {
                breakpoint: 699,
                settings: {
                slidesToShow: 3,
                }
            },
        ]
    }

    const Amount =[
        {id:1, amount:'2,000'},
        {id:2, amount:'3,000'},
        {id:3, amount:'4,000'},
        {id:4, amount:'5,000'},
        {id:5, amount:'7,500'},
        {id:6, amount:'10,000'}
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let hasError = false;
    const [open, setOpen] = useState('1');
    const [companyName, setCompanyName] = useState("");
    const [authorisedPersonName, setAuthorisedPersonName] = useState("");
    const [email, setEmail] = useState("");
    const [stdcode, setStdcode] = useState("IN 91");
    const [mobileNo, setMobileNo] = useState("");
    const [gstnNumber, setGstnNumber] = useState("");
    const [amount, setAmount] = useState("");
    const [selectedAmount, setSelectedAmount] = useState()
    const [quantity, setQuantity] = useState("");
    const [active, setActive] = useState();
    const [activeCard, setActiveCard] = useState();
    const [cardName, setCardName] = useState("");
    const [cardPath, setCardPath] = useState("");
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        errCompanyName: "",
        errAuthorisedPersonName: "",
        errEmail: "",
        errContryCode: "",
        errMobileNo: "",
        errQuantity: "",
        errAmount: "",
        errCard: ""
    })

    const handleStateChange = (name, value) => {
        // console.log("name and value", name ,value)

        if (name === "companyName") {
            let check = String(value)
            if (alphaNumericWithSpecil(check)) {
                setCompanyName(check)
                handleError(name, value)
            }
        }
        if (name === "authorisedPersonName") {
            let check = String(value)
            if (alphaNumericWithSpecil(check)) {
                setAuthorisedPersonName(check)
                handleError(name, value)
            }
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "mobileNo"){
            let check = value
            if(numberText(check)){
                setMobileNo(check)
                handleError(name,value)
            }
        }
        if (name === "stdcode") {
            setStdcode(value)
            handleError(name, value)
        }
        if (name === "gstnNumber") {
            setGstnNumber(value)
            handleError(name, value)
        }
        if (name === "amount") {
            let check = value
            if (numberText(check)) {
                setAmount(value)
                setActive("textamt")
                setSelectedAmount()
                handleError(name, value)
            }
        }
        if (name === "quantity") {
            let check = value.toUpperCase()
            if (alphaNumeric(check)) {
                setQuantity(check)
                handleError(name, value)
            }
        }
    }

    const handleError = (name, value) => {
    
        // console.log("handle OnBlure Change", name, "value", value)
    
        let allState = errors;

        if (name === "companyName") {
            if (!value.length) {
                allState["errCompanyName"] = "Enter your Company name"
            } else if (value.length < 3) {
                allState["errCompanyName"] = "Company Name length should be 3 charector"
            } else {
                allState["errCompanyName"] = ""
            }
        }
        if (name === "authorisedPersonName") {
            if (!value.length) {
                allState["errAuthorisedPersonName"] = "Enter Authorised Person Name"
            } else if (value.length < 3) {
                allState["errAuthorisedPersonName"] = "Authorised Person Name length should be 3 charector"
            } else {
                allState["errAuthorisedPersonName"] = ""
            }
        }
        if(name === "email"){
            if(!value.length){
                allState["errEmail"] = "Enter your email"
            }else if(!isValidEmail(value)){
                allState["errEmail"] = "Invalid email."
            }else{
                allState["errEmail"] = ""
            }
        }
        if (!stdcode.length) {
            allState["errContryCode"] = "Please select country code."
            hasError = true
        }else {
            allState["errContryCode"] = ""
        }
        if(name === "mobileNo"){
            if(!value.length){
                allState["errMobileNo"] = "Mobile no is required."
            }else if(!isValidMobileNo(value)){
                allState["errMobileNo"] = "Enter a valid number."
            }else{
                allState["errMobileNo"] = ""
            }
        }
        if (name === "quantity") {
            if (!value.length) {
                allState["errQuantity"] = "Please select Quantity."
            }else {
                allState["errQuantity"] = ""
            }
            // else if(value.length < 6) {
            //     allState["errQuantity"] = "Quantity error!"
            // }
        }
        if (name === "amount") {
            if (!value.length) {
                allState["errAmount"] = "Enter your Amount"
            } else {
                allState["errAmount"] = ""
            }
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () => {
        hasError = false;
        let allState = errors;

        if (!companyName.length) {
            allState["errCompanyName"] = "Enter your Company Name"
            hasError = true;
        } else if (companyName.length < 3) {
            allState["errCompanyName"] = "Company Name length should be 3 charector"
            hasError = true;
        } else {
            allState["errCompanyName"] = ""
        }
        if (!authorisedPersonName.length) {
            allState["errAuthorisedPersonName"] = "Enter Authorised Person Name"
            hasError = true;
        } else if (authorisedPersonName.length < 3) {
            allState["errAuthorisedPersonName"] = "Authorised Person Name length should be 3 charector"
            hasError = true;
        } else {
            allState["errAuthorisedPersonName"] = ""
        }
        if (!email.length) {
            allState["errEmail"] = "Enter your email"
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }
        if (!stdcode.length) {
            allState["errContryCode"] = "Please select country code."
            hasError = true
        }else {
            allState["errContryCode"] = ""
        }
        if (!mobileNo.length) {
            allState["errMobileNo"] = "Mobile no is required."
            hasError = true
        } else if (!isValidMobileNo(mobileNo)) {
            allState["errMobileNo"] = "Enter a valid number."
            hasError = true
        } else {
            allState["errMobileNo"] = ""
        }
        if (!quantity.length) {
            allState["errQuantity"] = "Please select Quantity."
            hasError = true
        }else {
            allState["errQuantity"] = ""
        }
        let errAmt = amount || selectedAmount 
        if (!errAmt) {
            allState["errAmount"] = "Please select Amount."
            hasError = true
        }else {
            allState["errAmount"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }    

        const toggle = (i) => {
            if (open === i) {
            setOpen();
            } else {
            setOpen(i);
        }
    };

    const handelFormSubmit = async(e) => {
        e.preventDefault()
        if(!checkVailidation()){
            navigate('/gifts/thank-you')
        }
    }

    const handleChooseCard = (index,name,imgsrc) => {
        setActiveCard(index)
        setCardName(name)
        setCardPath(imgsrc)
    }

    const handleSelectAmount = (index,name,value) => {
        hasError = false
        let allState = errors;

        setActive(index)
        setSelectedAmount(value)
        setAmount("")

        if (!value) {
            allState["errAmount"] = "Please select Amount."
            hasError = true
        }else {
            allState["errAmount"] = ""
        }
        
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }

  return (
    <div className='wrapper gifts'>

        <div className='banner-div'>
            <div className='container'>
                <h1>Gifts</h1>
            </div>
        </div>

        <div className='container'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Gifts</li>
                </ol>
            </nav>
        
            {/* <p>May no gift be too small to give, nor too simple to receive, which is wrapped in thoughtfulness and tied with love.  The greatest gift you can give someone is your time. It’s like you’re giving the portion of your life that you can never bring back.</p> */}
            {/* new content updated . */}
            <p>Make moments memorable with thoughtful gestures of affection - Explore our curated selection of exquisite gifts, each crafted to express love and appreciation</p>

            <h2>Steps of Process</h2>

            <ul className='steps'>
                <li>
                    <img src={SelectGiftCard} alt='Select Gift Card' title='Select Gift Card' />
                    <h5>Select Gift Card</h5>
                </li>
                <li>
                    <img src={EnterDetails} alt='Enter Details' title='Enter Details' />
                    <h5>Enter Details</h5>
                </li>
                <li>
                    <img src={MakePayment} alt='Make Payment' title='Make Payment' />
                    <h5>Make Payment</h5>
                </li>
            </ul>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="gift-card-tab" data-bs-toggle="tab" data-bs-target="#giftcard" type="button" role="tab" aria-controls="giftcard" aria-selected="true">Gift card</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="terms-conditions-tab" data-bs-toggle="tab" data-bs-target="#termsconditions" type="button" role="tab" aria-controls="termsconditions" aria-selected="false">Terms & Conditions</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="faqs-tab" data-bs-toggle="tab" data-bs-target="#faqs" type="button" role="tab" aria-controls="faqs" aria-selected="false">Faqs</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="corporate-enquiry-tab" data-bs-toggle="tab" data-bs-target="#corporateenquiry" type="button" role="tab" aria-controls="corporateenquiry" aria-selected="false">corporate enquiry</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane show active" id="giftcard" role="tabpanel" aria-labelledby="gift-card-tab">
                    <div className='row'>
                        {
                            giftcarddata.map((item, index) => {
                                return (
                                    <div className='gift-card' key={index}>
                                        <div className='card'>
                                            <img src={item.imgsrc} alt={item.name} title={item.name} />
                                            <span className='pric'>₹ {item.price}</span>
                                            <div className='card-body'>
                                                <Link to={item.buycardurl}><button className='btn btn-secondary'>Buy A card</button></Link>
                                                <Link to={item.giftcardurl}><button className='btn btn-primary'>Gift A card</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </div>

                <div className="tab-pane" id="termsconditions" role="tabpanel" aria-labelledby="terms-conditions-tab">
                    <div className='card'>
                        <h6>Lorem Ipsum is simply dummy</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.</p>

                        <h6>Lorem Ipsum is simply dummy</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.</p>
                        <ol type='1'>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem</li>
                        </ol>

                        <h6>Lorem Ipsum is simply dummy</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>
                </div>

                <div className="tab-pane" id="faqs" role="tabpanel" aria-labelledby="faqs-tab">
                    <div className="card">

                        {
                            Data.map((items, index) => {
                                return (
                                    <Accordion open={open} toggle={toggle} key={index}>
                                        <AccordionItem>
                                            <AccordionHeader targetId={index.toString()}>{items.title}
                                                <span className='plusicon'><HiPlusSm size={20} /></span>
                                                <span className='minusicon'><HiMinusSm size={20} /></span>
                                            </AccordionHeader>
                                            <AccordionBody accordionId={index.toString()}>
                                                <p>{items.content1}</p>
                                                <p>{items.content2}</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion>
                                )
                            })
                        }

                    </div>
                </div>

                <div className="tab-pane" id="corporateenquiry" role="tabpanel" aria-labelledby="corporate-enquiry-tab">
                    <div className="card">
                        <section className='gap0'>
                            <div className='col col-md-6'>
                                <figure className='bgImg'>
                                    <img src={CorporateEnquiryImg} alt='Corporate Enquiry' />
                                </figure>
                                <h2>Corporate Enquiry</h2>
                                <div className='orangeBox'>
                                    <ul>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Contact Information</b></h5>
                                                <p>The corporate client initiates the inquiry by providing their contact information, including name, company name, email address, and phone number.</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Gift Card Details</b></h5>
                                                <p>The corporate client specifies the details of the gift cards they are interested in, such as the desired denomination, quantity, and any customization options required (e.g., branding, personalized messages).</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Delivery and Distribution</b></h5>
                                                <p>The client discusses the preferred delivery method for the gift cards, whether they need physical cards shipped to their location or electronic codes delivered via email. They may also outline specific distribution requirements if the gift cards are intended for multiple recipients within the organization.</p>
                                            </span>
                                        </li>
                                        <li>
                                            <span className='icon'><img src={Loreum} alt='Lorem ipsum' /></span>
                                            <span>
                                                <h5><b>Payment and Terms</b></h5>
                                                <p>The final step involves discussing payment options, billing procedures, and any applicable discounts or promotions for bulk purchases. The terms and conditions, as well as the timeline for the delivery of the gift cards, are also clarified during this stage to ensure a smooth corporate gifting experience.</p>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>

                            <div className='col col-md-6'>
                                <div className='shadow-box-rounded'>
                                    <div className="topheading">
                                        <h5>Please fill the below details</h5>
                                        <span>* Mandatory Fields</span>
                                    </div>

                                    <div className="form-group">
                                        <TextInput
                                            type={"text"}
                                            name={"companyName"}
                                            id={"companyName"}
                                            placeholder={"Enter Company Name"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Company Name*"}
                                            value={companyName}
                                            autoComplete="off"
                                            errorMSg={errors.errCompanyName}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextInput
                                        type={"text"}
                                        name={"authorisedPersonName"}
                                        id={"authorisedPersonName"}
                                        placeholder={"Enter Name"}
                                        inputClass={"form-control"}
                                        lblClass={"input-lable"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        label={"Authorised Person Name*"}
                                        value={authorisedPersonName}
                                        autoComplete="off"
                                        errorMSg={errors.errAuthorisedPersonName}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextInput
                                            type={"text"}
                                            name={"email"}
                                            id={"email"}
                                            placeholder={"Enter Your Email Id"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Email*"}
                                            value={email}
                                            autoComplete="off"
                                            errorMSg={errors.errEmail}
                                        />
                                    </div>

                                    <div className="form-group mobile select-city">
                                        <div className="cust-select">
                                            <Reactselect
                                                name={"stdcode"}
                                                id={"stdcode"}
                                                label={"Mobile Number*"}
                                                value={stdcode}
                                                options={countryCodeData}
                                                onChange={handleStateChange}
                                                class={"form-control form-select"}
                                                onBlur={handleError}
                                                errorMsg={errors.errMobileNo}
                                            />
                                            
                                            <TextInput
                                                type={"tel"}
                                                value={mobileNo}
                                                name={"mobileNo"}
                                                id={"mobileNo"}
                                                placeholder={"Enter Mobile Number"}
                                                inputClass={"form-control"}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                autoComplete="off"
                                                maxLength="10"
                                                // errorMSg={errors.errMobileNo}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="Remarks">Choose a style for Your Gift Card</label>
                                        <Slider {...settings}>
                                            {giftcarddata.map((item, index) => (
                                                <div id={index} key={index} className={`card-style ${activeCard === index ? 'active' : "" }`} onClick={() => handleChooseCard(index,item.name,item.imgsrc)}>
                                                    <div className='card'>
                                                        <img src={item.imgsrc} alt={item.name} title={item.name} />
                                                        <div className='card-body'>
                                                            <h6>{item.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="Remarks">Amount*</label>
                                        <ul className='amount'>
                                            {
                                                Amount.map((item, index)=>{
                                                return(
                                                    <li key={index}>
                                                        <input type='button'
                                                            key={index} 
                                                            id={index}
                                                            name={"amount"} 
                                                            onClick={() => handleSelectAmount(index,'amount', item.amount)} 
                                                            className={`btn btn-primary ${active === index ? 'active' : "" }`} 
                                                            value={`₹ ${item.amount}`}
                                                        />
                                                    </li>
                                                )
                                                })
                                            } 
                                            
                                            <TextInput
                                                type={"text"}
                                                name={"amount"}
                                                id={"amount"}
                                                placeholder={"Other Amount"}
                                                inputClass={`form-control ${active === "textamt" ? "active" : "" }`}
                                                onChange={handleStateChange}
                                                onBlur={handleError}
                                                value={amount}
                                                autoComplete="off"
                                                maxLength="6"
                                            />
                                            <div className="error-message">{errors.errAmount}</div>
                                        </ul>
                                    </div>

                                    <div className="form-group quantity">
                                        <TextInput
                                            type={"text"}
                                            name={"quantity"}
                                            id={"quantity"}
                                            placeholder={"Enter"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"Quantity*"}
                                            value={quantity}
                                            autoComplete="off"
                                            errorMSg={errors.errQuantity}
                                            maxLength="3"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextInput
                                            type={"text"}
                                            name={"gstnNumber"}
                                            id={"gstnNumber"}
                                            placeholder={"Enter GSTN Number"}
                                            inputClass={"form-control"}
                                            lblClass={"input-lable"}
                                            onChange={handleStateChange}
                                            onBlur={handleError}
                                            label={"GSTN Number"}
                                            value={gstnNumber}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="Remarks">Remarks</label>
                                        <textarea rows='4' id='Remarks' name='Remarks' placeholder='Type here' />
                                    </div>

                                    <div className="make-info">
                                        <button className="btn btn-primary" type="submit" onClick={handelFormSubmit}><span>Submit</span></button>
                                    </div> 
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
