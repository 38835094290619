import { combineReducers } from 'redux';
import counter from './countReducer';
import cart from './cartReducer'
import address from './addressReducer'
import vendor from './vendorReducer'
import loader from './loader';
// let historyR = !process.env.IS_SERVER ? { router: connectRouter(history) } : {};


export default combineReducers({
   count: counter,
   cart,
   address,
   vendor,
   loader,
})
