// import Features_link from '../features/features_link';
import {React} from 'react';
// import ring from '../../../assets/images/products/diamonds/diamond-rings.png'
import ring from '../../../assets/images/ring-prod-detail-11.jpg';
import {BiRupee} from "react-icons/bi";
import './index.css'


function Review() {

    const data = [
        { id:1, src:ring, alt: "Diamond Ring", name: "Diamond Ring", detail: "18 CT (3.400 g)", charges: "10% off on Making Charges", final_price: "30,999", original_price: "35,999"}
    ]

    const size = [
        {
            id: 1,
            label: "23 (62.8mm)",
            value: "23 (62.8mm)",
        },
        {
            id: 2,
            label: "25 (62.8mm)",
            value: "25 (62.8mm)",
        },
        {
            id: 3,
            label: "23 (62.8mm)",
            value: "23 (62.8mm)",
        },
        {
            id: 4,
            label: "25 (62.8mm)",
            value: "25 (62.8mm)",
        },
    ]; 

    const qty = [
        {
            id: 1,
            label: "1",
            value: "1",
        },
        {
            id: 2,
            label: "2",
            value: "2",
        },
        {
            id: 3,
            label: "3",
            value: "3",
        },
        {
            id: 4,
            label: "4",
            value: "4",
        },
    ]; 

    return (
        <>

            {
                data.map((item,i)=>{
                    return(
                        <ul className='mapped' key={i}>

                            <li>
                                <figure>
                                    <img src={ring} className="card-img-top" alt="Ear Rings" title="Ear Rings" />
                                </figure>
                            </li>
                            <li>
                                <p>{item.name}</p>
                                <p className="size">{item.detail}</p>
                                <div className='order-wrapper'>
                                    <div className='selectwrapper'>
                                        <div className='selectfield'>
                                            <span className="title">Size:</span>
                                            <select className="form-select" id={item.id}>
                                                {size.map((size,s) => (
                                                    <option key={s} value={size.value}>{size.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        
                                        <div className='selectfield'>
                                            <span className="title">Qty:</span>
                                            <select className="form-select" id={item.id}>
                                                {qty.map((qty,q) => (
                                                    <option key={q} value={qty.value}>{qty.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                        <div className="prize-details">
                                            <div className='prize'>
                                                <span className="multiply-icon"><i className="fa fa-times"></i></span>
                                                <BiRupee />{item.final_price}
                                                <s><small><BiRupee /> {item.original_price}</small></s>
                                            </div>
                                            <h5 className='offer'>{item.charges}</h5>
                                        </div>

                                        <div className="last-prize">
                                            <BiRupee />{item.final_price}
                                        </div>
                                </div>
                            </li>
                        </ul>
                    )
                })
            }

        </>
    );
}

export default Review;