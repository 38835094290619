import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { ImMobile } from 'react-icons/im';
import { HiMail } from 'react-icons/hi';
import { BsFillShareFill } from 'react-icons/bs';
import {FaFacebookSquare} from "react-icons/fa";
import {FaYoutube} from "react-icons/fa";
import {FaInstagram} from "react-icons/fa";
import {FaLinkedin} from "react-icons/fa";

const CustomerService = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wrapper customer-service'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Customer Service</h1>
                </div>
            </div>

            <div className="container">

                <section className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Customer Service</li>
                            </ol>
                        </nav>

                        <div className="container main">

                            <p>Experience personalized assistance and utmost care with our dedicated Customer Service, ensuring your satisfaction is our top priority at Summera Jewels.</p>

                            <div className='services'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <ImMobile size={35} className="icon" />
                                        <h6>Let's Talk</h6>
                                        <p>
                                            <Link to={'tel:917770007841'}>+91 77700 07841</Link>,<Link to={'tel:918291890911'}>+91 82918 90911</Link><Link to={'tel:02235080525'}>022-35080525</Link>
                                        </p>
                                    </div>
                                </div>

                                <div className='card'>
                                    <div className='card-body'>
                                        <HiMail size={35} className="icon" />
                                        <h6>E-mail Us</h6>
                                        <p>
                                            <Link to={'mailto:enquiry@summera.in'}>enquiry@summera.in</Link>
                                        </p>
                                    </div>
                                </div>

                                <div className='card'>
                                    <div className='card-body'>
                                        <BsFillShareFill size={35} className="icon" />
                                        <h6>Follow us on</h6>
                                        <p>
                                        <ul className="social-link">
                                            <li className="youtube"><Link to={'https://www.youtube.com/channel/UCXIGx7jmMwupGP5uQn7HMiQ/videos'} target="_blank" rel="noreferrer"><FaYoutube /></Link></li>
                                            <li className="insta"><Link to={'https://www.instagram.com/Summeraofficial'} target="_blank" rel="noreferrer"><FaInstagram /></Link></li>
                                            <li className="linkedin"><Link to={'https://www.linkedin.com/in/summera-e-commerce-platform-b65a34219/'} target="_blank" rel="noreferrer"><FaLinkedin /></Link></li>
                                            <li className="facebook"><Link to={'https://www.facebook.com/Summeraofficial'} target="_blank" rel="noreferrer"><FaFacebookSquare /></Link></li>
                                        </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}


export default CustomerService;