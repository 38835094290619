import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
// import Wishlist from '../cards/wishlist';
// import { Tooltip } from '@nextui-org/react';
import { Link } from 'react-router-dom';
// import { Tooltip } from "reactstrap";
// import EmptyImage from '../../../assets/images/myaccount/wishlist.svg';
// import Close from '../../../assets/images/myaccount/close.svg';
// import Empty from '../../atom/empty';
import { CgClose } from 'react-icons/cg';
import { BiRupee } from 'react-icons/bi';
import silver1 from '../../../assets/images/products/silver/silver.png';
import './mywishlist.css'

function MyWishlist() {
    // const router = useRouter()

    // const { wishlist } = useSelector((state) => state.cart.wishlist)
    // const { wishlist } = useSelector((state) => state.cart);

    // console.log("wishlist data ", wishlist)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

      const shopping = [
        { id:1, imgsrc: silver1, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"}
      ];

      const handleClick = event => {
        // 👇️ toggle class on click
        event.currentTarget.classList.toggle('active');
    };

    //   const removeElement = (index) => {
    //     const newShopping = shopping.filter((_, i) => i !== index);
    //     setShopping(newShopping);
    // };

    return (
        <>
        <h5>My Wishlist</h5>   
        {
            <div className="my_wishlist myaccWislist">  
              
                {/* <div className="mainCards"> */}
                    {/* <Wishlist Content={wishlist} path={router.route} /> */}
                    {/* <Wishlist Content={wishlist} /> */}
                {/* </div> */}

                <div className='product-wrapper'>

                    {
                        shopping.map((item, index) => {
                        
                            return (
                                <div className='card' key={index}>
                                    <div className='card-header'>
                                        <Link to={'/productdetails'}>
                                            <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                            <h1>{item.index}</h1>
                                        </Link>
                                    </div>
                                    <div className='product-label'>
                                        <ul>
                                            <li className=
                                                {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                            </li>
                                        </ul>
                                        <span className='wishlist-link tooltip-label' data-tooltip="Remove">
                                            <CgClose onClick={handleClick} />
                                        </span>
                                    </div>
                                    <div className='card-body'>
                                        <p>{item.pname}</p>
                                        <div className='price'><span><BiRupee />{item.price}</span>
                                            {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                        </div>
                                        { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                    </div>
                                </div>
                            )

                        }
                        )
                    }

                        <div id='coins'></div>
                </div>

            {/* :  */}
             {/* <Empty imgsrc={EmptyImage} content={"Your Wishlist is Empty!"} /> */}
            </div>
        }
     </>

    );
}

export default MyWishlist;