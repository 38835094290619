import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonial from './Testimonial'
import { Link } from "react-router-dom";
import client from '../../assets/images/client.jpg'


import collImg from '../../assets/images/collection-1.jpg'
import collImg2 from '../../assets/images/collection-2.jpg'
import collImg3 from '../../assets/images/collection-3.jpg'
import collImg4 from '../../assets/images/collection-1.jpg'
import collImg5 from '../../assets/images/collection-3.jpg'
import './index.css'

export const TestimonialSlider = () => {
    const settings = {
        dots: true,
        arrows:false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return(
       
        <Slider {...settings}>
            <Testimonial
                content="Thank you so much for sending such joy my way. She was impressed from the moment she
                opened her package. It was wrapped to look like Million Dollars . The packaging is impeccable and the
                ring itself is awesome."
                imgsrc={client}
                title="Ms. Preeti"
                location="Gurugram, Haryana"
                    
            />
            <Testimonial
                content="She was impressed from the moment she opened her package. It was wrapped to look like
                Million Dollars . The packaging is impeccable and the ring itself is awesome."
                imgsrc={client}
                title="Ms. Preeti"
                location="Gurugram, Haryana"
                    
            />
            <Testimonial
                content="She was impressed from the moment she opened her package. It was wrapped to look like
                Million Dollars . The packaging is impeccable and the ring itself is awesome."
                imgsrc={client}
                title="Ms. Preeti"
                location="Gurugram, Haryana"
                    
            />
            <Testimonial
                content="Thank you so much for sending such joy my way. She was impressed from the moment she
                opened her package. It was wrapped to look like Million Dollars . The packaging is impeccable and the
                ring itself is awesome."
                imgsrc={client}
                title="Ms. Preeti"
                location="Gurugram, Haryana"
                    
            />
 
                    
        </Slider>
    )
}