import React, { useState, useEffect, useRef, useCallback } from 'react';
import Input from '../input/input';
import { RiMapPin2Line } from 'react-icons/ri';
import LocationIcon from '../../../assets/images/icons/location-icon.png';
import './DeliveryLocation.css';
import Popup from 'reactjs-popup';
import TextInput from '../../molecules/input'
import { GrFormClose } from 'react-icons/gr';
import { useLocation } from 'react-router-dom';

const DeliveryLocation = ({ isErrorPage }) => {
  
  const [open, setOpen] = useState(false);
  const [pincode, setPincode] = useState("");
  const location = useLocation()

  const url = location.pathname;

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }
  
  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }
  
  const handleOnSelect = (item) => {
    // the item selected
    console.log(item)
  }
  const handleStateChange = (name, value) => {
    setPincode(value)
  }
  
  const handleError = (name, value) => {
    // console.log("object")
  }
  
  const handleCheckPinCode = (e) => {
    e.preventDefault();
    console.log("handleCheckPinCode", pincode)
  }
  
  const handleRedirect = (close) => {
    close();
}
  
  const handleOnFocus = () => {
   // document.body.classList.add('overlay');
    // console.log('Focused')
  }
  
  const PopupExample = () => (
    <Popup trigger={<span>{`${pincode ? pincode : "Enter Pincode"}`}</span>} modal aria-describedby={"pinHome"}>
      {close => (
        <div className='pop-body'>
        <a className="close" onClick={close}>
          <span><GrFormClose className="closebtn" /></span>
        </a>
          <img src={LocationIcon} className="iconLocation" />
          <h2>Delivery Location</h2>
          <form className="d-flex" role="search">
            <div className="input-group">
          
            <TextInput
            type={"text"}
            name={"pincode"}
            placeholder={"Enter Pincode"}
            inputClass={"form-control"}
            onChange={handleStateChange}
            onBlur={handleError}
            value={pincode}
            autoComplete="off"
          />
              <button className='btn btn-primary' onClick={() =>handleRedirect(close)} /*onClick={(e) => { handleCheckPinCode(e), close() }}*/>Apply</button>
            </div>
          </form>
        </div>
      )}
    </Popup>
  );
  
  return (
    <div className="location">
        <span className='loc-text'>Delivery Location</span>
        <div className="input-group">
            <div className="input-group-prepend">
            <span className="input-group-text"><RiMapPin2Line /></span>
                {PopupExample()}
            </div>
            {/*<Input inputClass="form-control" type='text' placeholder={'Enter Pincode'}/>*/}
        </div>   
   </div>
  )
};
export default DeliveryLocation;