import {React} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import ringimg from '../../assets/images/ring1.png'
import { BiRupee } from 'react-icons/bi';
import {BiTrash} from "react-icons/bi";
import {  viewSimilarProductAction } from '../../redux/actions/cartAction';
import { IoClose } from "react-icons/io5";
import './index.css'
import Review from '../../components/molecules/ReviewCart/index';
import ProducCard from '../Card';

export default function SimilarProduct({similarActive }) {

    const size = [
        {
            label: "23 (62.8mm)",
            value: "23 (62.8mm)",
        },
        {
            label: "25 (62.8mm)",
            value: "25 (62.8mm)",
        },
        {
            label: "23 (62.8mm)",
            value: "23 (62.8mm)",
        },
        {
            label: "25 (62.8mm)",
            value: "25 (62.8mm)",
        },
    ]; 
    
    const qty = [
        {
            label: "1",
            value: "1",
        },
        {
            label: "2",
            value: "2",
        },
        {
            label: "3",
            value: "3",
        },
        {
            label: "4",
            value: "4",
        },
    ]; 

    const dispatch = useDispatch();

  return (
    <>
    <div className={ similarActive ? 'similar-product similar-show' : 'similar-product'}>
        <div className="topheader">
            <h2>Similar Products</h2>
            <span onClick={() => dispatch(viewSimilarProductAction())} className="btn-close btn-cart"><IoClose /></span>
        </div>
        <div className="cart-body">
            
        <ProducCard />
            
        </div>
    </div>
    <div className={ similarActive ? 'bg-overlay show' : 'bg-overlay'}></div>
    </>
  )
}
