import { Link } from "react-router-dom";
// import Product from '../components/Pages/product';
import './empty.css';

const Empty = (props) => {
    return(
        <div className='empty'>
            <img src={props.imgsrc} alt="Empty Wishlist" title="Empty Wishlist" />
            <p>{props.content}</p>
            <Link to={'/products'} className='btn btn-primary'>Shop Now</Link>
        </div>
    )
}
export default Empty;