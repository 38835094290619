import React, {useState} from "react";
import Reactselect from "../../molecules/select";

const SortBy = () => {

    const [active, setActive] = useState(0)

    const sortTypeData =[
        {id:1, value:'Most Popular', label:'Most Popular'},
        {id:2, value:'New', label:'New'},
        {id:3, value:'Trending', label:'Trending'},
        {id:4, value:'Price Low to High', label:'Price Low to High'},
        {id:5, value:'Price High to Low', label:'Price High to Low'}
    ]

    const [sortType, setSortType] = useState("Most Popular");

    const [errors, setErrors] = useState({
        errSortType: "",
    })

    const handleStateChange = (name, value) => {
        // if (name === "sortType") {
            // setSortType(value)
            // handleClick(name, value)
        // }
    }

    const handleError = (name, value) => {

        let allState = errors;

        if (name === "sortType") {
            if (!value.length) {
                allState["errSortType"] = "Select Sort Type."
            } else {
                allState["errSortType"] = ""
            }
        }
    }

    return (
        <div className="sort-option">
            <div>Sort By:</div>
            <ul>
                {
                    sortTypeData.map((item, index)=>{
                    return(
                        <li key={index}>
                            <span id={index} key={index} onClick={() => setActive(index)} className={`${active === index && 'active'}`}>{item.value}</span>
                        </li>
                    )
                    })
                }
            </ul>
            <Reactselect
                name={"sortType"}
                id={"sortType"}
                label={"Sort Type"}
                options={sortTypeData}
                value={sortType}
                lblClass={"input-lable"}
                onChange={handleStateChange}
                onBlur={handleError}
                errorMsg={errors.errSortType}
                class={"form-select"}
            />
        </div>
    );
};

export default SortBy;
