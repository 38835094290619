import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';
import Handshake from '../../assets/images/icons/handshake.png';

const VideoCallThankYou = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wrapper videocall-thank-you'>
            <div className="container">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">Thank You</li>
                    </ol>
                </nav>

                <div className='login-box'>
                    <div className="login-wrapper text-center">
                        <img src={Handshake} alt='Thank you' title='Thank you'/>
                        <h2>THANK YOU</h2>
                        <p className='desc'>Your request has been sent successfully.</p>
                        <p className='desc1'>Someone will be in connect with you shortly.</p>

                        <Link className="btn btn-primary" to={'/'}><span>Back To Home</span></Link>
                    </div>  
                </div>  
            </div>
        </div>
    )
}


export default VideoCallThankYou;