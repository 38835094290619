import React, { useState,useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment/moment";
// import DatePicker from 'react-datepicker';
import ProgressBar from 'react-bootstrap/ProgressBar';
// import Input from "../../atom/input/input";
// import { AiFillCloseCircle, AiOutlineCheck } from "react-icons/ai";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, } from 'reactstrap';
// import { FaTags } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
// import { IoInformationCircle } from "react-icons/io5";
import "react-datepicker/dist/react-datepicker.css";
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import Modal from 'react-bootstrap/Modal';
import Reactselect from '../../../components/molecules/select';
import MyAccData from '../myAccount/myAccData';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Return from "./formsitems/returnform";
import CancelItem from "./formsitems/cancelItemForm";
import RequestSuccess from "./formsitems/requestsuccess";
import Exchange from "../myAccount/formsitems/exchangeform";
import CancelOrder from "./formsitems/cancelOrderForm";
import ItemTable from "../../../components/atom/myaccountitems/itemsTable";
import StarRating from "../../../components/atom/starRating";
import PrintableInvoice from './invoice';
import { useReactToPrint } from 'react-to-print';
// import Empty from "../../atom/empty";

// import images 
import earringImg from '../../../assets/images/myaccount/earringImg.jpg';
import OrderPlaced from '../../../assets/images/icons/order-placed.svg';
import OrderInProgress from '../../../assets/images/icons/order-in-progress.svg';
import ReadyToShip from '../../../assets/images/icons/ready-to-ship.svg';
import Delivered from '../../../assets/images/icons/delivered.svg';
import status from '../../../assets/images/myaccount/icons/statusinfonew.svg';
import goldRing from '../../../assets/images/gold-ring.jpg';
import Close from "../../../assets/images/icons/close.svg";
import SubmitReview from "../../../assets/images/icons/submit-review.svg";
import pdf from "../../../assets/images/icons/pdf.svg";

const MyOrderSec = () => {
    const { shippingData, grandTotalAfterDis } = useSelector((state) => state.address);
    const [ToggleState, setToggleState] = useState(1);
    const [openCancelModal, setCancelModal] = useState(false);
    const [openModal, setOpenModal] = useState("")
    const [orderDetails, setOrderDetails] = useState("null");
    const [overlay, setOverlay] = useState(false);
    const [message, setMessage] = useState({});

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const handleOrderDetails = (value) => {
        setCancelModal(true)
        setOpenModal(value)
    }

    const handlingBack = () => {
        setCancelModal(false)
        setOpenModal("")
    }

    const handleOverlay = () => {
        setOverlay(true);
      };
    
      const handleClose = () => {
        setOverlay(false);
      };

      const ShowMessage = (value) => {
        setOpenModal(value?.requestModal);
        setMessage({
          name: value?.name,
          msg: value?.msg,
        });
      };

    return (
        <>
            {/* <TrackOrder shippingData={shippingData} grandTotalAfterDis={grandTotalAfterDis} /> */}

            {/* <InvoiceOrder shippingData={shippingData} /> */}

            {/* <Exchangeform /> */}

            {/* <Returnform /> */}

            {/* <Requestsuccess/> */}

            {/* <CancelItemForm /> */}

            {/* <CancelOrderForm />  */}
            {

                !openCancelModal ?
                    <div className="myOrderTab">
                        <h5>My Orders</h5>
                        <ul className="tab-list">
                            <li className={ToggleState === 1 ? "active-tabs" : "tabs"}
                                onClick={() => toggleTab(1)}>Orders</li>
                            <li className={ToggleState === 2 ? "active-tabs" : "tabs"}
                                onClick={() => toggleTab(2)}>Cancelled Orders</li>
                        </ul>
                        <div className="content-container">
                            <div className={ToggleState === 1 ? "active-content" : "content"}>
                                {/* <Empty imgsrc={orderempty} content={"You have not placed an order with us yet"} /> */}
                                <FilterMonth />
                                <AccordionSec shippingData={shippingData} handleOrderDetails={handleOrderDetails} />
                            </div>
                            <div className={ToggleState === 2 ? "active-content" : "content"}>
                                {/* <Empty imgsrc={orderempty} content={"You have not placed an order with us yet"} /> */}
                                <FilterMonth />
                                <AccordionSec shippingData={shippingData} handleOrderDetails={handleOrderDetails} />
                            </div>
                        </div>
                    </div> : <ModalShow handleOrderDetails={handleOrderDetails} shippingData={shippingData} grandTotalAfterDis={grandTotalAfterDis} openCancelModal={openCancelModal} openModal={openModal} handlingBack={handlingBack} />
            }
        </>
    );
};

export default MyOrderSec;

// Last month drop down section
export const FilterMonth = () => {
    const sortData = [
      { id: 1, label: "Past 3 Month", value: "past3month" },
      { id: 2, label: "Past 6 Month", value: "past6month" },
      { id: 3, label: "Past 9 Month", value: "past9month" },
      { id: 4, label: "Past 12 Month", value: "past12month" },
    ];
  
    const [selectMonth, setselectMonth] = useState(sortData[3].value);
  
    const handleStateChange = (name, value) => {
      setselectMonth(value);
    };
    const handleError = () => {};
  
    return (
      <div className="filterMonth">
            <label>Showing <span>3 orders</span> in</label>
        <Reactselect
          name={"selectMonth"}
          id={"selectMonth"}
                // label={"Showing <span>3 orders</span> in"}
          options={sortData}
          value={selectMonth}
          lblClass={"input-lable"}
          onChange={handleStateChange}
          onBlur={handleError}
          class={"form-select"}
        />
      </div>
    );
  };
  
  export const AccordionSec = ({ shippingData, handleOrderDetails }) => {
    const [open, setOpen] = useState();
    const [open1, setOpen1] = useState();
    const [productreview, setproductreview] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [tooltipCon, setTooltipCon] = useState(false);
    const [arrowDown, setArrowDown] = useState(false);
    // ToolTip Content
    const handleTooltipStar = (value) => {
      setTooltipCon(value);
      setArrowDown(!arrowDown);
    };
  
    // Modal Function
    const handleModalOpen = () => {
      setModalOpen(!modalOpen);
    };
    // accordion function
    const toggle = (i) => {
      if (open === i) {
        setOpen();
      } else {
        setOpen(i);
      }
    };
    const toggle1 = (i) => {
      if (open1 === i) {
        setOpen1();
      } else {
        setOpen1(i);
      }
    };
    const productreviewtoggle = () => {
      setproductreview(!productreview);
    };

    const Data = [
        { id: 1, cover_image:goldRing, itemnamename: "Gold Ear Ring Lorem Ipsum - SJERN23568", itemdetail: "18 KT Yellow Gold (3.400 g)", afterDiscountAmu: "99,999",orderStatus: "In Progress", date: "Fri, 10 Jan 2023"},
        { id: 3, cover_image:goldRing, itemnamename: "Gold Ear Ring Lorem Ipsum - SJERN23568", itemdetail: "18 KT Yellow Gold (3.400 g)", afterDiscountAmu: "99,999",orderStatus: "Replacement requested", date: "Fri, 10 Jan 2023"},
        { id: 3, cover_image:goldRing, itemnamename: "Gold Ear Ring Lorem Ipsum - SJERN23568", itemdetail: "18 KT Yellow Gold (3.400 g)", afterDiscountAmu: "99,999",orderStatus: "Return requested", date: "Fri, 10 Jan 2023"},
        { id: 3, cover_image:goldRing, itemnamename: "Gold Ear Ring Lorem Ipsum - SJERN23568", itemdetail: "18 KT Yellow Gold (3.400 g)", afterDiscountAmu: "99,999",orderStatus: "Delivered", date: "Fri, 10 Jan 2023"}
    ];
  
    return (
      <>
        {MyAccData.map((items, index) => {
          return (
            <div className="myacc_accordion">
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId={index}>
                    <div className="accorHeading table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Order Placed</th>
                            <th>Total Amount</th>
                            <th>Total Items</th>
                            <th>Mode</th>
                            <th colSpan={5}>
                              Order No.: <b>{items.order_no}</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span>{items.order_placed}</span>
                            </td>
                            <td>
                              <span>₹ {items.total_amount}</span>
                            </td>
                            <td>
                              <span>{items.total_items}</span>
                            </td>
                            <td>
                              <span>
                                {items.mode}
                                <Tooltip>
                                  <img src={status} alt="Status" />
                                </Tooltip>
                              </span>
                            </td>
                            <td>
                              {items.mode === "Delivery" ? (
                                <ul>
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleOrderDetails("OrderDetails")
                                      }
                                    >
                                      Order Details
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleOrderDetails("InvoiceOrder")
                                      }
                                    >
                                      Invoice
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleOrderDetails("CancelOrder")
                                      }
                                    >
                                      Cancel Order
                                    </button>
                                  </li>
                                </ul>
                              ) : (
                                <ul>
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleOrderDetails("OrderDetails")
                                      }
                                    >
                                      Order Details
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleOrderDetails("InvoiceOrder")
                                      }
                                    >
                                      Invoice
                                    </button>
                                  </li>
                                </ul>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </AccordionHeader>
  
                  <AccordionBody accordionId={index}>
                    <div className="tableContent table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th colSpan={10}>Item(s)</th>
                            <th>Qty.</th>
                            <th>Amount</th>
                            <th>Order Status</th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                            <>
                                {
                                    Data.map((data, inx) => {
                                        return <tr key={inx}>
                                            <td colSpan={10}>
                                                <ul>
                                                    <li><img src={data.cover_image} alt={data.itemnamename} title={data.itemnamename} /></li>
                                                    <li>
                                                        <span className="name">{data.itemnamename}</span>
                                                        <span className="detail">{data.itemdetail}</span>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td data-label="Qty."><span>1</span></td>
                                            <td data-label="Amount"><span><BiRupee />{data.afterDiscountAmu}</span></td>
                                            <td data-label="Order Status">
                                                <p className={data.orderStatus === "In Progress" ? "modeInProgress" : data.orderStatus === "Replacement requested" ? "modeReplacementOrder" : data.orderStatus === "Return requested" ? "modeReturnOrder" : "modeDelivered"}>{data.orderStatus}</p>
                                                <span>On {data.date}</span>
                                            </td>
                                            <td>
                                                {data.orderStatus === "In Progress" ? (
                                                    <>
                                                        <button type="button" onClick={() => handleOrderDetails("TrackOrder")}><span>Track Order</span></button>
                                                        <button type="button" onClick={() => handleOrderDetails("CancelItem")}><span>Cancel Item</span></button>
                                                    </>
                                                ) : data.orderStatus === "Delivered" ? (
                                                    <>
                                                        <button type="button" onClick={() => handleOrderDetails("Exchange")}><span>Exchange</span></button>
                                                        <button type="button" onClick={() => handleOrderDetails("Return")}><span>Return</span></button>
                                                        <button type="button" onClick={() => handleTooltipStar(6)}><span className={arrowDown ? "arrowdown" : null}>Write a Product Review</span>{tooltipCon === 6 && <TooltipContent />}</button>
                                                    </>
                                                ) : data.orderStatus === "Return requested" ? (
                                                    <>
                                                        <button type="button" onClick={() => handleOrderDetails("Exchange")}><span>Exchange</span></button>
                                                        <button type="button" onClick={() => handleTooltipStar(5)}><span className={arrowDown ? "arrowdown" : null}>Write a Product Review</span>{tooltipCon === 5 && <TooltipContent />}</button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button type="button" onClick={() => handleOrderDetails("Exchange")}><span>Exchange</span></button>
                                                        <button type="button" onClick={() => handleOrderDetails("Return")}><span>Return</span></button>
                                                        <button type="button" onClick={() => handleTooltipStar(4)}><span className={arrowDown ? "arrowdown" : null}>Write a Product Review</span>{tooltipCon === 4 && <TooltipContent />}</button>
                                                    </>
                                                )}
                                                
                                            </td>
                                        </tr>
                                    })
                                }
                            </>
                        </tbody>
                      </table>
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          );
        })}
  
        {/* ++++++++++ Demo Accordion Replacement and return demo mode status++++++++ */}
        {/* <div className="myacc_accordion replacementWrapper returnWrapper">
          <Accordion open={open1} toggle={() => toggle1(2)}>
            <AccordionItem>
              <AccordionHeader targetId={2}>
                <div className="accorHeading table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Order Placed</th>
                        <th>Total Amount</th>
                        <th>Total Items</th>
                        <th>Mode</th>
                        <th colSpan={5}>
                          Order No.: <b>BJFE2045653216</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>02 Jan 2023</span>
                        </td>
                        <td>
                          <span>₹ 45500</span>
                        </td>
                        <td>
                          <span>4</span>
                        </td>
                        <td>
                          <span>
                            Delivery
                            <Tooltip>
                            </Tooltip>
                          </span>
                        </td>
                        <td>
                          <ul>
                            <li>
                              <button
                                onClick={() => handleOrderDetails("OrderDetails")}
                              >
                                Order Details
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => handleOrderDetails("InvoiceOrder")}
                              >
                                Invoice
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleOrderDetails("CancelOrder")}
                              >
                                Cancel Order
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionHeader>
  
              <AccordionBody accordionId={2}>
                <div className="tableContent table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={10}>Item(s)</th>
                        <th>Qty.</th>
                        <th>Amount</th>
                        <th>Order Status</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={10}>
                          <ul>
                            <li>
                              <img src={goldRing} />
                            </li>
                            <li>
                              <span className="tags">Gold Ear Ring Lorem Ipsum - SJERN23568</span>
                              <p>18 KT Yellow Gold (3.400 g) </p>
                            </li>
                          </ul>
                        </td>
                        <td data-label="Qty.">
                          <span>1</span>
                        </td>
                        <td data-label="Amount">
                          <span>₹ 9,535</span>
                        </td>
                        <td data-label="Order Status">
                          <p className="modeReplacementOrder">
                            Replacement requested{" "}
                          </p>
                          <span>On Fri, 10 Jan 2023</span>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("Exchange")}
                          >
                            <span>Exchange</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("Return")}
                          >
                            <span>Return</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => handleTooltipStar(4)}
                          >
                            <span className={arrowDown ? "arrowdown" : null}>
                              Write a Product Review
                            </span>
                            {tooltipCon === 4 && <TooltipContent />}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={10}>
                          <ul>
                            <li>
                              <img src={earringImg} />
                            </li>
                            <li>
                              <span className="tags">Gold Ear Ring Lorem Ipsum - SJERN23568</span>
                              <p>Kotpad Cotton Yellow - Black Handloom Saree </p>
                            </li>
                          </ul>
                        </td>
                        <td data-label="Qty.">
                          <span>1</span>
                        </td>
                        <td data-label="Amount">
                          <span>₹ 9,535</span>
                        </td>
                        <td data-label="Order Status">
                          <p className="modeReturnOrder">Return requested </p>
                          <span>On Fri, 10 Jan 2023</span>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("Exchange")}
                          >
                            <span>Exchange</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => handleTooltipStar(5)}
                          >
                            <span className={arrowDown ? "arrowdown" : null}>
                              Write a Product Review
                            </span>
                            {tooltipCon === 5 && <TooltipContent />}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={10}>
                          <ul>
                            <li>
                              <img src={earringImg} />
                            </li>
                            <li>
                              <span className="tags">Gold Ear Ring Lorem Ipsum - SJERN23568</span>
                              <p>
                                18 KT Yellow Gold (3.400 g)
                              </p>
                            </li>
                          </ul>
                        </td>
                        <td data-label="Qty.">
                          <span>1</span>
                        </td>
                        <td data-label="Amount">
                          <span>₹ 9,535</span>
                        </td>
                        <td data-label="Order Status">
                          <p className="modeDelivered">Delivered</p>
                          <span>On Fri, 10 Jan 2023</span>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("Exchange")}
                          >
                            <span>Exchange</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("Return")}
                          >
                            <span>Return</span>
                          </button>
                          <button
                            type="button"
                            onClick={() => handleTooltipStar(6)}
                          >
                            <span className={arrowDown ? "arrowdown" : null}>
                              Write a Product Review
                            </span>
                            {tooltipCon === 6 && <TooltipContent />}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div> */}
      </>
    );
  };

  export const AccordionCancleSec = ({ shippingData, handleOrderDetails }) => {
    const [open, setOpen] = useState("1");
    const [productreview, setproductreview] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [tooltipCon, setTooltipCon] = useState(false);
  
    // ToolTip Content
    const handleTooltipStar = () => {
      setTooltipCon((current) => !current);
    };
  
    // Modal Function
    const handleModalOpen = () => {
      setModalOpen(!modalOpen);
    };
    // accordion function
    const toggle = (i) => {
      if (open === i) {
        setOpen();
      } else {
        setOpen(i);
      }
    };
  
    const productreviewtoggle = () => {
      setproductreview(!productreview);
    };
  
    return (
      <>
        {/* +++++++++ demo Accordion cancel order status mode +++++++ */}
        {/* <div className="myacc_accordion">
          <Accordion open={open} toggle={() => toggle(1)}>
            <AccordionItem>
              <AccordionHeader targetId={1}>
                <div className="accorHeading table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Order Placed</th>
                        <th>Total Amount</th>
                        <th>Total Items</th>
                        <th>Mode</th>
                        <th colSpan={5}>
                          Order No.: <b>BJFE2045653216</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>02 Jan 2023</span>
                        </td>
                        <td>
                          <span>₹ 45500</span>
                        </td>
                        <td>
                          <span>4</span>
                        </td>
                        <td>
                          <span>
                            Delivery
                            <Tooltip>
                              <img src={status} alt="Status" title="Status" />
                            </Tooltip>
                          </span>
                        </td>
                        <td>
                          <ul>
                            <li>
                              <button
                                onClick={() => handleOrderDetails("OrderDetails")}
                              >
                                Order Details
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionHeader>
  
              <AccordionBody accordionId={1}>
                <div className="tableContent table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={10}>Item(s)</th>
                        <th>Qty.</th>
                        <th>Amount</th>
                        <th>Order Status</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={10}>
                          <ul>
                            <li>
                              <img src={goldRing} />
                            </li>
                            <li>
                              <span className="tags">Gold Ear Ring Lorem Ipsum - SJERN23568</span>
                              <p>18 KT Yellow Gold (3.400 g) </p>
                            </li>
                          </ul>
                        </td>
                        <td data-label="Qty.">
                          <span>1</span>
                        </td>
                        <td data-label="Amount">
                          <span>₹ 9,535</span>
                        </td>
                        <td data-label="Order Status">
                          <p className="modeCancelorder">Cancel Order Request</p>
                          <span className="reason">Reason</span>
                          <br />
                          <span className="reason-info">
                            Placed Duplicate Order
                          </span>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("TrackOrder")}
                          >
                            <span>Track Order</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colSpan={10}>
                          <ul>
                            <li>
                              <img src={earringImg} />
                            </li>
                            <li>
                              <span className="tags">Gold Ear Ring Lorem Ipsum - SJERN23568</span>
                              <p>Kotpad Cotton Yellow - Black Handloom Saree</p>
                            </li>
                          </ul>
                        </td>
                        <td data-label="Qty.">
                          <span>1</span>
                        </td>
                        <td data-label="Amount">
                          <span>₹ 9,535</span>
                        </td>
                        <td data-label="Order Status">
                          <p className="modeCancelorder">Cancel Order Request</p>
                          <span className="reason">Reason</span>
                          <br />
                          <span className="reason-info">
                            Placed Duplicate Order
                          </span>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("TrackOrder")}
                          >
                            <span>Track Order</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colSpan={10}>
                          <ul>
                            <li>
                              <img src={goldRing} />
                            </li>
                            <li>
                              <span className="tags">Gold Ear Ring Lorem Ipsum - SJERN23568</span>
                              <p>
                                18 KT Yellow Gold (3.400 g)
                              </p>
                            </li>
                          </ul>
                        </td>
                        <td data-label="Qty.">
                          <span>1</span>
                        </td>
                        <td data-label="Amount">
                          <span>₹ 9,535</span>
                        </td>
                        <td data-label="Order Status">
                          <p className="modeCancelorder">Cancel Order Request</p>
                          <span className="reason">Reason</span>
                          <br />
                          <span className="reason-info">
                            Placed Duplicate Order
                          </span>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleOrderDetails("TrackOrder")}
                          >
                            <span>Track Order</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div> */}
      </>
    );
  };

//Order details compounent
export const OrderDetails = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
  
    // Modal Function
    const handleModalOpen = () => {
      setModalOpen(!modalOpen);
    };

    const OpenOrderData = [
        { id: 1, src:goldRing, itemnamename: "Gold Ear Ring Lorem Ipsum", itemdetail: "18 KT Yellow Gold (3.400 g)", afterDiscountAmu: "99,999", qty: 1, amount: "4,600", discount: 500, gst: 205, total: "4,305", orderstatus: "In Progress"},
        { id: 1, src:goldRing, itemnamename: "Gold Ear Ring Lorem Ipsum", itemdetail: "18 KT Yellow Gold (3.400 g)", afterDiscountAmu: "99,999", qty: 1, amount: "4,600", discount: 500, gst: 205, total: "4,305", orderstatus: "In Progress"},
        { id: 1, src:goldRing, itemnamename: "Gold Ear Ring Lorem Ipsum", itemdetail: "18 KT Yellow Gold (3.400 g)", afterDiscountAmu: "99,999", qty: 1, amount: "4,600", discount: 500, gst: 205, total: "4,305", orderstatus: "In Progress"}
    ];

    return (
      <div className="orderDetailSec">
        <div className="order_heading">
          <h5>Order Details</h5>
          <button
            type="button"
            onClick={props.handlingBack}
            className="backBtnToTab btn btn-secondary"
          >
            Back
          </button>
        </div>
  
        <div className="orderlabel table-responsive">
          <table>
            <thead>
              <tr>
                <th>Order Placed</th>
                <th>Total Amount</th>
                <th>Total Items</th>
                <th>Mode</th>
                <th colSpan={5}>
                  Order No.: <b>BJFE2045653218</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>02 Jan 2023</span>
                </td>
                <td>
                  <span>₹ 15,400</span>
                </td>
                <td>
                  <span>03</span>
                </td>
                <td>
                    <span>
                        Delivery
                        <Tooltip>
                            <img src={status} alt="Status" />
                        </Tooltip>
                    </span>
                </td>
                <td>
                  <ul>
                    <li>
                      <button
                        onClick={() => props.handleOrderDetails("InvoiceOrder")}
                      >
                        Invoice
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => props.handleOrderDetails("CancelOrder")}
                      >
                        Cancel Order
                      </button>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div className="addresSec">
            <div className="shippingAdd">
                <caption>
                    <h6>Pickup from Summera Jewels Shop</h6>
                    <p>Shop No. 10, Rizvi Mahal, R K Patkar Road - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.</p>
                </caption>
            </div>
            <div className="orderSummary">
                <div className="subSection">
                    <h6>Order Summary</h6>
                    <ul>
                        <li><h6>Sub Total</h6><span><BiRupee />20,000</span></li>
                        <li><span>Discount</span><span><BiRupee />15,000</span></li>
                        <li><h6>Apply Promo</h6> <span>-</span></li>
                        <li><span>Delivery Charges</span> <span>-</span></li>
                        <li><span>GST</span> <span><BiRupee />925</span></li>
                        <li><h6>Total Amount</h6> <h6><BiRupee />19,425</h6></li>
                    </ul>
                </div>
            </div>
        </div>
  
        <div className="tableContent table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th colSpan={15}>Item(s)</th>
                            <th>Qty.</th>
                            <th>Amount</th>
                            <th>Discount</th>
                            <th>GST</th>
                            <th>Total</th>
                            <th>Order Status</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {
                                OpenOrderData.map((data, inx) => {
                                    return <tr key={inx}>
                                        <td colSpan={15}>
                                            <ul>
                                                <li><img src={data.src} alt={data.itemnamename} title={data.itemnamename} /></li>
                                                <li>
                                                    <h6>{data.itemnamename}</h6>
                                                    <p>{data.itemdetail}</p>
                                                    <Link to={'/product'} className="btn btn-secondary">Buy it again</Link>
                                                </li>
                                            </ul>
                                        </td>
                                        <td><span>{data.qty}</span></td>
                                        <td><span><BiRupee />{data.amount}</span></td>
                                        <td><span><BiRupee />{data.discount}</span></td>
                                        <td><span><BiRupee />{data.gst}</span></td>
                                        <td><span><BiRupee />{data.total}</span></td>
                                        <td><p className={data.orderstatus === "In Progress" ? "modeInProgress" : data.orderstatus === "Replacement requested" ? "modeReplacementOrder" : data.orderstatus === "Return requested" ? "modeReturnOrder" : "modeDelivered"}>{data.orderstatus}</p></td>
                                        <td>
                                            {data.orderstatus === "In Progress" ? (
                                                <>
                                                    <button type="button" onClick={() => props.handleOrderDetails("TrackOrder")}><span>Track Order</span></button>
                                                    <button type="button" onClick={() => props.handleOrderDetails("CancelItem")}><span>Cancel Item</span></button>
                                                </>
                                            ) : data.orderstatus === "Delivered" ? (
                                                <>
                                                    <button type="button" onClick={() => props.handleOrderDetails("Exchange")}><span>Exchange</span></button>
                                                    <button type="button" onClick={() => props.handleOrderDetails("Return")}><span>Return</span></button>
                                                    {/* <button type="button" onClick={() => props.handleTooltipStar(6)}><span className={arrowDown ? "arrowdown" : null}>Write a Product Review</span>{tooltipCon === 6 && <TooltipContent />}</button> */}
                                                </>
                                            ) : data.orderstatus === "Return requested" ? (
                                                <>
                                                    <button type="button" onClick={() => props.handleOrderDetails("Exchange")}><span>Exchange</span></button>
                                                    {/* <button type="button" onClick={() => props.handleTooltipStar(5)}><span className={arrowDown ? "arrowdown" : null}>Write a Product Review</span>{tooltipCon === 5 && <TooltipContent />}</button> */}
                                                </>
                                            ) : (
                                                <>
                                                    <button type="button" onClick={() => props.handleOrderDetails("Exchange")}><span>Exchange</span></button>
                                                    <button type="button" onClick={() => props.handleOrderDetails("Return")}><span>Return</span></button>
                                                    {/* <button type="button" onClick={() => props.handleTooltipStar(4)}><span className={arrowDown ? "arrowdown" : null}>Write a Product Review</span>{tooltipCon === 4 && <TooltipContent />}</button> */}
                                                </>
                                            )}
                                            
                                        </td>
                                    </tr>
                                })
                            }
                        </>
                    </tbody>
                </table>
            </div>
      </div>
    );
  };

export const UserDetails = ({ orderDetails }) => {
    const { address, orderData, orderId, shippingTo } = orderDetails

    return (
        <div className="shippingDts">
            <div className="secLeft table-responsive">
                <table>
                    <tbody>
                        <tr>
                            <td>Order No. :</td>
                            <td>{orderId}</td>
                        </tr>
                        <tr>
                            <td>Order Date :</td>
                            <td>{moment(orderData).format('lll')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="secRight table-responsive">
                <table>
                    <tbody>
                        <tr>
                            <td>Shipping To :</td>
                            <td>{shippingTo}</td>
                        </tr>
                        <tr>
                            <td>Shipping Address :</td>
                            <td>{`${address.address} ${address.optionalAddress} ${address.city} ${address.state} Pin No. ${address.pincode} `}</td>
                        </tr>
                        <tr>
                            <td>Shipping Date :</td>
                            <td>Sat, 17 Feb 2022</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const TooltipContent = () => {
    const [close, setClose] = useState(true);
  
    //Close Submit Review
    const handleSrClose = () => {
      setClose(!true);
    };
    return (
      <>
        {close ? (
          <div className="tootltipSec">
            <div className="head">
              <StarRating />
              <img
                src={Close}
                alt="close"
                title="close"
                onClick={handleSrClose}
              />
            </div>
            <div className="submit-review">
                <img src={SubmitReview} alt="Submit Review" />
                <h6>Submit Review</h6>
            </div>
            <textarea name="w3review" rows="4" cols="50" />
            <button type="button" className="btn btn-primary btn-submit">
              Submit
            </button>
          </div>
        ) : null}
      </>
    );
  };
  
//Cancel Order compounent
// export const CancelOrder = (props) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     const [check, setCheck] = useState(false);
//     const [disableBtn, setdisableBtn] = useState(false);

//     const handelCheck = () => {
//         setCheck(!check);
//         setdisableBtn(!disableBtn);
//     }
//     return (
//         <div className="cancelOrder">
//             <h5>Cancel Order</h5>
//             <div className="backBtn"><button type="button" onClick={props.handlingBack}>Back</button></div>
//             <UserDetails orderDetails={props.orderDetails} />
//             <div className="cancelForm">
//                 <h3>Are You Sure You Want To Send A Cancellation Request? 
//                     <small>*Mandatory Fields</small></h3>
//                 <form>
//                     <ul>
//                         <li><label>SELECT REASON FOR ORDER CANCELLATION*</label>
//                             <select placeholder="Select">
//                                 <option>Select</option>
//                                 <option>Reason One</option>
//                                 <option>Reason Two</option>
//                                 <option>Reason Three</option>
//                                 <option>Reason Four</option>
//                             </select>
//                         </li>

//                         <li><label>YOUR COMMENTS</label>
//                             <textarea cols="15" rows="7"></textarea>
//                         </li>
//                         <li>
//                             <div className={`${check ? "checkBox backGroundNon" : "checkBox"}`} onClick={handelCheck} >
//                                 <div className="checkedBox"><AiOutlineCheck /></div>
//                             </div>
//                             <label>I agree to <Link to={'#'} onClick={handleShow}>Terms and Conditions</Link></label>
//                         </li>
//                     </ul>

//                     <button className={`${disableBtn ? null : "disableBtn"}`} type="submit" name="submit">Submit</button>
//                 </form>
//             </div>

//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 centered
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>Terms and Conditions</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="container">
//                         <p>
//                             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
//                         </p>
//                         <p>
//                             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
//                         </p>
//                         <p>
//                             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
//                         </p>
//                         <p>
//                             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
//                         </p>
//                     </div>
//                 </Modal.Body>
//             </Modal>
//         </div>
//     )
// }

//Track Order Component
export const TrackOrder = ({ handlingBack }) => {
    const now = 35;
    return (
      <div className="trackOrder">
        <div className="track-result">
          <div className="top-info">
            <h5>Track My Order</h5>
            <button type="button" onClick={handlingBack} className="backBtnToTab btn btn-secondary">
              Back
            </button>
          </div>
          <div className="orderDelivered">
            <ul>
              <li className="OrderPlaced">
                <h4>Order Placed</h4>
                <span>16 Feb 2023</span>
              </li>
              <li className="OrderNo">
                <h4>Order No.</h4>
                <span>BJFE2045653216</span>
              </li>
              <li className="TrackingId">
                <h4>Tracking ID</h4>
                <span>BJ-64488635-1</span>
              </li>
              <li className="DeliverTo">
                <h4>Deliver To</h4>
                <span>Kaushal Ranpura</span>
              </li>
              <li className="DeliveryAdd">
                <h4>Delivery Address</h4>
                <span>
                  501, A, Jyoti Tower, Thakur Village, M Rao Marg, Kandivali (E),
                  Mumbai, Maharashtra, 400 045. India
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="track-info">
          <div className="track-details">
            <h4>Your Order is Placed.</h4>
            <ItemTable />
            <div className="progressbar-info">
              <ProgressBar now={now} />
              {/* <ProgressBar now={now} label={`${now}%`} /> */}
              <div className="info">
                <ul>
                  <li>
                    <ul className="orderPlaced">
                      <li>
                        <img src={OrderPlaced} alt="Order Placed" />
                      </li>
                      <li>
                        <h4>Order Placed</h4>
                        <p>15 Feb 2023, 11:30 am</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul className="orderProgress">
                      <li>
                        <img
                          src={OrderInProgress}
                          alt="Order in Progress"
                         />
                      </li>
                      <li>
                        <h4 className="incomplete">Order in Progress</h4>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul className="readyShip">
                      <li>
                        <img src={ReadyToShip} alt="Ready to Ship" />
                      </li>
                      <li>
                        <h4 className="incomplete">Ready to Ship</h4>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul className="delivered">
                      <li>
                        <img src={Delivered} alt="Delivered" />
                      </li>
                      <li>
                        <h4 className="incomplete">Delivered</h4>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="updates">
              <p>Updates:</p>
              <div className="table table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Event</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Date">Wed, 15 Feb 2023</td>
                      <td data-label="Time">11:30 am</td>
                      <td data-label="Event">
                        <p>Order Placed</p>
                        <small>Your order has been placed successfully.</small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

// Invoice Order
export const InvoiceOrder = (props) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    return (
      <div className="invoiceOrder">
        <div className="top-info">
          <h5>Invoice</h5>
          <button
            type="button"
            onClick={props.handlingBack}
            className="backBtnToTab btn btn-secondary"
          >
            Back
          </button>
        </div>
        <div className="topOrderInfo">
          <ul>
            <li>
              <ul>
                <li>
                  <h4>Order Placed</h4>
                  <span>16 Feb 2023</span>
                </li>
                <li>
                  <h4>Order No.</h4>
                  <span>BJFE2045653216</span>
                </li>
              </ul>
            </li>
            <li className="pdf">
                <PrintableInvoice  componentRef={componentRef} />
                <span onClick={handlePrint}><img src={pdf} alt="Download Invoice" /> Download Invoice</span>
            </li>
          </ul>
          {/* <Pdf componentRef={componentRef}/> */}
        </div>
  
        <div className="orderDetails">
          <div className="addressInfo">
            <ul>
              <li>
                <h4>Billing Address</h4>
                <h6>Kaushal Ranpura</h6>
                <p>
                  405 A Wing MK Plaza, Ghodbunder Rd, Aanand Nagar, Thane West,
                  Thane, Maharashtra 400615
                </p>
              </li>
              <li>
                <h4>Shipping Address</h4>
                <h6>Kaushal Ranpura</h6>
                <p>
                  501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali
                  (East), Mumbai, Maharashtra, 400 045. India
                </p>
              </li>
            </ul>
          </div>
  
          <div className="scocialInfo">
            <ul>
              <li>
                <p>Email ID :</p>
                <span>kaushalranpura077@gmail.com</span>
              </li>
              <li>
                <p>Mobile :</p>
                <span>9865532101</span>
              </li>
            </ul>
          </div>
  
          <div className="orderData">
            <ul>
              <li>
                <h4>Order No.</h4>
                <span>BJFE2045653216</span>
              </li>
              <li>
                <h4>Order Date</h4>
                <span>16 Feb 2023</span>
              </li>
              <li>
                <h4>Invoice No.</h4>
                <span>BJ00123-44-88</span>
              </li>
              <li>
                <h4>Invoice Date and Time</h4>
                <span>16 Feb 2023 10:05AM</span>
              </li>
              <li>
                <h4>Place of Supply</h4>
                <span>Maharashtra</span>
              </li>
            </ul>
          </div>
  
          <div className="details">
            <ul>
              <li>
                <h4>Seller Details</h4>
                <p>Do bhai bags & luggage</p>
                <p className="address">
                    Basement floor, B-86, Mayapuri industrial area, Pahse1, Delhi, West Delhi, Delhi, India-110064
                </p>
                <p className="gst">GSTIN : 07AKVPP3694A1ZQ</p>
              </li>
            </ul>
          </div>
  
          <div className="invoiceDetails table-responsive">
            <table>
              <thead>
                <tr>
                  <th className="sr" rowspan="2">Sr. No.</th>
                  <th className="itemInfo" rowspan="2">
                    Item Code
                  </th>
                  <th rowspan="2">HSN</th>
                  <th rowspan="2">Qty</th>
                  <th rowspan="2">Unit Price</th>
                  <th rowspan="2">Discount</th>
                  <th rowspan="2">Taxable Value</th>
                  <th colspan="2">CGST</th>
                  <th colspan="2">SGST/UGST</th>
                  <th colspan="2">IGST</th>
                  <th rowspan="2">Total</th>
                </tr>
                <tr>
                  <th>Rate</th>
                  <th>Amt</th>
                  <th>Rate</th>
                  <th>Amt</th>
                  <th>Rate</th>
                  <th>Amt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Sr. No.">1</td>
                  <td>
                    <div className="productInfo">
                      <span className="tags">Gold Ring Lorem Ipsum SJERN23568</span>
                      <p className="prodName">
                        18 KT Yellow Gold (3.400 g)
                      </p>
                    </div>
                  </td>
                  <td data-label="HSN">64019910</td>
                  <td data-label="Qty">1</td>
                  <td data-label="Unit Price">10,500</td>
                  <td data-label="Discount">1,065</td>
                  <td data-label="Taxable Value">6,000</td>
                  <td data-label="Rate">0.00</td>
                  <td data-label="Amt">0.00</td>
                  <td data-label="Rate">0.00</td>
                  <td data-label="Amt">0.00</td>
                  <td data-label="Rate">630</td>
                  <td data-label="Amt">630</td>
                  <td data-label="Total">9,535</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td>
                    <span>Total</span>
                  </td>
                  <td data-label="Unit Price">10,500</td>
                  <td data-label="Discount">1,065</td>
                  <td data-label="Taxable Value">0.00</td>
                  <td></td>
                  <td data-label="CGST Amt">0.00</td>
                  <td></td>
                  <td data-label="SGST/UGST Amt">0.00</td>
                  <td></td>
                  <td data-label="IGST Amt">1,260</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            </div>  
            <div className="totalInfo">
              <ul>
                <li>
                  <p className="amount">Total Amount(+)</p>
                  <p className="amount">₹ 7,000</p>
                </li>
                <li>
                  <p>Discount(-)</p>
                  <p>₹ 1,000</p>
                </li>
                <li>
                  <p>Total Tax(+)</p>
                  <p>₹ 480</p>
                </li>
                <li>
                  <p className="amount">Net Payable</p>
                  <p className="amount">₹ 6,480</p>
                </li>
              </ul>
            </div>
            <p className="wordAmt">
                Amount Chargeable (in Words) : Six Thousand Four Hundred and Eighty only.
            </p>
  
            <p className="signInfo">For Summera Jewels</p>
  
            <div className="declaration">
              <ul>
                <li>
                  <h4>Declaration</h4>
                  <p>
                    We declare that this invoice shows the actual price of the
                    goods described and that all particulars are true and correct.
                  </p>
                </li>
                <li>
                  <p className="authSign">Authorised Signatory</p>
                </li>
              </ul>
            </div>
          
  
          <div className="footerAddress">
            <p>Registered Address for Summera Jewels</p>
            <p className="address">
                Shop No. 10, Rizvi Mahal, R K Patkar Marg - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.
            </p>
            <ul>
              <li>
                <p>GSTN No. : 29AAAcp7879d1zs</p>
              </li>
              <li>
                <p>PAN : MassZ0567K0</p>
              </li>
              <li>
                <p>State : Maharashtra</p>
              </li>
              <li>
                <p>Code : 33</p>
              </li>
            </ul>
            <div className="info">
              <p>
              For more information on your order or to return an item write email to : <strong>enquiry@summera.in</strong>
              </p>
              <p>
                Customer Support : <strong>+91 77700 07841, +91 82918 90911</strong>
              </p>
            </div>
          </div>
  
          <div className="smallInfo">
            <small>This is a computer generated invoice.</small>
          </div>
        </div>
      </div>
    );
  };

// Hide and show pages
export const ModalShow = (props) => {
    if (props.openModal === "CancelOrder") {
      return (
        <CancelOrder
          handleOrderDetails={props.handleOrderDetails}
          handlingBack={props.handlingBack}
          orderDetails={props.orderDetails}
          handleSubmitMessage={props.ShowMessage}
        />
      );
    } else if (props.openModal === "OrderDetails") {
      return (
        <OrderDetails
          handleOrderDetails={props.handleOrderDetails}
          handlingBack={props.handlingBack}
          orderDetails={props.orderDetails}
        />
      );
    } else if (props.openModal === "TrackOrder") {
      return (
        <TrackOrder
          handleOrderDetails={props.handleOrderDetails}
          handlingBack={props.handlingBack}
          shippingData={props.shippingData}
          grandTotalAfterDis={props.grandTotalAfterDis}
        />
      );
    } else if (props.openModal === "CancelItem") {
      return (
        <CancelItem
          handleOrderDetails={props.handleOrderDetails}
          handlingBack={props.handlingBack}
          orderDetails={props.orderDetails}
          handleSubmitMessage={props.ShowMessage}
        />
      );
    } else if (props.openModal === "Exchange") {
      return (
        <Exchange
          handleOrderDetails={props.handleOrderDetails}
          handlingBack={props.handlingBack}
          orderDetails={props.orderDetails}
          handleSubmitMessage={props.ShowMessage}
        />
      );
    } else if (props.openModal === "Return") {
      return (
        <Return
          handleOrderDetails={props.handleOrderDetails}
          handlingBack={props.handlingBack}
          orderDetails={props.orderDetails}
          handleSubmitMessage={props.ShowMessage}
        />
      );
    } else if (props.openModal === "RequestSuccess") {
      return (
        <RequestSuccess
          handleSubmitMessage={props.ShowMessage}
          message={props?.message}
        />
      );
    } else if (props.openModal === "InvoiceOrder") {
      return (
        <InvoiceOrder
          handleOrderDetails={props.handleOrderDetails}
          handlingBack={props.handlingBack}
          orderDetails={props.orderDetails}
        />
      );
    }
  };

// Cancel item tab 
// export const CancelItem = () => {
//     return (
//         <ExchangeOrder />
//     )
// }

//tooltip info 
export const Tooltip = ({ index, children, text, ...rest }) => {
    const [show, setShow] = React.useState(false);
    return (
        <div className="tooltipWapper">
            {/* <div className="tooltipInfo" style={show ? { display: "block" } : {}}>
                <h4>Kaushal Ranpura</h4>
                <p>501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400 045. India</p>
                <span className="tooltip-arrowInfo" />
            </div> */}
            <div className="tooltipInfo" style={show ? { display: "block" } : {}}>
                <h4>Summera Jewels</h4>
                <p>Shop No. 10, Rizvi Mahal, R K Patkar Marg - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050</p>
                <span className="tooltip-arrowInfo" />
            </div>
            <div
                {...rest}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                {children}
            </div>
        </div>
    )
}