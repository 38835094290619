import React from 'react';
import Logo from '../../../assets/images/logo.png'

const Invoice = ({componentRef}) => {

  return (
    <div className='d-none'>

    <div className="invoiceOrder invoice-pdf" ref={componentRef}>
        <div className="invoiceHeader">         
            <img src={Logo} alt="Summera" />
            <h2>Retail / Tax Invoice</h2>
        </div>  
  
        <div className="orderDetails">
          <div className="addressInfo">
            <ul>
              <li>
                <h4>Billing Address</h4>
                <h6>Kaushal Ranpura</h6>
                <p>
                  405 A Wing MK Plaza, Ghodbunder Rd, Aanand Nagar, Thane West,
                  Thane, Maharashtra 400615
                </p>
              </li>
              <li>
                <h4>Shipping Address</h4>
                <h6>Kaushal Ranpura</h6>
                <p>
                  501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali
                  (East), Mumbai, Maharashtra, 400 045. India
                </p>
              </li>
            </ul>
          </div>
  
          <div className="scocialInfo">
            <ul>
              <li>
                <p>Email ID :</p>
                <span>kaushalranpura077@gmail.com</span>
              </li>
              <li>
                <p>Mobile :</p>
                <span>9865532101</span>
              </li>
            </ul>
          </div>
  
          <div className="orderData">
            <ul>
              <li>
                <h4>Order No.</h4>
                <span>BJFE2045653216</span>
              </li>
              <li>
                <h4>Order Date</h4>
                <span>16 Feb 2023</span>
              </li>
              <li>
                <h4>Invoice No.</h4>
                <span>BJ00123-44-88</span>
              </li>
              <li>
                <h4>Invoice Date and Time</h4>
                <span>16 Feb 2023 10:05AM</span>
              </li>
              <li>
                <h4>Place of Supply</h4>
                <span>Maharashtra</span>
              </li>
            </ul>
          </div>
  
          <div className="details">
            <ul>
              <li>
                <h4>Seller Details</h4>
                <p>Do bhai bags & luggage</p>
                <p className="address">
                    Basement floor, B-86, Mayapuri industrial area, Pahse1, Delhi, West Delhi, Delhi, India-110064
                </p>
                <p className="gst">GSTIN : 07AKVPP3694A1ZQ</p>
              </li>
            </ul>
          </div>
  
          <div className="invoiceDetails table-responsive">
            <table>
              <thead>
                <tr>
                  <th className="sr" rowspan="2">Sr. No.</th>
                  <th className="itemInfo" rowspan="2">
                    Item Code
                  </th>
                  <th rowspan="2">HSN</th>
                  <th rowspan="2">Qty</th>
                  <th rowspan="2">Unit Price</th>
                  <th rowspan="2">Discount</th>
                  <th rowspan="2">Taxable Value</th>
                  <th colspan="2">CGST</th>
                  <th colspan="2">SGST/UGST</th>
                  <th colspan="2">IGST</th>
                  <th rowspan="2">Total</th>
                </tr>
                <tr>
                  <th>Rate</th>
                  <th>Amt</th>
                  <th>Rate</th>
                  <th>Amt</th>
                  <th>Rate</th>
                  <th>Amt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Sr. No.">1</td>
                  <td>
                    <div className="productInfo">
                      <span className="tags">Gold Ring Lorem Ipsum SJERN23568</span>
                      <p className="prodName">
                        18 KT Yellow Gold (3.400 g)
                      </p>
                    </div>
                  </td>
                  <td data-label="HSN">64019910</td>
                  <td data-label="Qty">1</td>
                  <td data-label="Unit Price">10,500</td>
                  <td data-label="Discount">1,065</td>
                  <td data-label="Taxable Value">6,000</td>
                  <td data-label="Rate">0.00</td>
                  <td data-label="Amt">0.00</td>
                  <td data-label="Rate">0.00</td>
                  <td data-label="Amt">0.00</td>
                  <td data-label="Rate">630</td>
                  <td data-label="Amt">630</td>
                  <td data-label="Total">9,535</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td>
                    <span>Total</span>
                  </td>
                  <td data-label="Unit Price">10,500</td>
                  <td data-label="Discount">1,065</td>
                  <td data-label="Taxable Value">0.00</td>
                  <td></td>
                  <td data-label="CGST Amt">0.00</td>
                  <td></td>
                  <td data-label="SGST/UGST Amt">0.00</td>
                  <td></td>
                  <td data-label="IGST Amt">1,260</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <div className="totalInfo">
              <ul>
                <li>
                  <p className="amount">Total Amount(+)</p>
                  <p className="amount">₹ 7,000</p>
                </li>
                <li>
                  <p>Discount(-)</p>
                  <p>₹ 1,000</p>
                </li>
                <li>
                  <p>Total Tax(+)</p>
                  <p>₹ 480</p>
                </li>
                <li>
                  <p className="amount">Net Payable</p>
                  <p className="amount">₹ 6,480</p>
                </li>
              </ul>
            </div>
            <p className="wordAmt">
                Amount Chargeable (in Words) : Six Thousand Four Hundred and Eighty only.
            </p>
  
            <p className="signInfo">For Summera Jewels</p>
  
            <div className="declaration">
              <ul>
                <li>
                  <h4>Declaration</h4>
                  <p>
                    We declare that this invoice shows the actual price of the
                    goods described and that all particulars are true and correct.
                  </p>
                </li>
                <li>
                  <p className="authSign">Authorised Signatory</p>
                </li>
              </ul>
            </div>
          </div>
  
          <div className="footerAddress">
            <p>Registered Address for Summera Jewels</p>
            <p className="address">
                Shop No. 10, Rizvi Mahal, R K Patkar Marg - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.
            </p>
            <ul>
              <li>
                <p>GSTN No. : 29AAAcp7879d1zs</p>
              </li>
              <li>
                <p>PAN : MassZ0567K0</p>
              </li>
              <li>
                <p>State : Maharashtra</p>
              </li>
              <li>
                <p>Code : 33</p>
              </li>
            </ul>
            <div className="info">
              <p>
              For more information on your order or to return an item write email to : <strong>enquiry@summera.in</strong>
              </p>
              <p>
                Customer Support : <strong>+91 77700 07841, +91 82918 90911</strong>
              </p>
            </div>
          </div>
  
          <div className="smallInfo">
            <small>This is a computer generated invoice.</small>
          </div>
        </div>
      </div>

    </div>
  );

};

export default Invoice;