import React from "react";
import CityCarousel from "../atom/ImgCarousel/cityCarousel";


import CatImg from '../../assets/images/bracelets.jpg'
import Diamond from '../../assets/images/diamond.jpg'
import CatImg3 from '../../assets/images/earrings.jpg'
import CatImg4 from '../../assets/images/necklaces.jpg'
import CatImg5 from '../../assets/images/pendants1.jpg'

import CitiesImg1 from '../../assets/images/cities-img-1.jpg';
import CitiesImg2 from '../../assets/images/cities-img-2.jpg';
import CitiesImg3 from '../../assets/images/cities-img-3.jpg';
import CitiesImg4 from '../../assets/images/cities-img-4.jpg';
import CitiesImg5 from '../../assets/images/cities-img-5.jpg';
import CitiesImg6 from '../../assets/images/cities-img-6.jpg';
import CitiesImg7 from '../../assets/images/cities-img-7.jpg';
import CitiesImg8 from '../../assets/images/cities-img-8.jpg';


const Citiesdata = [
    {
        id: 1,
        alt: "Mumbai",
        src: CitiesImg1,
        title: "Mumbai",
        link: "/mumbai"
    },
    {
        id:2,
        alt: "Delhi",
        src: CitiesImg2,
        title: "Delhi",
        link: "/delhi"
    },
    {
        id: 3,
        alt: "Jaipur",
        src: CitiesImg3,
        title: "Jaipur",
        link: "/jaipur"
    },
    {
        id: 4,
        alt: "Bengaluru",
        src: CitiesImg4,
        title: "Bengaluru",
        link: "/bengaluru"
    },
    {
        id: 5,
        alt: "Chennai",
        src: CitiesImg5,
        title: "Karnataka",
        link: "/karnataka"
    },
    {
        id: 6,
        alt: "Gujrat",
        src: CitiesImg6,
        title: "Gujrat",
        link: "/gujrat"
    },
    {
        id: 7,
        alt: "Tamil",
        src: CitiesImg7,
        title: "Tamil",
        link: "/tamil"
    },
    {
        id: 8,
        alt: "Rajasthan",
        src: CitiesImg8,
        title: "Rajasthan",
        link: "/rajasthan"
    },
]

function ShopbyCities() {
    return (
        <CityCarousel categoriesSlider={Citiesdata} />
    )
}

export default ShopbyCities;