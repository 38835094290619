import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { FaSearch } from 'react-icons/fa';
import Brand1 from '../../../assets/images/brand1.png';
import Brand2 from '../../../assets/images/brand2.png';
import Brand3 from '../../../assets/images/brand3.png';
import Brand4 from '../../../assets/images/brand4.png';
import Brand5 from '../../../assets/images/brand5.png';
import '../../SummeraPromise/index.css';
import Reactselect from "../../molecules/select";

const AlphaData =[
    {id:1, value:'ALL', label:'ALL'},
    {id:2, value:'A', label:'A'},
    {id:3, value:'B', label:'B'},
    {id:4, value:'C', label:'C'},
    {id:5, value:'D', label:'D'},
    {id:6, value:'E', label:'E'},
    {id:7, value:'F', label:'F'},
    {id:8, value:'G', label:'G'},
    {id:9, value:'H', label:'H'},
    {id:10, value:'I', label:'I'},
    {id:11, value:'J', label:'J'},
    {id:12, value:'K', label:'K'},
    {id:13, value:'L', label:'L'},
    {id:14, value:'M', label:'M'},
    {id:15, value:'N', label:'N'},
    {id:16, value:'O', label:'O'},
    {id:17, value:'P', label:'P'},
    {id:18, value:'Q', label:'Q'},
    {id:19, value:'R', label:'R'},
    {id:20, value:'S', label:'S'},
    {id:21, value:'T', label:'T'},
    {id:22, value:'U', label:'U'},
    {id:23, value:'V', label:'V'},
    {id:24, value:'W', label:'W'},
    {id:25, value:'X', label:'X'},
    {id:26, value:'Y', label:'Y'},
    {id:27, value:'Z', label:'Z'}
  ]

  const items = [
    {
      id: 0,
      name: 'Jewels Box'
    },
    {
      id: 1,
      name: 'My Silver'
    },
    {
      id: 2,
      name: 'Anmol'
    },
    {
      id: 3,
      name: 'Zota Jewel'
    },
    {
      id: 4,
      name: 'Fine Jewel'
    }
  ]

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    // console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
      </>
    )
  }

  const BrandData =[
    {id:1, src:Brand1, cardtext:'Jewels Box'},
    {id:2, src:Brand2, cardtext:'My Silver'},
    {id:3, src:Brand3, cardtext:'Anmol'},
    {id:4, src:Brand4, cardtext:'Zota Jewel'},
    {id:5, src:Brand5, cardtext:'Fine Jewel'},
    {id:6, src:Brand1, cardtext:'Jewels Box'},
    {id:7, src:Brand2, cardtext:'My Silver'},
    {id:8, src:Brand3, cardtext:'Anmol'},
    {id:9, src:Brand4, cardtext:'Zota Jewel'},
    {id:10, src:Brand5, cardtext:'Fine Jewel'},
    {id:11, src:Brand1, cardtext:'Jewels Box'},
    {id:12, src:Brand2, cardtext:'My Silver'},
    {id:13, src:Brand3, cardtext:'Anmol'},
    {id:14, src:Brand4, cardtext:'Zota Jewel'},
    {id:15, src:Brand5, cardtext:'Fine Jewel'},
    {id:16, src:Brand1, cardtext:'Jewels Box'},
    {id:17, src:Brand2, cardtext:'My Silver'},
    {id:18, src:Brand3, cardtext:'Anmol'},
    {id:19, src:Brand4, cardtext:'Zota Jewel'},
    {id:20, src:Brand5, cardtext:'Fine Jewel'},
    {id:21, src:Brand1, cardtext:'Jewels Box'},
    {id:22, src:Brand2, cardtext:'My Silver'},
    {id:23, src:Brand3, cardtext:'Anmol'},
    {id:24, src:Brand4, cardtext:'Zota Jewel'}
  ]

const ShopByBrand = () => {

    // const [selected, setSelected] = useState('');
    const [data, setData] = useState(BrandData);
    const [brandname, setBrandname] = useState("ALL");

    const imagePerRow = 20;

  const [next, setNext] = useState(imagePerRow);
const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };
  

    const [errors, setErrors] = useState({
        errBrandname: "",
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [active, setActive] = useState(0);

    const handleClick = (index,value) => {
        setActive(index)
        let arr = []
        BrandData.map(item => {
            const {cardtext} = item
            const charOne = cardtext.charAt(0)
            if(value === charOne){
                return arr.push(item)
            } else if (value === "ALL") return arr.push(item)
            return arr;
        })
        setData(arr)
    }

    const handleStateChange = (name, value) => {
        if (name === "brandname") {
            setBrandname(value)
            handleClick(name, value)
        }
    }

    const handleError = (name, value) => {

        let allState = errors;

        if (name === "brandname") {
            if (!value.length) {
                allState["errBrandname"] = "Select Brandname."
            } else {
                allState["errBrandname"] = ""
            }
        }
    }

    return (
        <div className='wrapper shop-by-brand'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Shop by Brand</h1>
                </div>
            </div>

            <div className="container">
                 
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">Shop by Brand</li>
                    </ol>
                </nav>

                <div className='brands'>
                    <div className='brand-search'>
                        <div className='filter'>
                            <label>Filter By:</label>
                            <ul>
                            {
                                AlphaData.map((item,index)=>{
                                return(
                                    <li key={index}>
                                        <button id={index} key={index} onClick={() => handleClick(index, item.value)} className={`${active === index && 'active'}`}>{item.value}</button>
                                    </li>
                                )
                                })
                            }   
                            </ul>

                            <Reactselect
                                name={"brandname"}
                                id={"brandname"}
                                label={"Brand Name"}
                                options={AlphaData}
                                value={brandname}
                                lblClass={"input-lable"}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                errorMsg={errors.errBrandname}
                                class={"form-select"}
                            />
                        </div>

                        <div className="search">
                            <ReactSearchAutocomplete
                                items={items}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelect}
                                onFocus={handleOnFocus}
                                autoFocus
                                formatResult={formatResult}
                                placeholder={'Search Brand...'}
                            />
                            <button className="btn btn-outline-secondary" type="button">
                                <FaSearch />
                            </button>
                        </div>
                    </div>

                    <div className='brand-cards'>
                        {data.length ? (
                                data?.slice(0, next)?.map((item,index)=>{
                                    return(
                                        <Link to={'/shop-by-brand/jewels-box'} key={index}>
                                            <div className='promise-card card'>
                                                <figure className='icon'>
                                                    <img src={item.src} alt={item.cardtext} title={item.cardtext} />
                                                </figure>
                                                <div className='card-body'>
                                                    <h3>
                                                        {item.cardtext}
                                                    </h3>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })
                            )
                            : <p> No Brands Found. </p>
                        } 

       
                        {next < data?.length && (
                            <div className='load-more'>
                            <span className="btn btn-primary" onClick={handleMoreImage}>Load more</span>
                            </div>
                        )}

                    </div>
                </div>

            </div>
        </div>
    )
}


export default ShopByBrand;