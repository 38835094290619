import {React} from 'react';
import { Link } from 'react-router-dom';
import TextInput from "../../../components/atom/input/input";
import './index.css'
import { useLocation } from 'react-router-dom';

const OTPVerification = () => {
    //document.body.classList.add('login-page');
    const location = useLocation()
    const url = location.pathname;

  return (
    <div className="container">
        <div className="login-box">
            <div className="login-wrapper text-center">
                <div className="otpverifyPage">
                    <h2>OTP Verification</h2>
                    <form>
                        <label>Enter the OTP sent to +91 XXXXXX0245</label>
                        <div className="inputFields">
                            <TextInput
                                type={"tel"}
                                autoComplete={"off"}
                            />
                            <TextInput
                                type={"tel"}
                                autoComplete={"off"}
                            />
                            <TextInput
                                type={"tel"}
                                autoComplete={"off"}
                            />
                            <TextInput
                                type={"tel"}
                                autoComplete={"off"}
                            />
                            <TextInput
                                type={"tel"}
                                autoComplete={"off"}
                            />
                            <TextInput
                                type={"tel"}
                                autoComplete={"off"}
                            />
                        </div>
                        <Link className="btn btn-primary" to={`${url}/review`}><span>Verify & Proceed</span></Link>
                        <p>Don't receive the OTP? <Link to={''}>Resend OTP</Link></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OTPVerification
