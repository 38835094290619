import React,{useEffect} from 'react'
import { Link,useNavigate,useLocation } from 'react-router-dom';
import './index.css'
<meta http-equiv = "refresh" content = "2; url = thank-you.shtml" />
export default function Payment() {
  const navigate = useNavigate();
  const received = useLocation().state
  useEffect(()=> {
    if(received === 'giftsreview'){
        setTimeout(()=>{
            navigate('/gifts/buy-a-card/thank-you')
        },3000)
    } else if(received === 'giftreview'){
        setTimeout(()=>{
            navigate('/gifts/gift-a-card/thank-you')
        },3000)
    } else if(received === 'customisegiftreview'){
        setTimeout(()=>{
            navigate('/gifts/buy-a-card-customised/thank-you')
        },3000)
    } else if(received === 'membershipreview'){
        setTimeout(()=>{
            navigate('/sell-on-summera/thankyou')
        },3000)
    } else {
        setTimeout(()=>{
            navigate('/thankyou')
        },3000)
    }
  })
document.body.classList.add('payment'); 
  return (
    <div>
      <div className='container'>
        <div className='custompayment'>
            <h2>Payment Gateway Redirect</h2>
        </div>
      </div>
    </div>
  )
}
