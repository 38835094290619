import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';
import BrandLogo from '../../../assets/images/brands/jewel-box.png';
import JewellerySets from '../../../assets/images/brands/jewellery-sets.png';
import Ring from '../../../assets/images/brands/ring.png';
import Bangels from '../../../assets/images/brands/bangels.png';
import Mangalsutra from '../../../assets/images/brands/mangalsutra.png';
import TraditionalWear from '../../../assets/images/brands/traditional-wear.png';
import DiamondCollection from '../../../assets/images/brands/diamond-collection.png';
import PartyWear from '../../../assets/images/brands/party-wear.png';
import NosePins from '../../../assets/images/brands/nose-pins.png';
import Pendant from '../../../assets/images/brands/pendant.png';
import Chains from '../../../assets/images/brands/chains.png';
import Bracelets from '../../../assets/images/brands/bracelets.png';
import Earrings from '../../../assets/images/brands/earrings.png';
import Necklaces from '../../../assets/images/brands/necklaces.png';
import ring1 from '../../../assets/images/products/rings/ring1.png';
import ring2 from '../../../assets/images/products/rings/ring2.png';
import ring3 from '../../../assets/images/products/rings/ring3.png';
import ring4 from '../../../assets/images/products/rings/ring4.png';
import { FiHeart } from 'react-icons/fi';
import { BiRupee } from 'react-icons/bi';
import '../../Card/card.css';
import BrandMenu from './brandmenu';
import ReactPlayer from 'react-player'
import posterimg from '../../../assets/images/brands/poster-img.png';

const Sdata = [
    {id:1, imgsrc: ring1, pname:'Gold Ring', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
    {id:2, imgsrc: ring2, pname:'Rings', price: "5000", offerPrice:"", category:"Trending",  discountOffer:"" },
    {id:3, imgsrc: ring3, pname:'Gold Ring', price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
    {id:4, imgsrc: ring4, pname:'Rings', price: "5770", offerPrice:"", category:"Trending", discountOffer:"" },
    {id:5, imgsrc: ring1, pname:'Gold Ring', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
    // {id:6, imgsrc: ring2, pname:'Rings', price: "5000", offerPrice:"", category:"Trending",  discountOffer:"" },
    // {id:7, imgsrc: ring3, pname:'Gold Ring', price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
    // {id:8, imgsrc: ring4, pname:'Rings', price: "5770", offerPrice:"", category:"Trending", discountOffer:"" },
    // {id:9, imgsrc: ring1, pname:'Gold Ring', price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
    // {id:10, imgsrc: ring2, pname:'Rings', price: "5770", offerPrice:"", category:"Trending", discountOffer:"" }
]    

const Brand = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleClick = event => {
        event.currentTarget.classList.toggle('active');
    };

    return (
        <div className='wrapper brand'>

            <div className='banner-div'>
                <div className='container'>
                    <img src={BrandLogo} alt="Jewels Box" title="Jewels Box" />
                </div>
            </div>

            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={'/shop-by-brand'}>Shop by Brand</Link></li>
                        <li className="breadcrumb-item active">Jewels Box</li>
                    </ol>
                </nav>

                <BrandMenu />

                <div className='brand-products'>

                    <p>Make a statement with all that shines </p>
                    <p>Indulge in the brilliance of everlasting love and sophistication with our stunning gold and diamond rings, meticulously crafted to captivate hearts.</p>

                    <ul className='product-cards'>
                        <li className='jewellery-sets'>
                            <figure>
                                <img src={JewellerySets} alt="Jewellery Sets" title="Jewellery Sets" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li>
                        <li className='ring'>
                            <figure>
                                <img src={Ring} alt="Ring" title="Ring" />
                                <figcaption>
                                    <Link to={'/shop-by-brand/jewels-box/gold/rings'} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li>
                        <li className='bangels'>
                            <figure>
                                <img src={Bangels} alt="Bangels" title="Bangels" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li>
                        <li className='mangalsutra'>
                            <figure>
                                <img src={Mangalsutra} alt="Mangalsutra" title="Mangalsutra" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li>

                        <li className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url='https://youtube.com/shorts/6U-7TKB_NHE?feature=share'
                                width='100%'
                                height='100%'
                                light={<img src={posterimg} alt="Poster" />}
                            />
                        </li>

                        {/* <li className='traditional-wear'>
                            <figure>
                                <img src={TraditionalWear} alt="Traditional Wear" title="Traditional Wear" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li> */}
                        {/* <li className='diamond-collection'>
                            <figure>
                                <img src={DiamondCollection} alt="Diamond Collection" title="Diamond Collection" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li> */}
                        {/* <li className='party-wear'>
                            <figure>
                                <img src={PartyWear} alt="Party Wear" title="Party Wear" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li> */}

                        {/* <li className='nose-pins'>
                            <figure>
                                <img src={NosePins} alt="Nose Pins" title="Nose Pins" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li> */}
                        {/* <li className='pendant'>
                            <figure>
                                <img src={Pendant} alt="Pendant" title="Pendant" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li> */}
                        {/* <li className='chains'>
                            <figure>
                                <img src={Chains} alt="Chains" title="Chains" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li> */}
                        {/* <li className='bracelets'>
                            <figure>
                                <img src={Bracelets} alt="Bracelets" title="Bracelets" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li> */}

                        <li className='earrings'>
                            <figure>
                                <img src={Earrings} alt="Earrings" title="Earrings" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li>
                        <li className='necklaces'>
                            <figure>
                                <img src={Necklaces} alt="Necklaces" title="Necklaces" />
                                <figcaption>
                                    <Link to={''} className="btn btn-outline">Explore More</Link>
                                </figcaption>
                            </figure>
                        </li>
                    </ul>

                    <h2>Lorem Ipsum is simply </h2>

                    <div className='product-wrapper'>
                        {
                            Sdata.map((item,index)=>{
                            return(
                                <div className='card' key={index}>
                                    <div className='card-header'>
                                        <Link to={'/productdetails'}>
                                            <img src={item.imgsrc} className='card-img-top' alt={item.pname} title={item.pname} />
                                        </Link>
                                    </div>
                                    <div className='product-label'>
                                        <ul>
                                            <li className=
                                                {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                            </li>
                                        </ul>
                                        <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                            <FiHeart js-heart="true" onClick={handleClick} />
                                        </span>
                                    </div>
                                    <div className='card-body'>
                                        <p>{item.pname}</p>
                                        <div className='price'><span><BiRupee />{item.price}</span>
                                            {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                        </div>
                                        { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                    </div>
                                </div>
                            )
                            })
                        } 
                    </div>
                
                </div>
            </div>
        </div>
    )
}


export default Brand;