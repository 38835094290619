import {React, useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BiRupee } from 'react-icons/bi';
import Checkbox from '../atom/checkbox/input'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ImCross } from 'react-icons/im';

export default function CustomiseGiftsReview() {
    const [checkbox,setCheckbox] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handelFormSubmit = async(e) => {
            if(checkbox){
                e.preventDefault()
                navigate('/payment', {
                    state : "customisegiftreview"
                })
            }else{
                alert("Please check term & conditions.")
            }
    }

    const TermsAndConditions = () => (
        <Popup trigger={<Link>Terms and Conditions</Link>} modal aria-describedby={"pinHome"}>
          {close => (
            <div className='terms-popup'>
                <div className='pop-title'>
                    <h2>Terms and Conditions</h2>
                    <span className="close" onClick={close}><span><ImCross className="closebtn" /></span></span>
                </div>
    
                <div className='pop-body'>
                    <div className='inner-div'>
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>
    
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>
    
                        <div className="para">
                            <h3 className="title">Lorem Ipsum is simply dummy</h3>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            </ul>
                        </div>     
                    </div>  
                </div>
            </div>
          )}
          </Popup>
      );

      const handleCheckboxChange = () => {
        setCheckbox(!checkbox)
    }

  return (
    <div className='wrapper customise-gifts-review'>
       <div className="checkout-details">
            <div className="container">
                <h2>Review</h2>
                <div className="contact">
                    <div className='left'>
                        <div className='contact-details'>
                        <div className='review-add-details'>
                                <h4>Buy A Card - Perfect Couple</h4>

                                <ul>
                                    <li>
                                        <h6>Card</h6>
                                        <p>Perfect Couple</p>
                                    </li>
                                    <li>
                                        <h6>Amount</h6>
                                        <p>₹ 4,000</p>
                                    </li>
                                </ul>

                                <h5>Contact Details</h5>

                                <ul>
                                    <li>
                                        <h6>Name</h6>
                                        <p>Kaushal Ranpura</p>
                                    </li>
                                    <li>
                                        <h6>Email ID</h6>
                                        <p><Link to={'mailto:Kaushalranpura@gmail.com'}>Kaushalranpura@gmail.com</Link></p>
                                    </li>
                                    <li>
                                        <h6>Mobile</h6>
                                        <p><Link to={'tel:9865890245'}>91-9865890245</Link></p>
                                    </li>
                                </ul>
                            </div>
                        
                        </div>
                        <div className="make-info">
                            <div className="checkbox">
                                <Checkbox 
                                    autoComplete="off"
                                    id={"agree"}
                                    type={"checkbox"}
                                    name={"checkbox"}
                                    inputClass={"styled-checkbox"}
                                    onChange={handleCheckboxChange}
                                    value={checkbox}
                                    checked={checkbox ? true : false}
                                />
                                <label htmlFor="agree">I agree the {TermsAndConditions()} </label>
                            </div>
                            <button className="btn btn-primary" type="submit" onClick={handelFormSubmit}><span>Make Payment</span></button>
                        </div> 
                    </div>
                    <div className="right">
                        <div className="checkout-summary">
                            <h4>Order Summary</h4>
                        </div>

                        <div className="summary-data" >

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6 className="cust-total">Sub Total</h6>
                                    <div className="price cust-total"><BiRupee />4,000</div>
                                </div>
                            </div>

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6>GST</h6>
                                    <div className="price"><BiRupee />320</div>
                                </div>
                            </div>



                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6 className="cust-total">Total Amount</h6>
                                    <div className="price cust-total"><BiRupee />4,320</div>
                                </div>
                            </div>
                        </div>
            
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}