import React, {useState, useEffect} from 'react'

import SimilarProduct from '../../../../components/SimilarProducts';
// import ProducCard from '../../../../components/Card';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import "../../../../components/atom/ImgCarousel/Slider.css";
import Engagement from '../../../../assets/images/engagement-banner.jpg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from 'react-responsive';
import SortBy from '../../../atom/SortBy';
import GoldSideNav from '../../../atom/SideNav/goldsidenav';
import { FiHeart } from 'react-icons/fi';
import { BiRupee } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { viewSimilarProductAction } from '../../../../redux/actions/cartAction';

// images 
import RingsImg1 from '../../../../assets/images/ring-prod-detail-11.jpg';
import RingsImg2 from '../../../../assets/images/ring-prod-detail-2.jpg';
import RingsImg3 from '../../../../assets/images/ring-prod-detail-3.jpg';
import RingsImg4 from '../../../../assets/images/ring-prod-detail-4.jpg';
import RingsImg5 from '../../../../assets/images/ring-prod-detail-5.jpg';
import RingsImg6 from '../../../../assets/images/ring-prod-detail-6.jpg';
import RingsImg7 from '../../../../assets/images/ring-prod-detail-7.jpg';
import RingsImg8 from '../../../../assets/images/ring-prod-detail-8.jpg';
import RingsImg9 from '../../../../assets/images/ring-prod-detail-9.jpg';
import RingsImg10 from '../../../../assets/images/ring-prod-detail-10.jpg';

const Data = [
    { id:1,  src:Engagement, title:"Rings"}   
]

export default function Rings() {
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const dispatch = useDispatch();

    const handleClick = event => {
        event.currentTarget.classList.toggle('active');
    };

    const { similarActive  } = useSelector((state)  => state.cart); 

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    
    const Sdata = [
        {id:1, imgsrc: RingsImg1, pname:'Gold Ring', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:2, imgsrc: RingsImg2, pname:'Rings', price: "5000", offerPrice:"", category:"Trending",  discountOffer:"" },
        {id:3, imgsrc: RingsImg3, pname:'Gold Ring', price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
        {id:4, imgsrc: RingsImg4, pname:'Rings', price: "5770", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:5, imgsrc: RingsImg5, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:6, imgsrc: RingsImg6, pname:'Rings', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:7, imgsrc: RingsImg7, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:8, imgsrc: RingsImg8, pname:'Rings', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:9, imgsrc: RingsImg9, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:10, imgsrc: RingsImg10, pname:'Rings', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:11, imgsrc: RingsImg3, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        { id: 12, imgsrc: RingsImg4, pname: 'Rings', price: "5440", offerPrice: "", category: "Trending", discountOffer: "" },
        {id:13, imgsrc: RingsImg3, pname:'Gold Ring', price: "1000", offerPrice:"47,551", category:"New", discountOffer:"5" },
        {id:14, imgsrc: RingsImg4, pname:'Rings', price: "5770", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:15, imgsrc: RingsImg1, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:16, imgsrc: RingsImg10, pname:'Rings', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:17, imgsrc: RingsImg3, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:18, imgsrc: RingsImg4, pname:'Rings', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:19, imgsrc: RingsImg6, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:20, imgsrc: RingsImg2, pname:'Rings', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:21, imgsrc: RingsImg3, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:22, imgsrc: RingsImg1, pname:'Rings', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" }
    ] 

  return (
            <div className='wrapper'>
                
                <Slider {...settings}>
                    {
                        Data.map((item,index)=>{
                            return(
                                <div className='singleimgslider' key={index}>
                                    <figure>
                                        <img src={item.src} alt={item.title} title={item.title}/>
                                        <div className='container'>
                                            <h3>{item.title}</h3>
                                        </div>
                                    </figure>
                                </div>
                                    
                            )
                    })
                    }
                </Slider>

               <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={'/gold'}>Gold</Link></li>
                        <li className="breadcrumb-item active">Rings</li>
                        </ol>
                    </nav>
                    <h2 className='title'>Rings</h2>
                    <div className='product-listing'>
                    <div className='left-column desktop'>
                        <GoldSideNav />
                    </div>
                    <div className={`left-column mobile ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
                    {isMobile && (
                        <GoldSideNav />
                        )}
                    </div>
                    <div className='right-column'>
                        <div className='title-sort-option'>
                            <h3 className='title'>296 Products</h3>
                            <SortBy />
                        </div>
                        <div className='product-wrapper'>
                          {/* <ProducCard />  */}
                            {
                                Sdata.map((item, index) => {
                                
                                return (
                                <div className='card' key={index}>
                                    <div className='card-header'>
                                        <Link to={'/gold/product-details'}>
                                            <img src={item.imgsrc} className='card-img-top' alt={item.pname} title={item.pname} />
                                        </Link>
                                    </div>
                                    <div className='product-label'>
                                        <ul>
                                            <li className=
                                                {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                            </li>
                                        </ul>
                                        <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                            <FiHeart js-heart="true" onClick={handleClick} />
                                        </span>
                                    </div>
                                    <div className='card-body'>
                                        <p>{item.pname}</p>
                                        <div className='price'><span><BiRupee />{item.price}</span>
                                            {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                        </div>
                                        { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                        <div className='btn-similar'>
                                            <button className='btn' onClick={() => dispatch(viewSimilarProductAction())} >View Similar</button>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                            }
                            <div className='d-flex justify-content-center w-100 mt-3'>
                                <Link to={''} className='btn btn-primary'><span>Load More</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <SimilarProduct similarActive={similarActive}  />
               </div>     
            </div>
        )
}
