import React, {useEffect} from 'react';
import {  Route, Routes, useLocation  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../Header';
import Mycart from '../components/Mycart'
import Contact from './Pages/Contact';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
import Footer from '../shared/footer';
import Product from '../components/Pages/product';
import ProductDetails from './Pages/Productdetails';
import ReviewCart from './Pages/Reviewcart';
import Login from '../components/molecules/Login'
import Checkout from './Pages/checkout';
import CheckoutReview from './Checkout/CheckoutReview';
import Payment from '../components/Checkout/payment'
import Thankyou from '../components/Thankyou'
import Registration from './molecules/Registration';
import Thank from '../components/Pages/Thank';
import RequestVideoCall from '../components/Pages/RequestVideoCall';
import ThankyouForTryAtHome from '../components/Pages/ThankyouForTryAtHome';
import BookingInformation from '../components/molecules/BookingInformation';
import ScheduleYourAppointment from '../components/molecules/ScheduleYourAppointment';
import VideoCallThankYou from '../components/Pages/VideoCallThankYou';
import MyAccount from '../components/Pages/MyAccount';
import OrdersAndReturns from '../components/Pages/OrdersAndReturns';
import PaymentPolicy from '../components/Pages/PaymentPolicy';
import ShippingPolicy from '../components/Pages/ShippingPolicy';
import TermsAndConditions from '../components/Pages/TermsAndConditions';
import PrivacyPolicy from '../components/Pages/PrivacyPolicy';
import CookiePolicy from '../components/Pages/CookiePolicy';
import Faqs from '../components/Pages/Faqs';
import CustomerSays from '../components/Pages/CustomerSays';
import CustomerService from '../components/Pages/CustomerService';
import AboutSummera from '../components/Pages/AboutSummera';
import FindStore from '../components/Pages/FindStore';
import WhatsNew from '../components/Pages/WhatsNew';
import Gold from './ShopByCategory/Gold';
import Rings from './ShopByCategory/Gold/Rings';
import GoldEngagement from './ShopByCategory/Gold/Rings/engagement';
import GoldEngagementProductDetails from './ShopByCategory/Gold/Rings/Productdetails';
import Diamond from './ShopByCategory/Diamond';
import DiamondRings from './ShopByCategory/Diamond/Rings';
import DiamondEngagement from './ShopByCategory/Diamond/Rings/engagement';
import DiamondEngagementProductDetails from './ShopByCategory/Diamond/Rings/Productdetails';
import Gemstones from './ShopByCategory/Gemstones';
import GemstonesRings from './ShopByCategory/Gemstones/Rings';
import GemstonesEngagement from './ShopByCategory/Gemstones/Rings/engagement';
import GemstonesEngagementProductDetails from './ShopByCategory/Gemstones/Rings/Productdetails';
import Platinum from './ShopByCategory/Platinum';
import PlatinumRings from './ShopByCategory/Platinum/Rings';
import PlatinumEngagement from './ShopByCategory/Platinum/Rings/engagement';
import PlatinumEngagementProductDetails from './ShopByCategory/Platinum/Rings/Productdetails';
import Silver from './ShopByCategory/Silver';
import SilverRings from './ShopByCategory/Silver/Rings';
import SilverEngagement from './ShopByCategory/Silver/Rings/engagement';
import SilverEngagementProductDetails from './ShopByCategory/Silver/Rings/Productdetails';
import Solitaire from './ShopByCategory/Solitaire';
import SolitaireRings from './ShopByCategory/Solitaire/Rings';
import SolitaireEngagement from './ShopByCategory/Solitaire/Rings/engagement';
import SolitaireEngagementProductDetails from './ShopByCategory/Solitaire/Rings/Productdetails';
import CoinsBar from './ShopByCategory/CoinsBar';
import GoldCoins from './ShopByCategory/CoinsBar/Gold/GoldCoins';
import SilverCoins from './ShopByCategory/CoinsBar/Silver/SilverCoins';
import SellOnSummera from './molecules/SellOnSummera';
import SubscriptionPlan from './molecules/SellOnSummera/subscription-plan';
import SellOnSummeraReview from './molecules/SellOnSummera/CheckoutReview';
import SubscriptionThanks from './molecules/SellOnSummera/thankyou';
import Customised from './molecules/CustomiseRequest';
import TryatHome from '../components/Pages/TryatHome';
import TryatHomeDetails from './Pages/TryatHomeDetails';
import SellOnSummeraThankYou from './molecules/SellOnSummera/thank-you';
import CustomisedRequestThankYou from './molecules/CustomiseRequest/thank-you';
import JewelleryCareGuide from '../components/Pages/JewelleryCareGuide';
import GemstoneCareGuide from '../components/Pages/GemstoneCareGuide';
import DiamondCareGuide from '../components/Pages/DiamondCareGuide';
import CustomiseRequest from '../components/Pages/CustomiseRequest';
import GoldProductDetails from './ShopByCategory/CoinsBar/Gold/Goldproductdetails';
import SilverProductDetails from './ShopByCategory/CoinsBar/Silver/Silverproductdetails';
import ShopByBrand from '../components/molecules/ShopByBrand';
import Brand from '../components/molecules/ShopByBrand/brand';
import BrandGoldRings from './molecules/ShopByBrand/brandgoldrings';
import BrandGoldProductDetails from './molecules/ShopByBrand/productdetails';
import ShopForWomen from '../components/ShopGender/ShopForWomen';
import ShopForMen from '../components/ShopGender/ShopForMen';
import ShopForKids from '../components/ShopGender/ShopForKids';
import WomenProductDetails from '../components/ShopGender/ShopForWomen/productdetails';
import MenProductDetails from '../components/ShopGender/ShopForMen/productdetails';
import KidsProductDetails from '../components/ShopGender/ShopForKids/productdetails';
import WeddingJewelry from '../components/ShopOccasion/WeddingJewelry';
import WeddingJewelryDetails from '../components/ShopOccasion/WeddingJewelry/productdetails';
import ModernManglsutra from '../components/ShopOccasion/ModernManglsutra';
import ModernManglsutraDetails from '../components/ShopOccasion/ModernManglsutra/productdetails';
import Gifts from '../components/Gifts';
import BuyACard from '../components/Gifts/buyacard';
import GiftsReview from '../components/Gifts/giftsreview';
import GiftsBuyCardThankYou from '../components/Gifts/thankyou';
import GiftACard from '../components/Gifts/giftacard';
import GiftReview from '../components/Gifts/giftreview';
import GiftBuyCardThankYou from '../components/Gifts/thankyou';
import BuyACardCustomised from '../components/Gifts/buyacardcustomised';
import CustomiseGiftsReview from '../components/Gifts/customisegiftsreview';
import CustomiseGiftsThankYou from '../components/Gifts/thankyou';
import EngagementRings from '../components/ShopOccasion/EngagementRings';
import EngagementRingsDetails from '../components/ShopOccasion/EngagementRings/productdetails';
import EverydayWearBracelets from '../components/ShopOccasion/EverydayWearBracelets';
import EverydayWearBraceletsDetails from '../components/ShopOccasion/EverydayWearBracelets/productdetails';
import GorgeousStuds from '../components/ShopOccasion/GorgeousStuds';
import GorgeousStudsDetails from '../components/ShopOccasion/GorgeousStuds/productdetails';
import ShopByNewArrivals from '../components/ShopArrivals/shopbynewarrivals';
import ShopByNewArrivalsDetails from '../components/ShopArrivals/productdetails';
// import { PrivateRoute } from './hoc/PrivateRoute';


const Router = () => {

  const router = useLocation()

  
  const { isActive } = useSelector((state)  => state.cart);
  useEffect(() => {
    if(router.pathname==="/checkout"){
      document.body.classList.add('checkout');
    }
    else if(router.pathname==="/"){
        document.body.classList.add('home');
        document.body.classList.remove('product_details');
    }
    else if(router.pathname==="/productdetails" || router.pathname==="/shop-for-women/product-details" || router.pathname==="/shop-for-men/product-details" || router.pathname==="/shop-for-kids/product-details" || router.pathname==="/wedding-jewelry/product-details" || router.pathname==="/modern-manglsutra/product-details" || router.pathname==="/engagement-rings/product-details" || router.pathname==="/everyday-wear-bracelets/product-details" || router.pathname==="/gorgeous-studs/product-details" || router.pathname==="/coins-bar/silver-coins-bar/silver-coins" || router.pathname==="/gold/rings/engagement/product-details" || router.pathname==="/diamond/rings/engagement/product-details" || router.pathname==="/gemstones/rings/engagement/product-details" || router.pathname==="/platinum/rings/engagement/product-details" || router.pathname==="/silver/rings/engagement/product-details" || router.pathname==="/solitaire/rings/engagement/product-details" || router.pathname==="/shop-by-new-arrivals/product-details"){
      document.body.classList.add('product_details');
    }
    else if(router.pathname==="/coins-bar/gold-coins-bar/gold-coins"){
      document.body.classList.add('gold_product_details');
    }
    else if(router.pathname==="/shop-by-brand/jewels-box/gold/rings/gold-ring"){
      document.body.classList.add('gold_product_details');
    }
    else if(router.pathname==="/checkoutreview"){
      document.body.classList.add('checkout');
    }
    else if(router.pathname==="/sell-on-summera"){
        document.body.classList.add('sellOnSummera');
    }
    else if(router.pathname==="/gifts/buy-a-card"){
        document.body.classList.add('buyacard');
    }
    else if(router.pathname==="/gifts/buy-a-card/review"){
        document.body.classList.add('giftsreview');
    }
    else if(router.pathname==="/gifts/gift-a-card/review"){
        document.body.classList.add('giftreview');
    }
    else if(router.pathname==="/gifts/buy-a-card-customised"){
        document.body.classList.add('buyacard');
    }
    else if(router.pathname==="/gifts/buy-a-card-customised/review"){
        document.body.classList.add('customisegiftsreview');
    }
    else if(router.pathname==="/gold/product-details"){
        document.body.classList.add('product_details');
    }
    else if(router.pathname==="/myaccount"){
        document.body.classList.add('myaccount');
    }else if(router.pathname==="/sell-on-summera/review"){
      document.body.classList.add('membership-review');
    } 
    else if(router.pathname==="/sell-on-summera/subscription-plan"){
        document.body.classList.add('subscription-task');
    }
    else{
      document.body.classList.remove('home');
      document.body.classList.remove('checkout');
      document.body.classList.remove('sellOnSummera');
      document.body.classList.remove('product_details');
      document.body.classList.remove('gold_product_details');
      document.body.classList.remove('buyacard');
      document.body.classList.remove('giftreview');
      document.body.classList.remove('customisegiftsreview');
      document.body.classList.remove('myaccount');
      document.body.classList.remove('subscription-task');
      document.body.classList.remove('membership-review');
    }
  })
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
        <Header />
        <Mycart isActive={isActive} />
        <Routes>
          {/* <Route element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>} path="/" exact /> */}

          <Route element= {<Home />} path="/" exact />

          <Route element= {<Contact />} path="/contact-us" exact />

          <Route element={<NotFound />} path="*" />
          <Route element={<Product />} path="/product" exact />
          <Route element={<ProductDetails />} path="/productdetails" />
          <Route element={<ReviewCart />} path="/review-cart" />
          <Route element={<Login />} path="/login" />
          <Route element={<Checkout />} path="/checkout" />
          <Route element={<CheckoutReview />} path="/checkoutreview" />
          <Route element={<Payment />} path="/payment" />
          <Route element={<Thankyou />} path="/thankyou" />
          <Route element={<Registration />} path="/registration" />
          <Route element={<Thank />} path="/thank-you" />
          <Route element={<RequestVideoCall />} path="/request-video-call" />
          {/* <Route element={<CustomiseRequest />} path="/customise-request" /> */}
          <Route element={<ThankyouForTryAtHome />} path="/thankyou-for-tryathome" />
          <Route element={<BookingInformation />} path="/booking-information" />
          <Route element={<ScheduleYourAppointment />} path="/schedule-your-appointment" />
          <Route element={<VideoCallThankYou />} path="/video-call-thank-you" />
          <Route element={<MyAccount />} path="/myaccount" />
          <Route element={<OrdersAndReturns />} path="/orders-and-returns" />
          <Route element={<PaymentPolicy />} path="/payment-policy" />
          <Route element={<ShippingPolicy />} path="/shipping-policy" />
          <Route element={<TermsAndConditions />} path="/terms-and-conditions" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<CookiePolicy />} path="/cookie-policy" />
          <Route element={<Faqs />} path="/faqs" />
          <Route element={<CustomerSays />} path="/customer-says" />
          <Route element={<CustomerService />} path="/customer-service" />
          <Route element={<AboutSummera />} path="/about-summera" />
          <Route element={<FindStore />} path="/find-store" />
          <Route element={<WhatsNew />} path="/whats-new" />
          <Route element={<Gold />} path="/:slug" />
          <Route element={<Rings />} path="/gold/rings" />
          <Route element={<GoldEngagement />} path="/gold/rings/engagement" />
          <Route element={<GoldEngagementProductDetails />} path="/gold/product-details" />
          {/* <Route element={<Diamond />} path="/diamond" /> */}
          <Route element={<DiamondRings />} path="/diamond/rings" />
          <Route element={<DiamondEngagement />} path="/diamond/rings/engagement" />
          <Route element={<DiamondEngagementProductDetails />} path="/diamond/rings/engagement/product-details" />
          <Route element={<Gemstones />} path="/jadau" />
          <Route element={<GemstonesRings />} path="/jadau/rings" />
          <Route element={<GemstonesEngagement />} path="/jadau/rings/engagement" />
          <Route element={<GemstonesEngagementProductDetails />} path="/jadau/rings/engagement/product-details" />
          <Route element={<Platinum />} path="/platinum" />
          <Route element={<PlatinumRings />} path="/platinum/rings" />
          <Route element={<PlatinumEngagement />} path="/platinum/rings/engagement" />
          <Route element={<PlatinumEngagementProductDetails />} path="/platinum/rings/engagement/product-details" />
          <Route element={<Silver />} path="/silver" />
          <Route element={<SilverRings />} path="/silver/rings" />
          <Route element={<SilverEngagement />} path="/silver/rings/engagement" />
          <Route element={<SilverEngagementProductDetails />} path="/silver/rings/engagement/product-details" />
          <Route element={<Solitaire />} path="/solitaire" />
          <Route element={<SolitaireRings />} path="/solitaire/rings" />
          <Route element={<SolitaireEngagement />} path="/solitaire/rings/engagement" />
          <Route element={<SolitaireEngagementProductDetails />} path="/solitaire/rings/engagement/product-details" />
          <Route element={<CoinsBar />} path="/coins-bar" />
          <Route element={<GoldCoins />} path="/coins-bar/gold-coins-bar" />
          <Route element={<SilverCoins />} path="/coins-bar/silver-coins-bar" />
          <Route element={<SellOnSummera />} path="/sell-on-summera" />
          <Route element={<SubscriptionPlan />} path="/sell-on-summera/subscription-plan" />
          <Route element={<SellOnSummeraReview />} path="/sell-on-summera/review" />
          <Route element={<SubscriptionThanks />} path="/sell-on-summera/thankyou" />
          <Route element={<Customised />} path="/customised" />
          <Route element={<TryatHome />} path="/try-at-home" />
          <Route element={<TryatHomeDetails />} path="/try-at-home-details" />
          <Route element={<SellOnSummeraThankYou />} path="/SellOnSummera/thank-you" />
          <Route element={<CustomisedRequestThankYou />} path="/customise-request/thank-you" />
          <Route element={<JewelleryCareGuide />} path="/jewellery-care-guide" />
          <Route element={<GemstoneCareGuide />} path="/jewellery-care-guide/gemstone-care-guide" />
          <Route element={<DiamondCareGuide />} path="/jewellery-care-guide/diamond-care-guide" />
          <Route element={<CustomiseRequest />} path="/customise-request" />
          <Route element={<GoldProductDetails />} path="/coins-bar/gold-coins-bar/gold-coins" />
          <Route element={<SilverProductDetails />} path="/coins-bar/silver-coins-bar/silver-coins" />
          <Route element={<ShopByBrand />} path="/shop-by-brand" />
          <Route element={<Brand />} path="/shop-by-brand/jewels-box" />
          <Route element={<BrandGoldRings />} path="/shop-by-brand/jewels-box/gold" />
          <Route element={<BrandGoldRings />} path="/shop-by-brand/jewels-box/gold/rings" />
          <Route element={<BrandGoldProductDetails />} path="/shop-by-brand/jewels-box/gold/rings/gold-ring" />
          <Route element={<ShopForWomen />} path="/shop-for-women" />
          <Route element={<ShopForMen />} path="/shop-for-men" />
          <Route element={<ShopForKids />} path="/shop-for-kids" />
          <Route element={<WomenProductDetails />} path="/shop-for-women/product-details" />
          <Route element={<MenProductDetails />} path="/shop-for-men/product-details" />
          <Route element={<KidsProductDetails />} path="/shop-for-kids/product-details" />
          <Route element={<WeddingJewelry />} path="/wedding-jewelry" />
          <Route element={<WeddingJewelryDetails />} path="/wedding-jewelry/product-details" />
          <Route element={<ModernManglsutra />} path="/modern-manglsutra" />
          <Route element={<ModernManglsutraDetails />} path="/modern-manglsutra/product-details" />
          <Route element={<Gifts />} path="/gifts" />
          <Route element={<BuyACard />} path="/gifts/buy-a-card" />
          <Route element={<GiftsReview />} path="/gifts/buy-a-card/review" />
          <Route element={<GiftsBuyCardThankYou />} path="/gifts/buy-a-card/thank-you" />
          <Route element={<GiftBuyCardThankYou />} path="/gifts/gift-a-card/thank-you" />
          <Route element={<BuyACardCustomised />} path="/gifts/buy-a-card-customised" />
          <Route element={<VideoCallThankYou />} path="/gifts/thank-you" />
          <Route element={<GiftACard />} path="/gifts/gift-a-card" />
          <Route element={<GiftReview />} path="/gifts/gift-a-card/review" />
          <Route element={<CustomiseGiftsReview />} path="/gifts/buy-a-card-customised/review" />
          <Route element={<CustomiseGiftsThankYou />} path="/gifts/buy-a-card-customised/thank-you" />
          <Route element={<EngagementRings />} path="/engagement-rings" />
          <Route element={<EngagementRingsDetails />} path="/engagement-rings/product-details" />
          <Route element={<EverydayWearBracelets />} path="/everyday-wear-bracelets" />
          <Route element={<EverydayWearBraceletsDetails />} path="/everyday-wear-bracelets/product-details" />
          <Route element={<GorgeousStuds />} path="/gorgeous-studs" />
          <Route element={<GorgeousStudsDetails />} path="/gorgeous-studs/product-details" />
          <Route element={<ShopByNewArrivals />} path="/shop-by-new-arrivals" />
          <Route element={<ShopByNewArrivalsDetails />} path="/shop-by-new-arrivals/product-details" />

        </Routes>
      
        <Footer />

    </React.Fragment>
  )
}

export default Router;