import React from 'react'
import { Link } from 'react-router-dom';

export default function collections(props) {
    // console.log(props);
    return (
        <div className='collection-list'>
            <Link to={'/shop-by-brand/jewels-box'}>
                <figure>
                    <img src={props.imgsrc} alt='collection' title='collection'/>
                </figure>
            </Link>
         </div>
    )
  }
  