import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import moment from "moment/moment";
// import DatePicker from 'react-datepicker';
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import Input from "../../atom/input/input";
import { AiFillCloseCircle } from "react-icons/ai";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, } from 'reactstrap';
// import { FaTags } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
// import { IoInformationCircle } from "react-icons/io5";
import "react-datepicker/dist/react-datepicker.css";
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import Reactselect from '../../../components/molecules/select';
import MyAccData from './myAccData';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// import images 
import earringImg from '../../../assets/images/myaccount/earringImg.jpg';
// import OrderPlaced from '../../../assets/images/icons/order-placed.svg';
// import ProDelivered from '../../../assets/images/icons/pro-delivered.svg';
// import Shipment from '../../../assets/images/icons/ship.svg';
// import status_icon from '../../../assets/images/myaccount/icons/status_info.svg';
import status from '../../../assets/images/myaccount/icons/statusinfonew.svg';
// import rupeesIcon from '../../../assets/images/icons/rupeesIcon.svg';
// import orderempty from '../../../assets/images/myaccount/tryathome.png';

// import PdfDownload from '../../../assets/images/myaccount/download-pdf.svg';
// import Empty from "../../atom/empty";
import './index.css';

// let arr = []

const TryAtHome = () => {
    const { shippingData } = useSelector((state) => state.address);
    // const [ToggleState, setToggleState] = useState(1);
    // const [orderDetails, setOrderDetails] = useState("null");

    // const toggleTab = (index) => {
    //     setToggleState(index);
    // };

    const handleOrderDetails = (value) => {
        // setCancelModal(true)
        // setOpenModal(value)
    }

    // const handlingBack = () => {
    //     setCancelModal(false)
    //     setOpenModal("")
    // }

    return (
        <>
            <div className="myOrderTab">
                <h5>Try At Home</h5>
                {
                    // arr.length ? <>

                    <div className="content-container">
                        <div className="active-content">
                            <FilterMonth />
                            <AccordionSec shippingData={shippingData} handleOrderDetails={handleOrderDetails} />
                        </div>
                    </div>
                    // </> : <Empty imgsrc={orderempty} content={"No Try At Home Found Yet"} /> 
                }
                
            </div> 
        </>
    );
};

export default TryAtHome;

// Last month drop down section 
export const FilterMonth = () => {

    const sortData = [
        { id: 1, src:earringImg, label: "Past 3 Month", value: "past3month" },
        { id: 2, src:earringImg, label: "Past 6 Month", value: "past6month" },
        { id: 3, src:earringImg, label: "Past 9 Month", value: "past9month" },
        { id: 4, src:earringImg, label: "Past 12 Month", value: "past12month" },
    ]

    const [selectMonth, setselectMonth] = useState(sortData[3].value);
    const handleStateChange = (name, value) => {
        setselectMonth(value);
    }
    const handleError = () => { }


    return (
        <div className="filterMonth">
            <Reactselect
                name={"selectMonth"}
                id={"selectMonth"}
                label={"Showing <span>3 orders</span> in"}
                options={sortData}
                value={selectMonth}
                lblClass={"input-lable"}
                onChange={handleStateChange}
                onBlur={handleError}
                class={"form-control"}
            />
        </div>
    )
}

export const AccordionSec = ({ shippingData, handleOrderDetails }) => {
    const [open, setOpen] = useState('1');
    // const [productreview, setproductreview] = useState();
    // accordion function 
    const toggle = (i) => {
        if (open === i) {
            setOpen();
        } else {
            setOpen(i);
        }
    };

    // const productreviewtoggle = () => {
    //     setproductreview(!productreview);
    // }
  
    const tryAtHomeProducts = [
        { id:1, src:earringImg, name: "Diamond Ear Ring", details: "18 CT (3.400 g)", qty: "1", price: "99,999", appointment_status: "confirm"},
        { id:2, src:earringImg, name: "Diamond Ear Ring", details: "18 CT (3.400 g)", qty: "1", price: "99,999", appointment_status: "confirm"},
        { id:3, src:earringImg, name: "Diamond Ear Ring", details: "18 cT (3.400 g)", qty: "1", price: "99,999", appointment_status: "confirm"}
    ];

    return (
        <>
            {
                MyAccData.map((items, index) => {
                    return (
                        <div className="myacc_accordion tryathome_acc">
                            <Accordion open={open} toggle={toggle}>
                                <AccordionItem>
                                    <AccordionHeader targetId={index.toString()}>
                                        <div className="accorHeading table-responsive">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Appointment Placed</th>
                                                        <th>Appointment Date and Time</th>
                                                        <th>Total Items</th>
                                                        <th>Appointment Address</th>
                                                        <th colSpan={5}>Appointment  No.: <b>{items.appointment_no}</b></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><span>{items.appointment_placed}</span></td>
                                                        <td><span>{items.appointment_date_time}</span></td>
                                                        <td><span>{items.total_items}</span></td>
                                                        <td><span>{items.appointment_address}<Tooltip><img src={status} alt="" title="" /></Tooltip></span></td>
                                                        <td>
                                                            {items.appointment_status === "confirm" ?
                                                                <ul>
                                                                    <li><button onClick={() => handleOrderDetails("OrderDetails")}>Cancel Appointment</button></li>
                                                                </ul>
                                                                :
                                                                null
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionHeader>

                                    <AccordionBody accordionId={index.toString()}>
                                        <div className="tableContent table-responsive">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={10}>Item(s)</th>
                                                        <th>Qty.</th>
                                                        <th>Price</th>
                                                        <th>Appointment Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <>
                                                        {
                                                            tryAtHomeProducts.map((itemm,index)=>{
                                                                return(
                                                                    <tr key={index}>
                                                                        <td colSpan={10}>
                                                                            <ul>
                                                                                <li>
                                                                                    <img src={itemm.src} alt={itemm.name} title={itemm.name} />
                                                                                </li>
                                                                                <li>
                                                                                    <h6>{itemm.name}</h6>
                                                                                    <p>{itemm.details}</p>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                        <td><span>{itemm.qty}</span></td>
                                                                        <td><span><BiRupee />{itemm.price}</span></td>
                                                                        <td><span className="modeDelivered">{itemm.appointment_status}</span></td>
                                                                        <td>
                                                                            <button type="button"><span>Cancel Item</span></button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    )
                })
            }
            

        </>
    )
}

//Order details compounent
// export const OrderDetails = (props) => {
//     return (
//         <div className="orderDetailSec">
//             <h5>Order Details</h5>
//             <button type="button" onClick={props.handlingBack} className="backBtnToTab">Back</button>
//             <div className="orderlabel">
//                 <table>
//                     <thead>
//                         <tr>
//                             <th>Order Placed</th>
//                             <th>Total Amount</th>
//                             <th>Total Items</th>
//                             <th>Mode</th>
//                             <th colSpan={5}>Order No.: <b>MAZE202471002</b></th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td><span>02 Jan 2023</span></td>
//                             <td><span><BiRupee />15,400</span></td>
//                             <td><span>03</span></td>
//                             <td><span>Delivery <img src={status} alt="" title="" /></span></td>
//                             <td>
//                                 <ul>
//                                     <li><Link to={'#'}>Cancel Order</Link></li>
//                                 </ul>
//                             </td>
//                         </tr>

//                     </tbody>
//                 </table>
//             </div>

//             <div className="addresSec">
//                 <div className="shippingAdd">
//                     <h6>Shipping Address</h6>
//                     <caption>
//                         <p><b>Kaushal Ranpura</b></p>
//                         <p>501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400 045. India</p>
//                     </caption>
//                 </div>
//                 <div className="orderSummary">
//                     <div className="subSection">
//                         <h6>Order Summary</h6>
//                         <ul>
//                             <li><span>Sub Total</span> <span><BiRupee /><b>20,000</b></span></li>
//                             <li><span>Discount</span> <span><BiRupee />15,000</span></li>
//                             <li><span><b>APPLY PROMO</b></span> <span><BiRupee />-</span></li>
//                             <li><span>Delivery Charges</span> <span><BiRupee />-</span></li>
//                             <li><span>GST</span> <span><BiRupee />925</span></li>
//                             <li><span><b>Total Amount</b></span> <span><BiRupee /><b>19,425</b></span></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>

//             <div className="tableContent">
//                 <table>
//                     <thead>
//                         <tr>
//                             <th colSpan={15}>Item(s)</th>
//                             <th>Qty.</th>
//                             <th>Amount</th>
//                             <th>Discount</th>
//                             <th>GST</th>
//                             <th>Total</th>
//                             <th>Order Status</th>
//                             <th> </th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td colSpan={15}>
//                                 <ul>
//                                     <li><img src={acrylic_table2} alt="" title="" /></li>
//                                     <li>
//                                         <span className="tag-wrap"><span className="tags">LUXURY</span></span>
//                                         <p>Acrylic Centre Table - M-CT2</p>
//                                         <button type="button">Buy it again</button>
//                                     </li>
//                                 </ul>
//                             </td>
//                             <td><span>1</span></td>
//                             <td><span><BiRupee />4,600</span></td>
//                             <td><span><BiRupee />500</span></td>
//                             <td><span><BiRupee />205</span></td>
//                             <td><span><BiRupee />4,305</span></td>
//                             <td><span className="orderStatus">In Progress</span></td>
//                             <td>
//                                 <button type="button"><span>Cancel Item</span></button>
//                             </td>
//                         </tr>
//                         <tr>
//                             <td colSpan={15}>
//                                 <ul>
//                                     <li><img src={acrylic_table2} alt="" title="" /></li>
//                                     <li>
//                                         <span className="tag-wrap"><span className="tags">LUXURY</span></span>
//                                         <p>Acrylic Centre Table - M-CT2</p>
//                                         <button type="button">Buy it again</button>
//                                     </li>
//                                 </ul>
//                             </td>
//                             <td><span>1</span></td>
//                             <td><span><BiRupee />4,600</span></td>
//                             <td><span><BiRupee />500</span></td>
//                             <td><span><BiRupee />205</span></td>
//                             <td><span><BiRupee />4,305</span></td>
//                             <td><span className="orderStatus">In Progress</span></td>
//                             <td>
//                                 <button type="button"><span>Cancel Item</span></button>
//                             </td>
//                         </tr>
//                         <tr>
//                             <td colSpan={15}>
//                                 <ul>
//                                     <li><img src={acrylic_table2} alt="" title="" /></li>
//                                     <li>
//                                         <span className="tag-wrap"><span className="tags">LUXURY</span></span>
//                                         <p>Acrylic Centre Table - M-CT2</p>
//                                         <button type="button">Buy it again</button>
//                                     </li>
//                                 </ul>
//                             </td>
//                             <td><span>1</span></td>
//                             <td><span><BiRupee />4,600</span></td>
//                             <td><span><BiRupee />500</span></td>
//                             <td><span><BiRupee />205</span></td>
//                             <td><span><BiRupee />4,305</span></td>
//                             <td><span className="orderStatus">In Progress</span></td>
//                             <td>
//                                 <button type="button"><span>Cancel Item</span></button>
//                             </td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     )
// }

// export const UserDetails = ({ orderDetails }) => {
//     const { address, orderData, orderId, shippingTo } = orderDetails

//     return (
//         <div className="shippingDts">
//             <div className="secLeft">
//                 <table>
//                     <tbody>
//                         <tr>
//                             <td>Order No. :</td>
//                             <td>{orderId}</td>
//                         </tr>
//                         <tr>
//                             <td>Order Date :</td>
//                             <td>{moment(orderData).format('lll')}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//             <div className="secRight">
//                 <table>
//                     <tbody>
//                         <tr>
//                             <td>Shipping To :</td>
//                             <td>{shippingTo}</td>
//                         </tr>
//                         <tr>
//                             <td>Shipping Address :</td>
//                             <td>{`${address.address} ${address.optionalAddress} ${address.city} ${address.state} Pin No. ${address.pincode} `}</td>
//                         </tr>
//                         <tr>
//                             <td>Shipping Date :</td>
//                             <td>Sat, 17 Feb 2022</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     )
// }

export const TooltipContent = () => {
    return (
        <div className="tootltipSec">
            <div className="head"><h6>Submit Review</h6><AiFillCloseCircle /></div>
            <textarea name="w3review" rows="4" cols="50" />
            <button type="submit">Submit</button>
        </div>
    )
}

//Cancel Order compounent
// export const CancelOrder = (props) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     const [check, setCheck] = useState(false);
//     const [disableBtn, setdisableBtn] = useState(false);

//     const handelCheck = () => {
//         setCheck(!check);
//         setdisableBtn(!disableBtn);
//     }
//     return (
//         <div className="cancelOrder">
//             <h5>Cancel Order</h5>
//             <div className="backBtn"><button type="button" onClick={props.handlingBack}>Back</button></div>
//             <UserDetails orderDetails={props.orderDetails} />
//             <div className="cancelForm">
//                 <h3>Are You Sure You Want To Send A Cancellation Request? <br />
//                     <small>*Mandatory Fields</small></h3>
//                 <form>
//                     <ul>
//                         <li><label>SELECT REASON FOR ORDER CANCELLATION*</label>
//                             <select placeholder="Select">
//                                 <option>Select</option>
//                                 <option>Reason One</option>
//                                 <option>Reason Two</option>
//                                 <option>Reason Three</option>
//                                 <option>Reason Four</option>
//                             </select>
//                         </li>

//                         <li><label>YOUR COMMENTS</label>
//                             <textarea cols="15" rows="7"></textarea>
//                         </li>
//                         <li>
//                             <div className={`${check ? "checkBox backGroundNon" : "checkBox"}`} onClick={handelCheck} >
//                                 <div className="checkedBox"><AiOutlineCheck /></div>
//                             </div>
//                             <label>I agree to <Link to={'#'} onClick={handleShow}>Terms and Conditions</Link></label>
//                         </li>
//                     </ul>

//                     <button className={`${disableBtn ? null : "disableBtn"}`} type="submit" name="submit">Submit</button>
//                 </form>
//             </div>

//         </div>
//     )
// }

//tooltip info 
export const Tooltip = ({ index, children, text, ...rest }) => {
    const [show, setShow] = React.useState(false);
    return (
        <div className="tooltipWapper">
            <div className="tooltipInfo" style={show ? { display: "block" } : {}}>
                <h4>Kaushal Ranpura</h4>
                <p>501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400 045. India</p>
                <span className="tooltip-arrowInfo" />
            </div>
            <div
                {...rest}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                {children}
            </div>
        </div>
    )
}