import {React,useState,useEffect} from 'react'
import { Link,useNavigate } from 'react-router-dom';
import Checkbox from '../../components/atom/checkbox/input'
// import TextInput from  '../../components/molecules/input'
import {BiRupee} from "react-icons/bi";
import './index.css'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ImCross } from 'react-icons/im';

export default function CheckoutReview() {
    const navigate = useNavigate();
//   document.body.classList.add('checkout'); 
  const [checkbox,setCheckbox] = useState(true);
//   const [pin, setpin] = useState("");
//   const [errors, setErrors] = useState({
//     errfullName: "",
//     errEmail: "",
//     errMobileno: "",
//     errAdress:"",
//     errOptionalAddress:"",
//     errPincode:"",
//     errState:"",
//     errCity:"",
//     errCompanyName:"",
//     errCompanyPerson:"",
//     errPersonName:"",
//     errPartnerName:"",
//     errOtherField:"",
//     errpin: "",
//     errcompanyName:"",
// }) 
const handleCheckboxChange = () => {
    setCheckbox(!checkbox)
}
const handelFormSubmit = (e) => {
    e.preventDefault()
    navigate('/payment')
    
}

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const TermsAndConditions = () => (
    <Popup trigger={<Link>Terms and Conditions</Link>} modal aria-describedby={"pinHome"}>
      {close => (
        <div className='terms-popup'>
            <div className='pop-title'>
                <h2>Terms and Conditions</h2>
                <a className="close" onClick={close}><span><ImCross className="closebtn" /></span></a>
            </div>

            <div className='pop-body'>
                <div className='inner-div'>
                    <div className="para">
                        <h3 className="title">Lorem Ipsum is simply dummy</h3>
                        <ul>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                        </ul>
                    </div>

                    <div className="para">
                        <h3 className="title">Lorem Ipsum is simply dummy</h3>
                        <ul>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                        </ul>
                    </div>

                    <div className="para">
                        <h3 className="title">Lorem Ipsum is simply dummy</h3>
                        <ul>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply.Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the.</li>
                        </ul>
                    </div>     
                </div>  
            </div>
        </div>
      )}
      </Popup>
  );

  return (
    <div className='wrapper'>
        <div className="checkout-details">
            <div className="container">
                <h2>Review</h2>
                <div className="contact">
                    <div className='left'>
                        <div className='contact-details'>
                            <div className='review-add-details'>
                                <h4>Contact Details</h4>

                                <ul>
                                    <li>
                                        <h6>Email ID</h6>
                                        <p><Link to={'mailto:Kaushalranpura@gmail.com'}>Kaushalranpura@gmail.com</Link></p>
                                    </li>
                                    <li>
                                        <h6>Mobile</h6>
                                        <p><Link to={'tel:9865890245'}>91-9865890245</Link></p>
                                    </li>
                                </ul>

                                <h4>Shipping  Details</h4>
                                <address>
                                    <span>Kaushal Ranpura</span>
                                    501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400 045. India
                                </address>

                                <h4>Pickup Store Details</h4>
                                <address>
                                    Shop No. 10, Rizvi Mahal, R K Patkar Road - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.
                                </address>

                                <h4>Billing Address</h4>
                                <address>
                                    <span>Kaushal Ranpura</span>
                                    405 A Wing MK Plaza, Ghodbunder Rd, Anand Nagar, Thane West, Thane, Maharashtra 400615
                                </address>

                                <h4>GSTN Details <span>(Optional)</span></h4>

                                <ul>
                                    <li>
                                        <h6>Company Name</h6>
                                        <p>Sublimis Technologies</p>
                                    </li>
                                    <li>
                                        <h6>GSTN No.</h6>
                                        <p>07AAECR2971C1Z</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="make-info">
                            <div className="checkbox">
                                <Checkbox 
                                    autoComplete="off"
                                    id={"agree"}
                                    type={"checkbox"}
                                    name={"checkbox"}
                                    inputClass={"styled-checkbox"}
                                    onChange={handleCheckboxChange}
                                    value={checkbox}
                                    checked={true}
                                />
                                <label for="agree">I agree to {TermsAndConditions()} </label>
                            </div>
                            <button 
                                className="btn btn-primary" 
                                type="submit" 
                                onClick={handelFormSubmit}><span>Make Payment</span></button>
                        </div>
                    </div>
                    <div className="right">
                        <div className="_border_bottom checkout-summary">
                            <h4>Order Summary</h4>
                        </div>

                        <div className="summary-data">
                            <span className="close">&times;</span>

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6 className="cust-total">Sub Total</h6>
                                    <div className="price cust-total"><BiRupee />1,09,999</div>
                                </div>
                            </div>

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6>Discount</h6>
                                    <div className="price"><BiRupee />10,000</div>
                                </div>
                            </div>

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6>Saved with Promo</h6>
                                    <div className="price"><BiRupee />5,998</div>
                                </div>
                            </div>

                            {/* <div className="_border_bottom">
                                <div className="custom_flex custapplypromo">
                                    <Link to={''} className="applypromocode">Apply Promo</Link>
                                    <div className="price"><i className="fa fa-caret-right" aria-hidden="true"></i></div>
                                    <div className="down-arrow"><i className="fa fa-caret-down" aria-hidden="true"></i></div>
                                </div>
                                <div className="apply-promo">
                                    <TextInput
                                        type={"text"}
                                        name={"pin"}
                                        placeholder={"CODE"}
                                        inputClass={"code"}
                                        lblClass={"input-lable"}
                                        value={pin}
                                        autoComplete="off"
                                        errorMSg={errors.errfullName}
                                    />
                                    <Link to={''} className="btn btn-primary code-btn" onclick="validateCode()">Apply</Link>
                                    <div id="msg" className="msg"></div>
                                </div>
                                <div className="custom_flex save-promo">
                                    <div className="savepromocode">
                                        <Link to={''} className="withpromo">Saved with Promo</Link>
                                        <Link to={''} className="remove">Remove</Link>
                                        <div className="price price-promo"><BiRupee />5,999</div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6>Delivery Charges</h6>
                                    <div className="price">-</div>
                                </div>
                            </div>

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6>GST</h6>
                                    <div className="price"><BiRupee />5,999</div>
                                </div>
                            </div>

                            <div className="_border_bottom">
                                <div className="custom_flex">
                                    <h6 className="cust-total">Total Amount</h6>
                                    <div className="price cust-total"><BiRupee />1,05,998</div>
                                </div>
                            </div>
                        </div>

                        <div className="summary-cancle">
                            <Link className="btn btn-primary btn-cancle" title="Link to Cancel">Cancel</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
