import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

export default function index() {
    return (
        <section className="about">
            <figure></figure>
            <div className="container">
                <section>
                    <h2>About Summera</h2>
                    <p>
                        Summera Fine Diamond Jewelry was born out of a passion
                        for precious stones, driven by the vision to make them
                        attainable to all. We diligently source exquisite
                        gemstones and diamonds that meet international standards
                        of cut, color, and clarity. Keeping a close eye on
                        market trends, we craft inspiring collections that cater
                        to every taste. Our design process involves meticulous
                        hand-sketching to curate pieces that harmoniously
                        complement our collection narratives, while also
                        accommodating personalized custom design requests we
                        receive from our valued customers.
                    </p>
                    <div className="text-center">
                        <Link to={"/about-summera"} className="btn btn-outline">
                            <span>Read More about summera jewels</span>
                        </Link>
                    </div>
                </section>
            </div>
        </section>
    );
}
