import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Collections from './Collections'
import { Link } from "react-router-dom";

import collImg from '../../assets/images/collection-1.jpg';
import collImg2 from '../../assets/images/collection-2.jpg';
import collImg3 from '../../assets/images/collection-3.jpg';
import collImg4 from '../../assets/images/collection-1.jpg';
import collImg5 from '../../assets/images/collection-3.jpg';  
import './index.css'

export const ShopByCollections = () => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
    };

    return(
        <Slider {...settings}>
                
            <Collections
                imgsrc={collImg}
            />
        
            <Collections
                imgsrc={collImg2}
            />
        
            <Collections
                imgsrc={collImg3}
            />
        
            <Collections
                imgsrc={collImg4}
            />
        
            <Collections
                imgsrc={collImg5}
            />
        
            <Collections
                imgsrc={collImg2}
            />
                    
        </Slider>
    )
}