import {React,useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../../components/Card/card.css';
import PickTheRightMetal from '../../assets/images/jewelry-guide/pick-metal.jpg';
import DiamondSelection from '../../assets/images/jewelry-guide/diamond-selection.jpg';
import GemstoneSelection from '../../assets/images/jewelry-guide/gemstone-selection.jpg';
import StyleTip from '../../assets/images/icons/style-tip.png';
import Engagement from '../../assets/images/jewelry-guide/engagement.png';
import Fashion from '../../assets/images/jewelry-guide/fashion.png';
import Casual from '../../assets/images/jewelry-guide/casual.png';
import Cocktail from '../../assets/images/jewelry-guide/coctail.png';
import Bands from '../../assets/images/jewelry-guide/bands.png';
import Studs from '../../assets/images/jewelry-guide/studs.png';
import Jhumkas from '../../assets/images/jewelry-guide/jhumkas.png';
import Earcuffs from '../../assets/images/jewelry-guide/earcuffs.png';
import Chandbali from '../../assets/images/jewelry-guide/chandbali.png';
import Drops from '../../assets/images/jewelry-guide/drops.png';
import FlexibleBracelet from '../../assets/images/jewelry-guide/flexible-bracelet.png';
import ChainBracelet from '../../assets/images/jewelry-guide/chain-bracelet.png';
import MangalsutraBracelets from '../../assets/images/jewelry-guide/mangalsutra-bracelets.png';
import OvalBracelet from '../../assets/images/jewelry-guide/oval-bracelet.png';
import NazariaBracelet from '../../assets/images/jewelry-guide/nazaria-bracelet.png';
import JewelleryCare from '../../assets/images/jewellery-care.jpg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RingsData = [
    { id:1, src:Engagement, title:'Engagement'},
    { id:2, src:Fashion, title:'Fashion'},
    { id:3, src:Casual, title:'Casual'},
    { id:4, src:Cocktail, title:'Cocktail'},
    { id:5, src:Bands, title:'Bands'},
    { id:6, src:Fashion, title:'Fashion'},
    { id:7, src:Cocktail, title:'Cocktail'}
]

const EarringsData = [
    { id:1, src:Studs, title:'Studs'},
    { id:2, src:Jhumkas, title:'Jhumkas'},
    { id:3, src:Earcuffs, title:'Earcuffs'},
    { id:4, src:Chandbali, title:'Chandbali'},
    { id:5, src:Drops, title:'Drops'},
    { id:6, src:Jhumkas, title:'Jhumkas'},
    { id:7, src:Chandbali, title:'Chandbali'}
]

const BraceletsData = [
    { id:1, src:FlexibleBracelet, title:'Flexible Bracelet'},
    { id:2, src:ChainBracelet, title:'Chain Bracelet'},
    { id:3, src:MangalsutraBracelets, title:'Mangalsutra Bracelets'},
    { id:4, src:OvalBracelet, title:'Oval Bracelet'},
    { id:5, src:NazariaBracelet, title:'Nazaria Bracelet'},
    { id:6, src:ChainBracelet, title:'Chain Bracelet'},
    { id:7, src:OvalBracelet, title:'Oval Bracelet'}
]

const JewelleryCareGuide = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const rings = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
              }
            },
            {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
    }

    const earrings = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
              }
            },
            {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
    }

    const bracelets = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
              }
            },
            {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
    }

    return (
        <div className='wrapper jewellery-care-guide'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Jewellery Care Guide</h1>
                </div>
            </div>

            <div className="container"> 

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">Jewelry Care Guide</li>
                    </ol>
                </nav>
                    
                <div className='pick-the-right-metal'>    
                    <div className='container'>
                        <h2 className='mb-0'>Pick the Right Metal</h2>
                        <p>Jewelery care guide instead Preserve the Brilliance: Your Ultimate Jewelry Care Guide.</p>
                        <ul>
                            <li>
                                <div className='metal'>
                                    <h5>White Gold</h5>
                                    <p>White gold is an alloy of gold. This means that it is made up of pure yellow gold, mixed with alloy metals that have a silvery-white colour, such as palladium and silver. Its neutral colour and durability make it the perfect setting for all gemstones. It looks beautiful and classic, suiting all outfits and events.</p>
                                </div>
                                <div className='metal'>
                                    <h5>Rose Gold</h5>
                                    <p>Rose gold is a gorgeous blend of gold with a small percentage of copper which adds strength and durability. It’s basically gold with a hint of pretty and has been trending a lot recently.</p>
                                </div>
                            </li>
                            <li>
                                <img src={PickTheRightMetal} alt="Pick the Right Metal" title="" />
                            </li>
                            <li>
                                <div className='metal'>
                                    <h5>Yellow Gold</h5>
                                    <p>Yellow gold is the all natural version of gold. It’s our favourite choice for all things wedding! It’s super pleasing to the eye and complements most skin tones. Diamonds mounted on gold bands are what “happily ever after’s” are made up of.</p>
                                </div>
                                <div className='metal'>
                                    <h5>Platinum</h5>
                                    <p>Platinum is a sturdy white toned metal. It's an exquisite metal and rarer than gold! Do you know what’s great about Platinum? It’s low maintenance!</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='choose'>    
                    <div className='container'>
                        <h2>Choose Your Diamond or Gemstone</h2>

                        <ul className='selection'>
                            <li className='JewelryBanner'>
                                <figure>
                                    <img src={DiamondSelection} alt="Diamond Selection" title="Diamond Selection" />
                                    <figcaption>
                                        <h3>Diamond Selection</h3>
                                        <p>Lorem Ipsum is simply dummy text</p>
                                        <Link to={'/jewellery-care-guide/diamond-care-guide'} className="btn btn-outline"><span>Learn About Diamond</span></Link>
                                    </figcaption>
                                </figure>
                            </li>
                            <li className='JewelryBanner'>
                                <figure>
                                    <img src={GemstoneSelection} alt="Gemstone Selection" title="Gemstone Selection" />
                                    <figcaption>
                                        <h3>Gemstone Selection</h3> 
                                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                                        <Link to={'/jewellery-care-guide/gemstone-care-guide'} className="btn btn-outline"><span>Learn About Gemstones</span></Link>
                                    </figcaption>
                                </figure>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='sliders'>    
                    <div className='container'>
                        <h2>Rings</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a type and scrambled it to make a type specimen book.</p>

                        <div className='style-tip'>
                            <img src={StyleTip} alt='Summera Style Tip:'  />
                            <h6>Summera Style Tip:</h6>
                        </div>

                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</p>

                        <div className='slider-container'>
                            <Slider {...rings}>
                                {
                                    RingsData.map((item,index)=>{
                                        return(
                                            <div className='brand-img' key={index}>
                                                <figure>
                                                    <img src={item.src} alt={item.title} title={item.title} />
                                                    <h5>{item.title}</h5>
                                                </figure>
                                            </div>
                                        )
                                })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className='sliders'>    
                    <div className='container'>
                        <h2>Earrings</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a type and scrambled it to make a type specimen book.</p>

                        <div className='style-tip'>
                            <img src={StyleTip} alt='Summera Style Tip:'  />
                            <h6>Summera Style Tip:</h6>
                        </div>

                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</p>

                        <div className='slider-container'>
                            <Slider {...earrings}>
                                {
                                    EarringsData.map((item,index)=>{
                                        return(
                                            <div className='brand-img' key={index}>
                                                <figure>
                                                    <img src={item.src} alt={item.title} title={item.title} />
                                                    <h5>{item.title}</h5>
                                                </figure>
                                            </div>
                                        )
                                })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className='sliders'>    
                    <div className='container'>
                        <h2>Bracelets</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a type and scrambled it to make a type specimen book.</p>

                        <div className='style-tip'>
                            <img src={StyleTip} alt='Summera Style Tip:'  />
                            <h6>Summera Style Tip:</h6>
                        </div>

                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</p>

                        <div className='slider-container'>
                            <Slider {...bracelets}>
                                {
                                    BraceletsData.map((item,index)=>{
                                        return(
                                            <div className='brand-img' key={index}>
                                                <figure>
                                                    <img src={item.src} alt={item.title} title={item.title} />
                                                    <h5>{item.title}</h5>
                                                </figure>
                                            </div>
                                        )
                                })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>

            <div className='jewellery-care'>
                <div className='container'>
                    <h2>Jewellery Care</h2>
                    <ul className='outer-div'>
                        <li>
                            <ul>
                                <li>MHandle it with Love</li>
                                <li>Put on your jewellery after you’ve applied your cosmetics like creams and perfumes</li>
                                <li>Clean your jewellery with warm water, gentle soap and a soft brush</li>
                                <li>Remove it when you go swimming or while doing household chores</li>
                                <li>Get your jewellery to any of our CaratLane stores for a professional clean-up once every 6 months</li>
                                <li>Store it right to keep your jewellery in separate boxes to reduce the chances of scratches and tangles</li>
                                <li>Keep it away from moisture by storing it in a fabric-lined box</li>
                                <li>Wipe your pearl jewellery with a soft cloth before you store them after wearing, as even sweat can be damaging to pearls</li>
                            </ul>
                        </li>
                        <li>
                            <img src={JewelleryCare} alt='Jewellery Care' />
                        </li>
                    </ul>
                    </div>
            </div>
            
        </div>
    )
}


export default JewelleryCareGuide;