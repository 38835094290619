import React, { useState } from 'react';
import {Accordion,AccordionBody,AccordionHeader,AccordionItem,} from 'reactstrap';
import Data from './data'
import './index.css'
import { HiPlusSm } from "react-icons/hi";
import { HiMinusSm } from "react-icons/hi";
import { BiRupee } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import LightIcon from  '../../assets/images/icons/light-icon.png';
import ChemicalIcon from '../../assets/images/icons/chemical-icon.png'
import StoreIcon from '../../assets/images/icons/store-icon.png'

function GoldProductAccordion(props) {
  const [open, setOpen] = useState('0');
  const toggle = (i) => {
    if (open === i) {
      setOpen();
    } else {
      setOpen(i);
    }
  };

  return (
    <>
      <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">Product Details
        <span className='plusicon'><HiPlusSm /></span>
        <span className='minusicon'><HiMinusSm /></span>
        </AccordionHeader>
        <AccordionBody accordionId="1">
            <table>
                <tbody>
                    <tr>
                    <td width={100}>Width</td>
                    <td>: 6.3 mm</td>
                    </tr>
                    <tr>
                    <td>Height </td>
                    <td>: 10.4 mm</td>
                    </tr>
                    <tr>
                    <td>Purity </td>
                    <td>: 18 KT</td>
                    </tr>
                    <tr>
                    <td>Gross Weight </td>
                    <td>: 2 g</td>
                    </tr>
                    <tr>
                    <td>Size</td>
                    <td>: 12 (51.8 mm)</td>
                    </tr>
                </tbody>
          </table>

            <h6>Coins</h6>
                <table>
                    <tbody>
                        <tr>
                        <td width={100}>Coin Type</td>
                        <td>: IJ-SI</td>
                        </tr>
                        <tr>
                        <td>Setting Type</td>
                        <td>: Pave</td>
                        </tr>
                        <tr>
                        <td>Total Number </td>
                        <td>: 60</td>
                        </tr>
                        <tr>
                        <td>Total Weight </td>
                        <td>: 0.090 kt</td>
                        </tr>
                    </tbody>
                </table>
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="2">Price Breakup
        <span className='plusicon'><HiPlusSm /></span>
        <span className='minusicon'><HiMinusSm /></span>
        </AccordionHeader>
        <AccordionBody accordionId="2">
          <p className='imp-heading'>Gold</p>
            <table className='price-breakup'>
                <tbody>
                    <tr>
                    <th>Component</th>
                    <th>Rate <span>(INR)</span></th>
                    <th>Weight</th>
                    <th>Discount</th>
                    <th>Value <span>(INR)</span></th>
                    </tr>
                    <tr>
                    <td>18 KT Gold</td>
                    <td><BiRupee /> 4,482 / g</td>
                    <td>3.400 g</td>
                    <td>-</td>
                    <td><BiRupee /> 71,999</td>
                    </tr>
                    <tr className='paddbtm'>
                    <td className='imp-heading'>Making Charges</td>
                    <td><BiRupee /> 10,000</td>
                    <td>3.400 g</td>
                    <td>10%</td>
                    <td><BiRupee /> 10,000</td>
                    </tr>
                    <tr className='border-top'>
                    <td colSpan={4} className='imp-heading sub-total'>Sub Total</td>
                    <td className='imp-heading sub-total'><BiRupee /> 97,329</td>
                    </tr>
                    <tr>
                    <td colSpan={4} className='imp-heading'>GST</td>
                    <td className='imp-heading'><BiRupee /> 2,670</td>
                    </tr>
                    <tr>
                    <td colSpan={4} className='imp-heading'>Grand Total</td>
                    <td className='imp-heading'><BiRupee /> 99,999</td>
                    </tr>
                </tbody>
            </table>
        </AccordionBody>
      </AccordionItem>
      {/* <AccordionItem>
        <AccordionHeader targetId="3">Jewelry Care
          <span className='plusicon'><HiPlusSm /></span>
          <span className='minusicon'><HiMinusSm /></span>
        </AccordionHeader>
        <AccordionBody accordionId="3">
          <ul className='jewelry-list'>
            <li>
              <span className='icon'>
                <img src={LightIcon} alt='Light Icon' title='Light Icon' />
              </span>
              <span className='title'> Take care of light and heat</span>
              Prolonged exposure to light and heat can have a negative effect on your jewellery. 
            </li>
            <li>
              <span className='icon'>
                <img src={ChemicalIcon} alt='Chemical Icon' title='Chemical Icon' />
              </span>
              <span className='title'> Stay away from chemicals</span>
              Even daily chemicals such as hairspray, perfume, cosmetics and more can discolour precious metals, such as gold, silver and platinum. They can also damage other items such as pearls.
            </li>
            <li>
              <span className='icon'>
                <img src={StoreIcon} alt='Store Icon' title='Store Icon' />
              </span>
              <span className='title'> Store Them Carefully</span>
              Gold need to be protected not only against getting lost or being stolen, but also against damage. 
            </li>
          </ul>
        </AccordionBody>
      </AccordionItem> */}
      <AccordionItem>
        <AccordionHeader targetId="4">Review 
          <span className='plusicon'><HiPlusSm /></span>
          <span className='minusicon'><HiMinusSm /></span>
        </AccordionHeader>
        <AccordionBody accordionId="4">
          <div className='review-wrapper'>
            <div className="reviewStats">
              <div className="overall">
                  <h2>4</h2>
                  <span className="stars">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiOutlineStar />
                  </span>
                  <p>Overall rating of 2 reviews</p>
              </div>
              <div className="detailReviews">
                  <ul>
                      <li>
                          <span>5</span>
                          <span><AiFillStar /></span>
                          <span className="progress">
                              <span className="progress-bar" role="progressbar"></span>
                          </span>
                          <span className="count">0</span>
                      </li>
                      <li>
                          <span>4</span>
                          <span><AiFillStar /></span>
                          <span className="progress">
                              <span className="progress-bar" role="progressbar" style={{width:"100%"}}></span>
                          </span>
                          <span className="count">2</span>
                      </li>
                      <li>
                          <span>3</span>
                          <span><AiFillStar /></span>
                          <span className="progress">
                              <span className="progress-bar" role="progressbar"></span>
                          </span>
                          <span className="count">0</span>
                      </li>
                      <li>
                          <span>2</span>
                          <span><AiFillStar /></span>
                          <span className="progress">
                              <span className="progress-bar" role="progressbar"></span>
                          </span>
                          <span className="count">0</span>
                      </li>
                      <li>
                          <span>1</span>
                          <span><AiFillStar /></span>
                          <span className="progress">
                              <span className="progress-bar" role="progressbar"></span>
                          </span>
                          <span className="count">0</span>
                      </li>
                      
                  </ul>
              </div>
            </div>
            <ul className='review-list'>
                <li>
                    <span className="stars">
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                    </span>
                    <strong>Title of reivew</strong>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                    <div className="reviewerDls">
                        <span>Lorem Ipsum</span>
                        <span className="date">02 Jan 2023</span>
                    </div>
                </li>
                <li>
                  <span className="stars">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  </span>
                  <strong>Title of reivew</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <div className="reviewerDls">
                      <span>Lorem Ipsum</span>
                      <span className="date">02 Jan 2023</span>
                  </div>
              </li>
              <li>
                  <span className="stars">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  </span>
                  <strong>Title of reivew</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <div className="reviewerDls">
                      <span>Lorem Ipsum</span>
                      <span className="date">02 Jan 2023</span>
                  </div>
              </li>
              <li>
                  <span className="stars">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  </span>
                  <strong>Title of reivew</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <div className="reviewerDls">
                      <span>Lorem Ipsum</span>
                      <span className="date">02 Jan 2023</span>
                  </div>
              </li>
              <li>
                  <span className="stars">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  </span>
                  <strong>Title of reivew</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <div className="reviewerDls">
                      <span>Lorem Ipsum</span>
                      <span className="date">02 Jan 2023</span>
                  </div>
              </li>
            </ul>
          </div>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
    </>
  );
}

export default GoldProductAccordion;