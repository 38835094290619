import React from 'react'

export default function Testimonial(props) {
  return (
    <div className="item">
        <div>
            <p className="content">{props.content} </p>
            <div className='img-wrapper'>
                <figure>
                    <img src={props.imgsrc} alt="client-img" title="client-img" />
                </figure>
                <figcaption>
                    <p className="title">{props.title}</p>
                    <p className='client-location'>{props.location}</p>
                </figcaption>
            </div>
        </div>
    </div>
  )
}
