import SliderImg from '../../../assets/images/01-Homepage-Banner.jpg'
import SliderImg2 from '../../../assets/images/02-Homepage-Banner.jpg'
import SliderImg3 from '../../../assets/images/03-Homepage-Banner.jpg'
import SliderImg4 from '../../../assets/images/04-Homepage-Banner.jpg'
import SliderImg5 from '../../../assets/images/05-Homepage-Banner.jpg'

const images = [{
    id: 1,
    src: SliderImg,
    alt: "Be Precious Be you",
    title:"",
    subtitle:"",
    btn:""
},
{
    id: 2,
    src: SliderImg2,
    alt: "Get your sparkle one",
    title:"",
    subtitle:"",
    btn:""
},
{
    id: 3,
    src: SliderImg3,
    alt: "Elegance you can wear",
    title:"",
    subtitle:"",
    btn:""
},
{
    id: 4,
    src: SliderImg4,
    alt: "Leave a little sparkle whenever you go",
    title:"",
    subtitle:"",
    btn:""
},
{
    id: 5,
    src: SliderImg5,
    alt: "Feel like a diamond its wedding session fall in love",
    title:"",
    subtitle:"",
    btn:""
}
];
export default images;