import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FiHeart } from 'react-icons/fi';
import { BiRupee } from 'react-icons/bi';
import '../../components/Card/card.css';

import ring1 from '../../assets/images/products/rings/ring1.png';
import ring2 from '../../assets/images/products/rings/ring2.png';
import ring3 from '../../assets/images/products/rings/ring3.png';
import ring4 from '../../assets/images/products/rings/ring4.png';
import diamond1 from '../../assets/images/products/diamonds/diamond1.png';
import diamond2 from '../../assets/images/products/diamonds/diamond2.png';
import solitaire1 from '../../assets/images/products/solitaire/solitaire1.png';
import solitaire2 from '../../assets/images/products/solitaire/solitaire2.png';
import solitaire3 from '../../assets/images/products/solitaire/solitaire3.png';
import solitaire4 from '../../assets/images/products/solitaire/solitaire4.png';
import gemstones1 from '../../assets/images/products/gemstone/gemstone1.png';
import gemstones2 from '../../assets/images/products/gemstone/gemstone2.png';
import gemstones3 from '../../assets/images/products/gemstone/gemstone3.png';
import platinum1 from '../../assets/images/products/platinum/platinum.png';
import silver1 from '../../assets/images/products/silver/silver.png';
import coins1 from '../../assets/images/products/coins/coin1.png';
import coins2 from '../../assets/images/products/coins/coin2.png';
import coins3 from '../../assets/images/products/coins/coin3.png';

const WhatsNew = () => {

    const [active, setActive] = useState("gold")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleClick = event => {
        event.currentTarget.classList.toggle('active');
    };

    const handleSmoothScroll = (value) => {
        setActive(value)
        const element = document.getElementById(value);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      

      const jewelry =[
        {id:1, type:'Gold',value:'gold'},
        {id:2, type:'Diamond',value:'diamond'},
        {id:3, type:'Solitaire',value:'solitaire'},
        {id:4, type:'Gemstones',value:'gemstones'},
        {id:5, type:'Platinum',value:'platinum'},
        {id:6, type:'Silver',value:'silver'},
        {id:7, type:'Coins / Bar',value:'coins'}
    ]

      const gold = [
        {id:1, imgsrc: ring1, pname:'Gold Ring', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:2, imgsrc: ring2, pname:'Gold Ring', price: "1000", offerPrice:"47,551", category:"Trending", discountOffer:"5" },
        {id:3, imgsrc: ring3, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:4, imgsrc: ring4, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:5, imgsrc: ring1, pname:'Gold Ring', price: "5440", offerPrice:"", category:"New", discountOffer:"" },
        {id:6, imgsrc: ring2, pname:'Gold Ring', price: "5440", offerPrice:"", category:"Trending", discountOffer:"" }
      ]   

      const diamond = [
        {id:1, imgsrc: diamond1, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:2, imgsrc: diamond2, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" }
      ]

      const solitaire = [
        {id:1, imgsrc: solitaire1, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:2, imgsrc: solitaire2, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:3, imgsrc: solitaire3, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:4, imgsrc: solitaire4, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" }
      ]

      const gemstones = [
        {id:1, imgsrc: gemstones1, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:2, imgsrc: gemstones2, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:3, imgsrc: gemstones3, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" }
      ]

      const platinum = [
        {id:1, imgsrc: platinum1, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"}
      ]

      const silver = [
        {id:1, imgsrc: silver1, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"}
      ]

      const coins = [
        {id:1, imgsrc: coins1, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "78,999", offerPrice:"77,999", category:"New", discountOffer:"10"},
        {id:2, imgsrc: coins2, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" },
        {id:3, imgsrc: coins3, pname:'Earrings Lorem Ipsum dummy text dummy text', price: "1000", offerPrice:"", category:"Trending", discountOffer:"" }
      ]

    return (
        <div className='wrapper whats-new'>

            <div className='banner-div'>
                <div className='container' id='gold'>
                    <h1>What's New</h1>
                </div>
            </div>

            <div className="container"> 

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">What's New</li>
                    </ol>
                </nav>
                    
                <section className='filter'>    
                    <div className='product-listing'>
                        <div className='left-column'>
                            <ul className='sideNav'>
                                {
                                    jewelry.map((item, index)=>{
                                    return(
                                        <li key={index} onClick={() => handleSmoothScroll(item.value)} className={`${item.value === active ? "active" : "" }`}>
                                            <h2>{item.type}</h2>
                                        </li>
                                    )
                                    })
                                } 
                            </ul>
                        </div>
                        <div className='right-column'>

                            <div>
                                <div className='title-sort-option'>
                                    <h3 className='title'>Gold</h3>
                                    
                                </div>
                                <div className='product-wrapper'>

                                {
                                    gold.map((item, index) => {
                                    
                                        return (
                                            <div className='card' key={index}>
                                                <div className='card-header'>
                                                    <Link to={'/productdetails'}>
                                                        <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                                        <h1>{item.index}</h1>
                                                    </Link>
                                                </div>
                                                <div className='product-label'>
                                                    <ul>
                                                        <li className=
                                                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                                        </li>
                                                    </ul>
                                                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                    </span>
                                                </div>
                                                <div className='card-body'>
                                                    <p>{item.pname}</p>
                                                    <div className='price'><span><BiRupee />{item.price}</span>
                                                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                                    </div>
                                                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                                </div>
                                            </div>
                                        )

                                    }
                                    )
                                }

                                <div id='diamond'></div>

                                </div>
                            </div>

                            <div >
                                <div className='title-sort-option'>
                                    <h3 className='title'>Diamond</h3>
                                    
                                </div>
                                <div className='product-wrapper'>

                                {
                                    diamond.map((item, index) => {
                                    
                                        return (
                                            <div className='card' key={index}>
                                                <div className='card-header'>
                                                    <Link to={'/productdetails'}>
                                                        <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                                        <h1>{item.index}</h1>
                                                    </Link>
                                                </div>
                                                <div className='product-label'>
                                                    <ul>
                                                        <li className=
                                                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                                        </li>
                                                    </ul>
                                                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                    </span>
                                                </div>
                                                <div className='card-body'>
                                                    <p>{item.pname}</p>
                                                    <div className='price'><span><BiRupee />{item.price}</span>
                                                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                                    </div>
                                                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                                </div>
                                            </div>
                                        )

                                    }
                                    )
                                }

                                    <div id='solitaire'></div>
                                </div>
                            </div>

                            <div >
                                <div className='title-sort-option'>
                                    <h3 className='title'>Solitaire</h3>
                                    
                                </div>
                                <div className='product-wrapper'>

                                {
                                    solitaire.map((item, index) => {
                                    
                                        return (
                                            <div className='card' key={index}>
                                                <div className='card-header'>
                                                    <Link to={'/productdetails'}>
                                                        <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                                        <h1>{item.index}</h1>
                                                    </Link>
                                                </div>
                                                <div className='product-label'>
                                                    <ul>
                                                        <li className=
                                                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                                        </li>
                                                    </ul>
                                                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                    </span>
                                                </div>
                                                <div className='card-body'>
                                                    <p>{item.pname}</p>
                                                    <div className='price'><span><BiRupee />{item.price}</span>
                                                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                                    </div>
                                                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                                </div>
                                            </div>
                                        )

                                    }
                                    )
                                }

                                    <div id='gemstones'></div>

                                </div>
                            </div>

                            <div>
                                <div className='title-sort-option'>
                                    <h3 className='title'>Gemstones</h3>
                                    
                                </div>
                                <div className='product-wrapper'>

                                {
                                    gemstones.map((item, index) => {
                                    
                                        return (
                                            <div className='card' key={index}>
                                                <div className='card-header'>
                                                    <Link to={'/productdetails'}>
                                                        <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                                        <h1>{item.index}</h1>
                                                    </Link>
                                                </div>
                                                <div className='product-label'>
                                                    <ul>
                                                        <li className=
                                                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                                        </li>
                                                    </ul>
                                                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                    </span>
                                                </div>
                                                <div className='card-body'>
                                                    <p>{item.pname}</p>
                                                    <div className='price'><span><BiRupee />{item.price}</span>
                                                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                                    </div>
                                                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                                </div>
                                            </div>
                                        )

                                    }
                                    )
                                }

                                    <div id='platinum'></div>
                                </div>
                            </div>

                            <div>
                                <div className='title-sort-option'>
                                    <h3 className='title'>Platinum</h3>
                                    
                                </div>
                                <div className='product-wrapper'>

                                {
                                    platinum.map((item, index) => {
                                    
                                        return (
                                            <div className='card' key={index}>
                                                <div className='card-header'>
                                                    <Link to={'/productdetails'}>
                                                        <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                                        <h1>{item.index}</h1>
                                                    </Link>
                                                </div>
                                                <div className='product-label'>
                                                    <ul>
                                                        <li className=
                                                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                                        </li>
                                                    </ul>
                                                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                    </span>
                                                </div>
                                                <div className='card-body'>
                                                    <p>{item.pname}</p>
                                                    <div className='price'><span><BiRupee />{item.price}</span>
                                                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                                    </div>
                                                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                                </div>
                                            </div>
                                        )

                                    }
                                    )
                                }

                                    <div id='silver'></div>                                  

                                </div>
                            </div>

                            <div>
                                <div className='title-sort-option'>
                                    <h3 className='title'>Silver</h3>
                                    
                                </div>
                                <div className='product-wrapper'>

                                {
                                    silver.map((item, index) => {
                                    
                                        return (
                                            <div className='card' key={index}>
                                                <div className='card-header'>
                                                    <Link to={'/productdetails'}>
                                                        <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                                        <h1>{item.index}</h1>
                                                    </Link>
                                                </div>
                                                <div className='product-label'>
                                                    <ul>
                                                        <li className=
                                                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                                        </li>
                                                    </ul>
                                                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                    </span>
                                                </div>
                                                <div className='card-body'>
                                                    <p>{item.pname}</p>
                                                    <div className='price'><span><BiRupee />{item.price}</span>
                                                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                                    </div>
                                                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                                </div>
                                            </div>
                                        )

                                    }
                                    )
                                }

                                    <div id='coins'></div>
                                </div>
                            </div>

                            <div>
                                <div className='title-sort-option'>
                                    <h3 className='title'>Coins / Bar</h3>
                                    
                                </div>
                                <div className='product-wrapper'>

                                {
                                    coins.map((item, index) => {
                                    
                                        return (
                                            <div className='card' key={index}>
                                                <div className='card-header'>
                                                    <Link to={'/productdetails'}>
                                                        <img src={item.imgsrc} className='card-img-top' title={item.pname} alt={item.pname} />
                                                        <h1>{item.index}</h1>
                                                    </Link>
                                                </div>
                                                <div className='product-label'>
                                                    <ul>
                                                        <li className=
                                                            {`${item.category === "Trending"  ? "trending"  :  "cart-label"}`}>{item.category}
                                                        </li>
                                                    </ul>
                                                    <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                                        <FiHeart js-heart="true" onClick={handleClick} />
                                                    </span>
                                                </div>
                                                <div className='card-body'>
                                                    <p>{item.pname}</p>
                                                    <div className='price'><span><BiRupee />{item.price}</span>
                                                        {item.discountOffer ? <s><small><BiRupee />{item.offerPrice}</small></s> : "" }
                                                    </div>
                                                    { item.discountOffer ? <h5 className='offer'><strong>{`${item.discountOffer}% off on making charges`}</strong> </h5> : "" }
                                                </div>
                                            </div>
                                        )

                                    }
                                    )
                                }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}


export default WhatsNew;