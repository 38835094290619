import React, { useState } from "react";
// import { useRoutes } from "react-router-dom";
import Tabs from 'react-responsive-tabs';
// import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import MyOrderSec from './myOrderTab';
import { Link } from "react-router-dom";

// icons and Images imports 
import Userimg from '../../../assets/images/myaccount/user-img.jpg';
import tab1 from '../../../assets/images/myaccount/icons/tab-1.svg';
import tab2 from '../../../assets/images/myaccount/icons/tab-2.svg';
import tab3 from '../../../assets/images/myaccount/icons/tab-3.svg';
import tab4 from '../../../assets/images/myaccount/icons/tab-4.svg';
import tab5 from '../../../assets/images/myaccount/icons/tab-5.svg';
import tab6 from '../../../assets/images/myaccount/icons/tab-6.svg';
import tab7 from '../../../assets/images/myaccount/icons/tab-7.svg';
import tab8 from '../../../assets/images/myaccount/icons/tab-8.svg';
import tab9 from '../../../assets/images/myaccount/icons/tab-9.svg';

import MyWishlist from '../../molecules/MyWishlist';
import MyCustomiseOrder from '../../molecules/MyCustomiseOrder';
import TryAtHome from '../../molecules/TryAtHome';
import VideoCall from '../../molecules/VideoCall';
import MyProfile from '../../molecules/MyProfile';
import AddressForm from '../../molecules/addressForm';
import GiftCard from '../../molecules/GiftCard';
import MySettings from '../../molecules/MySettings';
import 'react-responsive-tabs/styles.css';
import './myAccount.css';


const MyAccountPage = () => {
    // const router = useRoutes()
    // const url = router.query

    const [image, setImage] = useState(Userimg);

    const accountTabs = [
        { name: <><div><img src={tab1} alt="My Orders" title="My Orders" /><span>My Orders</span></div></>, biography: <MyOrderSec /> },
        { name: <><div><img src={tab2} alt="My Wishlist" title="My Wishlist" /><span>My Wishlist</span></div></>, biography: <MyWishlist /> },
        { name: <><div><img src={tab3} alt="My Customise Order" title="My Customise Order" /><span>My Customise Order</span></div></>, biography: <MyCustomiseOrder />},
        { name: <><div><img src={tab4} alt="Try At Home" title="Try At Home" /><span>Try At Home</span></div></>, biography: <TryAtHome />},
        { name: <><div><img src={tab5} alt="Video Call" title="Video Call" /><span>Video Call</span></div></>, biography: <VideoCall />},
        { name: <><div><img src={tab6} alt="My Profile" title="My Profile" /><span>My Profile</span></div></>, biography: <MyProfile /> },
        { name: <><div><img src={tab7} alt="My Address" title="My Address" /><span>My Address</span></div></>, biography: <AddressForm /> },
        { name: <><div><img src={tab8} alt="Gift Card" title="Gift Card" /><span>Gift Card</span></div></>, biography: <GiftCard /> },
        { name: <><div><img src={tab9} alt="My Settings" title="My Settings" /><span>My Settings</span></div></>, biography: <MySettings /> }
    ];

    const getTabs = () => {
        return accountTabs.map((accountTab, index) => ({
            title: accountTab.name,
            getContent: () => accountTab.biography,
            /* Optional parameters */
            key: index,
            tabClassName: 'tab',
            panelClassName: 'panel',
        }));
    }


    const handleImageChange = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    const handleImageDelete = () => {
        setImage(Userimg);
    };

    return (
        <div className="container">
            <div className="mainWapper">
                <div className='breadcrumbs'>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item active">My Account</li>
                    </ol>
                </div>

                <div className="userdetails">
                    <div className="uploadImg">
                        <img src={image} width={100} height={100} alt="Upload" title="Upload" />
                        <input className="editImg" type="file" onChange={handleImageChange} />
                        <div className="dltImg" onClick={handleImageDelete}></div>
                    </div>
                    <div className="userName">Hi, Kaushal Ranpura 
                        <span>Last logged on : 31 May 2022, 10:57am</span>
                    </div>
                </div>
                <Tabs items={getTabs()} />
            </div>
        </div>
    )
}

export default MyAccountPage;
