import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import TextInput from  '../../components/molecules/input'
import Reactselect from "../../components/molecules/select";
import { numberText } from "../../helper/method";
import { FiRefreshCw } from "react-icons/fi";
import '../../components/Checkout/index.css'
import '../../components/molecules/VideoCall/videoCall.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { FaSearch } from 'react-icons/fa';

const stateData = [
    {id: 1, value: "maharashtra", label: "Maharashtra"},
    {id: 2, value: "gujarat", label: "Gujarat"},
    {id: 3, value: "himanchal", label: "Himanchal"},
    {id: 4, value: "utterprdesh", label: "UP"},
]

const cityData = [
    {id: 1, value: "thane", label: "Thane"},
    {id: 2, value: "vapi", label: "Vapi"},
    {id: 3, value: "sodhi", label: "Sodhi"},
    {id: 4, value: "deoria", label: "Deoria"},
]

const placeData = [
    {id: 1, value: "kalyan", label: "Kalyan"},
    {id: 2, value: "diva", label: "Diva"},
    {id: 3, value: "kalwa", label: "Kalwa"},
    {id: 4, value: "mulund", label: "Mulund"},
]

const items = [
    {
      id: 0,
      name: 'Jewels Box'
    },
    {
      id: 1,
      name: 'My Silver'
    },
    {
      id: 2,
      name: 'Anmol'
    },
    {
      id: 3,
      name: 'Zota Jewel'
    },
    {
      id: 4,
      name: 'Fine Jewel'
    }
  ]

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    // console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left'}}>name: {item.name}</span>
      </>
    )
  }

const FindStore = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let hasError = false;

    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [place, setPlace] = useState("");
    const [pincode, setPincode] = useState("");

    const [errors, setErrors] = useState({
        errState:"",
        errCity:"",
        errPlace:"",
        errPincode:"",
    }) 

    const handleStateChange = (name, value) => {
        if(name === "state"){
            setState(value)
            handleError(name,value)
        }
        if(name === "city"){
            setCity(value)
            handleError(name,value)
        }
        if(name === "place"){
            setPlace(value)
            handleError(name,value)
        }
        if(name === "pincode"){
            let check = value
            if(numberText(check)){
                setPincode(check)
                // handleError(name,value)
            }
        }
    }
    
    const handleError = (name, value) => {
    
        console.log("handle OnBlure Change", name, "value", value)
    
        let allState = errors;
    
         if(name === "state"){
            if(!value.length){
                allState["errState"] = "Select state."
            }else{
                allState["errState"] = ""
            }
        }
         if(name === "city"){
             if(!value.length){
                 allState["errCity"] = "Select city."
             }else{
                 allState["errCity"] = ""
             }
         }
         if(name === "place"){
            if(!value.length){
                allState["errPlace"] = "Select Place."
            }else{
                allState["errPlace"] = ""
            }
        }
         if(name === "pincode"){
            if(!value.length){
                allState["errPincode"] = "Pincode is required."
            }else{
                allState["errPincode"] = ""
            }
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }
    
    const checkVailidation = () => {
        hasError = false;
        let allState = errors;
        
        
        if(!state.length){
            allState["errState"] = "Select state."
            hasError = true
        }else{
            allState["errState"] = ""
        }
        if(!city.length){
            allState["errCity"] = "Select city."
            hasError = true
        }else{
            allState["errCity"] = ""
        }
        if(!place.length){
            allState["errPlace"] = "Select place."
            hasError = true
        }else{
            allState["errPlace"] = ""
        }
        if(!pincode.length){
            allState["errPincode"] = "Pincode is required."
            hasError = true
        }else{
            allState["errPincode"] = ""
        }
    
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }
    
    const handelFormSubmit = (e) => {
        e.preventDefault()
        if(!checkVailidation()){
            let data = {
                state: state,
                city: city,
                place: place,
                pincode: pincode,
            }

        }
    }

    const [listData, setListData] = useState([
        { id:1, state: "Maharashtra", city: "Mumbai", store: "Bandra", address: "Shop No. 10, Rizvi Mahal, R K Patkar Marg - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.", contact: "022-35080525"}
    ]);

    return (
        <div className='wrapper find-store'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Find Store</h1>
                </div>
            </div>

            <div className="container">

                <div className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Find Store</li>
                            </ol>
                        </nav>
                            
                        <section className='filter'>
                            <p>Discover the elegance and luxury of Summera Jewels at our exquisite store, where timeless beauty and exceptional craftsmanship await you.</p>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='title'>
                                        <h6>Filter By</h6>
                                    </div>
                                    <form action="#">
                                        <ul className='form-row'>

                                            <li className="form-group">
                                                <Reactselect
                                                    name={"state"}
                                                    id={"state"}
                                                    label={"State *"}
                                                    options={stateData}
                                                    value={state}
                                                    class={"form-select"}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    // errorMsg={errors.errState}
                                                />
                                            </li>

                                            <li className="form-group">
                                                <Reactselect
                                                    name={"city"}
                                                    id={"city"}
                                                    label={"City *"}
                                                    options={cityData}
                                                    class={"form-select"}
                                                    value={city}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    // errorMsg={errors.errCity}
                                                />
                                            </li>

                                            <li className="form-group">
                                                <Reactselect
                                                    name={"place"}
                                                    id={"place"}
                                                    label={"Place *"}
                                                    options={placeData}
                                                    class={"form-select"}
                                                    value={place}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    // errorMsg={errors.errPlace}
                                                />
                                            </li>

                                            <li className="form-group search">
                                                <ReactSearchAutocomplete
                                                    items={items}
                                                    onSearch={handleOnSearch}
                                                    onHover={handleOnHover}
                                                    onSelect={handleOnSelect}
                                                    onFocus={handleOnFocus}
                                                    autoFocus
                                                    formatResult={formatResult}
                                                    placeholder={'Search by Pincode'}
                                                />
                                                <button className="btn btn-outline-secondary" type="button">
                                                    <FaSearch />
                                                </button>
                                                {/* <TextInput
                                                    type={"text"}
                                                    name={"pincode"}
                                                    label={"Pincode"}
                                                    value={pincode}
                                                    placeholder={"Search by Pincode"}
                                                    inputClass={"form-control"}
                                                    lblClass={"input-lable"}
                                                    autoComplete={"off"}
                                                    onChange={handleStateChange}
                                                    onBlur={handleError}
                                                    errorMSg={errors.errPincode}
                                                /> */}
                                            </li>

                                        </ul>
                                        
                                    </form>
                                    <div className='clear-all'>
                                        <Link to={'/'} className='clearAll'><FiRefreshCw /> Clear All</Link>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='list'>
                            <div className='Table table-responsive'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>State</th>
                                            <th>City</th>
                                            <th>Store</th>
                                            <th>Address</th>
                                            <th>Contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    <>

                                        {
                                            listData.map((item,index)=>{
                                                return(
                                                    <tr key={index}>
                                                        <td><p>{item.id}</p></td>
                                                        <td><p>{item.state}</p></td>
                                                        <td><p>{item.city}</p></td>
                                                        <td><p>{item.store}</p></td>
                                                        <td><p>{item.address}</p></td>
                                                        <td><p>{item.contact}</p></td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </>

                                    </tbody>
                                </table>
                            </div>

                            {/* <div className='load'>
                                <Link className="btn btn-primary" to={'/'}><span>Load More</span></Link>
                            </div> */}
                        </section>

                    </div>
                </div>

            </div>
        </div>
    )
}


export default FindStore;