import React,{useState,useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import TextInput from "../../../components/molecules/input";
import Reactselect from "../../molecules/select";
import Option from "../../molecules/select/select";
import { alphaNumeric, alphaNumericWithSpecil, alphabatesWithSpace, checkPanNumber, fetchWithWait, isGstNumber, isValidEmail, isValidMobileNo, numberText } from "../../../components/helper/method";
import bannerImage from '../../../assets/images/sellonsummera-banner.jpg';
import sellingbg from '../../../assets/images/selling-form-bg.png';
import iconUSer from '../../../assets/images/icons/icon-user.png';
import iconDoc from '../../../assets/images/icons/icon-document.png';
import iconSubscription from '../../../assets/images/icons/icon-subscription.png';
import iconUpload from '../../../assets/images/icons/icon-upload.png';
import iconCart from '../../../assets/images/icons/icon-cart.png';
import {Accordion,AccordionBody,AccordionHeader,AccordionItem,Input,FormGroup} from 'reactstrap';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaQuoteLeft } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonial from '../../../components/TestimonialSlider/Testimonial';
import Ratan from '../../../assets/images/ratan.png';
import ExpandYourBusiness from '../../../assets/images/icons/expand_your_business.png';
import IncreaseAccessibility from '../../../assets/images/icons/increase_accessibility.png';
import TimelyPayment from '../../../assets/images/icons/timely_payment.png';
import MarketingPlatform from '../../../assets/images/icons/marketing_platform.png';
import axios from 'axios';
import './index.css'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ImCross } from 'react-icons/im';
import { useDispatch , useSelector} from 'react-redux'
// import Checkbox from '../../../components/atom/checkbox/input'
import { fetchMasterAction, fetchCountryAction, fetchCityAction } from '../../../redux/actions/vendorAction';
import  PageLoader from '../../atom/loader/pageLoader'
import { panVerificationAction } from '../../../redux/actions/verificationAction';

let baseUrl = process.env.REACT_APP_BASE_URL;
let baseDocumentUrl = process.env.REACT_APP_VERIFY_DOCUMENTS;
let kycToken = process.env.REACT_APP_KYCTOKEN;

function SellOnSummera() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let hasError = false;
    const { countrys,city,states, businessType, salutation, vendorType } = useSelector((state) => state.vendor);

    const [selectedBtn, setSelectedBtn] = useState(0);
    const [message,setMessage] = useState("");
    // const [checkbox,setCheckbox] = useState(false);
    const [loader,setLoader] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [selectBusinessType, setSelectBusinessType] = useState("");
    const [website, setWebsite] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [selectSalutation, setSelectSalutation] = useState("");
    const [salutationlbl, setSalutationlbl] = useState("");
    const [email, setEmail] = useState("");
    const [stdcode, setStdcode] = useState("IN 91");
    const [mobileNo, setMobileNo] = useState("");
    const [address, setAddress] = useState("");
    const [slectedCountry, setSlectedCountry] = useState(101);
    const [pincode, setPincode] = useState("");
    const [region, setRegion] = useState("");
    const [selectedCity, setCity] = useState("");
    const [panNumber, setPanNumber] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [gstLabel, setGstLabel] = useState("Verify");
    const [panLabel, setPanLabel] = useState("Verify");
    // const [panFile, setPanFile] = useState(null);
    // const [panName, setPanName] = useState("");
    const [btnPan, setBtnPan] = useState("");
    const [gstBtn, setGstBtn] = useState("");
    const [panLoad, setPanLoad] = useState(false);
    const [verifiedPen, setVerifiedPan] = useState(null);
    const [verifiedfullName, setVerifiedFullName] = useState("");
    const [verificationResult, setVerificationResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
        errCompanyName: "",
        errBusinessType: "",
        errFirstName: "",
        errLastName: "",
        errSalutation: "",
        errEmail: "",
        errMobileNo: "",
        errAddress: "",
        errRegion: "",
        errCity: "",
        errPanNumber: "",
        errGstNumber: "",
        // errPanFile: "",
        // errGstFile: "",
        errSelectBtn: "",
        errCountry: "",
        errContryCode: "",
        errPincode: "",
    }) 

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    

    useEffect(() => {
        dispatch(fetchCountryAction())
        dispatch(fetchMasterAction())
    }, [])

    useEffect(() => {
         let statedata = []
         states.map(li => {
             if(li.countryId === slectedCountry){
                 statedata.push({label : li.name, value: li.id})
             }
         })
         setStateData(statedata)
     }, [states, slectedCountry])


    useEffect(() => {
       if(countrys){
        let countryOption = [];
        let countrycode = [];
        countrys.map((li) => {
            countryOption.push({ id: li.id, label: li.name, value: li.id, phCode: li.phoneCode, cntCode: li.iso2 })
            countrycode.push({ id: li.id, label: `${li.iso2} ${li.phoneCode}`, value: li.phoneCode })
        })
        setCountryData(countryOption)
        setCountryCode(countrycode)
       }
    }, [countrys])

    useEffect(() => {
       if(region !== null ){
        dispatch(fetchCityAction({"param": region}))
       }
    }, [region])

    useEffect(() => {
        if(city){
        let cityData = []
        city.map(li => {
            cityData.push({label : li.name, value: li.id})
        })
        setCityData(cityData)
        }
     }, [city])


    const handleStateChange = (name, value) => {
        // console.log("name and value", name ,value)

        if (name === "companyName") {
            let check = String(value)
            if (alphaNumericWithSpecil(check)) {
                setCompanyName(check)
                handleError(name, value)
            }
        }
        if (name === "selectBusinessType") {
            setSelectBusinessType(value)
            handleError(name, value)
        }

        if (name === "selectedBtn") {
            setSelectedBtn(value)
            handleError(name, value)
        }
        if (name === "website") {
            setWebsite(value)
            handleError(name, value)
        }
        if (name === "firstName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setFirstName(check)
                handleError(name, value)
            }
        }
        if (name === "lastName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setLastName(check)
                handleError(name, value)
            }
        }
        if (name === "selectSalutation") {
            setSelectSalutation(value)
            value === 1 ? setSalutationlbl("Mr") : setSalutationlbl("Mrs")
            handleError(name, value)
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "mobileNo"){
            let check = value
            if(numberText(check)){
                setMobileNo(check)
                handleError(name,value)
            }
        }
        if (name === "address") {
            setAddress(value)
            handleError(name, value)
        }
        if (name === "region") {
            setRegion(value)
            handleError(name, value)
        }
        if (name === "selectedCity") {
            setCity(value)
            handleError(name, value)
        }
        if (name === "panNumber") {
            let check = value.toUpperCase()
            setPanLabel("Verify")
            if (alphaNumeric(check)) {
                setPanNumber(check)
                setGstBtn("")
                setBtnPan("")
                setVerifiedPan(null)
                handleError(name, value)
            }
        }
        if (name === "gstNumber") {
            let check = value.toUpperCase()
            setGstLabel("Verify")
            if (alphaNumeric(check)) {
                setGstNumber(check)
                setGstBtn("")
                handleError(name, value)
            }
        }
        if (name === "pincode") {
            if(numberText(value)){
                setPincode(value)
                handleError(name, value)
            }
           
        }
        if (name === "slectedCountry") {
            setSlectedCountry(value)
            handleError(name, value)
        }
        if (name === "stdcode") {
            setStdcode(value)
            handleError(name, value)
        }
    }

    const handleError = (name, value) => {
    
        let allState = errors;

        if (name === "companyName") {
            if (!value.length) {
                allState["errCompanyName"] = "Enter your Company name"
            } else if (value.length < 3) {
                allState["errCompanyName"] = "Company Name length should be 3 charector"
            } else {
                allState["errCompanyName"] = ""
            }
        }
        if (name === "selectBusinessType") {
            if (!value.length) {
                allState["errBusinessType"] = "Select Business Type."
            } else {
                allState["errBusinessType"] = ""
            }
        }
    
        if (name === "firstName") {
            if (!value.length) {
                allState["errFirstName"] = "Enter your First Name"
            } else if (value.length < 3) {
                allState["errFirstName"] = "First Name length should be 3 charector"
            } else {
                allState["errFirstName"] = ""
            }
        }
        if (name === "lastName") {
            if (!value.length) {
                allState["errLastName"] = "Enter your Last Name"
            } else if (value.length < 3) {
                allState["errLastName"] = "Last Name length should be 3 charector"
            } else {
                allState["errLastName"] = ""
            }
        }
        if (name === "selectSalutation") {
            if (!value.length) {
                allState["errSalutation"] = "Select Salutation"
            } else {
                allState["errSalutation"] = ""
            }
        }
        if(name === "mobileNo"){
            if(!value.length){
                allState["errMobileNo"] = "Mobile no is required."
            }else if(!isValidMobileNo(value)){
                allState["errMobileNo"] = "Enter a valid number."
            }else{
                allState["errMobileNo"] = ""
            }
        }
        if(name === "email"){
            if(!value.length){
                allState["errEmail"] = "Enter your email"
            }else if(!isValidEmail(value)){
                allState["errEmail"] = "Invalid email."
            }else{
                allState["errEmail"] = ""
            }
        }
        if (name === "address") {
            if (!value.length) {
                allState["errAddress"] = "Enter your Address"
            } else if (value.length > 225) {
                allState["errAddress"] = "Address should less then 225 charector"
            } else {
                allState["errAddress"] = ""
            }
        }
        if (name === "region") {
            if (!value.length) {
                allState["errRegion"] = "Select Region."
            } else {
                allState["errRegion"] = ""
            }
        }
        if (name === "selectedCity") {
            if (!value.length) {
                allState["errCity"] = "Select City."
            } else {
                allState["errCity"] = ""
            }
        }
        if (name === "panNumber") {
            let check = value
            if (!value.length) {
                allState["errPanNumber"] = "Enter your PAN Number"
            } else if (!checkPanNumber(check)) {
                allState["errPanNumber"] = "Invailid Pan number!"
            } else {
                allState["errPanNumber"] = ""
            }
        }
        if (name === "gstNumber") {
            let check = value;
            if (!value.length) {
                allState["errGstNumber"] = "Enter your GST Number"
            } else if (!isGstNumber(check)) {
                allState["errGstNumber"] = "Invailid GST Number"
            } else {
                allState["errGstNumber"] = ""
            }
        }
        if (name === "slectedCountry") {
            if (!value.length) {
                allState["errCountry"] = "Select country"
            } else {
                allState["errCountry"] = ""
            }
        }
        if (name === "selectedBtn") {
            if (value < 0) {
                allState["errSelectBtn"] = "Please select 'Are you' field."
            } else {
                allState["errSelectBtn"] = ""
            }
        }

        if (name === "stdcode") {
            if (!value.length) {
                allState["errContryCode"] = "Please select country code."
            } else {
                allState["errContryCode"] = ""
            }
        }
        if (name === "pincode") {
            if (!value.length) {
                allState["errPincode"] = "Pincode required."
            } else if(value.length < 6) {
                allState["errPincode"] = "Pincode error!"
            } else {
                allState["errPincode"] = ""
            }
        }
      
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }

    const checkVailidation = () => {

        hasError = false;
        let allState = errors;

        if (!companyName.length) {
            allState["errCompanyName"] = "Enter your Company Name"
            hasError = true;
        } else if (companyName.length < 3) {
            allState["errCompanyName"] = "Company Name length should be 3 charector"
            hasError = true;
        } else {
            allState["errCompanyName"] = ""
        }

        if (!selectBusinessType.length) {
            allState["errBusinessType"] = "Select Business Type."
            hasError = true
        } else {
            allState["errBusinessType"] = ""
        }

        if (!firstName.length) {
            allState["errFirstName"] = "Enter your First Name"
            hasError = true;
        } else if (firstName.length < 3) {
            allState["errFirstName"] = "First Name length should be 3 charector"
            hasError = true;
        } else {
            allState["errFirstName"] = ""
        }

        if (!lastName.length) {
            allState["errLastName"] = "Enter your Last Name"
            hasError = true;
        } else if (lastName.length < 3) {
            allState["errLastName"] = "Last Name length should be 3 charector"
            hasError = true;
        } else {
            allState["errLastName"] = ""
        }

        if (!selectSalutation.length) {
            allState["errSalutation"] = "Select Salutation"
            hasError = true
        } else {
            allState["errSalutation"] = ""
        }

        if (!mobileNo.length) {
            allState["errMobileNo"] = "Mobile no is required."
            hasError = true
        } else if (!isValidMobileNo(mobileNo)) {
            allState["errMobileNo"] = "Enter a valid number."
            hasError = true
        } else {
            allState["errMobileNo"] = ""
        }

        if (!email.length) {
            allState["errEmail"] = "Enter your email"
            hasError = true
        } else if (!isValidEmail(email)) {
            allState["errEmail"] = "Invailid email."
            hasError = true
        } else {
            allState["errEmail"] = ""
        }

        if (!address.length) {
            allState["errAddress"] = "Enter your Address"
            hasError = true;
        } else if (address.length > 225) {
            allState["errAddress"] = "Address should less then 225 charector"
            hasError = true;
        } else {
            allState["errAddress"] = ""
        }

        if (!region.length) {
            allState["errRegion"] = "Select Region."
            hasError = true
        } else {
            allState["errRegion"] = ""
        }

        if (!selectedCity.length) {
            allState["errCity"] = "Select City."
            hasError = true
        } else {
            allState["errCity"] = ""
        }

        if (!panNumber.length) {
            allState["errPanNumber"] = "Enter your PAN Number"
            hasError = true;
        } else {
            allState["errPanNumber"] = ""
        }

        if (panNumber) {
            if(!verifiedPen && !verifiedPen?.success){
                allState["errPanNumber"] = "Please verify Pan number."
                hasError = true;
            } else {
                allState["errPanNumber"] = ""
            }
        }

        if (!gstNumber.length) {
            allState["errGstNumber"] = "Enter your GST Number"
            hasError = true;
        } else if (!isGstNumber(gstNumber)) {
            allState["errGstNumber"] = "Invailid GST Number"
            hasError = true;
        } else if(!verifiedPen){
            allState["errGstNumber"] = "Please verified GST Number."
            hasError = true;
        }else {
            allState["errGstNumber"] = ""
        }

        if(gstNumber && verifiedPen){
            const { data : {pan_number} } = verifiedPen
            let checkGst = gstNumber.includes(pan_number)

            if(!checkGst || gstBtn !== "green"){
                allState["errGstNumber"] = "Please verified GST Number."
                hasError = true;
            } else {
                allState["errGstNumber"] = ""
            }
        }

        // if (!panName.length) {
        //     allState["errPanFile"] = "Select PAN File."
        //     hasError = true
        // } else {
        //     allState["errPanFile"] = ""
        // }
        // if (!gstFileName.length) {
        //     allState["errGstFile"] = "Select GST File."
        //     hasError = true
        // } else {
        //     allState["errGstFile"] = ""
        // }
        if (!selectedBtn > 0) {
            allState["errSelectBtn"] = "Please select 'Are you' field."
            hasError = true
        } else {
            allState["errSelectBtn"] = ""
        }
        if (!slectedCountry) {
            allState["errCountry"] = "Select country"
            hasError = true
        } else {
            allState["errCountry"] = ""
        }
        // if (!stdcode.length) {
        //     allState["errContryCode"] = "Please select country code."
        //     hasError = true
        // }else {
        //     allState["errContryCode"] = ""
        // }
        if (!pincode.length) {
            allState["errPincode"] = "Pincode required."
            hasError = true
        } else if(pincode.length < 6) {
            allState["errPincode"] = "Pincode error!"
            hasError = true
        } else {
            allState["errPincode"] = ""
        }

        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }     

    console.log("let checkGst (panNumber)", verifiedPen)


    // const handlePanUpload = ({target: {files,name}}) => {
    //     const file = files[0];
    //     const length = files.length;
    //     const size = file.size;
    //     const type = file.type;

    //     if (name === "panName") {
    //         setPanFile(file)
    //         setPanName(file.name)
    //         handleFileError(name,length,size,type)
    //     }
    //     if (name === "gstFileName") {
    //         setGstFile(file)
    //         setGstFileName(file.name)
    //         handleFileError(name,length,size,type)
    //     }
    // }


    // const handleFileError = (name,length,size,type) => {
    //     const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    //     // console.log("handleFileError" ,size,type)

    //     let allState = errors;

    //     if (name === "panName") {
    //         if (!length) {
    //             allState["errPanFile"] = "Select PAN File."
    //             return false;
    //         }
    //         if (size > 2 * 1024 * 1024) {
    //             allState["errPanFile"] = "File size should be less than 200kb."
    //             return false;
    //         }
    //         if (!validTypes.includes(type)) {
    //             allState["errPanFile"] = "Please select jpg, png or pdf only."
    //             return false;
    //         } else {
    //             allState["errPanFile"] = ""
    //         }
    //     }
    //     if (name === "gstFileName") {
    //         if (!length) {
    //             allState["errGstFile"] = "Select GST File."
    //             return false;
    //         }
    //         if (size > 2 * 1024 * 1024) {
    //             allState["errGstFile"] = "File size should be less than 200kb."
    //             return false;
    //         }
    //         if (!validTypes.includes(type)) {
    //             allState["errGstFile"] = "Please select jpg, png or pdf only."
    //             return false;
    //         } else {
    //             allState["errGstFile"] = ""
    //         }
    //     }
      
    //     setErrors(prevError => ({
    //         ...prevError,
    //         ...allState
    //     }))
    // }



    const handelFormSubmit = async(e) => {
        e.preventDefault()
        if(!checkVailidation()){
            try{
            const data = {
                'companyName': companyName,
                'email': email,
                'businessType': selectBusinessType,
                'vendorType': selectedBtn,
                'website': website,
                'salutation': selectSalutation,
                'firstName': firstName,
                'lastName': lastName,
                'mobileCode': stdcode,
                'mobile': mobileNo,
                'address': address,
                'country': slectedCountry,
                'state': region,
                'city': selectedCity,
                'panNumber': panNumber,
                'gstNumber': gstNumber,
                'pincode': pincode,

            }

            const formDataObject = new FormData();
                formDataObject.append('companyName', companyName);
                formDataObject.append('email', email);
                formDataObject.append('businessType', selectBusinessType);
                formDataObject.append('vendorType', selectedBtn);
                formDataObject.append('website', website);
                formDataObject.append('salutation', selectSalutation);
                formDataObject.append('firstName', firstName);
                formDataObject.append('lastName', lastName);
                formDataObject.append('mobileCode', stdcode);
                formDataObject.append('mobile', mobileNo);
                formDataObject.append('address', address);
                formDataObject.append('country', slectedCountry);
                formDataObject.append('state', region);
                formDataObject.append('city', selectedCity);
                formDataObject.append('panNumber', panNumber);
                formDataObject.append('gstNumber', gstNumber);
                formDataObject.append('pincode', pincode);

            // fetchWithWait({ dispatch, action: addVendorAction(data) }).then((res) => {
            //     const { status, message } = res
                
            //     if(status === 200){
            //         setLoader(false)
            //         setMessage(message);
            //         navigate(`/SellOnSummera/thank-you`, {state: data})
            //     }else{
            //         setLoader(false)
            //         alert(message)
            //     }
            //     }).catch((e) => {
            //         console.log(`error`, e)
            //     })

                // console.log("verifiedPenverifiedPen",verifiedPen)
                // navigate("/sell-on-summera/subscription-plan",{state: data})
                
                    const response = await axios.post(`${baseUrl}/vendor`, formDataObject, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    });

                    const { status, message } = response.data
        
                    if(status === 200){
                        setLoader(false)
                        setMessage(message);
                        navigate(`/sell-on-summera/subscription-plan`, {state: data})
                    }else{
                        setLoader(false)
                        alert(message)
                    }
                }catch (error) {
                    setLoader(false)
                    setMessage(error);
                }
            }
        }

    const data = [
        {
            "id": 1,
            "title": "Lorem Ipsum is simply dummy text of the printing?",
            "content1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been."
        },
        {
            "id": 2,
            "title": "Lorem Ipsum is simply dummy text of ?",
            "content1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            "id": 3,
            "title": "Lorem Ipsum is simply dummy text of ?",
            "content1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            "id": 4,
            "title": "Lorem Ipsum is simply dummy text of ?",
            "content1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        },
        {
            "id": 5,
            "title": "Lorem Ipsum is simply dummy text of ?",
            "content1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
        }
    ]    

    const settings = {
        dots: true,
        arrows:false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [open, setOpen] = useState();
        const toggle = (i) => {
            if (open === i) {
            setOpen();
            } else {
            setOpen(i);
        }
    };

    const handlePanCartVerify = async() => {
        let allState = errors;

        if(panNumber){
            setPanLoad(true)
            let data = {
                "id_number":  panNumber
            }

            fetchWithWait({ dispatch, action: panVerificationAction(data) }).then((res) => {
                const { status_code,message, data: panRes } = res
                if(status_code === 200 ){
                    setBtnPan("green")
                    setPanLoad(false)
                    setVerifiedFullName(panRes.full_name)
                    setVerifiedPan(res)
                    setPanLabel("Verified")

                    allState["errPanNumber"] = ""
                    setErrors(prevError => ({
                        ...prevError,
                        ...allState
                    }))

                }else{
                    setPanLoad(false)
                    setPanLabel(message)
                    setBtnPan("red")
                    allState["errPanNumber"] = "Please check PAN no."
                    setErrors(prevError => ({
                        ...prevError,
                        ...allState
                    }))
                }
            }).catch((e) => {
                console.log(`error`, e)
            })
        }
    }

    const verifyGST = () => {
        let allState = errors;
        let checkGst = gstNumber.includes(panNumber)
        if(!verifiedPen){
            alert("First verify the pan card.")
            setGstBtn("red")
        }else if(verifiedPen.success && checkGst){
            setGstBtn("green")
            setGstLabel("Verified")
            allState["errGstNumber"] = ""
            setErrors(prevError => ({
                ...prevError,
                ...allState
            }))
        }else{
            setGstBtn("red")
            setGstLabel("Invalid GST")
            allState["errGstNumber"] = "Please check GST no."
            setErrors(prevError => ({
                ...prevError,
                ...allState
            }))
        }
    };

    

  return (
    <div className='wrapper sell-on-summera'>
    
        <section className='bannerSec'>
            <figure>
                <img src={bannerImage} alt='Sell on Summera today and Expand Your Business Globally' />
                <div className='container'>
                    <figcaption>
                        <h1>Sell on Summera today and Expand <span>Your Business Globally</span></h1>
                    </figcaption>
                </div>
            </figure>
        </section>

        <div className='container'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Sell on Summera</li>
                </ol>
            </nav>
            <p>Become a Part of Summera Jewels: Unlock New Possibilities and Showcase Your Exquisite Jewelry to a Global Audience. Join Us as a Seller</p>
            <section className='row gap0'>
                <div className='col-md-5'>
                    <div className='orangeBox'>
                        <h2>Start Selling Today in 4 Easy Steps:</h2>
                        <ul>
                            <li>
                                <span className='icon'><img src={iconUSer} alt='Register' /></span>
                                <span>
                                    <h5><b>Register</b></h5>
                                    <p>Sign up as a seller on our platform to begin your journey with Summera Jewels.</p>
                                </span>
                            </li>
                            <li>
                                <span className='icon'><img src={iconDoc} alt='Provide the Required Documents' /></span>
                                <span>
                                    <h5>Provide the <b>Required Documents</b></h5>
                                    <p>Submit the necessary documentation as part of the verification process to ensure a seamless selling experience.</p>
                                </span>
                            </li>
                            {/* <li>
                                <span className='icon'><img src={iconSubscription} alt='Select the  Subscription Plan' /></span>
                                <span>
                                    <h5>Select the <b>Subscription Plan</b></h5>
                                    <p>Lorem ipsum dummy text dummy text dummy text.</p>
                                </span>
                            </li> */}
                            <li>
                                <span className='icon'><img src={iconUpload} alt='Upload Your Products' /></span>
                                <span>
                                    <h5>Upload <b>Your Products</b></h5>
                                    <p>Showcase your exquisite jewelry by uploading your product listings, complete with captivating images and compelling descriptions.</p>
                                </span>
                            </li>
                            <li>
                                <span className='icon'><img src={iconCart} alt='Start Selling' /></span>
                                <span>
                                    <h5>Start <b>Selling</b></h5>
                                    <p>Once your products are approved, you can begin reaching a global audience and selling your jewelry through Summera Jewels. Happy selling!</p>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <figure className='bgImg'>
                        <img src={sellingbg} alt='' />
                    </figure>
                </div>

                <div className='col-md-7'>
                    <div className='shadow-box-rounded'>
                        <div className="topheading">
                            <h5>Customise Request</h5>
                            <span><span className='text-danger'>*</span> Mandatory Fields</span>
                        </div>
                        
                        <div className="form-group are-you">
                            <h6>Are you? <span className='text-danger'>*</span></h6>
                            <ul>
                                {
                                    Array.isArray(vendorType) && vendorType.map((item,index) =>{
                                        return <li key={index} onClick= {() => handleStateChange("selectedBtn",item.id)}
                                        onBlur={() => handleError("selectedBtn",item.id)}
                                        >
                                            <input type="radio"
                                                id= {item.id}
                                                name="are"
                                                value= {`${item.name}`}
                                                checked={selectedBtn === item.id}
                                            />
                                        <label htmlFor="are">{item.name}</label>
                                    </li>
                                    })
                                    
                                }
                            </ul>
                            <div className='error-message'>{errors.errSelectBtn}</div>
                        </div>

                        <div className='form-row company'>
                            <div className="form-group">
                                <TextInput
                                    type={"text"}
                                    name={"companyName"}
                                    id={"companyName"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"Company Name"}
                                    star={true}
                                    value={companyName}
                                    autoComplete="off"
                                    errorMSg={errors.errCompanyName}
                                />
                            </div>
                        </div>
                        
                        <div className="form-row">
                            <div className="form-group select-city">
                                <Option
                                    name={"selectBusinessType"}
                                    id={"selectBusinessType"}
                                    label={"Business Type"}
                                    options={businessType}
                                    star={true}
                                    value={selectBusinessType}
                                    lblClass={"input-lable"}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errBusinessType}
                                />
                            </div>
                            <div className="form-group">
                                <TextInput
                                    type={"text"}
                                    name={"website"}
                                    id={"website"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"Website"}
                                    value={website}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group salutation select-city">
                                <Option
                                    name={"selectSalutation"}
                                    id={"selectSalutation"}
                                    label={"Salutation"}
                                    star={true}
                                    options={salutation}
                                    value={selectSalutation}
                                    lblClass={"input-lable"}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errSalutation}
                                />
                            </div>

                            <div className="form-group fname">
                                <TextInput
                                    type={"text"}
                                    name={"firstName"}
                                    id={"firstName"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"First Name"}
                                    star={true}
                                    value={firstName}
                                    autoComplete="off"
                                    errorMSg={errors.errFirstName}
                                />
                            </div>

                            <div className="form-group">
                                <TextInput
                                    type={"text"}
                                    name={"lastName"}
                                    id={"lastName"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"Last Name"}
                                    star={true}
                                    value={lastName}
                                    autoComplete="off"
                                    errorMSg={errors.errLastName}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group mobile select-city">
                                <label>Mobile <span className='text-danger'>*</span></label>
                                <div className="cust-select">
                                    <Reactselect
                                        name={"stdcode"}
                                        id={"stdcode"}
                                        label={"Mobile"}
                                        value={91}
                                        disabled={true}
                                        options={countryCode}
                                        onChange={handleStateChange}
                                        class={"form-control form-select"}
                                        onBlur={handleError}
                                        errorMsg={errors.errContryCode}
                                    />
                                    
                                    <TextInput
                                        type={"tel"}
                                        value={mobileNo}
                                        name={"mobileNo"}
                                        id={"mobileNo"}
                                        placeholder={""}
                                        inputClass={"form-control"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        autoComplete="off"
                                        maxLength="10"
                                        errorMSg={errors.errMobileNo}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <TextInput
                                    type={"text"}
                                    name={"email"}
                                    id={"email"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"Email"}
                                    star={true}
                                    value={email}
                                    autoComplete="off"
                                    errorMSg={errors.errEmail}
                                />
                            </div>
                        </div>  

                        <div className="form-row company">
                            <div className="form-group">
                                <TextInput
                                    type={"text"}
                                    name={"address"}
                                    id={"address"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"Address"}
                                    star={true}
                                    value={address}
                                    autoComplete="off"
                                    errorMSg={errors.errAddress}
                                />
                            </div>  
                        </div>      

                        <div className="form-row">
                            <div className="form-group select-city">
                                <Reactselect
                                    name={"slectedCountry"}
                                    id={"slectedCountry"}
                                    label={"Country"}
                                    star={true}
                                    value={slectedCountry}
                                    disabled={true}
                                    options={countryData}
                                    onChange={handleStateChange}
                                    lblClass={"input-lable"}
                                    class={"form-select"}
                                    onBlur={handleError}
                                    errorMsg={errors.errCountry}
                                />
                            </div>
                            <div className="form-group select-city">
                                <Reactselect
                                    name={"region"}
                                    id={"region"}
                                    placeholder={""}
                                    options={stateData}
                                    class={"form-select"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"State / Region"}
                                    star={true}
                                    value={region}
                                    errorMsg={errors.errRegion}
                                />
                            </div>
                        </div> 

                        <div className="form-row">
                            <div className="form-group select-city">
                                <Reactselect
                                    name={"selectedCity"}
                                    id={"selectedCity"}
                                    label={"City"}
                                    star={true}
                                    options={cityData}
                                    value={selectedCity}
                                    lblClass={"input-lable"}
                                    class={"form-select city-err"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errCity}
                                />
                            </div>
                            <div className="form-group">
                                <TextInput
                                    type={"text"}
                                    name={"pincode"}
                                    id={"pincode"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"Pin code"}
                                    star={true}
                                    value={pincode}
                                    maxLength="6"
                                    autoComplete="off"
                                    errorMSg={errors.errPincode}
                                />
                            </div>
                        </div>  

                        <div className="form-row">
                            <div className="form-group resize">
                                <div className='pan-withbtn'>
                                    <TextInput
                                        type={"text"}
                                        name={"panNumber"}
                                        id={"panNumber"}
                                        placeholder={""}
                                        inputClass={"form-control"}
                                        lblClass={"input-lable"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        label={"PAN"}
                                        star={true}
                                        value={panNumber}
                                        maxLength="10"
                                        autoComplete="off"
                                        errorMSg={errors.errPanNumber}
                                    />
                                </div>
                                <div className='btn-verify'>
                                    <button onClick={handlePanCartVerify} className={btnPan}>
                                        { !panLoad ? panLabel : "Loading.." }
                                    </button>
                                </div>
                            </div>

                            <div className="form-group resize">
                                <div className='pan-withbtn'>
                                    <TextInput
                                        type={"text"}
                                        name={"gstNumber"}
                                        id={"gstNumber"}
                                        placeholder={""}
                                        inputClass={"form-control"}
                                        lblClass={"input-lable"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        label={"GST Number"}
                                        star={true}
                                        value={gstNumber}
                                        maxLength="15"
                                        autoComplete="off"
                                        errorMSg={errors.errGstNumber}
                                    />
                                </div>
                                <div className='btn-verify'>
                                    <button onClick={verifyGST} className={gstBtn}>
                                        {gstLabel}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className="form-row">
                            <div className='form-group'>
                                <label htmlFor="myfile">Upload PAN Card Copy <span className='text-danger'>*</span></label>
                                <FormGroup>
                                    <Input
                                        type="file"
                                        id="exampleCustomFileBrowser"
                                        name="panName"
                                        onChange={handlePanUpload}
                                    />
                                    {errors["errPanFile"] ? <div className='error-m     essage'>{errors.errPanFile}</div> : null}
                                </FormGroup>
                                <button className='btn btn-primary'>BROWSE FILE</button>
                                <div className="file-info">Image Format - jpgs, png and size 200 KB</div>
                            </div>      
                            <div className='form-group'>
                                <label htmlFor="myfile">Upload GST Copy <span className='text-danger'>*</span></label>
                                <FormGroup>
                                    <Input
                                     type="file"
                                     id="exampleCustomFilegst"
                                     name="gstFileName"
                                     onChange={handlePanUpload}
                                    />
                                    {errors["errGstFile"] ? <div className='error-message'>{errors.errGstFile}</div> : null}
                                </FormGroup>
                                <button className='btn btn-primary'>BROWSE FILE</button>
                                <div className="file-info">Image Format - jpgs, png and size 200 KB</div>
                            </div> 
                        </div>   */}

                        <div className="make-info">
                            <button 
                            className="btn btn-primary" 
                            type="submit" 
                            onClick={handelFormSubmit}><span>Proceed to Subscription Plan</span></button>
                        </div>  

                    </div>
                </div>
            </section>

            <section className="why-register-on-summera">
                <h2>Why register with Summera?</h2>

                <ul>
                    <li>
                        <img src={ExpandYourBusiness} alt="Expand Your Business" />
                        <h6>Expand Your Business</h6>
                        <p>Joining Summera Jewels allows you to tap into a larger customer base, reaching potential buyers from around the world, and expanding your market presence.</p>
                    </li>
                    <li>
                        <img src={IncreaseAccessibility} alt="Increase Accessibility" />
                        <h6>Increase Accessibility</h6>
                        <p>Showcase your jewelry on our user-friendly platform, making it easily accessible to a diverse audience, leading to higher visibility and sales opportunities.</p>
                    </li>
                    <li>
                        <img src={TimelyPayment} alt="Timely Payment" />
                        <h6>Timely Payment</h6>
                        <p>Enjoy the convenience of prompt and secure payments for your sales, ensuring a hassle-free transaction process.</p>
                    </li>
                    <li>
                        <img src={MarketingPlatform} alt="Marketing Platform" />
                        <h6>Marketing Platform</h6>
                        <p>Leverage our marketing efforts and expertise to boost the visibility of your jewelry, helping you connect with more customers and enhancing your brand presence.Experience growth, efficiency, and success in the jewelry industry by registering as a seller on Summera Jewels today! </p>
                    </li>
                </ul>
            </section>

            <section className="testimonial seller-says">
                <div className="container">
                    <div className="inner-wrapper">
                        <h2>What Our Seller Says</h2>
                        <div className="testi-icon">
                            <FaQuoteLeft />
                        </div>
                        
                        <Slider {...settings}>
                           {
                             [1,2,3,4].map((item,index) =>{
                                return <Testimonial key={index}
                                content="Lorem ipsum dummy text lorem dummy text ipsum dummy text lorem dummy text ipsum dummy text lorem dummy text ipsum dummy text lorem dummy text ipsum dummy text lorem dummy text ipsum dummy text "
                                imgsrc={Ratan}
                                />
                             })
                           }
                        </Slider>

                    </div>
                </div>
          </section>

            <section className='sell-faq-accordion'>

                <h2>Frequently asked questions</h2>

                {
                    data.map((items, index) => {
                        return (
                            <Accordion open={open} toggle={toggle} key={index}>
                                <AccordionItem>
                                    <AccordionHeader targetId={index.toString()}>{items.title}
                                        <span className='plusicon'><IoIosArrowDown size={20} /></span>
                                        <span className='minusicon'><IoIosArrowForward size={20} /></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId={index.toString()}>
                                        <p>{items.content1}</p>
                                        <p>{items.content2}</p>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        )
                    })
                }

            </section>

        </div>
        {
            loader ? <PageLoader /> : null
        }
    </div>
  )
}

export default SellOnSummera