import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Rings from '../assets/images/products/rings.png'
import EarRings from '../assets/images/products/earing.png'
import Pendents from '../assets/images/products/pendant.png'
import Bangles from '../assets/images/products/bangles.png'
import Bracelets from '../assets/images/products/bracelets.png'
import Necklaces from '../assets/images/products/neckless.png'
import Mangalsutra from '../assets/images/products/mangalsutra.png'
import Chains from '../assets/images/products/chains.png'
import NosePins from '../assets/images/products/nose-pins.png'
import OffersDeals from '../assets/images/icons/Icon_Offer.svg'
import DiamondRings from '../assets/images/products/diamonds/diamond-rings.png'
import DiamondEarRings from '../assets/images/products/diamonds/diamond-earrings.png'
import DiamondPendants from '../assets/images/products/diamonds/diamond-pendants.png'
import DiamondBangles from '../assets/images/products/diamonds/diamond-bangles.png'
import DiamondBracelets from '../assets/images/products/diamonds/diamond-bracelets.png'
import DiamondNecklaces from '../assets/images/products/diamonds/diamond-necklaces.png'
import DiamondMangalsutra from '../assets/images/products/diamonds/diamond-mangalsutra.png'
import DiamondNosePins from '../assets/images/products/diamonds/diamond-nosepins.png'
import SolitaireRings from '../assets/images/products/solitaire/solitaire-rings.png'
import SolitaireEarRings from '../assets/images/products/solitaire/solitaire-earrings.png'
import SolitairePendants from '../assets/images/products/solitaire/solitaire-pendants.png'
import SolitaireBangles from '../assets/images/products/solitaire/solitaire-bangles.png'
import SolitaireBracelets from '../assets/images/products/solitaire/solitaire-bracelets.png'
import SolitaireNecklaces from '../assets/images/products/solitaire/solitaire-necklaces.png'
import SolitaireMangalsutra from '../assets/images/products/solitaire/solitaire-mangalsutra.png'
import SolitaireChains from '../assets/images/products/solitaire/solitaire-chains.png'
import SolitaireNosePins from '../assets/images/products/solitaire/solitaire-nosepins.png'
import GemstonesRings from '../assets/images/products/gemstone/gemstones-rings.png'
import GemstonesEarRings from '../assets/images/products/gemstone/gemstones-earrings.png'
import GemstonesPendants from '../assets/images/products/gemstone/gemstones-pendants.png'
import GemstonesBangles from '../assets/images/products/gemstone/gemstones-bangles.png'
import GemstonesNecklaces from '../assets/images/products/gemstone/gemstones-necklaces.png'
import GemstonesAccessories from '../assets/images/products/gemstone/gemstones-accessories.png'
import PlatinumRings from '../assets/images/products/platinum/platinum-rings.png'
import PlatinumEarRings from '../assets/images/products/platinum/platinum-earrings.png'
import PlatinumPendants from '../assets/images/products/platinum/platinum-pendants.png'
import PlatinumBangles from '../assets/images/products/platinum/platinum-bangles.png'
import PlatinumBracelets from '../assets/images/products/platinum/platinum-bracelets.png'
import PlatinumNecklaces from '../assets/images/products/platinum/platinum-necklaces.png'
import PlatinumChain from '../assets/images/products/platinum/platinum-chain.png'
import SilverRings from '../assets/images/products/silver/silver-rings.png'
import SilverEarRings from '../assets/images/products/silver/silver-earrings.png'
import SilverPendants from '../assets/images/products/silver/silver-pendants.png'
import SilverBangles from '../assets/images/products/silver/silver-bangles.png'
import SilverBracelets from '../assets/images/products/silver/silver-bracelets.png'
import SilverNecklaces from '../assets/images/products/silver/silver-necklaces.png'
import SilverMangalsutra from '../assets/images/products/silver/silver-mangalsutra.png'
import SilverChains from '../assets/images/products/silver/silver-chains.png'
import SilverNosePins from '../assets/images/products/silver/silver-nosepins.png'
import WeddingJewelry from '../assets/images/occassion/wedding-jewelry.png'
import ModernManglsutra from '../assets/images/occassion/modern-manglsutra.png'
import Gifts from '../assets/images/occassion/gifts.png'
import EngagementRings from '../assets/images/occassion/engagement-rings.png'
import EverydayWearBracelets from '../assets/images/occassion/everyday-wear-bracelets.png'
import GorgeousStuds from '../assets/images/occassion/gorgeous-studs.png'

import { GiHamburgerMenu } from 'react-icons/gi';

export default function Navbar() {

	const navigate = useNavigate()

	const handleRoute = () =>{

	}
//   const [menu, setMenu] = useState(false)

//   const handleOnClick = () => {
//     setMenu( !menu )
//   }
//   const handleClose = () => {
//     setMenu( false )
//   }

  return (
    
    <nav className="navbar navbar-expand-lg navbar-light">
		<div className="container">
			<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				<span>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</span>
			</button>
			<div className="collapse navbar-collapse" id="navbarNavDropdown">
				<ul className="navbar-nav">
				    <li className="nav-item all-items">
						<GiHamburgerMenu />
						<Link className="nav-link" to={''}>All Jewelry</Link>	
					</li>
					<li className='nav-item'>
						<span className="nav-link">Exclusive Designs</span>
					</li>
					<li className="nav-item ">
					<span className="nav-link" onClick={()=>navigate('/gold', {state: ['Gold']})}>Gold</span>
						<ul className="sub-menu-block">
							<li>
								<Link to={'/gold/rings'} className="cat"> <img src={Rings} alt="Rings" title="Rings" /> Rings</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Engagement</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Fashion</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Cocktail</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Bands</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Adjustable Rings </Link></li>
								</ul>
							
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={EarRings} alt="EarRings" title="EarRings" />Earrings</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Studs</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Jhumkas</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Earcuffs</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Chandbali</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Drops</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Hoops & Huggies	Sudhaga</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={Pendents} alt="Pendents" title="Pendents" />Pendant</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Geometric</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Floral</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Abstract</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Hearts</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Bow</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Alphabet</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Feather</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Butterfly</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Hats</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Pendant Sets</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={Bangles} alt="Bangles" title="Bangles" />Bangles</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Single line</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Thin bangles</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Broad Bangles</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Oval</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Stackable</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Traditional </Link></li>
									<li><Link to={'/gold/rings/engagement'}>Kangan</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Daily Wear </Link></li>
									<li><Link to={'/gold/rings/engagement'}>Light weight</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={Bracelets} alt="Bracelets" title="Bracelets" />Bracelets</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Flexible Bracelet</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Chain Bracelet</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Mangalsutra Bracelets</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Oval Bracelet</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Nazaria Bracelet</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Name Bracelets</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Cord Bracelet</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Heart Bracelet</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={Necklaces} alt="Necklaces" title="Necklaces" />Necklaces</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Casual Necklace</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Bar Necklace</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Multi Layered</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Round Necklaces</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Heart necklace</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Emerald Necklace</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Evil Eye Necklace</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Necklace Sets</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={Mangalsutra} alt="Mangalsutra" title="Mangalsutra" />Mangalsutra</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Nallapasalu</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Regular</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={Chains} alt="Chains" title="Chains" />Chains</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Thin Chains</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Rope Chains</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Cable Chains</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/gold/rings'} className="cat"><img src={NosePins} alt="NosePins" title="NosePins" />Nose Pins</Link>
								<ul>
									<li><Link to={'/gold/rings/engagement'}>Wire</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Screw</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Mukku Pudaka</Link></li>
									<li><Link to={'/gold/rings/engagement'}>Round Nose Pins</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="nav-item">
						{/* <Link className="nav-link" to={'/diamond'}>Diamond</Link> */}
						<span className="nav-link" onClick={()=>navigate('/diamond', {state: ['Diamond']})}>Diamond</span>
                        <ul className="sub-menu-block">
							<li>
								<Link to={'/diamond/rings'} className="cat"> <img src={DiamondRings} alt="Rings" title="Rings" />Rings</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Engagement</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Cocktail</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Bands</Link></li>
								</ul>
							
							</li>
							<li>
								<Link to={'/diamond/rings'} className="cat"><img src={DiamondEarRings} alt="EarRings" title="EarRings" />Earrings</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Studs</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Jhumkas</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Drops</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Hoops & Huggies</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/diamond/rings'} className="cat"><img src={DiamondPendants} alt="Pendants" title="Pendants" />Pendant</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Fancy</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Personalised</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Alphabets</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>God</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/diamond/rings'} className="cat"><img src={DiamondBangles} alt="Bangles" title="Bangles" />Bangles</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Pola</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Noa</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/diamond/rings'} className="cat"><img src={DiamondBracelets} alt="Bracelets" title="Bracelets" />Bracelets</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Fancy</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Wristlet</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/diamond/rings'} className="cat"><img src={DiamondNecklaces} alt="Necklaces" title="Necklaces" />Necklaces</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Wedding</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Party</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/diamond/rings'} className="cat"><img src={DiamondMangalsutra} alt="Mangalsutra" title="Mangalsutra" />Mangalsutra</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Nallapasalu</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Regular</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/diamond/rings'} className="cat"><img src={DiamondNosePins} alt="NosePins" title="NosePins" />Nose Pins</Link>
								<ul>
									<li><Link to={'/diamond/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/diamond/rings/engagement'}>Fancy</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to={'/solitaire'}>Solitaire</Link>
                        <ul className="sub-menu-block">
							<li>
								<Link to={'/solitaire/rings'} className="cat"> <img src={SolitaireRings} alt="Rings" title="Rings" /> Rings</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Engagement</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Fashion</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Cocktail</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Bands</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Adjustable Rings </Link></li>
								</ul>
							
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitaireEarRings} alt="EarRings" title="EarRings" />Earrings</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Studs</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Jhumkas</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Earcuffs</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Chandbali</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Drops</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Hoops & Huggies</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Sudhaga</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitairePendants} alt="Pendants" title="Pendants" />Pendant</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Geometric</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Floral</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Abstract</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Hearts</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Bow</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Alphabet</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Feather</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Butterfly</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Hats</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Pendant Sets</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitaireBangles} alt="Bangles" title="Bangles" />Bangles</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Single line</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Thin bangles</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Broad Bangles</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Oval</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Stackable</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Traditional</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Kangan</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Daily Wear </Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Light weight</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitaireBracelets} alt="Bracelets" title="Bracelets" />Bracelets</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Flexible Bracelet</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Chain Bracelet</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Mangalsutra Bracelets</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Oval Bracelet</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Nazaria Bracelet</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Name Bracelets</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Cord Bracelet</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Heart Bracelet</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitaireNecklaces} alt="Necklaces" title="Necklaces" />Necklaces</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Casual Necklace</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Bar Necklace</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Multi Layered</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Round Necklaces</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Heart necklace</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Emerald Necklace</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Evil Eye Necklace</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Necklace Sets</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitaireMangalsutra} alt="Mangalsutra" title="Mangalsutra" />Mangalsutra</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Nallapasalu</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Regular</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitaireChains} alt="Chains" title="Chains" />Chains</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Thin Chains</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Rope Chains</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Cable Chains</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/solitaire/rings'} className="cat"><img src={SolitaireNosePins} alt="NosePins" title="NosePins" />Nose Pins</Link>
								<ul>
									<li><Link to={'/solitaire/rings/engagement'}>Wire</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Screw</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Mukku Pudaka</Link></li>
									<li><Link to={'/solitaire/rings/engagement'}>Round Nose Pins</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="nav-item gemstone">
						<Link className="nav-link" to={'/jadau'}>Jadau</Link>
                        <ul className="sub-menu-block">
							<li>
								<Link to={'/jadau/rings'} className="cat"> <img src={GemstonesRings} alt="Rings" title="Rings" /> Rings</Link>
								<ul>
									<li><Link to={'/jadau/rings/engagement'}>Brands</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Broad Rings</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Daily Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Office Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Casual Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Party Wear</Link></li>
								</ul>
							
							</li>
							<li>
								<Link to={'/jadau/rings'} className="cat"><img src={GemstonesEarRings} alt="EarRings" title="EarRings" />Earrings</Link>
								<ul>
									<li><Link to={'/jadau/rings/engagement'}>Studs</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Drops</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Jhumkas</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Chandbali</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Daily Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Office Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Casual Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Party Wear</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/jadau/rings'} className="cat"><img src={GemstonesPendants} alt="Pendants" title="Pendants" />Pendant</Link>
								<ul>
                                    <li><Link to={'/jadau/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Daily Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Office Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Casual Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Party Wear</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/jadau/rings'} className="cat"><img src={GemstonesBangles} alt="Bangles" title="Bangles" />Bangles</Link>
								<ul>
									<li><Link to={'/jadau/rings/engagement'}>Kangan</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Hollow</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Light weight</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Kada</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Oval</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Cuff</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Eternity</Link></li>
                                    <li><Link to={'/jadau/rings/engagement'}>Daily Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Office Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Casual Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Party Wear</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/jadau/rings'} className="cat"><img src={GemstonesNecklaces} alt="Necklaces" title="Necklaces" />Necklaces</Link>
								<ul>
									<li><Link to={'/jadau/rings/engagement'}>Long</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Close to Neck</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Semi Long</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Chocker</Link></li>
                                    <li><Link to={'/jadau/rings/engagement'}>Daily Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Office Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Casual Wear</Link></li>
									<li><Link to={'/jadau/rings/engagement'}>Party Wear</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/jadau/rings'} className="cat"><img src={GemstonesAccessories} alt="Accessories" title="Accessories" />Accessories</Link>
								<ul>
									<li><Link to={'/jadau/rings/engagement'}>Waist Belt / Vaddanam</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="nav-item platinum">
						<Link className="nav-link" to={'/platinum'}>Platinum</Link>
                        <ul className="sub-menu-block">
							<li>
								<Link to={'/platinum/rings'} className="cat"> <img src={PlatinumRings} alt="Rings" title="Rings" /> Rings</Link>
								<ul>
									<li><Link to={'/platinum/rings/engagement'}>Engagement</Link></li>
									<li><Link to={'/platinum/rings/engagement'}>Cocktail</Link></li>
								</ul>
							
							</li>
							<li>
								<Link to={'/platinum/rings'} className="cat"><img src={PlatinumEarRings} alt="EarRings" title="EarRings" />Earrings</Link>
								<ul>
									<li><Link to={'/platinum/rings/engagement'}>Studs</Link></li>
									<li><Link to={'/platinum/rings/engagement'}>Drops</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/platinum/rings'} className="cat"><img src={PlatinumPendants} alt="Pendants" title="Pendants" />Pendant</Link>
								<ul>
                                    <li><Link to={'/platinum/rings/engagement'}>Fancy</Link></li>
									<li><Link to={'/platinum/rings/engagement'}>Chain</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/platinum/rings'} className="cat"><img src={PlatinumBangles} alt="Bangles" title="Bangles" />Bangles</Link>
								<ul>
									<li><Link to={'/platinum/rings/engagement'}>Churi</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/platinum/rings'} className="cat"><img src={PlatinumBracelets} alt="Bracelets" title="Bracelets" />Bracelets</Link>
								<ul>
									<li><Link to={'/platinum/rings/engagement'}>Wristlet</Link></li>
									<li><Link to={'/platinum/rings/engagement'}>Charmslet</Link></li>
									<li><Link to={'/platinum/rings/engagement'}>Fancy</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/platinum/rings'} className="cat"><img src={PlatinumNecklaces} alt="Necklaces" title="Necklaces" />Necklaces</Link>
								<ul>
									<li><Link to={'/platinum/rings/engagement'}>Fancy</Link></li>
									<li><Link to={'/platinum/rings/engagement'}>Sleek</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/platinum/rings'} className="cat"><img src={PlatinumChain} alt="Chain" title="Chain" />Chain</Link>
								<ul>
									<li><Link to={'/platinum/rings/engagement'}>Fancy</Link></li>
									<li><Link to={'/platinum/rings/engagement'}>Double</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to={'/silver'}>Silver</Link>
                        <ul className="sub-menu-block">
							<li>
								<Link to={'/silver/rings'} className="cat"> <img src={SilverRings} alt="Rings" title="Rings" /> Rings</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Engagement</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Fashion</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Casual</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Cocktail</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Bands</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Adjustable Rings </Link></li>
								</ul>
							
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverEarRings} alt="EarRings" title="EarRings" />Earrings</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Studs</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Jhumkas</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Earcuffs</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Chandbali</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Drops</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Hoops & Huggies</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Sudhaga</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverPendants} alt="Pendants" title="Pendants" />Pendant</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Geometric</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Floral</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Abstract</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Hearts</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Bow</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Alphabet</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Feather</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Butterfly</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Hats</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Pendant Sets</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverBangles} alt="Bangles" title="Bangles" />Bangles</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Single line</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Thin bangles</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Broad Bangles</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Oval</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Stackable</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Traditional</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Kangan</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Daily Wear </Link></li>
									<li><Link to={'/silver/rings/engagement'}>Light weight</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverBracelets} alt="Bracelets" title="Bracelets" />Bracelets</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Flexible Bracelet</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Chain Bracelet</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Mangalsutra Bracelets</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Oval Bracelet</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Nazaria Bracelet</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Name Bracelets</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Cord Bracelet</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Heart Bracelet</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverNecklaces} alt="Necklaces" title="Necklaces" />Necklaces</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Casual Necklace</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Bar Necklace</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Multi Layered</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Round Necklaces</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Heart necklace</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Emerald Necklace</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Evil Eye Necklace</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Necklace Sets</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverMangalsutra} alt="Mangalsutra" title="Mangalsutra" />Mangalsutra</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Nallapasalu</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Regular</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverChains} alt="Chains" title="Chains" />Chains</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Thin Chains</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Rope Chains</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Cable Chains</Link></li>
								</ul>
							</li>
							<li>
								<Link to={'/silver/rings'} className="cat"><img src={SilverNosePins} alt="NosePins" title="NosePins" />Nose Pins</Link>
								<ul>
									<li><Link to={'/silver/rings/engagement'}>Wire</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Screw</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Mukku Pudaka</Link></li>
									<li><Link to={'/silver/rings/engagement'}>Round Nose Pins</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li className="nav-item coins">
						<Link className="nav-link" to={'/coins-bar'}>Coins / Bar</Link>
                        <ul className="sub-menu-block">
							<li>
                                <ul>
									<li><Link to={'/coins-bar/gold-coins-bar'}>Gold Coins / Bar</Link></li>
									<li><Link to={'/coins-bar/silver-coins-bar'}>Silver Coins / Bar</Link></li>
								</ul>
							</li>
                        </ul>
					</li>
					<li className="nav-item occassion">
						<Link className="nav-link" to={''}>Occasion</Link>
                        <ul className="sub-menu-block">
							<li>
                                <ul>
                                    <li>
                                        <Link to={'/wedding-jewelry'}>
                                            <img src={WeddingJewelry} alt='Wedding Jewelry' />
                                            <span>Wedding Jewelry</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/modern-manglsutra'}>
                                            <img src={ModernManglsutra} alt='Modern Manglsutra' />
                                            <span>Modern Manglsutra</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/gifts'}>
                                            <img src={Gifts} alt='Gifts' />
                                            <span>Gifts</span>
                                        </Link>
                                    </li>
                                </ul>
							</li>
                            <li>
                                <ul>
                                    <li>
                                        <Link to={'/engagement-rings'}>
                                            <img src={EngagementRings} alt='Engagement Rings' />
                                            <span>Engagement Rings</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/everyday-wear-bracelets'}>
                                            <img src={EverydayWearBracelets} alt='Everyday Wear Bracelets' />
                                            <span>Everyday Wear Bracelets</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/gorgeous-studs'}>
                                            <img src={GorgeousStuds} alt='Gorgeous Studs' />
                                            <span>Gorgeous Studs</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
					</li>
					<li className="nav-item">
						{/* <Link className="nav-link" to={'/customised'}>Customised</Link> */}
						<Link className="nav-link" to={'/shop-by-brand'}>Bridal </Link>
					</li>
					{/* <li className="nav-item">
						<Link className="nav-link" to={'/gifts'}>Gifts</Link>
					</li> */}
					<li className="nav-item">
						<Link className="nav-link" to={'#'}><img src={OffersDeals} alt='Offers / Deals' /> Offers / Deals</Link>
					</li>
					{/* <li className="nav-item cities">
						<span className="nav-link" onClick={()=>navigate('/cities', {state: ['Cities']})}>Cities</span>
						<ul className="sub-menu-block">
							<li>
                                <ul>
									<li><Link to={'#'}>Mumbai</Link></li>
									<li><Link to={'#'}>Delhi</Link></li>
									<li><Link to={'#'}>Jaipur</Link></li>
									<li><Link to={'#'}>Banglore</Link></li>
									<li><Link to={'#'}>Patna</Link></li>
								</ul>
							</li>
                        </ul>
					</li> */}
					<li className="nav-item mobile-purpose">
						<Link className="nav-link" to={'/find-store'}>Find Store</Link>
					</li>
					<li className="nav-item mobile-purpose">
						<Link className="nav-link" to={'/sell-on-summera'}>Sell On Summera</Link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
  )
}
