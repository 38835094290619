import { put } from 'redux-saga/effects';
import loginApi from "../services/loginApi";
import { START_LOADING, RESET_LOADER } from '../redux/types/loader';

let api = new loginApi();

export function* paymentGetwaySaga({ payload, resolve }) {  

    try {
        yield put({ type: START_LOADING, isLoading: true })
        let data = yield api.PaymentGetway(payload);
        resolve && resolve(data)
    } catch (e) {
        alert(e)
    }
}