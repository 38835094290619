import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import TextInput from "../../components/molecules/input";
import Reactselect from "../../components/molecules/select";
import { alphabatesWithSpace, isValidEmail, isValidMobileNo, numberText } from "../../helper/method";

const CustomiseRequest = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [fullName, setFullName] = useState("");
    const [stdcode, setStdcode] = useState("IN 91");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [productType, setProductType] = useState("Diamond");
    const [category, setCategory] = useState("Rings");
    const [subCategory, setSubCategory] = useState("Engagement");
    const [product, setProduct] = useState("Gold Ring Lorem - SJERN23568");
    const [purity, setPurity] = useState("14 KT Yellow Gold");
    const [color, setColor] = useState("Rose");

    const productTypeOptions = [
        { id: 1, value: "Gold", label: "Gold" },
        { id: 2, value: "Diamond", label: "Diamond" },
        { id: 3, value: "Solitaire", label: "Solitaire" },
        { id: 4, value: "Gemstones", label: "Gemstones" },
        { id: 5, value: "Platinum", label: "Platinum" },
        { id: 6, value: "Silver", label: "Silver" },
        { id: 7, value: "Coins / Bar", label: "Coins / Bar" }
    ]

    const categoryOptions = [
        {id: 1, value: "Rings", label: "Rings"},
        {id: 2, value: "Earrings", label: "Earrings"},
        {id: 3, value: "Pendant", label: "Pendant"},
        {id: 4, value: "Bangles", label: "Bangles"},
        {id: 5, value: "Bracelets", label: "Bracelets"},
        {id: 6, value: "Necklaces", label: "Necklaces"},
        {id: 7, value: "Mangalsutra", label: "Mangalsutra"},
        {id: 8, value: "Chains", label: "Chains"},
        {id: 9, value: "Nose Pins", label: "Nose Pins"}
    ]

    const subCategoryOptions = [
        {id: 1, value: "Engagement", label: "Engagement"},
        {id: 2, value: "Wedding", label: "Wedding"},
        {id: 3, value: "Birthday", label: "Birthday"},
    ]

    const productOptions = [
        {id: 1, value: "Gold Ring Lorem - SJERN23568", label: "Gold Ring Lorem - SJERN23568"},
        {id: 2, value: "Silver Ring Lorem - SJERN23568", label: "Silver Ring Lorem - SJERN23568"},
        {id: 3, value: "Diamond Ring Lorem - SJERN23568", label: "Diamond Ring Lorem - SJERN23568"},
    ]

    const purityOptions = [
        {id: 1, value: "18 KT", label: "18 KT"},
        {id: 2, value: "24 KT", label: "24 KT"},
    ]

    const colorOptions = [
        {id: 1, value: "Rose", label: "Rose"},
        {id: 2, value: "Yellow", label: "Yellow"},
    ]
    const [errors, setErrors] = useState({
        errfullName: "",
        errEmail: "",
        errMobileno: "",
        errProductType: "",
        errCategory: "",
        errSubCategory: "",
        errProduct: "",
        errMetal: ""
    }) 

    const handleStateChange = (name, value) => {

        if (name === "fullName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setFullName(check)
                handleError(name, value)
            }
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "mobile"){
            let check = value
            if(numberText(check)){
                setMobile(check)
                handleError(name,value)
            }
        }
        if(name === "productType"){
            setProductType(value)
            handleError(name,value)
        }
        if(name === "category"){
            setCategory(value)
            handleError(name,value)
        }
        if(name === "subCategory"){
            setSubCategory(value)
            handleError(name,value)
        }
        if(name === "product"){
            setProduct(value)
            handleError(name,value)
        }
        if(name === "purity"){
            setPurity(value)
            handleError(name,value)
        }
        if(name === "color"){
            setColor(value)
            handleError(name,value)
        }
    }
    
    const handleError = (name, value) => {
    
        // console.log("handle OnBlure Change", name, "value", value)
    
        let allState = errors;
    
        if (name === "fullName") {
            if (!value.length) {
                allState["errfullName"] = "Enter your full name"
            } else if (value.length < 3) {
                allState["errfullName"] = "Name length should be 3 charector"
            } else {
                allState["errfullName"] = ""
            }
        }
        if(name === "mobile"){
            if(!value.length){
                allState["errMobileno"] = "Mobile no is required."
            }else if(!isValidMobileNo(value)){
                allState["errMobileno"] = "Enter a valid number."
            }else{
                allState["errMobileno"] = ""
            }
        }
        if(name === "email"){
            if(!value.length){
                allState["errEmail"] = "Enter your email"
            }else if(!isValidEmail(value)){
                allState["errEmail"] = "Invailid email."
            }else{
                allState["errEmail"] = ""
            }
        }
        if(name === "productType"){
            if(!value.length){
                allState["errproductType"] = "Select Product Type."
            }else{
                allState["errproductType"] = ""
            }
        }
        if(name === "category"){
            if(!value.length){
                allState["errCategory"] = "Select Category."
            }else{
                allState["errCategory"] = ""
            }
        }
        if(name === "subCategory"){
            if(!value.length){
                allState["errSubCategory"] = "Select Sub Category."
            }else{
                allState["errSubCategory"] = ""
            }
        }
        if(name === "product"){
            if(!value.length){
                allState["errproduct"] = "Select Product."
            }else{
                allState["errProduct"] = ""
            }
        }
        if(name === "metal"){
            if(!value.length){
                allState["errMetal"] = "Select Metal."
            }else{
                allState["errMetal"] = ""
            }
        }
        // setErrors(prevError => ({
        //     ...prevError,
        //     ...allState
        // }))
    }

    return (
        <div className='wrapper customise-request'>
            <div className="container main-div">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Customise Request</li>
                    </ol>
                </nav>

                <Link className="btn btn-primary back-button" to={'/productdetails'}><span>Back</span></Link>

                <div className="login-box">
                    <div className="login-wrapper">
                        <h2>Customise Request</h2>
                        <h5>Please fill the below details.</h5>
                        <h6>* Mandatory Fields</h6>

                        <div className="form-group">
                            <label>Full Name*</label>
                            <TextInput
                                type={"text"}
                                name={"fullName"}
                                placeholder={"Enter First and Last Name"}
                                inputClass={"form-control"}
                                lblClass={"input-lable"}
                                onChange={handleStateChange}
                                onBlur={handleError}
                                value={fullName}
                                autoComplete="off"
                                errorMSg={errors.errfullName}
                            />
                        </div>

                        <div className="select-group">

                            <li className="form-group mobile">
                                <label>Mobile Number*</label>
                                <div className="cust-select">
                                <select 
                                    required="required" 
                                    className="form-control form-select"
                                    value={stdcode}
                                    onChange={(e) =>handleStateChange(e)}
                                    name="stdcode">
                                    <option value="">Select</option>
                                    {
                                    <option value={stdcode}>{stdcode}</option>
                                    }
                                </select>
                                <TextInput
                                    type={"tel"}
                                    value={mobile}
                                    name={"mobile"}
                                    placeholder={""}
                                    inputClass={"form-control"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    autoComplete="off"
                                    // errorMSg={errors.errMobileno}
                                    disabled={true}
                                />
                                
                                </div>
                            </li>

                            <li className="form-group">
                                <label>Email*</label>
                                <TextInput
                                    type={"text"}
                                    name={"email"}
                                    inputClass={"form-control"}
                                    lblClass={"input-lable"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    label={"Email ID*"}
                                    value={email}
                                    autoComplete="off"
                                    errorMSg={errors.errEmail}
                                />
                            </li>

                            <li className="form-group">
                                <label className="price-info" htmlFor="productType">Category*</label>
                                <Reactselect
                                    name={"productType"}
                                    id={"productType"}
                                    options={productTypeOptions}
                                    value={productType}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errState}
                                />
                            </li>

                            <li className="form-group">
                                <label className="price-info" htmlFor="category">Sub Category*</label>
                                <Reactselect
                                    name={"category"}
                                    id={"category"}
                                    options={categoryOptions}
                                    value={category}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errState}
                                />
                            </li>

                            <li className="form-group">
                                <label className="price-info" htmlFor="subCategory">Product Type*</label>
                                <Reactselect
                                    name={"subCategory"}
                                    id={"subCategory"}
                                    options={subCategoryOptions}
                                    value={subCategory}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errState}
                                />
                            </li>

                            <li className="form-group">
                                <label className="price-info" htmlFor="product">Product*</label>
                                <Reactselect
                                    name={"product"}
                                    id={"product"}
                                    options={productOptions}
                                    value={product}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errState}
                                />
                            </li>

                            <li className="form-group">
                                <label className="price-info" htmlFor="purity">Purity*</label>
                                <Reactselect
                                    name={"purity"}
                                    id={"purity"}
                                    options={purityOptions}
                                    value={purity}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errState}
                                />
                            </li>

                            <li className="form-group">
                                <label className="price-info" htmlFor="color">Color</label>
                                <Reactselect
                                    name={"color"}
                                    id={"color"}
                                    options={colorOptions}
                                    value={color}
                                    class={"form-select"}
                                    onChange={handleStateChange}
                                    onBlur={handleError}
                                    errorMsg={errors.errState}
                                />
                            </li>

                        </div>    

                        <div className="form-group textarea">
                            <label className="price-info" htmlFor="customise-request">Enter here Customise Request</label>
                            <textarea rows="4" name="customise-request" />
                        </div>

                        <div className="customise">
                            <Link className="btn btn-primary" to={'/video-call-thank-you'}><span>Customise Now</span></Link>
                        </div>
                    </div>  
                </div>  
            </div>
        </div>
    )
}


export default CustomiseRequest;