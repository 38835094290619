import React from 'react'
import ring from '../../assets/images/ring-prod-detail-11.jpg';
import { Link } from 'react-router-dom'
import './index.css'
import { AiOutlineFilePdf } from "react-icons/ai";
import { FaTruck } from "react-icons/fa";
import { BiRupee } from 'react-icons/bi';


export default function index() {
document.body.classList.add('thankyou');    
  return (
    <div className='thankyou-wrapper'>
        <div className='container'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                    <li className="breadcrumb-item active">Thank You</li>
                </ol>
            </nav>
        </div>
        <div className='d-flex'>
            <div className='inner'>
            <div className="heading">
                <div className="container">
                <h2>Thank you for your Order</h2>
                <h4>Your payment has been received. We’ll initiate your order shortly</h4>
                </div>
            </div>

            <div className="order-info">
                <div className="container">
                <div className="track-order">      
                    <div className="order-details">
                    <ul>
                        <li>Order Number : <span>2326562</span></li>
                        <li>Order Status : <span>Confirmed</span></li>
                    </ul>
                    </div>
                    <div className="track-order-details">
                    <ul>
                        <li><Link to={''} className="btn btn-secondary"><FaTruck /> Track Order</Link></li>
                        <li><Link to={''} className="btn btn-secondary"><AiOutlineFilePdf /> Download Invoice</Link></li>
                    </ul>       
                    </div>
                </div>
                </div>
            </div>

            <div className="address-details">
                <div className="container">
                <div className="billing-shipping">
                    <ul>
                    <li>
                        <h4>Billing Address</h4>
                        <div className="custom-address">
                        <h6>Kaushal Ranpura</h6>
                        <address>501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400 045. India</address>
                        </div>
                    </li>
                    <li>
                        <h4>Shipping Address</h4>
                        <div className="custom-address">
                        <h6>Kaushal Ranpura</h6>
                        <address>501, A, Jyouti Tower, Thakur Village, M Rao Marg, Kandivali (E), Mumbai, Maharashtra, 400 045. India</address>
                        </div>
                    </li>
                    {/* <li>
                        <h4>Pickup Store Address</h4>
                        <div className="custom-address">
                        <h6>Summera Jewels Shop</h6>
                        <address>Shop No. 10, Rizvi Mahal, R K Patkar Marg - Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.</address>
                        </div>
                    </li> */}
                    </ul>
                </div>
                </div>
            </div>

            <div className="thank-order-summary">
                <div className="container">
                
                    <div className="your-order-summary"> 
                            
                        <div className="left">  
                            <h4>Your order summary</h4> 
                            <div className='left-wrapper'>
                                <ul>
                                    <li>
                                        <div className="order-product">
                                            <div className="order_product_details">
                                                <ul>
                                                    <li>
                                                        <figure>
                                                            <img src={ring} className="card-img-top" alt="Ear Rings" title="Ear Rings" />
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <h4>Gold Ear Ring Lorem Ipsum</h4>
                                                        <h6>18 KT Yellow Gold (3.400 g)</h6>
                                                        <h6>Ring Size: 23 (62.8 mm)</h6>
                                                    </li>                  
                                                </ul>
                                                <ul className="second-list">
                                                    <li>QTY: 1</li>
                                                    <li><BiRupee />1,09,999</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="right">
                            <div className='order-summary'>
                                <div className="summary-data">
                                    <span className="close">&times;</span>
                                    
                                    <ul>
                                        <li>
                                            <h5>Sub Total</h5>
                                            <span className="amount"><BiRupee />1,09,999</span>
                                        </li>
                                        <li>
                                            Discount<span className="amount"><BiRupee />10,000</span>
                                        </li>
                                        <li>
                                            Saved with Promo<span className="amount"><BiRupee />5,000</span>
                                        </li>
                                        <li>Delivery Charges<span className="amount"><BiRupee />1500</span></li>
                                        <li>
                                            GST<span className="amount"><BiRupee />5,099</span>
                                        </li>
                                        <li>
                                            <h5>Total Amount</h5><span className="amount"><BiRupee />98,598</span>
                                        </li>
                                    </ul>
                                
                                </div>
                            </div>
                            <div className="summary-cancle">
                                <Link className="btn btn-primary btn-cancle" title="Link to Cancel">Cancel</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}
