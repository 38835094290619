import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ring1 from '../../assets/images/products/solitaire/solitaire1.png';
import ring2 from '../../assets/images/products/solitaire/solitaire2.png';
import ring3 from '../../assets/images/products/solitaire/solitaire3.png';
import ring4 from '../../assets/images/products/solitaire/solitaire4.png';
import ReactImageMagnify from 'react-image-magnify';
import { useFormik } from 'formik';
import SignupSchema from './SignupSchema';
import './index.css'
import { BiRupee } from "react-icons/bi";
import { BsBag } from "react-icons/bs";
import certified from '../../assets/images/icons/icon-certified.png';
import BIS from '../../assets/images/icons/bis-hallmark.png';
import IGI from '../../assets/images/icons/igi-hallmark.png';
import Exchange from '../../assets/images/icons/exchange-icon.png';
import Delivery from '../../assets/images/icons/delivery-icon1.png';
import Transparancy from '../../assets/images/icons/search.png';
import ProductAccordion from '../ProductAccordion';
import { FiHeart } from "react-icons/fi";
import { BiShareAlt, BiLink } from "react-icons/bi";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FaInstagramSquare,FaWhatsappSquare,FaFacebookSquare,FaTwitterSquare,FaLinkedin } from "react-icons/fa";
import {MdMail} from "react-icons/md";
import { BsCheckCircle } from "react-icons/bs";
import { viewCartAction } from '../../redux/actions/cartAction';
import ProducCard from '../Card';
import { IoClose } from "react-icons/io5";
import { addCartAction } from '../../redux/actions/cartAction';
// import TryAtHome from '../molecules/TryAtHome';
import TryAtHomePopup from '../atom/TryAtHome';
import VideoCallPopup from '../atom/VideoCall';
import FindYourRingSize from '../molecules/FindYourRingSize';

const images = [
    {
        imgScr: ring1,
        alt: "udtryht"
    },
    {
        imgScr: ring2,
        alt: "udtryht"
    },
    {
        imgScr: ring3,
        alt: "udtryht"
    },
    {
        imgScr: ring4,
        alt: "udtryht"
    },
    
]


const productcat = [
    {
        label: "18 CT Solitaire",
        value: "18 CT Solitaire",
    },
    {
        label: "20 CT Solitaire",
        value: "20 CT Solitaire",
    },
    {
        label: "22 CT Solitaire",
        value: "22 CT Solitaire",
    },
    {
        label: "24 CT Solitaire",
        value: "24 CT Solitaire",
    }
];

const size = [
    {
        label: "23 (62.8mm)",
        value: "23 (62.8mm)",
    },
    {
        label: "25 (62.8mm)",
        value: "25 (62.8mm)",
    },
    {
        label: "23 (62.8mm)",
        value: "23 (62.8mm)",
    },
    {
        label: "25 (62.8mm)",
        value: "25 (62.8mm)",
    },
]; 

const qualitydata = [
    {
        label: "SI-GH",
        value: "SI-GH",
    },
    {
        label: "SI-GI",
        value: "SI-GI",
    },
    {
        label: "SI-IJ",
        value: "SI-IJ",
    },
    {
        label: "VS-FG",
        value: "VS-FG",
    },
    {
        label: "VS-GH",
        value: "VS-GH",
    },
    {
        label: "VS-IJ",
        value: "VS-IJ",
    },
    {
        label: "VS-SH-HI",
        value: "VS-SH-HI",
    },
    {
        label: "VVS-VS-GH",
        value: "VVS-VS-GH",
    },
    {
        label: "VVS-VS-HI",
        value: "VVS-VS-HI",
    },
    {
        label: "VVS-VS-HIJ",
        value: "VVS-VS-HIJ",
    }
];

const colordata = [
    {
        label:"ROSE GOLD",
        value:"ROSE GOLD"
    },
    {
        label:"TWO TONE",
        value:"TWO TONE"
    },
    {
        label:"YELLOW GOLD",
        value:"YELLOW GOLD"
    },
];

function ProductGallery(){
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const dispatch = useDispatch();

    const initialValues = {
        pincode: "",
    }
    const [selectedImg, setSelectedImg] = useState(images[0].imgScr);
    const navRef = useRef(null);
    const [isActive, setActive] = useState("false");
   
    /* For Wishlist */
    const [phoneTooltip, setPhoneTooltip] = useState({
        show: false,
        x: 0,
        y: 0,
        orientLeft: false
      });
      const displayWishlist = event => {
         // 👇️ toggle class on click
         event.currentTarget.classList.toggle('active');
        // console.log("hi");
        if (!phoneTooltip.show) {
          setPhoneTooltip(prev => ({ ...prev, show: true })); // show tooltip
          setTimeout(() => {
            setPhoneTooltip(prev => ({ ...prev, show: false })); // remove/hide tooltip
          }, 2000);
        }
      };

      /* For Add to Cart */
      const [phoneTooltipcart, setPhoneTooltipcart] = useState({
        show: false,
        x: 0,
        y: 0,
        orientLeft: false
      });
      const displayAddtocart = event => {
        dispatch(addCartAction());
         // 👇️ toggle class on click
         event.currentTarget.classList.toggle('disabled');
        // console.log("hi");
        if (!phoneTooltipcart.show) {
          setPhoneTooltipcart(prev => ({ ...prev, show: true })); // show tooltip
          setTimeout(() => {
            setPhoneTooltipcart(prev => ({ ...prev, show: false })); // remove/hide tooltip
          }, 2000);
        }
      };
    

    // form Validation
    const { values, errors, handleBlur, touched, handleChange } = useFormik({
        initialValues: initialValues,
        validationSchema: SignupSchema,
        onSubmit: (values) => {
            // console.log("this is value", values);
        }
    })

    
    // const handleToggle1 = () => {
               
    // };

    useEffect(() => {
        let html = "";
        if (!isActive) {
            html = document.querySelector("html");
            html.classList.add("overflow-hidden")
        } else {
            html = document.querySelector("html");
            html.classList.remove("overflow-hidden");
        }
    }, [isActive])

    


    const handleSelectedImage = (value) => {
        setSelectedImg(value)
    }

    const [copied, setCopied] = useState(false);     

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

    const SelectedImages = () => { 

        return (
            <div className="view-img">
                <ReactImageMagnify
                    enlargedImageContainerClassName="largeimage"
                    lensStyle={{ width: 500 }}

                    {...{
                        smallImage: {
                            alt: `${selectedImg.alt}`,
                            isFluidWidth: true,
                            src: `${selectedImg}`,
                        },
                        largeImage: {
                            src: `${selectedImg}`,
                            width: 1451,
                            height: 1541
                        }
                    }} />
            </div>
        )
    }

    const Images = () => {
        return (
            <div className="flex">
                {images.map((data, index) => {
                    return (
                        <div className="img-div" key={index}>
                            <img className="mini-img" src={data.imgScr} alt="" title="" onClick={() => handleSelectedImage(data.imgScr)} />
                        </div>
                    )
                })}
            </div>
        )
    }

    return(
        <>
            <div ref={navRef} className={`bg-overlay ${isActive ? "" : "show"}`}></div>
            <div className="product-detail">
                <div className="desc">
                    <div className="maain">
                        <Images className="img" />
                        <SelectedImages selectedImg={selectedImg.imgScr} />
                    </div>
                    <div className="details">
                        <div className='product-top'>
                            <h3 className="product-title">SJERN23568</h3>
                            <div className='product-share'>
                                <span className='wishlist-link tooltip-label' data-tooltip="Add To Wishlist">
                                    <FiHeart js-heart="true" onClick={displayWishlist} />
                                </span>
                                <Popup trigger={<span className='share-icon'><BiShareAlt className='shareicon' /></span>} position="bottom center">
                                <span className="close"><IoClose /></span>
                                    <div>     
                                        <Link className='insta' url={"https://www.instagram.com/"} quote={''} hashtag="#">
                                            <FaInstagramSquare  size={25} />
                                        </Link>
                                        <Link className='linkedin' url={"https://in.linkedin.com/"} quote={''} hashtag="#">
                                            <FaLinkedin size={25} />
                                        </Link> 
                                        <Link className='facebook' url={"https://www.facebook.com/"} quote={''} hashtag="#">
                                            <FaFacebookSquare size={25} />
                                        </Link>
                                        <Link className='whatsapp' to={"https://www.whatsapp.com/"} quote={''} hashtag="#">
                                            <FaWhatsappSquare size={25} />
                                        </Link>
                                        <Link className='mail' url={"mailto:"} quote={''} hashtag="#">
                                            <MdMail size={27} />
                                        </Link>
                                        <Link className='twitter' url={"https://twitter.com/"} quote={''} hashtag="#">
                                            <FaTwitterSquare size={25} />
                                        </Link> 
                                        <span className='link' onClick={copy} title={!copied ? "Copy link" : "Copied!"}>
                                            <BiLink size={23} /> 
                                        </span> 
                                            
                                    </div>
                                </Popup>
                            </div>
                            
                        </div>
                        
                        <h1 className="cat">Solitaire Ring</h1>

                        <div className="price">
                            <h3 className="value"> 
                            <BiRupee /> 99,999 
                            <span className='can-price'> <BiRupee /> 1,09,999</span>
                            <span className='offer'>10% off on Making Charges</span>
                            </h3>
                        </div>
                        <div className="product-size">
                        <div className="add">
                                <div className='select-wrapper'> 
                                    <h3 className="title">Metal</h3>
                                    <select className="form-select" id='productcat'>
                                        {productcat.map((productcat,p) => (
                                            <option key={p} value={productcat.value}>{productcat.label}</option>
                                        ))}
                                    </select>
                                    <h3 className='title'>3.400 g</h3>
                                </div>
                                <div className='select-wrapper'>
                                    <h3 className="title">Size <span><Link to="" onClick={onOpenModal}>Don't know your ring size?</Link></span></h3>
                                    <select className="form-select" id='productweight'>
                                        {size.map((size,s) => (
                                            <option key={s} value={size.value}>{size.label}</option>
                                        ))}
                                    </select>
                                </div>    

                                <div className='select-wrapper'> 
                                    <h3 className="title">Quality</h3>
                                    <select className="form-select" id='productcat'>
                                        {qualitydata.map((quality,q) => (
                                            <option key={q} value={quality.value}>{quality.label}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='select-wrapper'> 
                                    <h3 className="title">Colour</h3>
                                    <select className="form-select" id='productcat'>
                                        {colordata.map((color,c) => (
                                            <option key={c} value={color.value}>{color.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <Modal open={open} onClose={onCloseModal} center>
                                    <FindYourRingSize />
                                </Modal>
                            </div>
                            

                            <div className='add btngroup'>
                                <button className="btn btn-primary site-header__btn-menu" 
                                onClick={displayAddtocart}>
                                    <span>
                                        <BsBag />Add to Cart
                                    </span>
                                </button>
                                
                                {/* <Link className="btn btn-secondary" to={'/customise-request'}>Customise</Link> */}
                                <TryAtHomePopup />
                                <VideoCallPopup />
                            </div>
                        </div>
                        <ul className='product-fetures'>
                            <li><img src={certified} alt='100% Certified' title='100% Certified' />100% Certified</li>
                            <li><img src={IGI} alt='IGI Certified' title='IGI Certified' />IGI Certified</li>
                            <li><img src={BIS} alt='BIS Hallmark' title='BIS Hallmark' />BIS Hallmark</li>
                            <li><img src={Exchange} alt='Lifetime Exchange' title='Lifetime Exchange'/>Lifetime Exchange</li>
                            {/* <li><img src={Delivery} alt='Free & Insured Delivery' title='Free & Insured Delivery'/>Free & Insured Delivery</li> */}
                            <li><img src={Transparancy} alt='Complete Transparency' title='Complete Transparency'/>Complete Transparency</li>
                        </ul>
                        <p className="select_delivery"><strong>Select Delivery Location</strong>
                        <span className="enter">Enter the pincode of your area to check product availability and delivery options</span></p>
                        <div className="pin-check">
                            <form className="pin" role="search">
                                <div className="input-group">
                                    <input 
                                        className="form-control" 
                                        type="text" 
                                        name="pincode" 
                                        placeholder="Enter Pincode" 
                                        id="enter-pincode" 
                                        value={values.pincode} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur} 
                                    />
                                    <span className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button">
                                            Check
                                        </button>
                                    </span>
                                </div>
                                {errors.pincode && touched.pincode ? <p className="form-error">{errors.pincode}</p> : null} 

                            </form>
                        </div>
                        <div className='wishlisttooltip'>
                            {phoneTooltip.show && (
                                <button className='btn btn-wishlist'><BsCheckCircle />Added to Wishlist</button>
                            )}
                            {phoneTooltipcart.show && (
                                <button className='btn btn-wishlist'><BsCheckCircle />Added to Cart</button>
                            )}
                        </div>

                        <ProductAccordion  />
                    </div>
                </div>
                <div className="sliderWapper">
                    <h1 className="title">Similar Products</h1>
                    <ProducCard slider={true} slidestoshow ={5} slidestoscroll ={3}  />
                    <div className='text-center mt-4'>
                        <Link className="btn btn-secondary" to={'/product'}>Explore all Rings</Link>                      
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductGallery;