import React from 'react'
import { Link } from 'react-router-dom';
import Card from '../Card'
import { BiRupee } from 'react-icons/bi';

export default function budget_card(props) {
//   console.log(props);
  return (
            
    <div className='card BudgetCard'>
      <Link to={props.link}>
          <img src={props.imgsrc} className='card-img-top' alt='' title='' />
      </Link>
      <div className='card-body'>
          <p>{props.productinfo}</p>
          <div className='price'><span><BiRupee />{props.CorrectAmount}</span>
          </div>
      </div>
    </div>
      
  )
}
