import React, { useState } from 'react';
import ItemTable from '../../../atom/myaccountitems/itemsTable';
import OrderHeader from '../../../atom/myaccountitems/OrderHeader';
import Reactselect from '../../../molecules/select';
import TermsConditions from "../../../molecules/termsConditions";

const orderReturnOption = [
    { id: 1, label: "Select Reason", value: "selectreason" },
    { id: 2, label: "Damaged recevied", value: "damagedrecevied" },
    { id: 3, label: "Wrong product recevied", value: "wrongproductrecevied" },
    { id: 4, label: "Other", value: "other" },
]

const Returnform = (props) => {

    const [orderReturn, setorderReturn] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => {
        setModalOpen(!modalOpen);
    };
    const [errors, setErrors] = useState({
        errReturnMsg: ""
    });

    const handleStateChange = (name, value) => {
        setorderReturn(value);
        handleError(name, value);
    }

    const handleError = (name, value) => {
        let allState = errors
        if (name === "orderReturn") {
            if (!value.length) {
                allState["errReturnMsg"] = "Please select reason";
            } else {
                allState["errReturnMsg"] = "";
            }
        }

        setErrors(prevErr => ({
            ...prevErr,
            ...allState
        }))
    }

    return (
        <div className="Order">
            <div className='top-info'>
                <h2>Return</h2>
                <button type="button" className="backBtnToTab btn btn-secondary" onClick={props.handlingBack}>Back</button>
            </div>

            <OrderHeader orderplace={"02 Jan 2023"} ordernumber={"MAZE202471002"} />

            <div className="order-info">
                <div className="order-details">
                    <h4>Are You sure you want to send a return request?</h4>

                    <ItemTable />

                    <div className="FormDetails">
                        <p>*Mandatory Fields</p>
                        <form>
                            <ul className="form-row">
                                <li className="form-group return">
                                    <Reactselect
                                        name={"orderReturn"}
                                        id={"orderReturn"}
                                        label={"Select Reason for Order Return*"}
                                        options={orderReturnOption}
                                        value={orderReturn}
                                        class={"form-select"}
                                        onChange={handleStateChange}
                                        onBlur={handleError}
                                        errorMsg={errors.errReturnMsg}
                                    />
                                </li>
                            </ul>
                            <div className="form-row">
                                <div className="form-group comments">
                                    <label>Your Comments</label>
                                    <textarea rows="4" cols="50"></textarea>
                                </div>
                            </div>
                        </form>

                        <div className="submitData">
                            <div className="checkbox">
                                <input
                                className="styled-checkbox"
                                id="agree"
                                type="checkbox"
                                checked
                                />
                                <label for="agree">
                                I agree to{" "}
                                <span onClick={handleModalOpen}>
                                    <TermsConditions
                                    modalOpen={modalOpen}
                                    handleModalOpen={handleModalOpen}
                                    />
                                    Terms and Conditions
                                </span>{" "}
                                </label>
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary btn-submit"
                                onClick={() => {
                                props.handleSubmitMessage({
                                    requestModal: "RequestSuccess",
                                    name: "Return",
                                    msg: "Your return request successfully sent to our team.",
                                });
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Returnform