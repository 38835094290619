import React from 'react';
import { Link } from 'react-router-dom';
import Input from '../radio'
import Checkbox from '../checkbox'
import { FiRefreshCw } from "react-icons/fi";

const GoldCoinsBarSideNav = () => {

    const [showprice, setShowprice] = React.useState();
    // const [showsize, setShowsize] = React.useState();
    const [showgifts, setShowgifts] = React.useState();
    const [showbrand, setShowbrand] = React.useState();

    return (
        <ul className='sideNav'>
            <li>
                <div className='filter-toggle'>Filter By
                    <Link to={''} className='clearAll'><FiRefreshCw /> Clear All</Link>
                </div>
            </li>
            
            <li>
                <ul className='filter_lbl'>
                    <li>
                        <ul>
                            <li><h2>Price</h2></li>
                            <div className={`mycheck ${showprice ? "open" : ""}`}>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 0 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 10,000 </span>
                                                <span>(30)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 10,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 20,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                            <Input name="radio" />
                                                <span>₹ 20,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 30,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 30,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 40,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 40,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 50,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 10,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 20,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                            <Input name="radio" />
                                                <span>₹ 20,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 30,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 30,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 40,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Input name="radio" />
                                                <span>₹ 40,001 </span>
                                            </span>
                                            <span className='toPrz'>
                                                <span>₹ 50,000 </span>
                                                <span>(25)</span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                            </div>
                        </ul>
                        <Link to='#' className='knowMore' onClick={() => setShowprice(true)}>More Price</Link>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Categories</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range" />
                                            <span>Gold Coins
                                                <span>(09)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range2" />
                                            <span>Gold Bars
                                                <span>(03)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Weight range</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="radio" />
                                            <span>0 g - 2 g
                                                <span>(40)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="range2" />
                                            <span>2 g - 5 g
                                                <span>(35)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="range3" />
                                            <span>5 g - 10 g 
                                                <span>(55)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="range3" />
                                            <span>10 g - 20 g
                                                <span>(25)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Input name="range4" />
                                            <span> &gt; 30 g
                                                <span>(10)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Metal</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range" />
                                            <span>18Kt Gold
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range2" />
                                            <span>22Kt Gold
                                                <span>(29)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="range2" />
                                            <span>24Kt Gold
                                                <span>(16)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Gifts</h2></li>
                            <div className={`mycheck ${showgifts ? "open" : ""}`}>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Birthday Gifts
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Annivarsary Gifts
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Gifts for Family
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Gifts for Friends
                                                    <span>(95)</span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className='form-group'>
                                        <label>
                                            <span className='fromPrz'>
                                                <Checkbox name="theme" />
                                                <span>Gifts for Wife
                                                    <span>(95)</span>
                                                </span>
                                                
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Gifts for Family
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Gifts for Friends
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Gifts for Wife
                                                <span>(95)</span>
                                            </span>
                                            
                                        </span>
                                    </label>
                                </div>
                            </li>
                            </div>
                        </ul>
                        <Link to={''} className='knowMore' onClick={() => setShowgifts(true)}>More Gifts</Link>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Discounts</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Upto 15% off
                                                <span>(95)</span>
                                            </span>
                                            
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Flat 10% on MRP</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Flat 20% on Making Charges
                                                <span>(95)</span>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Flat 10% on MRP 
                                                <span>(95)</span>
                                            </span>
                                            
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li><h2>Store Brands</h2></li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Anil Jewel Paradise</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Batukbhai Sons Jewellers</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Bella Pierre India</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Bharat Jewellers</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className='form-group'>
                                    <label>
                                        <span className='fromPrz'>
                                            <Checkbox name="theme" />
                                            <span>Damordas Jewellers</span>
                                            <span>(95)</span>
                                        </span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                        <Link to={''} className='knowMore' onClick={() => setShowbrand(true)}>More Store Brands</Link>
                    </li>
                </ul>
            </li>
        </ul>
    );
};

export default GoldCoinsBarSideNav