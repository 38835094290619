import React from 'react'
import "./Slider.css"
import Engagement from '../../../assets/images/engagement-banner.jpg'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Data = [
    { id:1, src:Engagement, title:"Trt at Home"}
]


const SingleImgslider = () =>{
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return(
        <Slider {...settings}>
        {
            Data.map((item,index)=>{
                return(
                    <div className='singleimgslider' key={index}>
                        <figure>
                            <img src={item.src} alt={item.title} title={item.title}/>
                            <div className='container'>
                                <h3>{item.title}</h3>
                            </div>
                        </figure>
                    </div>
                        
                )
          })
        }
        </Slider>
    )
}

export default SingleImgslider;


