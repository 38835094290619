import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { ImLocation } from 'react-icons/im';
import { ImMobile } from 'react-icons/im';
import { HiMail } from 'react-icons/hi';
import VisitOurStore from '../../assets/images/icons/visit_our_store.svg';
import QRCode from '../../assets/images/qrcode.png';
import TextInput from  '../../components/molecules/input'
import { alphabatesWithSpace, isValidEmail, isValidMobileNo, numberText } from "../../helper/method";
import '../../components/Checkout/index.css'

const Contact = () => {

    let hasError = false;

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [stdcode, setStdcode] = useState("IN 91");
    const [mobile, setMobile] = useState("");

    const [errors, setErrors] = useState({
        errfullName: "",
        errEmail: "",
        errMobileno: ""
    }) 

    const handleStateChange = (name, value) => {

        if (name === "fullName") {
            let check = String(value)
            if (alphabatesWithSpace(check)) {
                setFullName(check)
                handleError(name, value)
            }
        }
        if(name === "email"){
            setEmail(value)
            handleError(name,value)
        }
        if(name === "mobile"){
            let check = value
            if(numberText(check)){
                setMobile(check)
                handleError(name,value)
            }
        }
    }
    
    const handleError = (name, value) => {
    
        // console.log("handle OnBlure Change", name, "value", value)
    
        let allState = errors;
    
        if (name === "fullName") {
            if (!value.length) {
                allState["errfullName"] = "Enter your full name"
            } else if (value.length < 3) {
                allState["errfullName"] = "Name length should be 3 charector"
            } else {
                allState["errfullName"] = ""
            }
        }
        if(name === "email"){
            if(!value.length){
                allState["errEmail"] = "Enter your email"
            }else if(!isValidEmail(value)){
                allState["errEmail"] = "Invailid email."
            }else{
                allState["errEmail"] = ""
            }
        }
       if(name === "mobile"){
           if(!value.length){
               allState["errMobileno"] = "Mobile no is required."
           }else if(!isValidMobileNo(value)){
               allState["errMobileno"] = "Enter a valid number."
           }else{
               allState["errMobileno"] = ""
           }
       }
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
    }
    
    const checkVailidation = () => {
        hasError = false;
        let allState = errors;
        
        if(!fullName.length){
            allState["errfullName"] = "Enter your full name"
            hasError = true;
        }else if(fullName.length < 3){
            allState["errfullName"] = "Name length should be 3 charector"
            hasError = true;
        }else{
            allState["errfullName"] = ""
        }
        if(!email.length){
            allState["errEmail"] = "Enter your email"
            hasError = true
        }else if(!isValidEmail(email)){
            allState["errEmail"] = "Invailid email."
            hasError = true
        }else{
            allState["errEmail"] = ""
        }
        if(!mobile.length){
            allState["errMobileno"] = "Mobile no is required."
            hasError = true
        }else if(!isValidMobileNo(mobile)){
            allState["errMobileno"] = "Enter a valid number."
            hasError = true
        }else{
            allState["errMobileno"] = ""
        }
    
        setErrors(prevError => ({
            ...prevError,
            ...allState
        }))
        return hasError
    }
    
    const handelFormSubmit = (e) => {
        e.preventDefault()
        if(!checkVailidation()){
            let data = {
                fullName: fullName,
                email: email,
                mobile: mobile,
                stdcode: stdcode
            }

        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wrapper contact-us'>

            <div className='banner-div'>
                <div className='container'>
                    <h1>Contact Us</h1>
                </div>
            </div>

            <div className="container">

                <section className="all">
                    <div className="container">

                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumb-item active">Contact Us</li>
                            </ol>
                        </nav>

                        <div className="main">
                            <section className='services'>
                                <div className='details'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <ImLocation size={45} className="icon" />
                                            <h6>Visit Our Office</h6>
                                            <p>Shop No. 10, Rizvi Mahal, R K Patkar Marg Water Field Road, Next to Bhabha Hospital, Bandra (W), Mumbai 400 050.</p>
                                        </div>
                                    </div>

                                    <div className='card'>
                                        <div className='card-body'>
                                            <ImMobile size={45} className="icon" />
                                            <h6>Let's Talk</h6>
                                            <p>
                                                <Link to={'tel:917770007841'}>+91 77700 07841</Link>, <Link to={'tel:918291890911'}>+91 82918 90911</Link> <Link  to={'tel:02235080525'}>022-35080525</Link>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='card'>
                                        <div className='card-body'>
                                            <HiMail size={45} className="icon" />
                                            <h6>E-mail Us</h6>
                                            <p>
                                                <Link to={'mailto:enquiry@summera.in'}>enquiry@summera.in</Link>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='card'>
                                        <div className='card-body'>
                                            <img src={VisitOurStore} alt="Visit Our Store" title="Visit Our Store" />
                                            <h6>Visit Our Store</h6>
                                            <Link className="btn btn-secondary" to={'/'}>VIEW ALL STORES</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='payment'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='qr-code'>
                                                <img src={QRCode} alt='QR Code' title='QR Code' />
                                            </div>
                                            <div className='bank-details'>
                                                <ul>
                                                    <li><h6>BANK NAME</h6> <span>ICICI BANK</span></li>
                                                    <li><h6>ACCOUNT TYPE</h6> <span>Current Account</span></li>
                                                    <li><h6>ACCOUNT NAME</h6> <span>SUMMERA JEWELS PRIVATE LIMITED</span></li>
                                                    <li><h6>ACCOUNT NO.</h6> <span>003805013098</span></li>
                                                    <li><h6>IFSC CODE</h6> <span>ICIC0000038</span></li>
                                                    <li><h6>BRANCH ADDRESS</h6> <span>Plot No. 206 221, 27th Rd., Crystal Building, Waterfield Road, Bandra W, Mumbai, Maharashtra - 400050</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className='get-in-touch'>
                                <h5>Get in Touch</h5>
                                <p>Leave us your info and we will get back to you.</p>

                                <div className="contact">
                                    <div className='left'>
                                        <div className='contact-details'>
                                            <div className='topheading'>
                                                <h6>* Mandatory Fields</h6>
                                            </div>
                                            <form action="#">
                                                <ul className='form-row'>
                                                    <li className='form-group'>
                                                        <label>Full Name*</label>
                                                        <TextInput
                                                            type={"text"}
                                                            name={"fullName"}
                                                            placeholder={"Enter First and Last Name"}
                                                            inputClass={"form-control"}
                                                            lblClass={"input-lable"}
                                                            onChange={handleStateChange}
                                                            onBlur={handleError}
                                                            label={"Your Name *"}
                                                            value={fullName}
                                                            autoComplete="off"
                                                            errorMSg={errors.errfullName}
                                                        />
                                                    </li>

                                                    <li className="form-group">
                                                        <label>Email ID*</label>
                                                        <TextInput
                                                            type={"text"}
                                                            name={"email"}
                                                            inputClass={"form-control"}
                                                            lblClass={"input-lable"}
                                                            onChange={handleStateChange}
                                                            onBlur={handleError}
                                                            label={"Email ID*"}
                                                            value={email}
                                                            autoComplete="off"
                                                            errorMSg={errors.errEmail}
                                                        />
                                                    </li>

                                                    <li className="form-group mobile">
                                                        <label>Mobile *</label>
                                                        <div className="cust-select">
                                                        <select 
                                                            required="required" 
                                                            className="form-control form-select"
                                                            value={stdcode}
                                                            onChange={(e) =>handleStateChange(e)}
                                                            name="stdcode" disabled>
                                                            <option value="">Select</option>
                                                            {
                                                            <option value={stdcode}>{stdcode}</option>
                                                            }
                                                        </select>
                                                        <TextInput
                                                            type={"tel"}
                                                            value={mobile}
                                                            name={"mobile"}
                                                            placeholder={""}
                                                            inputClass={"form-control"}
                                                            onChange={handleStateChange}
                                                            onBlur={handleError}
                                                            autoComplete="off"
                                                            errorMSg={errors.errMobileno}
                                                        />
                                                        
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className="form-group textarea">
                                                            <label htmlFor="message">Message</label>
                                                            <textarea rows="4" name="message" />
                                                        </div>
                                                    </li>
                                                </ul>

                                                <div className="make-info">
                                                    <Link to={'/video-call-thank-you'}>
                                                        <button className="btn btn-primary"type="submit" onClick={handelFormSubmit}><span>Submit</span></button>
                                                    </Link>
                                                </div>
                                            </form>
                                        
                                        </div>
                                        
                                    </div>
                                    <div className="right">

                                    <div className='map'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1333087978!2d72.83142701538414!3d19.05787555749325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c91003d606cd%3A0xcc8d562c06b226e0!2sSUMMERA%20JEWELS!5e0!3m2!1sen!2sin!4v1678950795584!5m2!1sen!2sin" loading="lazy" />
                                    </div>

                                    </div>
                                </div>

                            </section>
                        </div>

                    </div>
                </section>

            </div>
        </div>
    )
}

export default Contact;