import React, { useState } from "react";
import { FaSearch } from 'react-icons/fa';

// Search Auto Complete data 
const SearchDatas = [
    { id: 1, valuename: "Gold", linkto: "#" },
    { id: 2, valuename: "Solitaire", linkto: "#" },
    { id: 3, valuename: "Diamond", linkto: "#" },
    { id: 4, valuename: "Gemstones", linkto: "#" },
    { id: 5, valuename: "Platinum", linkto: "#" },
    { id: 6, valuename: "Gold", linkto: "#" },
    { id: 7, valuename: "Solitaire", linkto: "#" },
    { id: 8, valuename: "Diamond", linkto: "#" },
    { id: 9, valuename: "Gemstones", linkto: "#" },
    { id: 10, valuename: "Platinum", linkto: "#" }
]

const AutoSearchComplete = () => {
    const [SearchValData, setSearchValData] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState("");


    const handleSearchInput = (e) => {
        const searchInput = e.target.value.toLowerCase();
        const filterInput = SearchDatas.filter(itemName => itemName.valuename.toLowerCase().includes(searchInput))
        if (searchInput) {
            setSearchValData(filterInput);
            setShowResult(true);
            handleNoResult(filterInput);
        } else {
            setSearchValData(null); 
            setShowResult(false);
            handleNoResult(null);
        }

    }

    const handleNoResult = (item) => {
        if (item === null) {
            setResult(null)
        } else if (!item.length) {
            setResult("No Result")
        } else {
            setResult(null)
        }
    }

    return (
        <div className="auto-search">
            <div className="search-input">
                <input
                    type="text"
                    placeholder="What are you looking for..."
                    className="search-input"
                    onChange={(e) => handleSearchInput(e)}
                />
                <div className="search-icon"><FaSearch /></div>
            </div>
            {showResult && SearchValData.length ?
                <div className="result-dropdown">
                    <ul className={`${showResult ? "addHeight" : " "}`}>
                        {
                            SearchValData?.map((item, i) => {
                                return <li key={i}><a href={item.linkto}>{item.valuename}</a></li>
                            })
                        }

                    </ul>
                </div> :
                <div className="result-dropdown result"><ul><li className={`search-noresult `}>{result}</li></ul></div>
            }
        </div>
    )
}

export default AutoSearchComplete;